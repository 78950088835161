import { css } from '@emotion/react';
import { theme } from 'global';

export const paper = css`
  border-radius: ${theme.radii.lg};

  & > div {
    padding: 32px;

    @media screen and (max-width: ${theme.breaks.xs}px) {
      padding: 24px 16px;
    }
  }

  &:not(last-of-type) {
    margin-bottom: 16px;
  }
`;

export const zipAccordion = css`
  & > .MuiAccordion-root {
    border-radius: ${theme.radii.lg};

    & > .MuiAccordionSummary-root {
      &.Mui-focused,
      .Mui-focusVisible {
        background-color: inherit;
      }

      .MuiAccordionSummary-content {
        margin: 0px;
        > h3.heading1 {
          > p.body2 {
            max-width: ${theme.maxTextWidth};
          }
        }
      }

      .MuiAccordionSummary-expandIcon {
        margin: 0px -12px 0px 0px;
      }
    }

    &
      .MuiCollapse-root
      > .MuiCollapse-wrapper
      > .MuiCollapse-wrapperInner
      > div
      > .MuiAccordionDetails-root {
      padding: 32px 0px 0px;
      display: block;
    }
  }
`;
