export enum QuickActionType {
  CreateOrder = 'CreateOrder',
  InviteCustomer = 'InviteCustomer',
  SearchOrders = 'SearchOrders',
  SearchCustomers = 'SearchCustomers',
  Settings = 'Settings',
  CreditLimitIncreaseInvite = 'CreditLimitIncreaseInvite',
  RepaymentCalculator = 'RepaymentCalculator',
  DisbursementReports = 'DisbursementReports',
  ManageUsers = 'ManageUsers',
}
