/* eslint-disable camelcase */
/** @jsxImportSource @emotion/react */
import createAuth0Client, {
  Auth0Client,
  IdToken,
  User,
} from '@auth0/auth0-spa-js';
import { LocalStorageKey, PageAction, PageRoute } from 'enums';
import { Constants } from 'global';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { hasAuth0Error, logError } from 'utils';

interface Auth0ProviderProps {
  children: React.ReactNode;
  domain: string;
  clientId: string;
  redirectUri: string;
}

interface Auth0ContextProps {
  isAuthenticated: boolean;
  user: unknown;
  accessToken: string;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: ({}: unknown) => unknown;
  handleRedirectCallback: ({}: unknown) => unknown;
  getIdTokenClaims: ({}: unknown) => unknown;
  loginWithRedirect: ({}: unknown) => unknown;
  getTokenSilently: ({}: unknown) => Promise<string>;
  getTokenWithPopup: ({}: unknown) => Promise<string>;
  logout: () => void;
}

export const Auth0Context = React.createContext<Auth0ContextProps>(null);
export const useAuth0 = (): Auth0ContextProps => React.useContext(Auth0Context);

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({
  children,
  domain,
  clientId,
  redirectUri,
}) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>();
  const [user, setUser] = React.useState<User>();
  const [accessToken, setAccessToken] = React.useState<string>();
  const [auth0Client, setAuth0] = React.useState<Auth0Client>();
  const [loading, setLoading] = React.useState(true);
  const [popupOpen, setPopupOpen] = React.useState(false);

  const onRedirectCallback = (): void => {
    const referringUrl =
      localStorage.getItem(LocalStorageKey.ReferringUrl) ?? PageRoute.Home;
    window.location.replace(referringUrl);
    localStorage.removeItem(LocalStorageKey.ReferringUrl);
  };

  const setCredentials = async (): Promise<void> => {
    const token = await auth0Client.getTokenSilently({});
    setAccessToken(token);
    const auth0User = await auth0Client.getUser({
      scope: `${process.env.REACT_APP_AUTH0_SCOPE}`,
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    });
    setUser(auth0User);
  };

  React.useEffect(() => {
    const initAuth0 = async (): Promise<void> => {
      const auth0FromHook = await createAuth0Client({
        domain,
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: `${process.env.REACT_APP_AUTH0_SCOPE}`,
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      });
      setAuth0(auth0FromHook);

      const passwordHasExpired = hasAuth0Error(
        Constants.errors.passwordHasExpired
      );
      const suspiciousLogin = hasAuth0Error(Constants.errors.suspiciousLogin);

      if (
        window.location.search.includes('code=') &&
        !passwordHasExpired &&
        !suspiciousLogin
      ) {
        await auth0FromHook.handleRedirectCallback();
        onRedirectCallback();
      }

      const isAuthed =
        passwordHasExpired || suspiciousLogin
          ? false
          : await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthed);

      setLoading(false);
    };

    initAuth0();
  }, []);

  React.useEffect(() => {
    if (!loading && isAuthenticated) {
      setCredentials();
    }
  }, [loading, isAuthenticated]);

  const loginWithPopup = async (params = {}): Promise<void> => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (err) {
      logError(PageAction.auth0Login, err, { params });
    } finally {
      setPopupOpen(false);
    }
    setUser(
      await auth0Client?.getUser({
        scope: `${process.env.REACT_APP_AUTH0_SCOPE}`,
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      })
    );
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async (): Promise<void> => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    onRedirectCallback();
    setLoading(false);
    setIsAuthenticated(true);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        accessToken,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p): Promise<IdToken> =>
          auth0Client?.getIdTokenClaims({
            scope: `${process.env.REACT_APP_AUTH0_SCOPE}`,
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
            ...p,
          }),
        loginWithRedirect: (...p): void => {
          localStorage.setItem(
            LocalStorageKey.ReferringUrl,
            window.location.pathname
          );
          auth0Client?.loginWithRedirect(...p);
        },
        getTokenSilently: (...p): Promise<string> =>
          auth0Client?.getTokenSilently({
            ...p,
            scope: `${process.env.REACT_APP_AUTH0_SCOPE}`,
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          }),
        getTokenWithPopup: (...p): Promise<string> =>
          auth0Client?.getTokenWithPopup(...p),
        logout: (): void | Promise<void> =>
          auth0Client?.logout({
            returnTo: window.location.origin,
          }),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

interface RequireUserProps {
  children: React.ReactNode;
}

export const requireUser = (page: React.FC): React.FC<RequireUserProps> => {
  return (props: unknown): React.FunctionComponentElement<unknown> => {
    const history = useHistory();
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

    React.useEffect(() => {
      if (loading || isAuthenticated) {
        return;
      }

      const fn = async (): Promise<void> => {
        await loginWithRedirect({
          appState: { targetUrl: history.location.pathname },
        });
      };
      fn();
    }, [
      loading,
      isAuthenticated,
      loginWithRedirect,
      history.location.pathname,
    ]);

    if (loading || !isAuthenticated) {
      return null;
    }

    return React.createElement(page, props);
  };
};
