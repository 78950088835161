/** @jsxImportSource @emotion/react */
import { Spinner } from '@zip/business-components';
import { BasePage, Campaign, Divider } from 'components';
import { useMerchantData } from 'contexts';
import { PageAction } from 'enums';
import { Constants, css, isSandbox } from 'global';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { ZipCampaign } from 'types';
import { logError, logEvent } from 'utils';

import * as styles from './CampaignsPage.styles';

const CampaignsPage: FC = () => {
  const { get } = useFetch();
  const { isMerchantDisabled } = useMerchantData();
  const [campaigns, setCampaigns] = useState<ZipCampaign[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(!isSandbox);
  const abortController = new AbortController();

  const getCampaigns = async (): Promise<void> => {
    setIsLoading(true);
    logEvent(PageAction.fetchZipCampaigns);
    await get('/marketing/campaigns', abortController.signal)
      .then((res) => (res.status === 200 ? res?.json() : []))
      .then((json) => {
        setIsLoading(false);
        setCampaigns(json?.campaigns as ZipCampaign[]);
      })
      .catch((err) => {
        logError(PageAction.fetchZipCampaigns, err);
        setIsLoading(false);
        setCampaigns([]);
      });
  };

  useEffect(() => {
    if (!isMerchantDisabled && !isSandbox) {
      getCampaigns();
    }

    return (): void => abortController.abort();
  }, [isMerchantDisabled]);

  return (
    <BasePage
      title="Zip campaigns"
      fullWidth
      sandboxAlert="Submitting a campaign will be available once your application is approved"
    >
      {isMerchantDisabled && (
        <>
          <p css={css.noTopMrgn}>
            Your account is currently <strong>disabled</strong> and not able to
            submit offers for our campaigns.
          </p>
          <p css={css.noBtmMrgn}>
            Please contact our support team for further assistance.
          </p>
        </>
      )}
      {!isMerchantDisabled && (
        <>
          <p css={[styles.description, css.noTopMrgn]}>
            Increase your sales by participating in Zip Campaigns. We’ve got
            exciting campaigns lined up to serve over 2 million Zip customers
            and we want you to be part of the action.{' '}
            <a
              href={Constants.helpArticles.campaigns}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </p>
          <Divider />
          <div id="campaign-container">
            {isLoading && <Spinner />}
            {!isLoading &&
              campaigns?.map((campaign, i) => (
                <Campaign key={i} campaign={campaign} />
              ))}
          </div>
        </>
      )}
    </BasePage>
  );
};

export default CampaignsPage;
