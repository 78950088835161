/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/** @jsxImportSource @emotion/react */
import { lighten } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Buttons, TextFields } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import parse from 'autosuggest-highlight/parse';
import { Country } from 'enums';
import { theme } from 'global';
import throttle from 'lodash/throttle';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Address } from 'types';
import { formatOneLineAddress } from 'utils';
import AddressManualDialog from '../address-manual';
import * as styles from './AddressLookup.styles';
import { AddressLookupProps } from './AddressLookupProps';

function loadScript(
  src: string,
  position: HTMLElement | null,
  id: string
): void {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

type AutocompleteService = google.maps.places.AutocompleteService;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type PlacesService = google.maps.places.PlacesService;
type PlaceResult = google.maps.places.PlaceResult;

let autocompleteService: { current: AutocompleteService };
let placesService: { current: PlacesService };

const useStyles = makeStyles(() => ({
  icon: {
    color: theme.colors.neutral[300],
    marginRight: 8,
  },
  paper: {
    border: `solid 1px ${lighten(theme.colors.neutral[300], 0.8)}`,
    borderRadius: '4px',
    boxShadow: `0px 0px 4px 1px ${lighten(theme.colors.black, 0.85)}`,
  },
}));

const AddressLookup: FC<AddressLookupProps> = ({
  onBlur,
  value,
  error,
  handleChange,
  name,
}) => {
  const classes = useStyles();
  const [autoCompleteValue, setAutoCompleteValue] =
    useState<AutocompletePrediction>({
      description: value,
      matched_substrings: [],
      place_id: '',
      structured_formatting: null,
      terms: [],
      types: [],
    });
  const [inputValue, setInputValue] = useState<string>(value);
  const [options, setOptions] = useState<AutocompletePrediction[]>([]);
  const loaded = useRef(false);

  const [address, setAddress] = useState<Address>();
  const [manualEntryOpen, setManualEntryOpen] = useState<boolean>(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetchPlacePredictions = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: AutocompletePrediction[]) => void
        ) => {
          autocompleteService.current.getPlacePredictions(
            {
              ...request,
              componentRestrictions: {
                country: [Country.Australia, Country.NewZealand],
              },
            },
            callback
          );
        },
        200
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService?.current && (window as any).google) {
      autocompleteService = {
        current: new (window as any).google.maps.places.AutocompleteService(),
      };
      placesService = {
        current: new (window as any).google.maps.places.PlacesService(
          document
            .getElementById('google-places-autocomplete')
            .appendChild(document.createElement('div'))
        ),
      };
    }
    if (!autocompleteService?.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(autoCompleteValue ? [autoCompleteValue] : []);
      return undefined;
    }
    fetchPlacePredictions(
      { input: inputValue },
      (results?: AutocompletePrediction[]) => {
        if (active) {
          let newOptions = [] as AutocompletePrediction[];

          if (autoCompleteValue) {
            newOptions = [autoCompleteValue];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return (): void => {
      active = false;
    };
  }, [autoCompleteValue, inputValue, fetchPlacePredictions]);

  function manualAddressEntry(data): void {
    const newAddress = formatOneLineAddress(data);
    setAddress(data);

    setAutoCompleteValue({
      description: newAddress,
      structured_formatting: {
        main_text: `${data.unitNumber ? `${data.unitNumber}/` : ''}${
          data.streetNumber
        } ${data.streetName}`,
        secondary_text: `${data.suburb} ${data.state} ${data.postcode}, ${data.country}`,
        main_text_matched_substrings: [
          {
            offset: 0,
            length: 0,
          },
        ],
      },
      matched_substrings: [],
      place_id: '',
      terms: [],
      types: [],
    });

    setInputValue(newAddress);
    handleChange(null, data);
  }

  function parseGoogleAttribute(attribute: string): string {
    let output;
    switch (attribute) {
      case 'subpremise':
        output = 'unitNumber';
        break;

      case 'street_number':
        output = 'streetNumber';
        break;

      case 'route':
        output = 'streetName';
        break;
      case 'locality':
        output = 'suburb';
        break;

      case 'postal_code':
        output = 'postcode';
        break;
      case 'administrative_area_level_1':
        output = 'state';
        break;
      default:
        output = attribute;
        break;
    }
    return output;
  }

  const handleAutoCompleteChange = async (event, newValue): Promise<void> => {
    if (newValue) {
      const newPlace = newValue as AutocompletePrediction;

      placesService.current.getDetails(
        {
          placeId: newPlace.place_id,
          fields: ['address_components'],
        },
        (details: PlaceResult) => {
          let parsedAddress = {};
          details.address_components?.forEach((component) => {
            if (component.types[0] !== 'administrative_area_level_2') {
              const attribute = parseGoogleAttribute(component.types[0]);
              parsedAddress = {
                ...parsedAddress,
                [attribute]:
                  component.types[0] === 'administrative_area_level_1'
                    ? component.short_name
                    : component.long_name,
              };
            }
          });
          const newAddress = Object.assign({}, parsedAddress) as Address;
          setAddress(newAddress);
          handleChange(null, newAddress);
        }
      );
      setAutoCompleteValue(newPlace);
      setOptions(newValue ? [newPlace, ...options] : options);
    } else {
      setAutoCompleteValue(newValue as AutocompletePrediction);
      setOptions(newValue ? [...options] : options);
    }
  };

  return (
    <>
      <Autocomplete
        id="google-places-autocomplete"
        getOptionLabel={(option): string =>
          typeof option === 'string' ? option : option.description ?? ''
        }
        filterOptions={(x): AutocompletePrediction[] => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={autoCompleteValue}
        forcePopupIcon={false}
        freeSolo
        onChange={handleAutoCompleteChange}
        onInputChange={(event, newInputValue): void =>
          setInputValue(newInputValue)
        }
        classes={{ paper: classes.paper }}
        renderInput={(params): JSX.Element => (
          <TextFields.Outlined
            {...params}
            name={name}
            label="Residential address"
            css={styles.helperText}
            className="fs-mask"
            variant="outlined"
            fullWidth
            error={error}
            onBlur={(focus): void => {
              if (!focus.target.value) {
                handleChange(null, null);
              }
              onBlur(focus);
            }}
            helperText={
              <>
                Search address or{' '}
                <Buttons.Text
                  css={styles.link}
                  onClick={(): void => setManualEntryOpen(true)}
                >
                  enter address manually
                </Buttons.Text>
              </>
            }
            value={value}
          />
        )}
        renderOption={(option: AutocompletePrediction): JSX.Element => {
          if (option.structured_formatting ?? false) {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [
                match.offset,
                match.offset + match.length,
              ])
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <Icons.MapPin className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }

          return <></>;
        }}
      />
      <AddressManualDialog
        open={manualEntryOpen}
        address={address}
        toggleOpen={setManualEntryOpen}
        onSuccess={(data): void => manualAddressEntry(data)}
      />
    </>
  );
};

export default AddressLookup;
