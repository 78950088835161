export enum OperationRequestStatus {
  New = 'New',
  Processed = 'Processed',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Declined = 'Declined',
  Expired = 'Expired',
  Approved = 'Approved',
  Completed = 'Completed',
  Failed = 'Failed',
}
