import { css } from '@emotion/react';

const columnBreak = 1232;

export const moduleContainer = (authAndCapEnabled) => css`
  max-height: ${authAndCapEnabled ? 750 : 650}px;
  width: 100%;
  margin: 0;
  padding: 8px;
  display: flex;

  @media screen and (min-width: ${columnBreak + 1}px) {
    flex-flow: column wrap;
    align-items: flex-start;
  }

  @media screen and (max-width: ${columnBreak}px) {
    max-height: unset;
    flex-flow: row wrap;
  }

  > div.MuiPaper-root {
    flex: 1 1 auto;
    @media screen and (min-width: ${columnBreak + 1}px) {
      width: calc(50% - 8px);
      &:last-of-type {
        margin-left: 16px;
      }
      &:not(:first-of-type):not(:last-of-type) {
        margin-top: 16px;
      }
    }
    @media screen and (max-width: ${columnBreak}px) {
      width: 100%;
      &:not(:first-of-type) {
        margin-top: 16px;
      }
    }
  }
`;
