import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.lg};
  max-width: 420px;
  padding: 8px;

  @media screen and (min-width: ${theme.breaks.sm}px) {
    padding: 32px;
    border-radius: 12px;
    margin: 0px auto;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    width: 100%;
    padding: 16px;
  }
`;

export const actionBar = (flexDirection) => css`
  width: 100%;
  display: flex;
  flex-direction: ${flexDirection ?? 'row'};
  justify-content: space-between;
  padding: 16px 0;
`;

export const textfieldTitle = css`
  display: flex;
  justify-content: space-between;
`;

export const textfield = css`
  width: 100%;

  > .MuiInputBase-root > input[type='number']::-webkit-inner-spin-button,
  > .MuiInputBase-root > input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > .MuiInputBase-root {
    background-color: ${theme.colors.white};
    &.Mui-disabled {
      background-color: transparent;
    }
  }

  > .MuiFormHelperText-root {
    background-color: transparent;
    margin-bottom: -10px;

    &.Mui-error {
      margin-bottom: -20px;
    }
  }
`;

export const dialogContainer = css`
  .MuiDialog-paperWidthSm {
    @media screen and (max-width: ${theme.breaks.xs}px) {
      max-width: none;
    }

    .MuiDialogContent-root {
      margin-right: -40px;
      padding-right: 40px;
    }
  }
`;

export const dialog = css`
  min-width: 325px;
`;

export const titleBar = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ifpTable = css`
  width: 100%;

  > tr > th {
    text-align: left;
  }
`;
