import { FullStorySampleMode, MerchantRoleGuid } from 'enums';

const constants = {
  assets: {
    animation: {
      remindCustomer:
        'https://zip.co/assets/business/animation/remind-customer.gif',
    },
    customerScreens: {
      customerPay: 'https://zip.co/assets/fearless/app/customer-shop.png',
      customerBarcode:
        'https://zip.co/assets/fearless/app/customer-barcode.png',
    },
    pictorials: {
      email: 'https://zip.co/assets/fearless/illustrations/email.svg',
      hooray: 'https://zip.co/assets/fearless/illustrations/hooray.svg',
      success: 'https://zip.co/assets/fearless/illustrations/success.svg',
      driverLicence:
        'https://zip.co/assets/fearless/illustrations/driver-licence.svg',
      driverLicenceHelp:
        'https://zip.co/assets/fearless/illustrations/driver-licence-help.svg',
      passport: 'https://zip.co/assets/fearless/illustrations/passport.svg',
      passportHelp:
        'https://zip.co/assets/fearless/illustrations/passport-help.svg',
    },
    articleImgBaseUrl: 'https://zip.co/assets/business/article-img/',
    illustrations: {
      calculator: 'https://static.zip.co/assets/svg/calculator.svg',
      rocket: 'https://zip.co/assets/fearless/illustrations/rocket.svg',
    },
    logo: {
      dark: 'https://zip.co/assets/fearless/logos/zip/white.svg',
      light: 'https://zip.co/assets/fearless/logos/zip/black.svg',
    },
    favicon: {
      dark: 'https://zip.co/assets/fearless/favicons/dark-square.ico',
      light: 'https://zip.co/assets/fearless/favicons/primary-square.ico',
    },
    productLogos: {
      'Zip Pay': {
        dark: 'https://static.zip.co/logo/zippay/white.svg',
        light: 'https://static.zip.co/logo/zippay/black.svg',
      },
      'Zip Money': {
        dark: 'https://static.zip.co/logo/zipmoney/white.svg',
        light: 'https://static.zip.co/logo/zipmoney/black.svg',
      },
      'Zip Plus': {
        dark: 'https://static.zip.co/logo/zipplus/white.svg',
        light: 'https://static.zip.co/logo/zipplus/black.svg',
      },
    },
  },
  branchId: {
    allBranches: -1,
    webStore: -2,
  },
  config: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    zipAnalytics: (hostname: string): object => ({
      fullStory: {
        enabled: isProduction || isSandbox,
        samplingMethod: FullStorySampleMode.Static,
        staticSampleSize: 75,
        fsLibOptions: {
          orgId: 'ZEQ9T',
          // only record merchant dashboard
          cookieDomain: hostname,
        },
      },
    }),
  },
  cookies: {
    ordUnificationQuickActTooltip: 'md-tooltip-order-unification-quickact-',
    promoBanner: 'md-promo-banner-',
    setupGuideBanner: 'md-promo-banner-setup-guide-',
    setupGuideTooltip: 'md-tooltip-setup-guide-',
    tooltip: 'md-tooltip-',
  },
  developerPortal: {
    testCustomer: 'https://developers.zip.co/v2/docs/test-customers',
  },
  dashboard: {
    production: 'https://merchant.zip.co',
    disbursements: `${process.env.REACT_APP_V1_DASHBOARD_URL}#/reports`,
    posMaterials: `${process.env.REACT_APP_V1_DASHBOARD_URL}#/in-store-advertising`,
    storeCategories: `${process.env.REACT_APP_V1_DASHBOARD_URL}#/store-category`,
    storeProfile: `${process.env.REACT_APP_V1_DASHBOARD_URL}#/store-profile`,
    userManagement: `${process.env.REACT_APP_V1_DASHBOARD_URL}#/userManagement`,
  },
  emails: {
    support: 'hello@partners.zip.co',
    integrations: 'integrations@zip.co',
  },
  errors: {
    abortedSignal: 'ABORT_NOT_ERROR',
    passwordHasExpired: 'CHANGE_PASSWORD_REQUIRED_CHECK_INBOX',
    suspiciousLogin: 'SUSPICIOUS_LOGIN',
    unlinkedUser: 'No UserProfile found to link to.',
    linkingUser: 'LINK_REAUTH_REQUIRED',
  },
  helpCenterBaseUrl: 'https://help.zip.co/hc/en-us',
  helpArticles: {
    campaigns: 'https://help.zip.co/hc/en-us/sections/360000433036-Campaigns',
    expiryFaq:
      'https://help.zip.co/hc/en-us/articles/8477993758863-How-do-Zip-order-expiry-periods-work-',
    orderStatuses:
      'https://help.zip.co/hc/en-us/articles/360001589896-What-does-the-Order-Status-mean-',
    overviewUpdate:
      'https://help.zip.co/hc/en-us/articles/4633928453647-Dashboard-Updates',
  },
  productDefaults: {
    'Zip Money': {
      minimumMonthlyPayment: 40,
      TransactionLimit: 50000,
      monthlyFee: 9.95,
      interestRate: {
        percent: 25.9,
        effectiveDate: '1 June 2023',
      },
      orderExpiryPeriodInMonths: 9,
    },
    'Zip Pay': {
      minimumMonthlyPayment: 40,
      TransactionLimit: 1000,
      monthlyFee: 9.95,
      interestRate: {
        percent: 'interest free always',
        effectiveDate: '1 Jan 2014',
      },
      orderExpiryPeriodInMonths: 3,
    },
    'Zip Plus': {
      minimumMonthlyPayment: 80,
      TransactionLimit: 4000,
      monthlyFee: 9.95,
      interestFreeThreshold: 1500,
      interestRate: {
        percent: 12.95,
        effectiveDate: '1 June 2023',
      },
      orderExpiryPeriodInMonths: 9,
    },
  },
  regex: {
    // named after the hook that generates the content it is designed to replace
    useParam: /(\/consumer\/\d+)|(\/\d+)/,
  },
  setupGuides: {
    dashboardSales:
      'https://help.zip.co/hc/en-us/articles/4408247682191-Placing-an-Instore-Order',
    dashboardRefunds:
      'https://help.zip.co/hc/en-us/articles/4408247783567-Refunding-an-Order',
    ecommerceIntegration: 'https://developers.zip.co/docs',
    posIntegration: 'https://developers.zip.co/v5/docs/getting-started-1',
    trainingResources:
      'https://help.zip.co/hc/en-us/categories/4408239686927-Training-Resources',
    socialMedia: 'https://developers.zip.co/docs/zip-marketing-assets',
    zipForCustomers:
      'https://help.zip.co/hc/en-us/categories/4407853482255-My-Customers',
  },
  storeProfile: {
    factor: 800 / 434,
    canvas: { width: 800, height: 640, displayWidth: 320, displayHeight: 256 },
  },
  formValidation: {
    // 1st Jan 1900
    minBirthDate: new Date(1900, 0, 1),
  },
  expiryDate: {
    // 1st April 2024
    offers: new Date(2024, 4, 1),
    // 1st June 2023
    accountSettings: new Date(2023, 5, 1),
    // 1st August 2023
    orderReminderBanner: new Date(2023, 7, 1),
    // 1st March 2024
    expiresSoonTag: new Date(2024, 3, 1),
  },
  timeframe: {
    /*
      rounded down from google's recommendation of 4.345 to over-estimate repayments
      https://bit.ly/3PBIWyo
    */
    weeksInAMonth: 4.33,
    fortnightsInAMonth: 2.171,
  },
  utmTags: {
    source: 'md-vnext',
  },
  zipInfo: {
    abn: '58 164 440 993',
    creditLicence: '441878',
  },
};

export const collections = {
  roles: {
    adminRoles: [MerchantRoleGuid.Admin, MerchantRoleGuid.AdminV3],
    rolesWithNoBranch: [
      MerchantRoleGuid.Admin,
      MerchantRoleGuid.AdminV3,
      MerchantRoleGuid.ReportingUser,
      MerchantRoleGuid.WebManager,
    ],
    franchiseManageCanManage: [
      MerchantRoleGuid.CustomerSupportFantastic,
      MerchantRoleGuid.MerchantBranchUser,
      MerchantRoleGuid.MerchantBranchUserCrazyDomains,
      MerchantRoleGuid.MerchantBranchUserGlamaCo,
      MerchantRoleGuid.MerchantBranchUserLube,
      MerchantRoleGuid.StoreUser,
      MerchantRoleGuid.StoreUserFantastic,
      MerchantRoleGuid.StoreUserJB,
      MerchantRoleGuid.StoreUserKitchenConnection,
      MerchantRoleGuid.StoreUserKmart,
    ],
    instoreRoles: [
      MerchantRoleGuid.CustomerSupportFantastic,
      MerchantRoleGuid.FranchiseManager,
      MerchantRoleGuid.Manager,
      MerchantRoleGuid.ManagerJB,
      MerchantRoleGuid.MarketingUser,
      MerchantRoleGuid.MerchantBranchManagerCanRefund,
      MerchantRoleGuid.MerchantBranchManagerHoneyBirdette,
      MerchantRoleGuid.MerchantBranchManagerLimitedOrderUpdates,
      MerchantRoleGuid.MerchantBranchManagerTripADeal,
      MerchantRoleGuid.MerchantBranchUser,
      MerchantRoleGuid.MerchantBranchUserCrazyDomains,
      MerchantRoleGuid.MerchantBranchUserGlamaCo,
      MerchantRoleGuid.MerchantBranchUserLube,
      MerchantRoleGuid.StoreUser,
      MerchantRoleGuid.StoreUserFantastic,
      MerchantRoleGuid.StoreUserJB,
      MerchantRoleGuid.StoreUserKitchenConnection,
      MerchantRoleGuid.StoreUserKmart,
    ],
    onlineRoles: [
      MerchantRoleGuid.MerchantWebManager,
      MerchantRoleGuid.MerchantWebManagerCanRefund,
      MerchantRoleGuid.MerchantWebManagerRefundOnly,
      MerchantRoleGuid.MerchantWebUser,
      MerchantRoleGuid.ReportingUser,
      MerchantRoleGuid.WebManager,
    ],
    shouldSeeCreateAndRefundArticle: [
      MerchantRoleGuid.FranchiseManager,
      MerchantRoleGuid.Manager,
      MerchantRoleGuid.ManagerJB,
      MerchantRoleGuid.MerchantBranchManagerCanRefund,
      MerchantRoleGuid.MerchantBranchManagerHoneyBirdette,
      MerchantRoleGuid.MerchantBranchManagerTripADeal,
      MerchantRoleGuid.MerchantWebManager,
      MerchantRoleGuid.MerchantWebManagerCanRefund,
    ],
    shouldSeeCreateOrderArticle: [
      MerchantRoleGuid.CustomerSupportFantastic,
      MerchantRoleGuid.MerchantBranchUser,
      MerchantRoleGuid.MerchantBranchUserCrazyDomains,
      MerchantRoleGuid.MerchantBranchUserGlamaCo,
      MerchantRoleGuid.MerchantBranchUserLube,
      MerchantRoleGuid.MerchantWebUser,
      MerchantRoleGuid.StoreUser,
      MerchantRoleGuid.StoreUserFantastic,
      MerchantRoleGuid.StoreUserJB,
      MerchantRoleGuid.StoreUserKitchenConnection,
      MerchantRoleGuid.StoreUserKmart,
    ],
  },
};

// For use when a feature is not for production use but needs use in other environments
export const isProduction: boolean =
  process.env.REACT_APP_ENVIRONMENT === 'prod';
// For use when a feature should only be accessible to an approved/live merchant
export const isSandbox: boolean = process.env.REACT_APP_ENVIRONMENT === 'sand';

export default constants;
