import { css } from '@emotion/react';

import { theme } from 'global';

export const h4 = css`
  margin: 0 0 24px;
`;

export const formControl = css`
  width: 100%;
`;

export const radioGroup = css`
  margin-top: 24px;
`;

export const radioButtonGrid = css`
  display: flex;
`;

export const radioButton = (selected) => css`
  border: 2px solid
    ${selected ? theme.colors.primary.medium : `rgb(221, 221, 227)`};
  border-radius: ${theme.radii.md};
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  > span {
    color: ${theme.colors.body} !important;
  }
`;

export const customIcon = css`
  font-size: inherit;

  &:hover,
  > .Mui-checked:hover {
    background-color: transparent !important;
  }

  > .MuiIconButton-label {
    flex-direction: column;
  }
`;
