/** @jsxImportSource @emotion/react */
import {
  Buttons,
  Progress,
  Selectable,
  TextFields,
} from '@zip/business-components';
import { BasePage } from 'components';
import { useSnackbar } from 'contexts';
import { PageAction, PageRoute } from 'enums';
import { css } from 'global';
import { InstoreCodeValidator } from 'modules';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CliInviteCustomer, CustomerToCharge, PageProps } from 'types';
import {
  currencyFormatter,
  logError,
  logEvent,
  selectFormatter,
  useCustomerFunctions,
} from 'utils';
import * as styles from './CliInvitePage.styles';

const CliInvitePage: FC<PageProps> = () => {
  const Snackbar = useSnackbar();
  const CustomerFunctions = useCustomerFunctions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cliStep, setCliStep] = useState<number>(1);
  const history = useHistory();
  const [accountLimitsToDisplay, setAccountLimitsToDisplay] = useState<
    Selectable[]
  >([]);
  const [selectedAccountLimit, setSelectedAccountLimit] = useState<string>('');

  const [cliInviteCustomer, setCliInviteCustomer] =
    useState<CliInviteCustomer>();

  async function sendCliInvite(): Promise<void> {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    await CustomerFunctions.sendCliInvite(
      cliInviteCustomer.consumerId,
      Number(selectedAccountLimit)
    )
      .then(() => {
        Snackbar.success('Invite sent successfully');
        history.push(PageRoute.Home);
      })
      .catch((err) => {
        logError(PageAction.createCliInvite, err, [
          { consumerId: cliInviteCustomer.consumerId },
          { selectedAccountLimit },
        ]);
        Snackbar.error(
          'Something went wrong sending the credit limit increase invitation'
        );
      });
    setIsLoading(false);
  }

  function nextStep(): void {
    setCliStep(cliStep + 1);
  }

  function prevStep(): void {
    setCliStep(cliStep - 1);
  }

  const handleCancel = (): void => history.goBack();

  const handleInstoreValidatorResponse = (
    customer: CustomerToCharge | CliInviteCustomer
  ): void => setCliInviteCustomer(customer as CliInviteCustomer);

  useEffect(() => {
    setAccountLimitsToDisplay(
      selectFormatter(
        (cliInviteCustomer?.accountTypes?.length > 0 &&
          cliInviteCustomer?.accountTypes?.map((accountType) => ({
            ...accountType,
            formattedLimit: currencyFormatter(accountType?.limit),
          }))) ||
          [],
        'formattedLimit',
        'id'
      )
    );
  }, [cliInviteCustomer]);

  return (
    <BasePage title="Account limit increase">
      <Progress.Bar currentStep={cliStep} totalSteps={3} />
      {cliStep === 1 && (
        <>
          <h1>Enter customer instore code</h1>
          <p>
            The customer needs to generate the 6 digit instore code from the Zip
            app or web wallet.
          </p>
          <p>Zip Money accounts only.</p>
          <InstoreCodeValidator
            setResponse={handleInstoreValidatorResponse}
            cliInviteCustomer
          />
        </>
      )}
      {cliStep === 2 && (
        <>
          <h1>Choose account limit</h1>
          <p>
            The account limit should cover the sale amount you want to make or
            the customer&apos;s purchase need.
          </p>
          <div>
            <div css={styles.textfieldTitle}>
              <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Account limit</h4>
            </div>
            <TextFields.Select
              id="accountLimitSelect"
              css={styles.textfield}
              options={accountLimitsToDisplay}
              value={selectedAccountLimit}
              onChange={(e): void =>
                setSelectedAccountLimit(e?.target?.value?.toString())
              }
            />
          </div>
        </>
      )}
      {cliStep === 3 && (
        <>
          <h1>Confirm and send invite</h1>
          <h4 css={css.noBtmMrgn}>Customer:</h4>
          <h3 css={css.noTopMrgn} className="fs-mask">
            {`${cliInviteCustomer?.firstName} ${cliInviteCustomer?.lastName}`}
          </h3>
          <h4 css={css.noBtmMrgn}>New account limit requested:</h4>
          <h3 css={css.noTopMrgn}>
            {currencyFormatter(
              cliInviteCustomer?.accountTypes?.find(
                (accountType) =>
                  accountType?.id?.toString() === selectedAccountLimit
              ).limit
            )}
          </h3>
          <p>
            Customer will receive an update in the Zip app or via email to
            continue.
          </p>
        </>
      )}

      <div css={styles.actionBar}>
        {(cliStep === 1 && (
          <Buttons.Text id="cliInviteCancel" onClick={handleCancel}>
            Cancel
          </Buttons.Text>
        )) || (
          <Buttons.Text id="cliInviteBack" onClick={prevStep}>
            Back
          </Buttons.Text>
        )}
        {cliStep < 3 && (
          <Buttons.Primary
            id="cliInviteContinue"
            type="submit"
            disabled={cliStep === 1 && !cliInviteCustomer}
            onClick={nextStep}
          >
            Continue
          </Buttons.Primary>
        )}
        {cliStep === 3 && (
          <Buttons.Primary
            id="cliInviteSubmit"
            type="submit"
            loading={isLoading}
            onClick={(): void => {
              logEvent(PageAction.createCliInvite, [
                { consumerId: cliInviteCustomer.consumerId },
                { selectedAccountLimit },
              ]);
              sendCliInvite();
            }}
          >
            Send invite
          </Buttons.Primary>
        )}
      </div>
    </BasePage>
  );
};

export default CliInvitePage;
