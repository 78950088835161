import { css } from '@emotion/react';

import { theme } from 'global';

export const textfieldTitle = css`
  display: flex;
  justify-content: space-between;
`;

export const textfield = css`
  width: 100%;
`;

export const loadingPrompt = css`
  text-align: center;
  max-width: 250px;
  margin: 8px auto 32px;
`;

export const subsectionHeader = css`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
  color: ${theme.colors.neutral[600]};
`;

export const sectionMrgnBtm = css`
  margin-botton: 32px;
`;

export const copy = css`
  color: ${theme.colors.neutral[500]};
  margin-bottom: 16px;
`;

export const listItem = css`
  display: list-item;
`;

export const listAlpha = css`
  & > li {
    list-style-type: lower-alpha;
  }
`;

export const listRoman = css`
  & > li {
    list-style-type: lower-roman;
  }
`;

export const label = css`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: ${theme.letterSpacings.body1};
  color: ${theme.colors.neutral[600]};
`;

export const table = css`
  margin: 16px 0;
  border-collapse: collapse;
`;

export const td = css`
  padding: 16px 0;

  border-top: 1px solid ${theme.colors.neutral[200]};

  &:first-of-type {
    border-left: 1px solid ${theme.colors.neutral[200]};
    padding: 0 15px;
  }
`;
export const tr = css`
  border: 1px solid ${theme.colors.neutral[200]};

  & > th {
    border-top: 1px solid ${theme.colors.neutral[200]};
    border-bottom: 1px solid ${theme.colors.neutral[200]};
    vertical-align: top;
    padding: 16px;
    font-size: ${theme.fontSizes.body1};
    font-weight: 500;
    letter-spacing: ${theme.letterSpacings.body1};
    line-height: ${theme.lineHeights.body1};
  }
  &:first-of-type td {
    border-top: 1px solid ${theme.colors.neutral[200]};
  }
  &:last-of-type td {
    border-bottom: 1px solid ${theme.colors.neutral[200]};
  }
`;
