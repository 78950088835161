/** @jsxImportSource @emotion/react */
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import { Buttons, Dialogs } from '@zip/business-components';
import { IconDefinition } from '@zip/react-icons/dist/cjs/types';
import * as Icons from '@zip/react-icons/fearless';
import { QuickActionRow } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import {
  MerchantClassSettingType,
  PageAction,
  PermissionEnum,
  QuickActionType,
} from 'enums';
import { useFetch } from 'hooks';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { logError, logEvent } from 'utils';
import * as styles from './QuickActionEditDialog.styles';
import { QuickActionEditDialogModuleProps } from './QuickActionEditDialogModuleProps';

const QuickActionEditDialogModule: FC<QuickActionEditDialogModuleProps> = ({
  onClose,
  open,
  quickActions,
  onSuccess,
}) => {
  const { put } = useFetch();
  const Snackbar = useSnackbar();
  const { checkMerchantDashboardSetting, checkPermission } = useMerchantData();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [quickActionsEditList, setQuickActionsEditList] = useState<
    QuickActionType[]
  >([]);
  const [selectedQuickActions, setSelectedQuickActions] =
    useState<QuickActionType[]>(quickActions);

  const enforcedQuickActions = {
    [QuickActionType.CreateOrder]: checkPermission(
      PermissionEnum.CreateOrderExecute
    ),
    [QuickActionType.CreditLimitIncreaseInvite]:
      checkMerchantDashboardSetting(
        MerchantClassSettingType.CLIInvitationEnabled
      ) && checkPermission(PermissionEnum.CreateCLIInvitationExecute),
    [QuickActionType.RepaymentCalculator]:
      checkMerchantDashboardSetting(
        MerchantClassSettingType.EnableRepaymentCalculator
      ) && checkPermission(PermissionEnum.CreateOrderExecute),
  };

  const handleClose = (e?, ref?): void => onClose(e, ref);

  const handleSubmit = async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const newQuickActions = quickActionsEditList
      .slice()
      .filter((quickAction) => selectedQuickActions?.includes(quickAction));

    // force enforced actions on all users it is enabled for
    Object.keys(enforcedQuickActions).forEach((quickActionType) => {
      if (
        enforcedQuickActions[quickActionType] &&
        !newQuickActions.includes(QuickActionType[quickActionType])
      ) {
        newQuickActions.push(QuickActionType[quickActionType]);
      }
    });

    logEvent(PageAction.updateQuickActions, {
      oldQuickActions: quickActions,
      newQuickActions,
    });
    await put('/userpreference/quickactions', { quickActions: newQuickActions })
      .then((res) => {
        if (!res?.ok) {
          throw new Error('Not ok');
        }
        handleClose();
        onSuccess();
      })
      .catch((err) => {
        logError(PageAction.updateQuickActions, err, newQuickActions);
        Snackbar.error('Something went wrong updating your preferences.');
      });
    setIsLoading(false);
  };

  const handleOnDragEnd = (result): void => {
    const items = Array.from(quickActionsEditList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuickActionsEditList(items);
  };

  const handleChecked = (quickAction, checked): void => {
    const array = selectedQuickActions.slice();
    if (checked) {
      array.push(QuickActionType[quickAction]);
    } else {
      array.splice(
        array.findIndex((selectedType) => selectedType === quickAction),
        1
      );
    }

    setSelectedQuickActions(array);
  };

  useEffect(() => {
    const newQuickActionsEditList = _.uniq([
      ...quickActions,
      ...Object.keys(QuickActionType),
    ]);
    setQuickActionsEditList(
      newQuickActionsEditList.filter((quickActionType) => {
        if (
          // Continue clean up due to OrderUnification deprecating RepaymentCalculator entry
          QuickActionType[quickActionType] ===
          QuickActionType.RepaymentCalculator
        ) {
          return false;
        }
        return true;
      }) as QuickActionType[]
    );
    setSelectedQuickActions(quickActions);
  }, [quickActions]);

  const getQuickActionIcon = (quickAction): FC<IconDefinition> => {
    switch (quickAction) {
      case QuickActionType.CreateOrder:
        return checkPermission(PermissionEnum.CreateOrderExecute) && Icons.Plus;
      case QuickActionType.InviteCustomer:
        return (
          checkPermission(PermissionEnum.CreateInviteExecute) &&
          Icons.Paperplane
        );
      case QuickActionType.SearchOrders:
        return checkPermission(PermissionEnum.OrdersRead) && Icons.Search;
      case QuickActionType.SearchCustomers:
        return checkPermission(PermissionEnum.CustomersRead) && Icons.Search;
      case QuickActionType.CreditLimitIncreaseInvite:
        return (
          checkMerchantDashboardSetting(
            MerchantClassSettingType.CLIInvitationEnabled
          ) &&
          checkPermission(PermissionEnum.CreateCLIInvitationExecute) &&
          Icons.ArrowUp
        );
      case QuickActionType.RepaymentCalculator:
        return (
          checkMerchantDashboardSetting(
            MerchantClassSettingType.EnableRepaymentCalculator
          ) &&
          checkPermission(PermissionEnum.CreateOrderExecute) &&
          Icons.Calculator
        );
      case QuickActionType.DisbursementReports:
        return (
          checkPermission(PermissionEnum.DisbursementsRead) &&
          Icons.CurrencyDollarCircle
        );
      case QuickActionType.ManageUsers:
        return (
          checkPermission(PermissionEnum.UserManagementExecute) &&
          Icons.UserCircle
        );
      default:
        return null;
    }
  };

  return (
    <Dialogs.Basic
      open={open}
      title="Edit quick actions"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            type="submit"
            loading={isLoading}
            onClick={handleSubmit}
            disabled={!selectedQuickActions?.length}
          >
            Save
          </Buttons.Primary>

          <Buttons.Text onClick={handleClose}>Cancel</Buttons.Text>
        </>
      }
      mobileFullscreen
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="quickActionTypes">
          {(provided): JSX.Element => (
            <MuiTable {...provided?.droppableProps} ref={provided?.innerRef}>
              <MuiTableBody>
                {quickActionsEditList.map((quickActionType, index) => (
                  <Draggable
                    key={quickActionType}
                    draggableId={quickActionType}
                    index={index}
                  >
                    {(provided): JSX.Element => {
                      const isEnforced =
                        enforcedQuickActions[QuickActionType[quickActionType]];
                      return (
                        // MuiTableRow has to be on this end or the ref isn't applied
                        <MuiTableRow
                          css={styles.draggableRow}
                          ref={provided?.innerRef}
                          {...provided?.draggableProps}
                          {...provided?.dragHandleProps}
                        >
                          <QuickActionRow
                            Icon={getQuickActionIcon(quickActionType)}
                            quickAction={quickActionType}
                            isChecked={
                              isEnforced ||
                              selectedQuickActions?.includes(
                                QuickActionType[quickActionType]
                              )
                            }
                            disabled={isEnforced}
                            onChange={handleChecked}
                          />
                        </MuiTableRow>
                      );
                    }}
                  </Draggable>
                ))}
              </MuiTableBody>
              {provided?.placeholder}
            </MuiTable>
          )}
        </Droppable>
      </DragDropContext>
    </Dialogs.Basic>
  );
};

export default QuickActionEditDialogModule;
