import { CustomerStatus, OrderStatus } from 'enums';

export function getStatusVariant(status: string): string | null {
  switch (String(status)?.toLowerCase()) {
    case OrderStatus.Authorised:
    case OrderStatus.PartiallyCaptured:
    case OrderStatus.UnderReview:
    case OrderStatus.InProgress:
    case OrderStatus.ContractPending:
    case CustomerStatus.ApplicationInProgress:
    case CustomerStatus.Refer1:
    case CustomerStatus.Verify:
      return 'warning';
    case OrderStatus.Completed:
    case CustomerStatus.Active:
    case CustomerStatus.ApplicationCompleted:
    case CustomerStatus.Approved:
    case CustomerStatus.Registered:
      return 'success';
    case OrderStatus.Cancelled:
    case OrderStatus.Refunded:
    case OrderStatus.Declined:
    case OrderStatus.Expired:
    case CustomerStatus.Cancelled:
    case CustomerStatus.Declined:
      return 'error';
    default:
      return null;
  }
}
