/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxImportSource @emotion/react */
import {
  Alert,
  Buttons,
  Dialogs,
  Progress,
  Slider,
} from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { ProfileCanvas, SimpleUpload } from 'components';
import { useFormik } from 'formik';
import { css, isSandbox, theme } from 'global';
import { FC, useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { StoreTile, StoreTileErrors } from 'types';

import { getCroppedImg, getImageWidthAndHeight } from 'utils';
import * as styles from './StoreTile.styles';
import { StoreTileModuleProps } from './StoreTileModuleProps';

enum StoreTileStep {
  BackgroundImage = 1,
  BackgroundEdit = 2,
  LogoImage = 3,
  LogoEdit = 4,
}

const StoreTileModule: FC<StoreTileModuleProps> = ({
  onClose,
  merchantProfileDetails,
  open,
  onComplete,
}) => {
  const [tileStep, setStoreTileStep] = useState<number>(
    StoreTileStep.BackgroundImage
  );
  const [canContinue, setCanContinue] = useState<boolean>(
    !!merchantProfileDetails?.profileImageUrl
  );

  const [backgroundBlobUrl, setBackgroundBlobUrl] = useState<string>(
    merchantProfileDetails?.profileImageUrl
  );
  const [croppedBackgroundUrl, setCroppedBackgroundUrl] = useState<string>();
  const [backgroundZoom, setBackgroundZoom] = useState(1);
  const [backgroundPoint, setBackgroundPoint] = useState<Point>({ x: 0, y: 0 });

  const [logoBlobUrl, setLogoBlobUrl] = useState<string>(
    merchantProfileDetails?.logoImageUrl
  );
  const [logoZoom, setLogoZoom] = useState<number>(
    Number(merchantProfileDetails?.logoScale ?? 1)
  );

  const handleClose = (e?, ref?): void => {
    onClose(e, ref);
    setStoreTileStep(StoreTileStep.BackgroundImage);
  };

  const handleSubmit = async (): Promise<void> => {
    onComplete({
      newBackgroundBlobUrl: croppedBackgroundUrl,
      newLogoBlobUrl: logoBlobUrl,
      newLogoScale: logoZoom,
    });
    handleClose();
  };

  const handleContinueClick = (): void => {
    function doesNotNeedAction(step): boolean {
      switch (step) {
        case StoreTileStep.BackgroundImage:
          return !!backgroundBlobUrl;
        case StoreTileStep.LogoImage:
          return !!logoBlobUrl;
        default:
          return true;
      }
    }
    if (tileStep === StoreTileStep.LogoEdit) {
      handleSubmit();
      return;
    }
    const nextStep = tileStep + 1;
    setStoreTileStep(nextStep);
    setCanContinue(doesNotNeedAction(nextStep));
  };

  const handleBackClick = (): void => {
    setStoreTileStep(tileStep - 1);
    setLogoZoom(1);
    setCanContinue(true);
  };

  const formik = useFormik({
    initialValues: {
      backgroundImage: [],
      logoImage: [],
    },
    validateOnBlur: false,
    onSubmit: async (): Promise<void> => {
      handleSubmit();
    },
    validate: async (values: StoreTile) => {
      const errors: StoreTileErrors = {};
      let enableContinue;
      const fileSizeLimit = 5242880; // 5MB

      // validate Background
      const bgMinWidth = 800;
      const bgMinHeight = 640;
      if (tileStep <= StoreTileStep.BackgroundEdit) {
        if (values?.backgroundImage && values?.backgroundImage?.length == 0) {
          errors.backgroundImage = 'Required';
          enableContinue = false;
        } else {
          // min values as px value - must match 5/4 aspect ratio
          const backgroundImage = values?.backgroundImage[0];
          const imgDimensions = await getImageWidthAndHeight(backgroundImage);

          if (backgroundImage.size > fileSizeLimit) {
            errors.backgroundImage = 'Maximum file size is 5MB.';
            enableContinue = false;
          } else if (
            imgDimensions.width < bgMinWidth &&
            imgDimensions.height < bgMinHeight
          ) {
            errors.backgroundImage = `Image must be at least ${bgMinWidth}px x ${bgMinHeight}px.`;
            enableContinue = false;
          } else {
            enableContinue = true;
          }
        }
      }

      // validate logo
      if (tileStep >= StoreTileStep.LogoImage) {
        if (values?.logoImage && values?.logoImage?.length == 0) {
          errors.logoImage = 'Required';
          enableContinue = false;
        } else {
          const logoImage = values?.logoImage[0];

          if (logoImage?.size > fileSizeLimit) {
            errors.logoImage = 'Maximum file size is 5MB.';
            enableContinue = false;
          } else if (logoImage?.type != 'image/png') {
            errors.logoImage =
              'Logo image must be a .png to support a transparent background.';
            enableContinue = false;
          } else {
            enableContinue = true;
          }
        }
      }

      setCanContinue(enableContinue);
      return errors;
    },
  });

  const onCropComplete = async (_: Area, pixelCrop: Area): Promise<void> => {
    const croppedSrc = await getCroppedImg(backgroundBlobUrl, pixelCrop);
    setCroppedBackgroundUrl(croppedSrc);
  };

  const onCrop = useCallback((cropPointPixels: Point) => {
    setBackgroundPoint(cropPointPixels);
  }, []);

  useEffect(() => {
    if (!backgroundBlobUrl) {
      return;
    }
  }, [backgroundBlobUrl]);

  useEffect(() => {
    if (
      !!merchantProfileDetails &&
      tileStep === StoreTileStep.BackgroundImage
    ) {
      setCanContinue(!!merchantProfileDetails.profileImageUrl);
    }
  }, [merchantProfileDetails, open]);

  return (
    <Dialogs.Basic
      open={open}
      title="Store tile"
      onClose={handleClose}
      spaceActions
      mobileFullscreen
      actions={
        <>
          <Buttons.Primary
            onClick={handleContinueClick}
            disabled={!canContinue}
          >
            {tileStep === StoreTileStep.LogoEdit ? 'Done' : 'Continue'}
          </Buttons.Primary>
          {tileStep > StoreTileStep.BackgroundImage && (
            <Buttons.Text onClick={handleBackClick}>Back</Buttons.Text>
          )}
        </>
      }
    >
      {isSandbox && (
        <Alert variant="error" css={{ margin: '0px 0px 16px' }}>
          You may create a tile, but will not be able to make a submission until
          your application is approved
        </Alert>
      )}
      <div css={styles.contentContainer}>
        <Progress.Bar currentStep={tileStep} totalSteps={4} />
        {tileStep === StoreTileStep.BackgroundImage && (
          <>
            <p className="heading3">1. Upload your background image</p>
            <ul css={[css.defaultList]}>
              <li>You must own the rights to use this image.</li>
              <li>It must have minimum size of 800 x 640px.</li>
              <li>It must be a .png or .jpg/.jpeg file type.</li>
              <li>It must be less than 5MB.</li>
            </ul>

            <div css={styles.storeTileContainer}>
              <div>
                <ProfileCanvas.Background src={backgroundBlobUrl} />
              </div>
            </div>

            <div css={styles.uploadButtonContainer}>
              <SimpleUpload
                name="backgroundImage"
                label="Upload image"
                handleChange={(value): void => {
                  formik.setFieldValue('backgroundImage', value);
                  setBackgroundBlobUrl(URL.createObjectURL(value[0] ?? null));
                }}
                onBlur={(): void => {
                  formik.setFieldTouched('backgroundImage');
                }}
                error={
                  formik.touched.backgroundImage &&
                  Boolean(formik.errors.backgroundImage)
                }
                helperText={
                  formik.touched.backgroundImage &&
                  formik.errors.backgroundImage &&
                  formik.errors.backgroundImage?.toString()
                }
              />
            </div>
          </>
        )}
        {tileStep === StoreTileStep.BackgroundEdit && (
          <>
            <p className="heading3">2. Resize &amp; crop your image</p>
            <div css={styles.storeTileContainer}>
              <div>
                <Cropper
                  image={backgroundBlobUrl}
                  crop={backgroundPoint}
                  zoom={backgroundZoom}
                  cropShape="rect"
                  aspect={5 / 4}
                  onCropChange={onCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={(zoom): void =>
                    setBackgroundZoom(Number(zoom.toFixed(1)))
                  }
                  style={{
                    containerStyle: {
                      width: 320,
                      height: 256,
                    },
                  }}
                  objectFit="auto-cover"
                />
              </div>
            </div>
            <Slider
              value={backgroundZoom}
              // @ts-ignore - event is handled as (event, newValue: number)
              onChange={(_, value: number): void =>
                setBackgroundZoom(Number(value))
              }
              min={1}
              max={3}
              step={0.1}
              marks={[{ value: 1 }]}
              valueLabelDisplay="auto"
              valueLabelFormat={(label): string => `${label}x`}
            />
          </>
        )}
        {tileStep === StoreTileStep.LogoImage && (
          <>
            <p className="heading3">3. Upload a white version of your logo</p>
            <p className="body2">
              For best results, upload a .png file with transparent background.
              File must not exceed 5MB.
            </p>

            <div css={styles.storeTileContainer}>
              <div>
                <ProfileCanvas.Background src={croppedBackgroundUrl} />
                {logoBlobUrl ? (
                  <ProfileCanvas.Logo
                    src={logoBlobUrl}
                    zoom={Number(merchantProfileDetails?.logoScale ?? 1)}
                  />
                ) : (
                  <Icons.Image fill={theme.colors.white} />
                )}
              </div>
            </div>

            <div css={styles.uploadButtonContainer}>
              <SimpleUpload
                name="logoImage"
                label="Upload logo"
                handleChange={(value): void => {
                  formik.setFieldValue('logoImage', value);
                  setLogoBlobUrl(URL.createObjectURL(value[0] ?? null));
                }}
                onBlur={(): void => {
                  formik.setFieldTouched('logoImage');
                }}
                error={
                  formik.touched.logoImage && Boolean(formik.errors.logoImage)
                }
                helperText={
                  formik.touched.logoImage &&
                  formik.errors.logoImage &&
                  formik.errors.logoImage?.toString()
                }
              />
            </div>
          </>
        )}
        {tileStep === StoreTileStep.LogoEdit && (
          <>
            <p className="heading3">4. Adjust the size of your logo</p>
            <p className="body2">Your logo should be clear and easy to read</p>

            <div css={styles.storeTileContainer}>
              <div>
                <ProfileCanvas.Background src={croppedBackgroundUrl} />
                <ProfileCanvas.Logo src={logoBlobUrl} zoom={logoZoom} />
              </div>
            </div>

            <Slider
              value={logoZoom}
              // @ts-ignore - event is handled as (event, newValue)
              onChange={(e, value): void => setLogoZoom(Number(value))}
              min={0.1}
              max={2}
              step={0.1}
              marks={[{ value: 1 }]}
              valueLabelDisplay="auto"
              valueLabelFormat={(label): string => `${label}x`}
            />
          </>
        )}
      </div>
    </Dialogs.Basic>
  );
};

export default StoreTileModule;
