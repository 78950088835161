/** @jsxImportSource @emotion/react */
import { Buttons } from '@zip/business-components';
import { ContactCard, ContactCardRef, Tooltip } from 'components';
import { PageAction } from 'enums';
import { css } from 'global';
import { FC, useRef, useState } from 'react';
import { ApplicationModuleProps } from 'types';
import { logEvent, useMerchantProfile } from 'utils';
import * as styles from './PrimaryContact.styles';

const PrimaryContactModule: FC<ApplicationModuleProps> = ({ callback }) => {
  const MerchantProfileFunctions = useMerchantProfile();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isContactBeingEdited, setIsContactBeingEdited] =
    useState<boolean>(false);

  const contactData = MerchantProfileFunctions.getContactFromClaims();

  const primaryContactCard = useRef<ContactCardRef>();

  const handleBackButton = (): void => primaryContactCard?.current?.resetForm();

  const handleSubmit = async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    logEvent(PageAction.submitPrimaryContact);
    await callback({
      ...(await primaryContactCard?.current?.getFormValues()),
    });
    setIsLoading(false);
  };

  const setupDetailsTooltip = (
    <>
      Includes:
      <ul css={styles.setupList}>
        <li>E-commerce integration</li>
        <li>Dashboard training</li>
        <li>Tips to boost conversion</li>
      </ul>
    </>
  );

  return (
    <div>
      <h1>Setting up Zip</h1>
      <p>
        On approval, we email{' '}
        <Tooltip
          content={setupDetailsTooltip}
          dense
          css={styles.tooltip}
          maxWidth={200}
          arrow
          interactive
        >
          <>setup details</>
        </Tooltip>{' '}
        to the person in charge of setting up Zip.
      </p>
      <ContactCard
        contactData={contactData}
        setIsFormValid={setIsFormValid}
        setIsLoading={setIsLoading}
        setIsContactBeingEdited={setIsContactBeingEdited}
        ref={primaryContactCard}
      />
      <div css={css.onboardingConfirmButton}>
        <Buttons.Primary
          type="submit"
          disabled={!isFormValid}
          loading={isLoading}
          onClick={handleSubmit}
        >
          {isContactBeingEdited ? 'Save' : 'Confirm'}
        </Buttons.Primary>
        {!isFormValid && (
          <Buttons.Text onClick={handleBackButton}>Cancel</Buttons.Text>
        )}
      </div>
    </div>
  );
};

export default PrimaryContactModule;
