/** @jsxImportSource @emotion/react */
import * as styles from './PhoneInput.styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, { FormEvent, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Buttons } from '@zip/business-components';
import { PhoneInputProps } from './PhoneInputProps';
import { Flag } from './flag';
import { Validators } from 'utils';

const PhoneInput: React.FC<PhoneInputProps> = ({
  placeholder,
  preButtonText,
  postButtonText,
  onSubmit,
}) => {
  const [buttonText, setButtonText] = useState<string>(preButtonText);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (buttonText === postButtonText) {
      const timer = setTimeout(() => {
        setButtonText(preButtonText);
      }, 5000);

      return (): void => clearTimeout(timer);
    }
  }, [buttonText]);

  const handleChange = (event): void => {
    const phoneNumber = event.target.value;
    setValue(phoneNumber);
  };

  const handleBlur = (event): void => {
    const phoneNumber = event.target.value;
    if (Validators.validateAustralianUserMobile(phoneNumber)) {
      setError(false);
      setHelperText('');
    } else {
      setError(true);
      setHelperText('Invalid mobile number');
    }
  };

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    if (!value) {
      setError(true);
      setHelperText('Invalid mobile number');
      return;
    }

    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);
      await onSubmit(value);
      setButtonText(postButtonText);
      setValue('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form css={styles.rootStyle} onSubmit={handleSubmit}>
      <TextField
        id="phone-input"
        css={styles.textFieldStyle}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        error={error}
        helperText={helperText}
        type="tel"
        onBlur={handleBlur}
        onChange={handleChange}
        className="fs-mask"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Flag />
            </InputAdornment>
          ),
        }}
      />
      <Buttons.Primary
        id="phone-input-button"
        aria-label="phone-input-button"
        type="submit"
        style={{
          margin: 0,
          minWidth: 121,
          height: 44,
          borderRadius: '0px 8px 8px 0px',
        }}
        disabled={error}
      >
        {buttonText}
      </Buttons.Primary>
    </form>
  );
};

export default PhoneInput;
