import { css } from '@emotion/react';
import { lighten } from '@material-ui/core';

import { theme } from 'global';
import { noMargin } from 'global/globalCss';

export const paper = (selected, withPadding = true) => css`
  box-shadow: unset;
  padding: ${withPadding ? '16px' : '0'};
  margin: 8px 0;
  white-space: nowrap;
  border-radius: ${theme.radii.lg};
  background-color: ${selected
    ? lighten(theme.colors.primary.medium, 0.92)
    : theme.colors.white};
  cursor: pointer;
  border: 1px solid ${theme.colors.neutral[200]};

  @media screen and (max-width: ${theme.breaks.md}px) {
    background-color: ${theme.colors.white};
  }
`;

export const paperContent = css`
  padding: 16px;
`;

export const date = css`
  font-weight: ${theme.fontWeights.medium};
  font-size: 0.88rem;
  line-height: 1.2857rem;
  margin-bottom: 4px;
  margin-top: 0;
  color: ${theme.colors.neutral[600]};
`;

export const summary = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
  line-height: 1.2em;
`;

export const customerDetails = (isCustomerCard) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${isCustomerCard ? 'space-between' : 'flex-end'};
  overflow: hidden;
  white-space: nowrap;
  color: ${theme.colors.neutral[500]};
  > p {
    margin: 8px 0 0;
  }
  > p:first-of-type {
    margin: 0;
  }
`;

export const orderDetails = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: ${theme.colors.primary.dark};
  margin-left: 8px;
  > p {
    margin: 0px;
  }
`;

export const orderExpiry = css`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
  background-color: ${theme.colors.neutral[50]};

  > p {
    color: ${theme.colors.neutral[500]};

    ${noMargin}
  }
`;

export const label = css`
  margin: 8px auto 0;
`;

export const showMoreIcon = css`
  background-color: ${theme.colors.neutral[50]};
  border-radius: 16px;
  height: 24px;
  width: 24px;
  padding: 5px 6px;
  margin-left: 8px;
`;
