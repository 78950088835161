import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.neutral[0]};
  padding: 16px;
  border-radius: ${theme.radii.lg};
  width: fit-content;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
  width: 100%;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
    min-width: 100%;
  }
`;

export const labelText = (isContentShown) => css`
  font-size: ${theme.fontSizes.body4};
  line-height: ${theme.lineHeights.body4};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${isContentShown ? '8px' : 0};
`;

export const content = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.neutral[200]};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const keyContainer = css`
  max-width: 370px;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin-bottom: 8px;
  }
`;

export const key = css`
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const keyActionsContainer = css`
  display: flex;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
  }
`;

export const keyManagement = css`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin-bottom: 8px;
  }
`;

export const keyStatus = css`
  @media screen and (min-width: ${theme.breaks.xs + 1}px) {
    margin: 0 8px;
  }
  @media screen and (max-width: ${theme.breaks.xs}px) {
    min-width: 120px;
  }
`;

export const actions = css`
  display: flex;
  align-items: stretch;
`;

export const actionIcon = (isClipboardValue) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${isClipboardValue
    ? theme.colors.neutral[500]
    : theme.colors.primary.medium} !important;
  margin-right: 16px;
  font-size: ${theme.fontSizes.body4};
  line-height: ${theme.lineHeights.body4};
  font-weight: ${theme.fontWeights.normal};
  letter-spacing: ${theme.letterSpacings.body4};
  min-width: 43px;

  &:last-of-type {
    margin-right: 0px;
  }

  &:hover {
    text-decoration: ${isClipboardValue ? 'none' : 'underline'};
    cursor: ${isClipboardValue ? 'default' : 'pointer'};
  }
`;

export const iconLabel = css`
  @media screen and (max-width: ${theme.breaks.xs}px) {
    display: none;
  }
`;

export const loading = css`
  width: 30px;
  height: 24px;

  @media screen and (min-width: ${theme.breaks.xs + 1}px) {
    margin: 0 !important;
  }
`;
