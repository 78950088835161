/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper } from '@material-ui/core';
import { Accordion as ZipAccordion } from '@zip/business-components';
import { ReactNodeLike } from 'prop-types';
import { FC } from 'react';
import * as styles from './SetupAccordion.styles';
import { SetupAccordionProps } from './SetupAccordionProps';

const SetupAccordion: FC<SetupAccordionProps> = ({
  summary,
  defaultExpanded,
  children,
}) => {
  const content = {
    summary,
    defaultExpanded,
    details: children as ReactNodeLike,
  };
  return (
    <MuiPaper css={[styles.paper, styles.zipAccordion]} elevation={0}>
      <ZipAccordion content={[content]} />
    </MuiPaper>
  );
};
export default SetupAccordion;
