import { css } from '@emotion/react';
import { theme } from 'global';

export const ownershipInputs = css`
  > div:first-of-type {
    display: flex;
    gap: 16px;
  }
  > div:first-of-type div {
    width: 100%;
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    > div:first-of-type {
      flex-direction: column;
    }

    > div:first-of-type div:nth-of-type(2) {
      display: none;
    }

    > div:nth-of-type(2) {
      flex-direction: column;
      gap: 0px;
    }

    > .MuiFormControl-root:first-of-type {
      margin: 0px 0px 16px;
    }
  }
`;

export const branchDetailsInput = css`
  display: flex;
  flex-direction: row;

  > .MuiButtonBase-root {
    display: block;
    margin-left: 16px;
    margin-top: 18px;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;

    > .MuiButtonBase-root {
      display: block;
      margin-left: 0px;
      width: 50%;
      text-align: center;
    }

    > .MuiSelect-root {
      width: 100%;
    }
  }
`;
export const statusCard = css`
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: ${theme.radii.lg};
  padding: 15px 40px 20px 25px;
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;

  > p {
    margin-bottom: 24px;
  }

  > .MuiButtonBase-root {
    width: 80%;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    > .MuiButtonBase-root {
      width: 60%;
    }
  }
}
`;

export const select = css`
  width: 312px;
  height: 50px;
  radius: 4px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    width: 100%;
  }
`;
