import { css } from '@emotion/react';
import { theme } from 'global';

export const rootStyle = css`
  display: flex;
  align-items: center;
  margin-lef: 9px;
`;

export const textFieldStyle = css`
  margin-right: 0;
  height: 44px;
  background-color: #f8f8f9;

  > .MuiOutlinedInput-root {
    height: 44px;
    width: 240px;
    border-radius: 8px 0px 0px 8px;
    font-family: Sharp Grotesk;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.5px;

    @media screen and (max-width: ${theme.breaks.xs}px) {
      width: auto;
      max-width: 240px;
    }
  }
`;
