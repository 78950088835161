/** @jsxImportSource @emotion/react */
import { useMediaQuery } from '@material-ui/core';
import { breakpoints, Selectable, Spinner } from '@zip/business-components';
import { BasePage, Tabs } from 'components';
import { useMerchantData } from 'contexts';
import { ProfileSettingFormType } from 'enums';
import { useFetch } from 'hooks';
import {
  UpdateBranchDetailsMultipleBranchModule,
  UpdateBranchDetailsSingleBranchModule,
  UpdateBusinessDetailsModule,
} from 'modules';
import { FC, useEffect, useState } from 'react';
import { PageProps } from 'types';
import { filterBranch } from 'utils/filters';

enum TabOption {
  Business = 'business',
  Branch = 'branch',
}

export const AccountPage: FC<PageProps> = () => {
  const { get } = useFetch();
  const { branches } = useMerchantData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availableFormTypes, setAvailableFormTypes] = useState<
    ProfileSettingFormType[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<TabOption>(TabOption.Business);
  const [physicalBranches, setPhysicalBranches] = useState<any[]>(
    filterBranch(branches)
  );
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const tabs: Selectable[] = [
    {
      label: isMobile ? 'Business' : 'Business information',
      value: TabOption.Business,
    },
    {
      label: isMobile ? 'Branch' : 'Branch information',
      value: TabOption.Branch,
    },
  ];

  const canChangeBusinessInformation = availableFormTypes.includes(
    ProfileSettingFormType.ChangeMerchantOwnership ||
      ProfileSettingFormType.UpdateMerchantDetails
  );

  useEffect(() => {
    const controller = new AbortController();

    const fetchFormTypes = async (): Promise<void> => {
      setIsLoading(true);
      const { formTypes } = await get('/form/available-form-types').then(
        (res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error();
          }
        }
      );

      setAvailableFormTypes(formTypes as ProfileSettingFormType[]);
      setIsLoading(false);
    };

    fetchFormTypes();

    // Cancel any fetch requests when page is unloaded
    return (): void => controller.abort();
  }, []);

  useEffect(() => {
    setPhysicalBranches(filterBranch(branches));
  }, [branches]);

  return (
    <BasePage title="Account">
      {isLoading ? (
        <Spinner />
      ) : canChangeBusinessInformation ? (
        <Tabs
          value={selectedTab}
          tabs={tabs}
          content={{
            [TabOption.Business]: <UpdateBusinessDetailsModule />,
            [TabOption.Branch]:
              physicalBranches?.length > 1 ? (
                <UpdateBranchDetailsMultipleBranchModule />
              ) : (
                <UpdateBranchDetailsSingleBranchModule />
              ),
          }}
          onChange={(_, value): void => setSelectedTab(value)}
        />
      ) : physicalBranches?.length > 1 ? (
        <UpdateBranchDetailsMultipleBranchModule />
      ) : (
        <UpdateBranchDetailsSingleBranchModule />
      )}
    </BasePage>
  );
};

export default AccountPage;
