/** @jsxImportSource @emotion/react */
import { Dialogs } from '@zip/business-components';
import { Constants } from 'global';
import { FC } from 'react';
import { BaseDialogProps } from 'types';

import * as styles from './RoleDesriptionDialog.styles';

interface RoleDescription {
  role: string;
  description: string;
}

const RoleDescriptionDialog: FC<BaseDialogProps> = ({ ...props }) => {
  const roleDescriptions: RoleDescription[] = [
    {
      role: 'Store User',
      description: 'Place and view orders for a specified instore branch*',
    },
    {
      role: 'Manager',
      description:
        'Place and view orders for specified instore branch, action refunds*',
    },
    {
      role: 'Web Manager',
      description: 'Place and view orders made online, action refunds*',
    },
    {
      role: 'Marketing User',
      description:
        'Access Zip assets, join marketing campaigns and set up your store visibility',
    },
    {
      role: 'Reporting User',
      description: 'View orders made online and instore, access reporting',
    },
    {
      role: 'Admin',
      description:
        'Access all functions of the dashboard and be authorised to perform account updates.**',
    },
  ];
  return (
    <Dialogs.Basic title="Roles" {...props}>
      <table>
        {roleDescriptions?.map((rd) => (
          <tr css={styles.row}>
            <th css={styles.role}>{rd.role}</th>
            <td css={styles.description}>{rd.description}</td>
          </tr>
        ))}
      </table>
      <p className="body4">
        *A store user and a manager will not be able to review web store orders,
        nor will a web manager be able to review instore orders.
      </p>
      <p className="body4">
        **To create an additional Admin user, email{' '}
        <a href={`mailto:${Constants.emails.support}`}>
          {Constants.emails.support}
        </a>{' '}
        (authorisation must be given by an existing Admin user)
      </p>
    </Dialogs.Basic>
  );
};

export default RoleDescriptionDialog;
