/** @jsxImportSource @emotion/react */
import { Hidden as MuiHidden } from '@material-ui/core';
import {
  Buttons,
  Dialogs,
  Select,
  Selectable,
  TextFields,
} from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { useMerchantData } from 'contexts';
import { css, theme } from 'global';
import { FC, useEffect, useState } from 'react';
import { selectFormatter } from 'utils';
import * as styles from './MerchantSelector.styles';

const MerchantSelector: FC = () => {
  const {
    merchantIdentity,
    merchants,
    branches,
    updateCurrentMerchantId,
    updateCurrentBranchId,
    isUnlinkedUser,
    isLinkingUser,
    needsToReauth,
  } = useMerchantData();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleClick = (): void => setIsDialogOpen(true);

  const handleClose = (): void => setIsDialogOpen(false);

  const sortEntities = (
    array: { id: number; name: string }[]
  ): { id: number; name: string }[] =>
    array?.slice()?.sort((a, b) =>
      // Display `All Branches` and `Web Store` at top, then sort alphabetically
      a.id < 0 || b.id < 0 || a.name?.toLowerCase() > b.name?.toLowerCase()
        ? 1
        : -1
    );

  const [merchantsToDisplay, setMerchantsToDisplay] = useState<Selectable[]>(
    selectFormatter(sortEntities(merchants), 'name', 'id')
  );
  const [branchesToDisplay, setBranchesToDisplay] = useState<Selectable[]>(
    selectFormatter(sortEntities(branches), 'name', 'id')
  );

  const handleMerchantIdChange = (newMerchantId: string): void => {
    updateCurrentBranchId(-1);
    updateCurrentMerchantId(Number(newMerchantId));
  };

  const handleBranchIdChange = (newBranchId: string): void =>
    updateCurrentBranchId(Number(newBranchId));

  useEffect(() => {
    setMerchantsToDisplay(
      selectFormatter(sortEntities(merchants), 'name', 'id')
    );
  }, [merchants]);

  useEffect(() => {
    setBranchesToDisplay(selectFormatter(sortEntities(branches), 'name', 'id'));
  }, [branches]);

  return (
    merchantIdentity?.branchId &&
    !isUnlinkedUser() &&
    !isLinkingUser() &&
    !needsToReauth() && (
      <>
        <MuiHidden smDown>
          <div css={styles.headerDropdowns}>
            {merchantsToDisplay && merchantsToDisplay?.length > 1 && (
              <Select.Ghost
                id="merchant-dropdown"
                options={merchantsToDisplay}
                css={styles.merchantDropdown}
                onChange={(e): void => handleMerchantIdChange(e.target.value)}
                value={merchantIdentity?.merchantId?.toString()}
                fullWidth
              />
            )}
            {branchesToDisplay && branchesToDisplay?.length >= 1 && (
              <Select.Ghost
                id="branch-dropdown"
                options={branchesToDisplay}
                onChange={(e): void => handleBranchIdChange(e.target.value)}
                value={merchantIdentity?.branchId?.toString()}
                disabled={branches?.length === 1}
                fullWidth
              />
            )}
          </div>
        </MuiHidden>
        <MuiHidden mdUp>
          <Icons.Shop
            fill={theme.colors.secondary}
            css={css.icon}
            onClick={handleClick}
          />
        </MuiHidden>
        <Dialogs.Basic
          open={isDialogOpen}
          title="Selector"
          onClose={handleClose}
          actions={
            <Buttons.Primary onClick={handleClose}>Close</Buttons.Primary>
          }
          mobileFullscreen
        >
          {merchantsToDisplay && merchantsToDisplay?.length > 1 && (
            <TextFields.Select
              id="merchant-dropdown"
              label="Select merchant"
              options={merchantsToDisplay}
              onChange={(e): void => handleMerchantIdChange(e.target.value)}
              value={merchantIdentity?.merchantId?.toString()}
              fullWidth
            />
          )}
          {branchesToDisplay && branchesToDisplay?.length >= 1 && (
            <TextFields.Select
              id="branch-dropdown"
              label="Select branch"
              options={branchesToDisplay}
              onChange={(e): void => handleBranchIdChange(e.target.value)}
              value={merchantIdentity?.branchId?.toString()}
              disabled={branches?.length === 1}
              fullWidth
            />
          )}
        </Dialogs.Basic>
      </>
    )
  );
};

export default MerchantSelector;
