import { css } from '@emotion/react';
import { theme } from 'global';

export const card = css`
  border: 1px solid ${theme.colors.neutral[100]};

  @media screen and (max-width: ${theme.breaks.xs}px) {
    box-shadow: 0px 8px 16px rgba(12, 0, 51, 0.1);
    border: unset;
  }
`;

export const heading = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const sendToLabel = css`
  margin-botton: 4px;
`;

export const emailImage = (showEditForm) => css`
  width: 85px;
  margin-bottom: ${showEditForm ? 0 : -56}px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    width: 60px;
    margin-bottom: ${showEditForm ? 0 : -33}px;
  }
`;
