import { css } from '@emotion/react';

import { theme } from 'global';

export const tabs = css`
  min-height: 40px;
  margin-bottom: 32px;
  width: 100%;

  & > .MuiTabs-scrollable {
    height: 40px;
    box-shadow: 0px -1px 0px 0px #7a7a7a40 inset;
  }

  & > .MuiTabs-scroller > .MuiTabs-indicator {
    background-color: ${theme.colors.primary.medium};
  }
`;

export const muiTab = css`
  text-transform: none;
  padding: 4px 0;
  min-width: unset;
  margin-left: 24px;
  min-height: 40px;

  &:first-of-type {
    margin-left: unset;
  }
`;
