/** @jsxImportSource @emotion/react */
import { Alert, Buttons, TextFields } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { PageActions, ProfileCanvas, Tooltip } from 'components';
import { useSnackbar } from 'contexts';
import { PageAction, StoreProfileStatus } from 'enums';
import { useFormik } from 'formik';
import { css, isSandbox, theme } from 'global';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import {
  StoreProfile,
  StoreProfileErrors,
  SubmitStoreProfileCommand,
} from 'types';
import { logError, logEvent } from 'utils';
import { validateProtocol, validateUrl } from 'utils/validators';

import { StoreTileSubmission } from 'modules/store-tile';
import StoreTileModule from 'modules/store-tile/StoreTile.module';
import storeTileExampleImage from 'public/store-tile-example.png';
import * as styles from './StoreProfile.styles';
import { StoreProfileModuleProps } from './StoreProfileModuleProps';

export const StoreProfileModule: FC<StoreProfileModuleProps> = ({
  storeProfile,
  refreshStoreProfile,
}) => {
  const { get, post } = useFetch();
  const Snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStoreTileDialogOpen, setIsStoreTileDialogOpen] =
    useState<boolean>(false);
  const [backgroundBlobUrl, setBackgroundBlobUrl] = useState<string>(
    storeProfile?.merchantProfileDetails?.profileImageUrl
  );
  const [logoBlobUrl, setLogoBlobUrl] = useState<string>(
    storeProfile?.merchantProfileDetails?.logoImageUrl
  );
  const isNewImage = !storeProfile?.merchantProfileDetails?.hasImage;
  const abortController = new AbortController();

  async function getImageUploadUrls(backgroundBlobType: string): Promise<{
    imageLogoUrl: string;
    imageLogoFileName: string;
    backgroundImageUrl: string;
    backgroundFileName: string;
  }> {
    // file formats are hardcoded as they are the specified formats for transparent logo & compressed background
    backgroundBlobType.split('/')[1];
    const response = await get(
      '/storeprofile/upload-url?LogoImageFileFormat=png&BackgroundImageFileFormat=jpeg'
    )
      .catch((err) => {
        if (abortController.signal.aborted) {
          return;
        }
        throw err;
      })
      .then((res) => {
        return res.ok && res?.json();
      })
      .catch((err) => {
        throw err;
      });

    return response;
  }

  async function uploadImages(
    logoBlob: Blob,
    backgroundBlob: Blob
  ): Promise<{ logoFile: File; backgroundFile: File }> {
    const {
      imageLogoUrl,
      imageLogoFileName,
      backgroundImageUrl,
      backgroundFileName,
    } = await getImageUploadUrls(backgroundBlob.type);

    if (!imageLogoUrl || !backgroundImageUrl) {
      throw new Error('No upload URLs provided');
    }

    const backgroundFile = new File([backgroundBlob], backgroundFileName, {
      type: backgroundBlob.type,
    });
    const logoFile = new File([logoBlob], imageLogoFileName, {
      type: logoBlob.type,
    });

    await fetch(imageLogoUrl, {
      body: logoFile,
      method: 'PUT',
      signal: abortController.signal,
    }).catch((err) => {
      throw err;
    });
    await fetch(backgroundImageUrl, {
      body: backgroundFile,
      method: 'PUT',
      signal: abortController.signal,
    }).catch((err) => {
      throw err;
    });

    return { logoFile, backgroundFile };
  }

  async function submitStoreProfile(values: StoreProfile): Promise<void> {
    const needToUpload = Boolean(formik.touched.logoImage);

    try {
      let payload: SubmitStoreProfileCommand = {
        url: values.url,
        aboutUs: values.aboutUs,
        isNewImage,
        // below is overwritten if there is new values
        logoImageName: storeProfile?.merchantProfileDetails?.logoImageName,
        profileImageName:
          storeProfile?.merchantProfileDetails?.profileImageName,
        logoScale: values.logoScale.toString(),
        cropH: values.cropH,
        cropW: values.cropW,
        cropX: values.cropX,
        cropY: values.cropY,
      };

      if (needToUpload) {
        const { logoFile, backgroundFile } = await uploadImages(
          values.logoImage,
          values.backgroundImage
        );

        payload = {
          ...payload,
          logoImageName: logoFile?.name,
          profileImageName: backgroundFile?.name,
        };
      }

      await post('/storeprofile', payload, abortController.signal).then(
        (res) => {
          if (!res.ok) {
            const { url, status } = res;
            throw new Error(`${url} :: ${status}`);
          }
        }
      );
      Snackbar.success('Your store profile has been submitted for review.');
      refreshStoreProfile();
      formik.setFieldTouched('logoImage', false);
    } catch (e) {
      logError(PageAction.submitStoreProfile, e);
      Snackbar.error('Something went wrong submitting your store profile.');
    } finally {
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      url: storeProfile?.merchantProfileDetails?.merchantUrl ?? '',
      aboutUs: storeProfile?.merchantProfileDetails?.aboutUs ?? '',
      backgroundImage: null,
      cropH: storeProfile?.merchantProfileDetails?.cropH,
      cropW: storeProfile?.merchantProfileDetails?.cropW,
      cropX: storeProfile?.merchantProfileDetails?.cropX,
      cropY: storeProfile?.merchantProfileDetails?.cropY,
      logoImage: null,
      logoScale: Number(storeProfile?.merchantProfileDetails?.logoScale) ?? 1,
    },
    onSubmit: async (values) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      logEvent(PageAction.submitStoreProfile);
      await submitStoreProfile(values);
      setIsLoading(false);
    },
    validate: (values: StoreProfile) => {
      const errors: StoreProfileErrors = {};

      if (!values.url) {
        errors.url = 'Required';
      } else if (values.url.length > 255) {
        errors.url = 'URL must not exceed 255 characters';
      } else if (!validateProtocol(values.url)) {
        errors.url = 'URL must start with http:// or https://';
      } else if (!validateUrl(values.url)) {
        errors.url = 'Must be a valid URL';
      }

      if (isNewImage && !values.logoImage) {
        errors.storeTile = 'Store tile must be provided';
      }

      return errors;
    },
  });

  const handleEditClick = (): void => setIsStoreTileDialogOpen(true);

  const handleDialogClose = (): void => setIsStoreTileDialogOpen(false);

  const handleStoreTileComplete = async (
    submission: StoreTileSubmission
  ): Promise<void> => {
    const { newBackgroundBlobUrl, newLogoBlobUrl, newLogoScale } = submission;

    const bgBlob = await fetch(newBackgroundBlobUrl).then((res) => res?.blob());
    const logoBlob = await fetch(newLogoBlobUrl).then((res) => res?.blob());
    formik.setFieldValue('backgroundImage', bgBlob);
    formik.setFieldValue('logoImage', logoBlob);
    formik.setFieldValue('logoScale', newLogoScale);
    // hardcode new submissions to Admin compatible crop sizing
    formik.setFieldValue('cropH', 320);
    formik.setFieldValue('cropW', 400);
    formik.setFieldValue('cropX', 0);
    formik.setFieldValue('cropY', 0);
    formik.setFieldTouched('logoImage');
    setBackgroundBlobUrl(newBackgroundBlobUrl);
    setLogoBlobUrl(newLogoBlobUrl);
  };

  const storeTileExample = (
    <>
      <p css={css.noTopMrgn}>Store tile example</p>
      <img
        src={storeTileExampleImage}
        alt="Example store tile"
        width={140}
        height={112}
      />
    </>
  );

  useEffect(() => {
    if (!storeProfile) {
      return;
    }
    formik.setFieldValue(
      'url',
      storeProfile.merchantProfileDetails.merchantUrl
    );
    formik.setFieldValue(
      'aboutUs',
      storeProfile.merchantProfileDetails.aboutUs
    );
    formik.setFieldTouched('aboutUs');

    setBackgroundBlobUrl(storeProfile?.merchantProfileDetails?.profileImageUrl);
    setLogoBlobUrl(storeProfile?.merchantProfileDetails?.logoImageUrl);
  }, [storeProfile]);

  useEffect(() => {
    (): void => abortController.abort();
  }, []);

  return (
    <>
      {storeProfile?.status === StoreProfileStatus.Approved && (
        <Alert variant="success" css={styles.alert}>
          Your store profile is approved and it will be live after the next
          transaction.
        </Alert>
      )}
      {storeProfile?.status === StoreProfileStatus.Declined && (
        <Alert variant="error" css={styles.profileStatus}>
          Your store profile has been declined.
          <br />
          {storeProfile?.comments}
        </Alert>
      )}
      {storeProfile?.status === StoreProfileStatus.Submitted && (
        <Alert variant="success" css={styles.profileStatus}>
          Store profile has been submitted
        </Alert>
      )}
      {storeProfile?.status !== StoreProfileStatus.Approved && (
        <Alert variant="info" css={styles.alert}>
          Your store profile will be presented on the Zip store directory:
          <ul css={[css.noMargin, css.defaultList]}>
            <li>
              After your store tile is
              {storeProfile?.status === StoreProfileStatus.Submitted
                ? ' approved'
                : ' submitted & approved'}
            </li>
            <li>
              Within 24 hours of your next transaction following tile approval
            </li>
          </ul>
        </Alert>
      )}
      <p className="heading2">Company info</p>
      <form
        onSubmit={async (e): Promise<void> => {
          setIsLoading(true);
          formik.handleSubmit(e);
          setIsLoading(false);
        }}
      >
        <TextFields.Outlined
          type="text"
          name="url"
          label="Store URL"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.url}
          error={formik.touched.url && Boolean(formik.errors.url)}
          helperText={formik.touched.url && formik.errors.url}
          fullWidth
          required
        />
        <TextFields.Outlined
          type="text"
          name="aboutUs"
          label="About us"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.aboutUs}
          error={formik.touched.aboutUs && Boolean(formik.errors.aboutUs)}
          helperText="Add your company description"
          css={styles.textArea}
          multiline
          fullWidth
        />
        <div className="heading2" css={css.flex()}>
          Store tile{' '}
          <Tooltip
            content={storeTileExample}
            style={{ marginLeft: 8 }}
            placement="right-end"
            dense
          >
            <Icons.InformationCircle fill={theme.colors.neutral[500]} />
          </Tooltip>
        </div>
        <p className="body2">
          Your store tile consists of a background image that represents your
          business and your company logo. It will be displayed on the Zip store
          directory.{' '}
          <a
            href="https://help.zip.co/hc/en-us/articles/360002042976-How-do-I-set-up-my-store-tile-"
            target="_blank"
            rel="noreferrer noopenner"
            className="body2"
          >
            Learn more
          </a>
        </p>

        <div css={styles.storeTileContainer}>
          <div>
            {backgroundBlobUrl && (
              <ProfileCanvas.Background src={backgroundBlobUrl} />
            )}
            {logoBlobUrl ? (
              <ProfileCanvas.Logo
                src={logoBlobUrl}
                zoom={Number(formik.values.logoScale)}
              />
            ) : (
              <Icons.Image fill={theme.colors.neutral[100]} />
            )}
          </div>
        </div>

        <Buttons.Secondary onClick={handleEditClick}>
          {isNewImage ? 'Create tile' : 'Edit'}
        </Buttons.Secondary>

        <PageActions>
          <Buttons.Primary
            type="submit"
            disabled={
              isSandbox ||
              Boolean(!Object.keys(formik.touched).length) ||
              Boolean(Object.keys(formik.errors).length) ||
              (formik.values.url ==
                storeProfile?.merchantProfileDetails?.merchantUrl &&
                formik.values.aboutUs ==
                  storeProfile?.merchantProfileDetails?.aboutUs &&
                !Boolean(formik?.touched?.logoImage))
            }
            loading={isLoading}
          >
            {isNewImage ? 'Submit' : 'Update'}
          </Buttons.Primary>
        </PageActions>
      </form>

      {isStoreTileDialogOpen && (
        <StoreTileModule
          open={isStoreTileDialogOpen}
          merchantProfileDetails={storeProfile?.merchantProfileDetails ?? null}
          onClose={handleDialogClose}
          onComplete={handleStoreTileComplete}
        />
      )}
    </>
  );
};
