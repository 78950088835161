import { css } from '@emotion/react';
import { theme } from 'global';

export const moduleContainer = css`
  max-height: 650px;
  width: calc(50% - 12px);
  display: flex;
  margin: 24px 0;

  @media screen and (max-width: ${theme.breaks.md}px) {
    width: 100%;
  }

  > div.MuiPaper-root {
    flex: 1 1 auto;
  }
`;
