import { css } from '@emotion/react';

import { theme } from 'global';

export const cardContainer = css`
  user-select: none;
  @media screen and (max-width: ${theme.breaks.md}px) {
    min-width: 268px;
    margin-left: 8px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    min-width: 144px;
    margin-left: 8px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const card = css`
  height: 93px;
  width: 100%;
  padding: 16px;
  overflow: hidden;
  cursor: pointer;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.neutral[200]};
  > svg {
    background-color: ${theme.colors.primary.lightest};
  }

  &:hover {
    background-color: ${theme.colors.neutral[50]};
  }

  &:focus {
    border: 1px solid ${theme.colors.primary.medium};
    box-shadow: 0px 0px 0px 4px ${theme.colors.primary.medium}26;
  }

  &:active {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.primary.medium};
    box-shadow: unset;
  }

  p {
    color: inherit;
    font-weight: 500;
  }

  @media screen and (max-width: ${theme.breaks.md}px) {
    min-width: 262px;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    min-width: 140px;
    height: 106px;
  }
`;

export const skeletonCard = css`
  ${card}

  &:hover {
    cursor: default;
    background-color: ${theme.colors.white};
  }

  &:focus {
    border: 1px solid ${theme.colors.neutral[200]};
    box-shadow: unset;
  }

  &:active {
    border: 1px solid ${theme.colors.neutral[200]};
    box-shadow: unset;
  }
`;

export const icon = css`
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 25px;
  overflow: visible;
  filter: unset;
`;

export const paragraph = css`
  font-size: 14px;
`;
