/** @jsxImportSource @emotion/react */
import { Buttons, TextFields } from '@zip/business-components';
import { Divider } from 'components';
import { useSnackbar } from 'contexts';
import { PageAction, ProfileSettingFormType } from 'enums';
import { useFormik } from 'formik';
import { useFetch } from 'hooks';
import { FC, useState } from 'react';
import { dateToIso, logError, logEvent, useErrorHandler } from 'utils';
import { isFormikValid, validateEmail } from 'utils/validators';

import * as styles from './UpdateBusinessDetails.styles';

const BusinessDetails: FC = () => {
  const [isSendingOwnershipForm, setIsSendingOwnershipForm] =
    useState<boolean>(false);
  const [hasSentOwnershipForm, setHasSentOwnershipForm] =
    useState<boolean>(false);

  const [isLoadingUpdateForm, setIsLoadingUpdateForm] =
    useState<boolean>(false);
  const [hasOpenedUpdateForm, setHasOpenedUpdateForm] =
    useState<boolean>(false);

  const { get, post } = useFetch();
  const { success, error } = useSnackbar();
  const { handleError } = useErrorHandler();

  const handleUpdateForm = async (): Promise<void> => {
    setIsLoadingUpdateForm(true);
    const url = `/form?type=${ProfileSettingFormType.UpdateMerchantDetails}`;
    const { url: formUrl } = await get(url).then((res) => {
      if (res.status === 200) {
        logEvent(PageAction.openedUpdateMerchantForm, { url });
        return res.json();
      } else {
        handleError(
          'Something went wrong when generating the form',
          PageAction.openedUpdateMerchantForm,
          { url }
        );
        setIsLoadingUpdateForm(false);
      }
    });
    setHasOpenedUpdateForm(true);
    window.open(formUrl, '_blank');
    setIsLoadingUpdateForm(false);
  };

  const handleOwnershipForm = async (): Promise<void> => {
    if (!isFormikValid(formik)) {
      error('Email or date is invalid');
      return;
    }

    setIsSendingOwnershipForm(true);

    const payload = {
      settlementDate: dateToIso(formik.values.date),
      newOwnerEmail: formik.values.email,
    };

    await post('/form/send-change-ownership-form', payload).then((res) => {
      if (res.status === 200) {
        logEvent(PageAction.sendMerchantOwnershipForm, {
          settlementDate: payload.settlementDate,
        });
        setHasSentOwnershipForm(true);
        success(
          'We have sent a form for the new owner to complete. Hang tight!'
        );
      } else {
        const errorMessage =
          'Something went wrong sending the change of ownership form';
        logError(PageAction.sendMerchantOwnershipForm, errorMessage, payload);
        error(errorMessage);
      }
    });

    setIsSendingOwnershipForm(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      date: new Date(),
    },
    onSubmit: () => handleOwnershipForm(),
    validate: (values) => {
      const errors: { email?: string; date?: string } = {};

      if (!values.email) {
        errors.email = 'Email is required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Email is not valid';
      }

      if (!values.date) {
        errors.date = 'Date is required';
      }

      return errors;
    },
  });

  return (
    <div>
      <h3>Business details</h3>
      {hasOpenedUpdateForm ? (
        <>
          <p>
            Once your form has been submitted, please allow 1-2 business days
            for this information to be verified by our team.
          </p>
          <p>Once the request is completed, we'll inform you via email.</p>
          <Buttons.Secondary css={{ marginBottom: 32 }} disabled>
            Updated
          </Buttons.Secondary>
        </>
      ) : (
        <>
          <p css={{ marginBottom: 24 }}>
            Update any recent changes to stay informed and get paid on time,
            including:
          </p>
          <ul css={{ marginBottom: 24 }}>
            <li>ABN</li>
            <li>Business name & director details</li>
            <li>Business address</li>
            <li>Bank settlement details</li>
          </ul>
          <Buttons.Secondary
            css={{ marginBottom: 32 }}
            onClick={handleUpdateForm}
            loading={isLoadingUpdateForm}
            disabled={hasOpenedUpdateForm}
          >
            Update details
          </Buttons.Secondary>
          <p>
            Note: Updating any business information will update all branches and
            websites under the corresponding ABN.
          </p>
        </>
      )}

      <Divider top={32} bottom={32} />

      <h3>Change of ownership</h3>
      {hasSentOwnershipForm ? (
        <>
          <p>
            The form has been forwarded to{' '}
            <strong className="fs-mask">{formik.values.email}</strong> to
            confirm details and transfer account permissions.
          </p>
          <p>
            Don't worry, we won't make any changes to your account information
            before the ownership transition day.
          </p>
        </>
      ) : (
        <>
          <p>
            To transfer ownership of this account, provide the new director's
            email and date of ownership change. We'll send the new director a
            form to collect their details, and transfer account permissions once
            verified.
          </p>
          <p>
            This will be for all branches or websites under the corresponding
            entity.
          </p>
        </>
      )}

      <form onSubmit={formik.handleSubmit}>
        {!hasSentOwnershipForm && (
          <div css={styles.ownershipInputs}>
            <TextFields.Outlined
              name="email"
              label="New director's email"
              className="fs-mask"
              fullWidth
              error={Boolean(formik.touched.email) && !!formik.errors.email}
              helperText={Boolean(formik.touched.email) && formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextFields.Date
              name="date"
              label="First date of new ownership"
              fullWidth
              value={formik.values.date}
              onChange={(value): void => {
                // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
                formik.setFieldValue(
                  'date',
                  value?.format('YYYY-MM-DDT00:00:00') ?? null
                );
              }}
              onBlur={formik.handleBlur}
            />
          </div>
        )}

        <Buttons.Secondary
          type="submit"
          loading={isSendingOwnershipForm}
          disabled={!isFormikValid(formik) || hasSentOwnershipForm}
        >
          {hasSentOwnershipForm ? 'Sent' : 'Send form'}
        </Buttons.Secondary>

        {hasSentOwnershipForm && (
          <p>
            Note: Any orders processed before the form submission is verified
            will be settled under the existing account details.
          </p>
        )}
      </form>
    </div>
  );
};

export default BusinessDetails;
