/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper } from '@material-ui/core';
import { Spinner } from '@zip/business-components';
import { DateFrequency, GraphUnit } from 'enums';
import { css, theme } from 'global';
import _ from 'lodash';
import { FC, ReactNode } from 'react';
import {
  Area as REArea,
  AreaChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie as REPie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RETooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { currencyFormatter, dateFrequencyFormatter, LegendColour } from 'utils';
import { AreaGraphProps, PieGraphProps } from './GraphProps';
import * as styles from './Graphs.styles';

declare type ValueType = number | string | Array<number | string>;
declare type NameValue = number | string;

const graphBuffer = 0.9;

function calculateMinAmount(data): number {
  const values = [];
  data?.forEach((d) => {
    d?.merchantSummaries?.forEach((account) => {
      values.push(account?.value);
    });
  });
  return _.min(values) * graphBuffer;
}

function calculateMaxAmount(data): number {
  const values = [];
  data?.forEach((d) => {
    d?.merchantSummaries?.forEach((account) => {
      values?.push(account?.value);
    });
  });
  return _.max(values) * graphBuffer;
}

function getDataKey(providedData, merchantName: string): string {
  return (
    providedData?.merchantSummaries &&
    providedData?.merchantSummaries[
      _.findKey(providedData?.merchantSummaries, ['name', merchantName])
    ]?.value
  );
}

export const Area: FC<AreaGraphProps> = ({
  data,
  frequency = DateFrequency.Hourly,
  unit = 'number',
  legend = false,
  display,
  loading = false,
  emptyLabel,
  showAllTicks = false,
}) => {
  const getGraphTooltip = (
    props: TooltipProps<ValueType, NameValue>
  ): ReactNode => {
    const { active, payload, label } = props;
    return (
      active &&
      payload &&
      payload.length && (
        <MuiPaper elevation={0} css={styles.tooltip}>
          <p className="body3" css={css.noMargin}>
            {dateFrequencyFormatter(label, frequency)}
          </p>
          <p className="body2" css={css.noMargin}>
            {unit === GraphUnit.currency
              ? currencyFormatter(Number(payload[0].value))
              : payload[0].value}
          </p>
        </MuiPaper>
      )
    );
  };

  return (
    <MuiPaper elevation={0}>
      {loading && (
        <div css={styles.innerContainer}>
          <Spinner />
        </div>
      )}

      {(!data || data.length <= 0) && !loading && !display && (
        <div css={styles.noResults}>
          <p className="body2">{emptyLabel ?? 'No data available.'}</p>
        </div>
      )}

      {data && data.length > 0 && !loading && !display && (
        <div css={styles.innerContainer}>
          <ResponsiveContainer>
            <AreaChart
              width={500}
              height={200}
              data={data}
              margin={{
                top: 16,
                right: 4,
                left: 4,
                bottom: 8,
              }}
            >
              <XAxis
                dataKey="periodStartDateTime"
                tick={{ fill: theme.colors.neutral[600] }}
                ticks={
                  (!showAllTicks || window.innerWidth < theme.breaks.xs) && [
                    data[0].periodStartDateTime,
                    data[data.length - 1].periodStartDateTime,
                  ]
                }
                tickSize={0}
                tickMargin={8}
                axisLine={{
                  stroke: theme.colors.neutral[200],
                  strokeWidth: 1,
                }}
                interval="preserveStartEnd"
                tickFormatter={(timestamp: string): string =>
                  dateFrequencyFormatter(timestamp, frequency)
                }
                fontSize={14}
              />
              <YAxis
                width={0}
                tickLine
                domain={[calculateMinAmount(data), calculateMaxAmount(data)]}
              />
              <CartesianGrid
                strokeDasharray="2"
                stroke={theme.colors.neutral[200]}
                horizontal={false}
              />
              <RETooltip
                animationDuration={500}
                content={getGraphTooltip}
                cursor={{ stroke: theme.colors.primary.medium }}
                wrapperStyle={{ outline: 'unset' }}
              />
              {legend && <Legend />}
              {data &&
                LegendColour.legends.map((legendToMap) => {
                  return (
                    <REArea
                      key={legendToMap.key}
                      type="monotone"
                      dataKey={(graphData): string =>
                        getDataKey(graphData, legendToMap.key)
                      }
                      name={legendToMap.key}
                      stroke={theme.colors.primary.medium}
                      strokeWidth={1}
                      fill={theme.colors.primary.lightest}
                      activeDot={{ r: 5 }}
                      isAnimationActive={false}
                      style={{ padding: '0 8' }}
                      connectNulls
                      fillOpacity={0.3}
                    />
                  );
                })}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </MuiPaper>
  );
};

export const Pie: FC<PieGraphProps> = ({
  data,
  loading = false,
  emptyLabel,
}) => {
  const pieColours = [
    theme.colors.primary.medium,
    theme.colors.primary.lighter,
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }): JSX.Element => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={index > 0 ? theme.colors.neutral[600] : theme.colors.white}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <MuiPaper elevation={0}>
      {loading && <Spinner />}

      {(!data || data.length <= 0) && !loading && (
        <div css={styles.noResults}>
          <p className="body2">{emptyLabel ?? 'No data available.'}</p>
        </div>
      )}

      {data && data.length > 0 && !loading && (
        <div css={styles.innerContainer}>
          <div>
            {data &&
              data.map((entry, i) => (
                <div css={styles.legend} key={entry?.label}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect width="16" height="16" rx="2" fill={pieColours[i]} />
                  </svg>
                  <p className="body2" css={css.noMargin}>
                    {entry?.label} ({entry?.value})
                  </p>
                </div>
              ))}
          </div>
          <ResponsiveContainer>
            <PieChart>
              <REPie
                data={data}
                dataKey="value"
                name="label"
                cx="50%"
                cy="50%"
                outerRadius={100}
                isAnimationActive={false}
                fill={theme.colors.black}
                label={renderCustomizedLabel}
                labelLine={false}
                blendStroke
                startAngle={180}
                endAngle={-180}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={pieColours[index % pieColours.length]}
                  />
                ))}
              </REPie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}
    </MuiPaper>
  );
};
