/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper, useMediaQuery, useTheme } from '@material-ui/core';
import { Buttons, TextFields } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { BasePage } from 'components';
import { useMerchantData } from 'contexts';
import { MerchantRoleGuid, PermissionEnum } from 'enums';
import { collections, css } from 'global';
import _ from 'lodash';
import {
  DeleteUserModule,
  NewUserModule,
  UpdateUserModule,
  UsersTableModule,
  UsersTableRef,
} from 'modules';
import { FC, useEffect, useRef, useState } from 'react';
import { MerchantUser, PageProps, UserRole } from 'types';
import { useUserFunctions } from 'utils';
import * as styles from './UsersPage.styles';

const OrdersPage: FC<PageProps> = () => {
  const { checkPermission, merchantIdentity, isCompanyUser, isAdmin, hasRole } =
    useMerchantData();
  const UserFunctions = useUserFunctions();
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const usersTableRef = useRef<UsersTableRef>();
  const [roles, setRoles] = useState<UserRole[]>([]);

  const [searchValue, setSearchValue] = useState<string>();

  const handleSearchValueChange = (e): void => setSearchValue(e?.target?.value);

  // Dialogs
  const [userToAction, setUserToAction] = useState<MerchantUser>();
  const [isNewUserDialogOpen, setIsNewUserDialogOpen] =
    useState<boolean>(false);
  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] =
    useState<boolean>(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] =
    useState<boolean>(false);

  const handleCreateUserClick = (): void => setIsNewUserDialogOpen(true);

  const handleSuccess = (): void => usersTableRef?.current?.getUsers(0);

  const getUserActions = (
    user: MerchantUser
  ): { label: string; onClick: () => void }[] => {
    const actions = [];

    const userToActionIsUneditable =
      // Below line will allow Company users to edit Merchant Users (pending risk discussion)
      // user.userType == UserType.Company &&
      collections.roles.adminRoles.includes(user.roleId as MerchantRoleGuid);

    const currentUserCanEditUserToAction =
      isCompanyUser ||
      (isAdmin &&
        !collections.roles.adminRoles.includes(
          user.roleId as MerchantRoleGuid
        )) ||
      (hasRole(MerchantRoleGuid.FranchiseManager) &&
        collections.roles.franchiseManageCanManage.includes(
          user.roleId as MerchantRoleGuid
        ));

    if (!userToActionIsUneditable && currentUserCanEditUserToAction) {
      actions.push(
        {
          label: 'Update',
          onClick: () => {
            setUserToAction(user);
            setIsUpdateUserDialogOpen(true);
          },
        },
        {
          label: 'Delete',
          onClick: () => {
            setUserToAction(user);
            setIsDeleteUserDialogOpen(true);
          },
        }
      );
    }
    return actions;
  };

  // Button to create order that is moved for mobile view
  const createUserButton = (
    <Buttons.Secondary
      css={styles.createUserButton(false)}
      onClick={handleCreateUserClick}
    >
      <Icons.UserCirclePlus width={20} height={20} />
      <span id="buttonLabel">Create user</span>
    </Buttons.Secondary>
  );

  // get roles to share across the modules
  useEffect(() => {
    async function updateRoles(): Promise<void> {
      const roles = (await UserFunctions.getAllRoles())?.roles;
      const rolesToSet = _.uniqBy(roles, (role) => role.roleGuid)?.sort(
        (a, b) => (a.name?.toLowerCase()[0] < b.name?.toLowerCase()[0] ? -1 : 1)
      );
      setRoles(rolesToSet);
    }

    setRoles([]);
    updateRoles();
  }, [merchantIdentity.merchantId]);

  return (
    <>
      <BasePage
        title="Users"
        variant="layout"
        fullWidth
        fullHeight
        flex
        action={isMobile && createUserButton}
      >
        <MuiPaper elevation={0} css={styles.ordersTableContainer(false)}>
          <div id="toolbar" css={styles.toolbar}>
            <span>
              <TextFields.Outlined
                id="searchValue"
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearchValueChange}
                css={styles.input}
                startIcon={<Icons.Search />}
                endIcon={
                  searchValue && (
                    <Icons.Close
                      css={css.clearIcon}
                      onClick={handleSearchValueChange}
                    />
                  )
                }
                fullWidth={isMobile}
              />
            </span>
            {!isMobile &&
              checkPermission(PermissionEnum.UserManagementExecute) &&
              createUserButton}
          </div>
          <UsersTableModule
            ref={usersTableRef}
            searchValue={searchValue}
            getUserActions={getUserActions}
          />
        </MuiPaper>
      </BasePage>

      <NewUserModule
        roles={roles}
        open={isNewUserDialogOpen}
        toggleOpen={setIsNewUserDialogOpen}
        onSuccess={handleSuccess}
      />
      <UpdateUserModule
        user={userToAction}
        roles={roles}
        open={isUpdateUserDialogOpen}
        toggleOpen={setIsUpdateUserDialogOpen}
        onSuccess={handleSuccess}
      />
      <DeleteUserModule
        user={userToAction}
        open={isDeleteUserDialogOpen}
        toggleOpen={setIsDeleteUserDialogOpen}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default OrdersPage;
