/** @jsxImportSource @emotion/react */
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';
import { FC } from 'react';
import * as styles from './Tabs.styles';
import { TabsProps } from './TabsProps';

const Tabs: FC<TabsProps> = ({ tabs, value, content, ...props }) => (
  <>
    <MuiTabs css={styles.tabs} variant="scrollable" value={value} {...props}>
      {tabs.map((tab) => (
        <MuiTab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          css={styles.muiTab}
          disableRipple
        />
      ))}
    </MuiTabs>
    <div>
      {Object.keys(content).map((key) => value === key && content[key])}
    </div>
  </>
);

export default Tabs;
