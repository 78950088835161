import { css } from '@emotion/react';

import { theme } from 'global';

export const ordersTableContainer = (isSidebarOpen) => css`
  width: calc(100% - ${isSidebarOpen ? 520 : 0}px);
  padding: 32px;

  @media screen and (max-width: ${theme.breaks.lg - 1}px) {
    width: calc(100% - ${isSidebarOpen ? 332 : 0}px);
  }

  @media screen and (max-width: ${theme.breaks.sm}px) {
    background-color: transparent;
    padding: 0;
  }
`;

export const toolbar = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  & > span {
    display: flex;
    align-items: center;
    width: 400px;

    & > :not(:first-child) {
      margin-left: 12px;
    }

    @media screen and (max-width: ${theme.breaks.sm}px) {
      flex-direction: column;
      width: 100%;

      & > :not(:first-child) {
        margin-left: 0;
      }
    }
  }
`;

export const input = css`
  background-color: ${theme.colors.white};

  @media screen and (max-width: ${theme.breaks.sm}px) {
    margin: 12px 0;

    &:first-of-type {
      margin: 0 0 12px;
    }
  }
`;

export const createUserButton = (isSidebarOpen) => css`
  min-width: unset;
  height: fit-content;

  & > .MuiButton-label > :not(:first-child) {
    margin-left: 8px;
  }

  @media screen and (min-width: ${theme.breaks.sm + 1}px) {
    padding: 14px 12px;
    & > .MuiButton-label > #buttonLabel {
      display: ${isSidebarOpen ? 'none' : 'block'};
    }
  }

  @media screen and (max-width: ${theme.breaks.sm}px) {
    padding: 8px 12px;
    & > .MuiButton-label > #buttonLabel {
      display: none;
    }
  }
`;
