import { css } from '@emotion/react';

import { theme } from 'global';

export const textfieldTitle = css`
  display: flex;
  justify-content: space-between;
`;

export const textfield = css`
  width: 100%;

  > .MuiInputBase-root > input[type='number']::-webkit-inner-spin-button,
  > .MuiInputBase-root > input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > .MuiInputBase-root {
    background-color: ${theme.colors.white};
    &.Mui-disabled {
      background-color: transparent;
    }
  }

  > .MuiFormHelperText-root {
    background-color: transparent;
    margin-bottom: -10px;

    &.Mui-error {
      margin-bottom: -20px;
    }
  }
`;
