import { css, SerializedStyles } from '@emotion/react';
import { theme } from 'global';

function line(width: number): SerializedStyles {
  return css`
    content: '';
    position: absolute;
    top: 20px;
    left: ${width == 16 ? '13px' : '-11px'};
    display: inline-block;
    height: 1px;
    width: ${width ? width : 0}px;
    background-color: #e0dee0;
    transform: rotate(90deg);
    transform-origin: center left;
    z-index: 0;
  `;
}

export const paymentSchedule = css`
  border-radius: 8px;
  padding: 16px 20px;
`;

export const amount = (
  isLoading: boolean,
  instalmentNumber: number,
  lineLength: number
): SerializedStyles =>
  css`
    position: relative;
    display: block;
    list-style-type: none;
    ${isLoading && `color: ${theme.colors.neutral[300]}`};
    font-weight: ${theme.fontWeights.medium};

    & > span {
      margin: 0 0 0 7px;
    }

    &::before {
      position: absolute;
      left: -14px;
      content: '${instalmentNumber + 1}';
      font-size: 11px;
      width: 21px;
      vertical-align: middle;
      z-index: 1;
      color: ${theme.colors.neutral[400]};
    }

    li:not(:last-of-type) &::after {
      ${line(lineLength)};
    }
  `;

export const description = css`
  position: relative;
  display: block;
  margin: 0;

  li:not(:last-of-type) > & {
    margin: 0 0 16px 0;
  }

  & > *:first-of-type {
    margin: 0 0 0 7px;
  }
`;

export const breakdown = css`
  margin: -8px 0 16px 0;
  padding-left: 20px;

  & > p {
    margin: 8px 0px;
    font-family: Sharp Grotesk;
    font-weight: 400;
  }

  & > b {
    font-family: Sharp Grotesk;
    font-weight: 500;
  }
`;

export const icon = css`
  height: 16px;
  width: auto;
`;

export const label = css`
  &::before {
    content: '';
    display: inline-block;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    margin: 0 4px;
    background-color: ${theme.colors.neutral[500]};
    transform: translateY(-100%);
  }
`;

export const mask = css`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7px;
    right: 0;
    .css-${description.name} > & {
      right: 30%;
    }
    border-radius: 2px;
    margin: 3px 0;
    background: linear-gradient(
      90deg,
      ${theme.colors.neutral[200]} 0%,
      ${theme.colors.neutral[300]} 128.58%
    );
    animation: shimmer 2s infinite linear;
    z-index: 1;

    @keyframes shimmer {
      0% 50% 100% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(1px);
      }
      75% {
        transform: translateX(-1px);
      }
    }
  }
`;

export const payments = (isEmpty: boolean): SerializedStyles => css`
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 16px;
  ${isEmpty &&
  `* {
    color: ${theme.colors.neutral[300]};
  }`}
`;

export const paymentSummary = css`
  margin: 0 0 16px -22px !important;
  font-size: 11px;
  font-weight: ${theme.fontWeights.normal};
  font-family: Sharp Grotesk;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: ${theme.colors.neutral[600]};
  pointer-events: none;
  &::after {
    ${line(17)};
    left: 11px;
  }
`;
