import { SerializedStyles, css } from '@emotion/react';

export const accordionSummary = (
  isSelectable: boolean
): SerializedStyles => css`
  margin: 0 0 24px;
  min-height: unset;

  &.Mui-focused,
  .Mui-focusVisible {
    background-color: inherit;
  }

  > .MuiAccordionSummary-content {
    margin: unset;
    cursor: ${isSelectable ? 'pointer' : 'default'};
  }

  > .MuiAccordionSummary-expandIcon {
    margin: 0 auto;
    padding: 0 5px;
  }
`;

export const accordionDetails = css`
  display: block;
`;
