/** @jsxImportSource @emotion/react */
import MuiDivider from '@material-ui/core/Divider';
import { FC } from 'react';
import * as styles from './Divider.styles';
import { DividerProps } from './DividerProps';

const Divider: FC<DividerProps> = ({
  top = 24,
  bottom = 0,
  vertical = false,
  ...props
}) => (
  <MuiDivider
    css={styles.divider(top, bottom, vertical)}
    orientation={vertical ? 'vertical' : 'horizontal'}
    {...props}
  />
);

export default Divider;
