/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs, Spinner } from '@zip/business-components';
import { PriceInput } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import { MerchantClassSettingType, PageAction } from 'enums';
import { css } from 'global';
import { FC, useEffect, useState } from 'react';
import { OrderDetails } from 'types';
import {
  currencyFormatter,
  logError,
  logEvent,
  useOrderFunctions,
} from 'utils';
import { CompleteOrderModuleProps } from './CompleteOrderModuleProps';

const CompleteOrderModule: FC<CompleteOrderModuleProps> = ({
  open = true,
  toggleOpen,
  orderToAction,
  onSuccess,
  ...props
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();
  const { checkMerchantDashboardSetting } = useMerchantData();

  const [orderDetails, setOrderDetails] = useState<OrderDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [amount, setAmount] = useState<number>();
  const [amountHelperText, setAmountHelperText] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = (): void => toggleOpen(false);

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      logEvent(PageAction.completeOrder, { orderToAction, amount });
      await OrderFunctions.completeOrder(
        orderToAction?.branchId,
        orderToAction.operationRequestId,
        orderDetails?.orderReference,
        amount
      );
      Snackbar.success('Order was processed successfully');
      handleClose();
      onSuccess();
    } catch (err) {
      logError(PageAction.completeOrder, err, {
        orderToAction,
        amount,
      });
      Snackbar.error('An error occurred processing your request.');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let helperText;
    const maxCaptureAmount = Math.abs(
      +parseFloat(orderDetails?.amountOwing?.toFixed(2))
    );
    if (amount > maxCaptureAmount) {
      helperText = `Maximum capture amount allowed is ${currencyFormatter(
        orderDetails?.amountOwing
      )}`;
    }

    const minAmount = checkMerchantDashboardSetting(
      MerchantClassSettingType.PartialCaptureEnabled
    )
      ? 0
      : orderDetails?.amountOwing;
    if (amount < minAmount) {
      helperText = `Minimum capture amount allowed is ${currencyFormatter(
        orderDetails?.amountOwing
      )}`;
    }
    setAmountHelperText(helperText);
  }, [amount, orderDetails]);

  useEffect(() => {
    const getOrderDetails = async (): Promise<void> => {
      setIsLoading(true);
      const details = await OrderFunctions.getOrderDetails(
        orderToAction.operationRequestId
      );
      setOrderDetails(details);
      setAmount(Math.abs(+parseFloat(details?.amountOwing?.toFixed(2))));
      setIsLoading(false);
    };

    if (!open) {
      return;
    }

    getOrderDetails();
  }, [open, orderToAction]);

  return (
    <Dialogs.Basic
      id="completeOrderDialog"
      open={open}
      title="Complete order"
      onClose={handleClose}
      spaceActions
      actions={
        !isLoading && (
          <>
            <Buttons.Primary
              id="submitButton"
              disabled={amount <= 0 || amount > orderDetails?.amountOwing}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Complete order
            </Buttons.Primary>

            <Buttons.Text id="cancelButton" onClick={handleClose}>
              Cancel
            </Buttons.Text>
          </>
        )
      }
      {...props}
    >
      {isLoading && (
        <div css={css.loadingPrompt}>
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <div>
          <p className="body2">
            Process payment for order{' '}
            <strong>{orderToAction?.orderReference}</strong>
          </p>
          <PriceInput
            id="captureAmount"
            label="Amount to process"
            value={amount}
            onChange={(e): void =>
              setAmount(Math.abs(+parseFloat(e?.target?.value).toFixed(2)))
            }
            error={Boolean(amountHelperText)}
            helperText={amountHelperText}
            max={orderDetails?.amountOwing}
            fullWidth
            min={
              checkMerchantDashboardSetting(
                MerchantClassSettingType.PartialCaptureEnabled
              )
                ? 0
                : orderDetails?.amountOwing
            }
            readOnly={
              !checkMerchantDashboardSetting(
                MerchantClassSettingType.PartialCaptureEnabled
              )
            }
          />
        </div>
      )}
    </Dialogs.Basic>
  );
};

export default CompleteOrderModule;
