/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import { withOptimizely } from '@optimizely/react-sdk';
import { Buttons } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Divider, QuickAction } from 'components';
import { QuickActionSkeleton } from 'components/quick-action';
import { useMerchantData } from 'contexts';
import {
  MerchantClassSettingType,
  PageAction,
  PageRoute,
  PermissionEnum,
  QuickActionType,
} from 'enums';
import { css } from 'global';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logError } from 'utils';
import InviteCustomerDialogModule from '../invite-customer';
import QuickActionEditDialogModule from '../quick-action-edit-dialog';

import * as styles from './QuickActionList.styles';

const QuickActionListModule: FC = () => {
  const { push } = useHistory();
  const { get } = useFetch();
  const { profileData, checkPermission, checkMerchantDashboardSetting } =
    useMerchantData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [quickActions, setQuickActions] = useState<QuickActionType[]>([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isInviteCustomerDialogOpen, setIsInviteCustomerDialogOpen] =
    useState<boolean>(false);

  async function getUserPreferences(): Promise<void> {
    setIsLoading(true);
    get('/userpreference')
      .then((res) => res?.json())
      .then((response) => {
        const quickActionsToSet =
          response?.userPreferences?.quickActions.slice();

        // force CreditLimitIncreaseInvite action on all users it is enabled for
        if (
          checkMerchantDashboardSetting(
            MerchantClassSettingType.CLIInvitationEnabled
          ) &&
          checkPermission(PermissionEnum.CreateCLIInvitationExecute) &&
          !quickActionsToSet?.includes(
            QuickActionType.CreditLimitIncreaseInvite
          )
        ) {
          quickActionsToSet.push(QuickActionType.CreditLimitIncreaseInvite);
        }

        setQuickActions(quickActionsToSet);
      })
      .catch((err) => {
        logError(PageAction.loadUserPreferences, err);
        setQuickActions([]);
      })
      .finally(() => setIsLoading(false));
  }

  const handleDialogOpen = (): void => setIsEditDialogOpen(true);

  const handleDialogClose = (): void => setIsEditDialogOpen(false);

  const OpenEditDialogButton = (props): JSX.Element => {
    const { optimizely } = props;
    const handleClick = (): void => {
      optimizely.track('opened_edit_dialog');
      handleDialogOpen();
    };

    return (
      <Buttons.Text css={styles.editLink} onClick={handleClick}>
        <Icons.Pencil css={styles.editIcon} />
        <p className="body2" css={css.noMargin}>
          Edit
        </p>
      </Buttons.Text>
    );
  };

  const OpenEditDialogOptimizelyButton = withOptimizely(OpenEditDialogButton);

  const handleEditSuccess = (): Promise<void> => getUserPreferences();

  useEffect(() => {
    if (profileData?.userProfile?.userProfileId) {
      getUserPreferences();
    }
  }, [profileData?.userProfile?.userProfileId]);

  const getQuickAction = (quickAction): JSX.Element => {
    switch (quickAction) {
      case QuickActionType.CreateOrder:
        return (
          checkPermission(PermissionEnum.CreateOrderExecute) && (
            <QuickAction
              key={quickAction}
              actionType={quickAction}
              Icon={Icons.Plus}
              onClick={(): void => push(PageRoute.CreateOrder)}
              id="createOrderAction"
            />
          )
        );
      case QuickActionType.InviteCustomer:
        return (
          checkPermission(PermissionEnum.CreateInviteExecute) && (
            <QuickAction
              key={quickAction}
              actionType={quickAction}
              Icon={Icons.Paperplane}
              onClick={(): void => setIsInviteCustomerDialogOpen(true)}
              id="inviteCustomerAction"
            />
          )
        );
      case QuickActionType.SearchOrders:
        return (
          checkPermission(PermissionEnum.OrdersRead) && (
            <QuickAction
              key={quickAction}
              actionType={quickAction}
              Icon={Icons.Search}
              path={PageRoute.Orders}
              id="searchOrdersAction"
            />
          )
        );
      case QuickActionType.SearchCustomers:
        return (
          checkPermission(PermissionEnum.CustomersRead) && (
            <QuickAction
              key={quickAction}
              actionType={quickAction}
              Icon={Icons.Search}
              path={PageRoute.Customers}
              id="searchCustomersAction"
            />
          )
        );
      case QuickActionType.CreditLimitIncreaseInvite:
        return (
          checkMerchantDashboardSetting(
            MerchantClassSettingType.CLIInvitationEnabled
          ) &&
          checkPermission(PermissionEnum.CreateCLIInvitationExecute) && (
            <QuickAction
              key={quickAction}
              actionType={quickAction}
              Icon={Icons.ArrowUp}
              path={`${PageRoute.Tools}${PageRoute.CliInvite}`}
              id="limitIncreaseInviteAction"
            />
          )
        );
      case QuickActionType.DisbursementReports:
        return (
          checkPermission(PermissionEnum.DisbursementsRead) && (
            <QuickAction
              key={quickAction}
              actionType={quickAction}
              Icon={Icons.CurrencyDollarCircle}
              path={`${PageRoute.Settings}${PageRoute.Disbursement}`}
              id="disbursementReportsAction"
            />
          )
        );
      case QuickActionType.ManageUsers:
        return (
          checkPermission(PermissionEnum.UserManagementExecute) && (
            <QuickAction
              key={quickAction}
              actionType={quickAction}
              Icon={Icons.UserCircle}
              path={`${PageRoute.Settings}${PageRoute.Users}`}
              id="manageUsersAction"
            />
          )
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <div css={styles.titleBar}>
          <p className="heading3" css={css.noMargin}>
            Quick actions
          </p>
          {!isLoading && (
            <OpenEditDialogOptimizelyButton onClick={handleDialogOpen} />
          )}
        </div>
        <MuiGrid container spacing={2} css={styles.quickActionsContainer}>
          {isLoading &&
            Array.from(Array(4)).map((_, i) => <QuickActionSkeleton key={i} />)}
          {!isLoading &&
            quickActions?.map((quickAction) => getQuickAction(quickAction))}
        </MuiGrid>
        <Divider bottom={24} />
      </div>

      <QuickActionEditDialogModule
        open={isEditDialogOpen}
        onClose={handleDialogClose}
        onSuccess={handleEditSuccess}
        quickActions={quickActions}
      />

      <InviteCustomerDialogModule
        open={isInviteCustomerDialogOpen}
        onClose={(): void => setIsInviteCustomerDialogOpen(false)}
      />
    </>
  );
};

export default QuickActionListModule;
