/** @jsxImportSource @emotion/react */
import { Hidden as MuiHidden } from '@material-ui/core';
import { Loading } from '@zip/business-components';
import { SummaryCards, TableRows } from 'components';
import { InfiniteScrollTable } from 'components/compact-table';
import { useMerchantData } from 'contexts';
import { PageAction } from 'enums';
import { Constants } from 'global';
import _ from 'lodash';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MerchantUser } from 'types';
import { logError, logEvent, useUserFunctions } from 'utils';
import * as styles from './UsersTable.styles';
import { UsersTableModuleProps } from './UsersTableModuleProps';
import { UsersTableRef } from './UsersTableRef';

const UsersTableModule = forwardRef((props: UsersTableModuleProps, ref) => {
  const { searchValue, getUserActions } = props;
  const { merchantIdentity, isCompanyUser } = useMerchantData();
  const UserFunctions = useUserFunctions();

  const [users, setUsers] = useState<MerchantUser[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Set pageSize to be responsive to window height
  const rowHeightPx = 56;
  const pageSize = Math.round(window.innerHeight / rowHeightPx) + 2;

  const columns = isCompanyUser
    ? ['Name', 'Type', 'Role', 'Email', 'Status', '']
    : ['Name', 'Role', 'Email', 'Status', ''];

  const getUsersDebounced = useMemo(
    () =>
      _.debounce(async (query) => {
        setIsLoading(true);

        const doConcat: boolean = query.skip > 0;

        if (!doConcat) {
          setUsers([]);
        }

        logEvent(PageAction.searchUsers, query);
        await UserFunctions.getUsers(query)
          .then((res) => {
            setUsers(
              doConcat
                ? UserFunctions.concatUniqueUsers(users, res?.users)
                : res?.users
            );
            setHasMore(res?.hasMore);
            setIsLoading(false);
          })
          .catch((err) => {
            if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
              logError(PageAction.searchUsers, err, query);
              setHasMore(false);
              setIsLoading(false);
            }
            setUsers([]);
          });
      }, 420),
    [users, merchantIdentity]
  );

  const getUsers = (page = 0): void => {
    //workaround to set the page back to zero when it is called from parent's ref
    setCurrentPage(page);

    const query = {
      searchValue,
      take: pageSize,
      skip: pageSize * page,
    };

    getUsersDebounced(query);
  };

  const handlePageChange = (event, newPage: number): void =>
    setCurrentPage(newPage);

  useEffect(() => {
    getUsers(0);

    return (): void => UserFunctions.resetGetRequests();
  }, [searchValue, merchantIdentity]);

  useEffect(() => {
    getUsers(currentPage);

    return (): void => UserFunctions.resetGetRequests();
  }, [currentPage]);

  useImperativeHandle(
    ref,
    (): UsersTableRef => ({
      getUsers,
    })
  );

  return (
    <>
      <MuiHidden smDown>
        <InfiniteScrollTable
          columns={columns}
          css={styles.usersTable}
          stickyHeader
          length={users?.length}
          loadMore={(): void => handlePageChange(null, currentPage + 1)}
          hasMore={hasMore}
          isLoading={isLoading}
          endMessage="No more users found matching the specified filters"
          emptyMessage="No users found matching the specified filters"
        >
          {users &&
            users?.map((user) => (
              <TableRows.User
                key={user?.userProfileId}
                user={user}
                onClick={null}
                actions={getUserActions(user)}
              />
            ))}
        </InfiniteScrollTable>
      </MuiHidden>
      <MuiHidden mdUp>
        <InfiniteScroll
          dataLength={users?.length}
          next={(): void => handlePageChange(null, currentPage + 1)}
          hasMore={hasMore}
          endMessage={
            !isLoading &&
            users?.length > 0 && (
              <p style={{ textAlign: 'center' }}>
                No more users found matching the specified filters
              </p>
            )
          }
          loader={<Loading />}
          scrollableTarget="content"
        >
          {users &&
            users?.map((user) => (
              <SummaryCards.User
                key={user.userProfileId}
                user={user}
                actions={getUserActions(user)}
              />
            ))}
          {!isLoading && users?.length < 1 && (
            <p style={{ textAlign: 'center' }}>
              No users found matching the specified filters
            </p>
          )}
        </InfiniteScroll>
      </MuiHidden>
    </>
  );
});

export default UsersTableModule;
