import { css } from '@emotion/react';

import { theme } from 'global';

export const textfieldTitle = css`
  display: flex;
  justify-content: space-between;
`;

export const helperText = css`
  > .MuiFormHelperText-root {
    margin-bottom: -10px;

    &.Mui-error {
      margin-bottom: -20px;
    }
  }
`;

export const textfield = css`
  width: 100%;

  ${helperText}
`;

export const select = css`
  z-index: 500;

  ${helperText}
`;

export const halfWidth = css`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
  }
`;

export const stateSelect = css`
  margin: 16px 16px 16px 0 !important;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin: 16px 0 16px 0 !important;
  }
`;
