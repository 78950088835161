import { useState } from 'react';
import { UseScrollBaseProps } from './useScrollBase.model';

interface UseScrollBottomProps extends UseScrollBaseProps {
  scrollBottom: boolean;
}

export const useScrollBottom = (): UseScrollBottomProps => {
  const [scrollBottom, setScrollBottom] = useState(false);

  const onScroll = (event): void =>
    setScrollBottom(
      event.target.scrollHeight - event.target.scrollTop !==
        event.target.clientHeight
    );
  return { scrollBottom, scrollProps: { onScroll } };
};

export default useScrollBottom;
