import { css } from '@emotion/react';

import { theme } from 'global';

export const tooltip = css`
  padding: 8px;
  color: ${theme.colors.neutral[500]};

  border: 1px solid ${theme.colors.neutral[200]};

  > .body2 {
    color: ${theme.colors.neutral[600]};
  }
`;

export const innerContainer = css`
  height: 205px;
`;

export const noResults = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 205px;
`;

export const legend = css`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > svg {
    margin-right: 8px;
  }
`;
