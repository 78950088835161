/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid, Paper as MuiPaper } from '@material-ui/core';
import { Alert, Buttons } from '@zip/business-components';
import Banners from 'components/banners';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from 'types';

import { isSandbox } from 'global';
import * as styles from './BasePage.styles';
import { BasePageProps } from './BasePageProps';

const BasePage: FC<BasePageProps> = ({
  title,
  hasActions = false,
  fullWidth = false,
  fullHeight = false,
  flex = false,
  flexCol = false,
  action,
  bannerProps = undefined,
  sandboxAlert = undefined,
  variant = 'paper',
  breadcrumbs,
  children,
}) => {
  const { push } = useHistory();
  const handleBreadcrumbClick = (breadcrumb: Breadcrumb): void => {
    if (breadcrumb.pageRoute == null) {
      breadcrumb.onClick();
    } else {
      push(breadcrumb.pageRoute);
    }
  };
  return (
    <div css={styles.pageContainer(hasActions, fullHeight)}>
      {breadcrumbs?.length && (
        <MuiGrid container css={styles.breadcrumbs}>
          {breadcrumbs?.map((breadcrumb, i) => (
            <MuiGrid item key={i}>
              <Buttons.Text
                className="body2"
                onClick={(): void => handleBreadcrumbClick(breadcrumb)}
              >
                {breadcrumb.pageName}
              </Buttons.Text>{' '}
              &gt;
            </MuiGrid>
          ))}
          <MuiGrid item>
            <Buttons.Text className="body2" css={styles.currentPageBreadcrumb}>
              {title}
            </Buttons.Text>
          </MuiGrid>
        </MuiGrid>
      )}
      <MuiGrid container justifyContent="space-between">
        <MuiGrid item>
          {title && (
            <p className="title3" css={styles.title} data-testid="page-title">
              {title}
            </p>
          )}
        </MuiGrid>
        {action && <MuiGrid item>{action}</MuiGrid>}
      </MuiGrid>
      {bannerProps && <Banners.Promo {...bannerProps} css={styles.banner} />}
      {sandboxAlert && isSandbox && (
        <Alert
          variant="error"
          children={sandboxAlert}
          css={styles.sandboxAlert}
        />
      )}
      {variant === 'paper' && (
        <MuiPaper elevation={0} css={styles.paper(fullHeight)}>
          <div css={styles.container(fullWidth, fullHeight, flex, flexCol)}>
            {children}
          </div>
        </MuiPaper>
      )}
      {variant === 'layout' && (
        <div css={styles.container(fullWidth, fullHeight, flex, flexCol)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default BasePage;
