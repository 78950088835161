/** @jsxImportSource @emotion/react */
import { Grid } from '@material-ui/core';
import * as Icons from '@zip/react-icons/fearless';
import { BasePage, Tile } from 'components';
import { useMerchantData } from 'contexts';
import { MerchantClassSettingType, PageRoute, PermissionEnum } from 'enums';
import { Constants } from 'global';
import { FC } from 'react';
import { PageProps } from 'types';

const MarketingPage: FC<PageProps> = () => {
  const { checkPermissions, checkMerchantDashboardSetting } = useMerchantData();
  return (
    <BasePage
      title="Marketing"
      variant="layout"
      fullWidth
      sandboxAlert="These functions are not available for use until your application is approved"
    >
      <Grid container spacing={3}>
        {checkPermissions([
          PermissionEnum.StoreProfileRead,
          PermissionEnum.StoreProfileUpdate,
        ]) && (
          <>
            <Tile
              id="campaignsTile"
              Icon={Icons.Calendar}
              title="Zip campaigns"
              subtitle="View current and future Zip campaigns that you can get involved in"
              path={`${PageRoute.Marketing}${PageRoute.Campaigns}`}
            />
            <Tile
              id="posMaterialsTile"
              Icon={Icons.Box}
              title="POS materials"
              subtitle="Request Zip marketing materials to display instore"
              path={`${PageRoute.Marketing}${PageRoute.PosMaterials}`}
            />
          </>
        )}
        {checkPermissions([
          PermissionEnum.StoreProfileRead,
          PermissionEnum.StoreProfileUpdate,
          PermissionEnum.StoreCategoryRead,
          PermissionEnum.StoreCategoryUpdate,
        ]) &&
          !checkMerchantDashboardSetting(
            MerchantClassSettingType.UseCompanyMarketing
          ) && (
            <Tile
              id="storeProfileTile"
              Icon={Icons.NewspaperClipping}
              title="Store directory"
              subtitle="Submit your business to our merchant directory"
              path={`${PageRoute.Marketing}${PageRoute.StoreDirectory}`}
            />
          )}
        <Tile
          id="offersTile"
          Icon={Icons.Celebrate}
          title="Offers for you"
          subtitle="View exclusive offers for you as a Zip Merchant"
          path={`${PageRoute.Marketing}${PageRoute.Offers}`}
          tag="NEW"
          tagExpiry={Constants.expiryDate.offers}
        />
      </Grid>
    </BasePage>
  );
};

export default MarketingPage;
