/** @jsxImportSource @emotion/react */
import {
  AppBar as MuiAppBar,
  Grid as MuiGrid,
  Hidden as MuiHidden,
  Toolbar as MuiToolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Buttons } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Tooltip } from 'components';
import { useAuth0, useCookies, useMerchantData } from 'contexts';
import { MerchantProfileStepStatus } from 'enums';
import { Constants, css, isProduction, theme } from 'global';
import { FC, useMemo } from 'react';
import Divider from '../divider';
import MerchantSelector from '../merchant-selector';
import UserMenu from '../user-menu';
import * as styles from './Header.styles';
import { HeaderProps } from './HeaderProps';

const Header: FC<HeaderProps> = ({
  openDrawer,
  closeDrawer,
  isDrawerOpen,
  openHelpCentre,
}) => {
  const { isAuthenticated, loading } = useAuth0();
  const {
    branches,
    hasPublicId,
    merchantProfile,
    profileDataIsLoading,
    currentUserId,
    isUnlinkedUser,
    isLinkingUser,
    needsToReauth,
  } = useMerchantData();
  const { cookies, getCookie, setCookie } = useCookies();

  const [setupGuideBannerCookie, setupGuideTooltipCookie] = useMemo(
    () => [
      getCookie(`${Constants.cookies.setupGuideBanner}${currentUserId}`),
      getCookie(`${Constants.cookies.setupGuideTooltip}${currentUserId}`),
    ],
    [cookies]
  );

  const showSetupGuideTooltip = useMemo(
    () =>
      setupGuideBannerCookie?.toLowerCase() === 'false' &&
      setupGuideTooltipCookie?.toLowerCase() !== 'false',
    [setupGuideBannerCookie, setupGuideTooltipCookie]
  );

  const isMerchantProfileSubmitted =
    !hasPublicId ||
    (!isProduction &&
      (merchantProfile?.profile?.submitted ||
        (merchantProfile?.contact === MerchantProfileStepStatus.Completed &&
          merchantProfile?.settlement !==
            MerchantProfileStepStatus.Completed)));

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const handleHelpCentreClick = (): void => {
    openHelpCentre();
    if (showSetupGuideTooltip) {
      setCookie(
        `${Constants.cookies.tooltip}setup-guide-${currentUserId}`,
        'false'
      );
    }
  };

  return (
    <MuiAppBar position="static" elevation={0} css={styles.appbar}>
      <MuiToolbar css={styles.toolbar}>
        <MuiGrid container justifyContent="space-between">
          {/* Desktop */}
          <MuiHidden smDown>
            <MuiGrid item xs={5} sm={4}>
              <img
                src={Constants.assets.logo.light}
                width={72}
                title="logo"
                alt="Logo"
                css={styles.logo}
              />
            </MuiGrid>
          </MuiHidden>

          {/* Mobile */}
          <MuiHidden mdUp>
            <MuiGrid
              item
              container
              xs={5}
              alignItems="center"
              css={css.flex('row', 'flex-start', 'center')}
            >
              {!loading &&
                !profileDataIsLoading &&
                !isUnlinkedUser() &&
                !isLinkingUser() &&
                !needsToReauth() &&
                (isDrawerOpen ? (
                  <Icons.Close
                    fill={theme.colors.secondary}
                    css={css.icon}
                    onClick={closeDrawer}
                  />
                ) : (
                  <Tooltip
                    id="setup-guide"
                    open={showSetupGuideTooltip}
                    arrow
                    dismissable
                    variant="grey"
                    maxWidth={260}
                    content={
                      <span className="body2">
                        Access Setup guide through Help centre here.
                      </span>
                    }
                  >
                    <Icons.Menu
                      fill={theme.colors.secondary}
                      css={css.icon}
                      onClick={openDrawer}
                    />
                  </Tooltip>
                ))}
            </MuiGrid>

            <MuiGrid
              item
              container
              xs={2}
              justifyContent="center"
              alignItems="center"
              css={css.flex('row', 'center', 'center')}
            >
              {isDrawerOpen ? (
                <img
                  src={Constants.assets.favicon.light}
                  width={28}
                  title="logo-square"
                  alt="Logo square"
                />
              ) : (
                <img
                  src={Constants.assets.logo.light}
                  width={72}
                  title="logo"
                  alt="Logo"
                />
              )}
            </MuiGrid>
          </MuiHidden>

          {/* Common */}
          <MuiGrid
            item
            container
            xs={5}
            md={8}
            justifyContent="flex-end"
            alignItems="center"
            css={css.flex('row', 'flex-end', 'center')}
          >
            {!loading && !profileDataIsLoading && (
              <>
                {/* Merchant/Branch selector */}
                {isMerchantProfileSubmitted && (
                  <>
                    {(window.innerWidth > theme.breaks.xs || !isDrawerOpen) &&
                      branches?.length > 0 && <MerchantSelector />}
                    {window.innerWidth > theme.breaks.xs &&
                      branches?.length > 0 && (
                        <Divider vertical top={12} bottom={12} />
                      )}
                  </>
                )}
                {/* Help centre */}
                {(window.innerWidth > theme.breaks.xs || isDrawerOpen) && (
                  <Tooltip
                    id="setup-guide"
                    open={showSetupGuideTooltip}
                    arrow
                    dismissable
                    variant="primary"
                    maxWidth={260}
                    content={
                      <span className="body2">
                        Access Setup guide through Help centre here.
                      </span>
                    }
                  >
                    {isMobile ? (
                      <Icons.QuestionCircle
                        fill={theme.colors.secondary}
                        css={css.icon}
                        onClick={handleHelpCentreClick}
                      />
                    ) : (
                      <Buttons.Text
                        css={{ margin: '0 4px' }}
                        onClick={handleHelpCentreClick}
                      >
                        Get help
                      </Buttons.Text>
                    )}
                  </Tooltip>
                )}
                {/* User menu */}
                {(window.innerWidth > theme.breaks.xs || isDrawerOpen) &&
                  isAuthenticated && <UserMenu />}
              </>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiToolbar>
    </MuiAppBar>
  );
};

export default Header;
