/** @jsxImportSource @emotion/react */
import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiGrid from '@material-ui/core/Grid';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import { ChangeEvent, FC } from 'react';
import * as styles from './RadioGroup.styles';
import { RadioGroupProps } from './RadioGroupProps';

const RadioGroup: FC<RadioGroupProps> = ({
  options = [],
  label,
  valueChanged,
  helperText = '',
  value,
  boolean = false,
  error = false,
  name,
  useLabelAsIcon = false,
  ...props
}) => {
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>): void =>
    valueChanged(event);

  return (
    <>
      <MuiFormControl error={error} css={styles.formControl}>
        {boolean && (
          <MuiRadioGroup
            name={name}
            onChange={handleValueChange}
            value={value}
            css={styles.radioGroup}
            {...props}
          >
            <h4 css={styles.h4}>{label}</h4>
            <MuiFormControlLabel
              value
              control={<MuiRadio color="primary" css={styles.radioButton} />}
              label="Yes"
              checked={Boolean(value) === true}
            />
            <MuiFormControlLabel
              value={false}
              control={<MuiRadio color="primary" />}
              label="No"
              checked={Boolean(value) === false}
              css={styles.radioButton}
            />
          </MuiRadioGroup>
        )}

        {!boolean && (
          <MuiRadioGroup
            name={name}
            onChange={handleValueChange}
            value={value}
            css={styles.radioGroup}
            {...props}
          >
            <h4 css={styles.h4}>{label}</h4>
            <MuiGrid
              container
              justify="flex-start"
              alignContent="stretch"
              spacing={1}
            >
              {options &&
                options.map((option) => (
                  <MuiGrid
                    key={option.value}
                    item
                    xs={6}
                    css={styles.radioButtonGrid}
                  >
                    <MuiFormControlLabel
                      id={`option-${option.value}`}
                      value={option.value}
                      control={
                        useLabelAsIcon ? (
                          <MuiRadio
                            color="primary"
                            css={styles.customIcon}
                            icon={option.label}
                            checkedIcon={option.label}
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                          />
                        ) : (
                          <MuiRadio color="primary" />
                        )
                      }
                      label={useLabelAsIcon ? '' : option.label}
                      checked={option.value === value}
                      css={styles.radioButton(option.value === value)}
                    />
                  </MuiGrid>
                ))}
            </MuiGrid>
          </MuiRadioGroup>
        )}
        {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
      </MuiFormControl>
    </>
  );
};

export default RadioGroup;
