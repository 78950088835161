import { DateRange } from 'enums';
import { Constants } from 'global';
import { useFetch } from 'hooks';
import { Moment } from 'moment';
import { GrossSalesQuery } from 'types';

export interface ReportFunctions {
  resetGetRequests(): void;
  getScheduleSettings(): Promise<any>;
  updateScheduleSettings(schedule: unknown): Promise<any>;
  generateReport(fromDate: Moment, toDate: Moment): Promise<any>;
  fetchReport(fileName: string): Promise<any>;
  getNumberOfDays(selectedDateRange: string): number;
  fetchGrossSales(
    query: GrossSalesQuery,
    abortSignal?: AbortSignal
  ): Promise<any>;
  fetchCustomerOverview(
    query: GrossSalesQuery,
    abortSignal?: AbortSignal
  ): Promise<any>;
  fetchOrdersToReview(
    query: GrossSalesQuery,
    abortSignal?: AbortSignal
  ): Promise<any>;
  fetchCompletedOrders(
    query: GrossSalesQuery,
    abortSignal?: AbortSignal
  ): Promise<any>;
}

export const useReportFunctions = (): ReportFunctions => {
  const { get, post } = useFetch();
  let controller = new AbortController();

  function resetGetRequests(): void {
    controller.abort();
    controller = new AbortController();
  }

  async function getScheduleSettings(): Promise<any> {
    return get(
      `/disbursement/schedules`,
      controller.signal,
      process.env.REACT_APP_REPORTS_API_URL
    )
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        if (res.status === 204) {
          return null;
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  async function updateScheduleSettings(schedule: unknown): Promise<any> {
    return post(
      `/disbursement/schedules`,
      schedule,
      controller.signal,
      process.env.REACT_APP_REPORTS_API_URL
    )
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  async function generateReport(
    fromDate: Moment,
    toDate: Moment
  ): Promise<any> {
    return post(
      `/disbursement/report`,
      {
        fromDate: fromDate.format('yyyy-MM-DD'),
        toDate: toDate.format('yyyy-MM-DD'),
      },
      controller.signal,
      process.env.REACT_APP_REPORTS_API_URL
    )
      .then((res) => {
        if (res.status === 200) {
          return res?.text();
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  async function fetchReport(fileName: string): Promise<any> {
    return get(
      `/disbursement/report?fileName=${fileName}`,
      controller.signal,
      process.env.REACT_APP_REPORTS_API_URL
    )
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  function getNumberOfDays(selectedDateRange: string): number {
    switch (selectedDateRange) {
      case DateRange.Last7Days:
        return 6;
      case DateRange.Last30Days:
        return 29;
      default:
        return 0;
    }
  }

  function getQuery(query: GrossSalesQuery): string {
    const [startDate] = query.startDate.toISOString().split('T');
    const [endDate] = query.endDate.toISOString().split('T');
    let queryParams = `startDate=${startDate}&endDate=${endDate}`;
    if (query?.frequency) {
      queryParams = `${queryParams}&frequency=${query.frequency}`;
    }
    return queryParams;
  }

  async function fetchGrossSales(
    query: GrossSalesQuery,
    abortSignal: AbortSignal
  ): Promise<any> {
    const url = `/transaction/gross-volume?${getQuery(query)}`;
    return get(url, abortSignal, process.env.REACT_APP_REPORTS_API_URL)
      .catch((err) => {
        if (abortSignal.aborted) {
          throw new Error(Constants.errors.abortedSignal);
        }
        throw err;
      })
      .then((res) => {
        return res?.status === 200 ? res?.json() : [];
      });
  }

  async function fetchCustomerOverview(
    query: GrossSalesQuery,
    abortSignal: AbortSignal
  ): Promise<any> {
    const url = `/customer/overview?${getQuery(query)}`;
    return get(url, abortSignal, process.env.REACT_APP_REPORTS_API_URL)
      .catch((err) => {
        if (abortSignal.aborted) {
          throw new Error(Constants.errors.abortedSignal);
        }
        throw err;
      })
      .then((res) => {
        return res?.status === 200 ? res?.json() : [];
      });
  }

  async function fetchOrdersToReview(
    query: GrossSalesQuery,
    abortSignal: AbortSignal
  ): Promise<any> {
    // 0 = NotStarted, 1 = InProgress, 5 = Authorised
    const url = `/order/orders-summary?${getQuery(
      query
    )}&statuses=0&statuses=1&statuses=5`;
    return get(url, abortSignal, process.env.REACT_APP_REPORTS_API_URL)
      .catch((err) => {
        if (abortSignal.aborted) {
          throw new Error(Constants.errors.abortedSignal);
        }
        throw err;
      })
      .then((res) => {
        return res?.status === 200 ? res?.json() : null;
      });
  }

  async function fetchCompletedOrders(
    query: GrossSalesQuery,
    abortSignal: AbortSignal
  ): Promise<any> {
    // 6 = Completed
    const url = `/order/orders-summary?${getQuery(query)}&statuses=6`;
    return get(url, abortSignal, process.env.REACT_APP_REPORTS_API_URL)
      .catch((err) => {
        if (abortSignal.aborted) {
          throw new Error(Constants.errors.abortedSignal);
        }
        throw err;
      })
      .then((res) => {
        return res?.status === 200 ? res?.json() : null;
      });
  }

  return {
    resetGetRequests,
    getScheduleSettings,
    updateScheduleSettings,
    generateReport,
    fetchReport,
    getNumberOfDays,
    fetchGrossSales,
    fetchCustomerOverview,
    fetchOrdersToReview,
    fetchCompletedOrders,
  };
};

export default useReportFunctions;
