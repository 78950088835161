/** @jsxImportSource @emotion/react */
import { Labels } from '@zip/business-components';
import { FC } from 'react';

import { hasDateExpired } from 'utils/validators';
import * as styles from './HighlightTag.styles';
import { HighlightTagProps } from './HighlightTagProps';

export const HighlightTag: FC<HighlightTagProps> = ({
  label,
  expiryDate = undefined,
}) => {
  const shouldDisplay = !expiryDate || !hasDateExpired(expiryDate);
  return (
    shouldDisplay && (
      <Labels.Basic
        variant={null}
        label={label}
        css={styles.label}
        className="body3"
      />
    )
  );
};
