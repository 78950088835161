/** @jsxImportSource @emotion/react */
import { Hidden as MuiHidden } from '@material-ui/core';
import { Loading } from '@zip/business-components';
import { InfiniteScrollTable, SummaryCards, TableRows } from 'components';
import { useMerchantData } from 'contexts';
import { CustomerSearchType, PageAction } from 'enums';
import { Constants } from 'global';
import _ from 'lodash';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import { CliRequest, CustomerDetails } from 'types';
import { logError, logEvent, useCustomerFunctions } from 'utils';
import * as styles from './CustomersTable.styles';
import { CustomersTableModuleProps } from './CustomersTableModuleProps';

const CustomersTableModule = forwardRef(
  (props: CustomersTableModuleProps, ref) => {
    const { searchValue, setSelectedConsumerId, selectedConsumerId } = props;
    const { merchantIdentity } = useMerchantData();
    const CustomerFunctions = useCustomerFunctions();
    const { status, consumerId } = useParams<{
      status: string;
      consumerId: string;
    }>();

    const { push, location } = useHistory();
    const isSearchingCliRequest = location?.pathname.includes('/cli-invite');

    const [customers, setCustomers] = useState<
      CustomerDetails[] | CliRequest[]
    >([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Set pageSize to be responsive to window height
    const rowHeightPx = 56;
    const pageSize = Math.round(window.innerHeight / rowHeightPx) + 2;

    const columns = ['Customer', 'Status', 'Last updated', 'Account'];

    const handleFetchError = (): void => {
      setHasMore(false);
      setIsLoading(false);
    };

    const getCliInvitesDebounced = useMemo(
      () =>
        _.debounce(async (query) => {
          setIsLoading(true);

          const doConcat: boolean = query.skip > 0;

          if (!doConcat) {
            setCustomers([]);
          }

          logEvent(PageAction.searchCliInvites, query);
          await CustomerFunctions.getCliInvites(query)
            .then((res) => {
              setCustomers(
                doConcat
                  ? CustomerFunctions.concatUniqueCliRequest(
                      // @ts-ignore - doConcat will cater for the conflicting models
                      customers,
                      res?.creditLimitInvitations
                    )
                  : res?.creditLimitInvitations
              );
              setHasMore(res?.hasMore);
              setIsLoading(false);
            })
            .catch((err) => {
              if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
                logError(PageAction.searchCliInvites, err, query);
                handleFetchError();
              }
              setCustomers([]);
            });
        }, 420),
      [customers]
    );

    const getCustomersDebounced = useMemo(
      () =>
        _.debounce(async (query) => {
          setIsLoading(true);

          const doConcat: boolean = query.skip > 0;

          if (!doConcat) {
            setCustomers([]);
          }

          logEvent(PageAction.searchCustomers, query);
          await CustomerFunctions.getCustomers(query)
            .then((res) => {
              setCustomers(
                doConcat
                  ? CustomerFunctions.concatUniqueCustomers(
                      // @ts-ignore - doConcat will cater for the conflicting models
                      customers,
                      res?.customers
                    )
                  : res?.customers
              );
              setHasMore(res?.hasMore);
              setIsLoading(false);
            })
            .catch((err) => {
              if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
                logError(PageAction.searchCustomers, err, query);
                handleFetchError();
              }
              setCustomers([]);
            });
        }, 420),
      [customers, merchantIdentity]
    );

    const getCustomers = (page = 0): void => {
      const query = {
        searchValue,
        searchType: CustomerSearchType.FullName,
        status,
        take: pageSize,
        skip: pageSize * page,
      };

      if (isSearchingCliRequest) {
        getCliInvitesDebounced(query);
      } else {
        getCustomersDebounced(query);
      }
    };

    const handlePageChange = (event, newPage: number): void => {
      setCurrentPage(newPage);
    };

    const handleCustomerRowClick = (consumerIdToSet: number): void => {
      if (selectedConsumerId === consumerIdToSet || isSearchingCliRequest) {
        return;
      }
      setSelectedConsumerId(consumerIdToSet);
      push(
        consumerId
          ? `${location.pathname.replace(
              `/consumer/${consumerId}`,
              `/consumer/${consumerIdToSet}`
            )}`
          : `${location.pathname}/consumer/${consumerIdToSet}`
      );
    };

    useEffect(() => {
      getCustomers(0);

      return (): void => CustomerFunctions.resetGetRequests();
    }, [searchValue, status, isSearchingCliRequest, merchantIdentity]);

    useEffect(() => {
      getCustomers(currentPage);

      return (): void => CustomerFunctions.resetGetRequests();
    }, [currentPage]);

    useImperativeHandle(ref, () => ({
      getCustomers,
    }));

    return (
      <>
        <MuiHidden smDown>
          <InfiniteScrollTable
            columns={columns}
            css={styles.customersTable}
            stickyHeader
            length={customers?.length}
            loadMore={(): void => handlePageChange(null, currentPage + 1)}
            hasMore={hasMore}
            isLoading={isLoading}
            endMessage="No more customers found matching the specified filters"
            emptyMessage="No customers found matching the specified filters"
          >
            {customers &&
              customers?.map((customer) => (
                <TableRows.Customer
                  key={customer?.id ?? customer?.consumerId}
                  customer={customer}
                  selected={selectedConsumerId === customer?.consumerId}
                  isCliInvite={isSearchingCliRequest}
                  onClick={(): void =>
                    handleCustomerRowClick(customer.consumerId)
                  }
                />
              ))}
          </InfiniteScrollTable>
        </MuiHidden>
        <MuiHidden mdUp>
          <InfiniteScroll
            dataLength={customers?.length}
            next={(): void => handlePageChange(null, currentPage + 1)}
            hasMore={hasMore}
            endMessage={
              !isLoading &&
              customers?.length > 0 && (
                <p style={{ textAlign: 'center' }}>
                  No more customers found matching the specified filters
                </p>
              )
            }
            loader={<Loading />}
            scrollableTarget="content"
          >
            {customers &&
              customers?.map((customer) => (
                <SummaryCards.Customer
                  key={customer.consumerId}
                  customer={customer}
                  onClick={(): void =>
                    handleCustomerRowClick(customer.consumerId)
                  }
                />
              ))}
            {!isLoading && customers?.length < 1 && (
              <p style={{ textAlign: 'center' }}>
                No customers found matching the specified filters
              </p>
            )}
          </InfiniteScroll>
        </MuiHidden>
      </>
    );
  }
);

export default CustomersTableModule;
