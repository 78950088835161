/** @jsxImportSource @emotion/react */
import { Button as MuiButton } from '@material-ui/core';
import { Buttons, Dialogs, Paper } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { css, theme } from 'global';
import { FC, useState } from 'react';
import * as styles from './FileUpload.styles';
import { FileUploadProps } from './FileUploadProps';

const FileUpload: FC<FileUploadProps> = ({
  label,
  handleChange,
  onBlur,
  name,
  error,
  helperText,
  multiple = false,
  helpContent = null,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState<boolean>(false);

  const changeHandler = (event): void => {
    const newFiles = event?.target?.files;
    if (newFiles?.length > 0) {
      setSelectedFiles([...newFiles]);
      handleChange([...newFiles]);
    }
  };

  const blurHandler = (event): void => onBlur(event);

  const removeImageHandler = (fileName): void => {
    const listToSet = selectedFiles
      ?.slice()
      ?.filter((file) => file.name !== fileName);

    handleChange(listToSet);
    setSelectedFiles(listToSet);
  };

  return (
    <>
      <Paper css={styles.card(error)}>
        <MuiButton
          variant="contained"
          component="label"
          disableRipple
          css={styles.uploadButton}
          onBlur={blurHandler}
        >
          {(selectedFiles?.length < 1 || error) && (
            <div css={styles.uploadIcon}>
              <Icons.ArrowUpload
                fill={theme.colors.primary.medium}
                width="28"
                height="44"
              />
            </div>
          )}
          <input
            type="file"
            accept="image/*,.pdf"
            name={name}
            onChange={(e): void => {
              changeHandler(e);
              e.target.value = null;
            }}
            onBlur={onBlur}
            multiple={multiple}
            hidden
          />
          {selectedFiles?.length > 0 && !error && (
            <>
              <ul css={styles.list} onClick={(e): void => e.preventDefault()}>
                {selectedFiles?.map((file) => (
                  <li key={file?.name} css={styles.listItem}>
                    <div css={styles.imageContainer}>
                      {(file?.type?.includes('image') && (
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Your uploaded file"
                          css={styles.imagePreview}
                        />
                      )) || (
                        <Icons.Statement
                          fill={theme.colors.primary.medium}
                          width={48}
                          height={48}
                        />
                      )}
                      <Icons.Close
                        css={styles.removeImageIcon}
                        onClick={(): void => removeImageHandler(file?.name)}
                      />
                    </div>

                    <p css={[css.ellipsisOverflow, styles.fileName]}>
                      {file?.name}
                    </p>
                  </li>
                ))}
              </ul>
            </>
          )}
          {(selectedFiles?.length === 0 || error) && (
            <p css={styles.label(error)}>{helperText ?? label}</p>
          )}
        </MuiButton>
      </Paper>
      <p className="body2" css={styles.caption}>
        PDF, JPEG, PNG or IMG accepted. 8MB max.
        {helpContent && (
          <>
            <br />
            <Buttons.Text
              className="body2"
              onClick={(): void => setIsHelpDialogOpen(true)}
            >
              Need help?
            </Buttons.Text>
          </>
        )}
      </p>

      <Dialogs.Basic
        id="helpDialog"
        onClose={(): void => setIsHelpDialogOpen(false)}
        open={isHelpDialogOpen}
        title={label}
        actions={
          <Buttons.Primary
            onClick={(): void => setIsHelpDialogOpen(false)}
            className="close"
          >
            Okay, got it
          </Buttons.Primary>
        }
      >
        {helpContent}
      </Dialogs.Basic>
    </>
  );
};

export default FileUpload;
