/** @jsxImportSource @emotion/react */
import { Button as MuiButton } from '@material-ui/core';
import { css } from 'global';
import { FC } from 'react';
import * as styles from './BasicButton.styles';
import { BasicButtonProps } from './BasicButtonProps';

const BasicButton: FC<BasicButtonProps> = ({
  count = 0,
  children,
  disabled,
  showBadgeOnZeroCount,
  ...props
}) => {
  const showBadge = (count >= 0 && showBadgeOnZeroCount) || count > 0;
  return (
    <MuiButton
      type="submit"
      css={styles.button(count)}
      disableRipple
      disabled={disabled}
      {...props}
    >
      <span css={styles.label}>{children}</span>
      {showBadge && (
        <span id="badge" css={css.badge}>
          {count}
        </span>
      )}
    </MuiButton>
  );
};

export default BasicButton;
