/** @jsxImportSource @emotion/react */
import {
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
} from '@material-ui/core';
import { FC } from 'react';
import * as styles from './PriceInput.styles';
import { PriceInputProps } from './PriceInputProps';

const PriceInput: FC<PriceInputProps> = ({
  inputProps,
  max,
  min,
  readOnly,
  endIcon,
  fullWidth,
  value,
  ...props
}) => {
  return (
    <>
      <MuiTextField
        type="number"
        variant="outlined"
        css={styles.textfield}
        fullWidth={fullWidth}
        InputProps={{
          inputProps: {
            step: 0.01,
            max,
            min,
            readOnly,
            onWheel: (e): void => e.currentTarget.blur(),
            'data-testid': 'price-input',
            ...inputProps,
          },
          startAdornment: !!value && (
            <MuiInputAdornment position="start">$</MuiInputAdornment>
          ),
          endAdornment: endIcon,
        }}
        disabled={readOnly}
        value={value}
        {...props}
      />
    </>
  );
};

export default PriceInput;
