import { Constants } from 'global';
import { Area } from 'react-easy-crop';
import { MerchantProfileDetails } from 'types';

export const createImage = (
  url: string,
  callback: () => void = undefined
): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    if (callback != undefined) {
      image.onload = (): void => callback();
    }
    image.src = url;
    image.crossOrigin = 'Anonymous';
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number }
): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = image.width;
  canvas.height = image.height;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(image.width / 2, image.height / 2);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As a blob
  return canvas.toDataURL('image/jpeg');
}

export async function getCroppedImgSrc(
  imageSrc: string,
  preserveTransparency = false
): Promise<string> {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = image.width;
  canvas.height = image.height;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(image.width / 2, image.height / 2);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // As a string
  return canvas.toDataURL(`image/${preserveTransparency ? 'png' : 'jpeg'}`);
}

export const getImageWidthAndHeight = (
  providedImage: File
): Promise<{ width: number; height: number }> => {
  const imgDimensions = { width: null, height: null };

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(providedImage);
    reader.onload = (): void => {
      const img = new Image();
      img.src = String(reader.result);

      img.onload = (): void => {
        imgDimensions.width = img.width;
        imgDimensions.height = img.height;

        resolve(imgDimensions);
      };
    };
  });
};

export const getScaleRatio = (
  image: HTMLImageElement,
  useFactor = true
): number => {
  if (!image) {
    return 1;
  }
  const { factor, canvas } = Constants.storeProfile;
  const { naturalWidth: width, naturalHeight: height } = image;
  const ratio = Math.min(canvas.width / width, canvas.height / height);
  return useFactor ? ratio * factor : ratio;
};

export const multiplyArea = (
  area: Area,
  multiplier = Constants.storeProfile.factor
): Area => ({
  width: area.width * multiplier,
  height: area.height * multiplier,
  x: area.x * multiplier,
  y: area.y * multiplier,
});

export const convertMerchantProfileDetailToArea = (
  merchantProfileDetails: MerchantProfileDetails,
  multiply = true
): Area => {
  if (!merchantProfileDetails) {
    return multiplyArea({ width: 400, height: 320, x: 0, y: 0 });
  }

  const convertedValue: Area = {
    width: Number(merchantProfileDetails?.cropW),
    height: Number(merchantProfileDetails?.cropH),
    x: Number(merchantProfileDetails?.cropX),
    y: Number(merchantProfileDetails?.cropY),
  };
  return multiply ? multiplyArea(convertedValue) : convertedValue;
};

export const convertAreaToMerchantProfileDetail = (
  area: Area
): { cropH: string; cropW: string; cropX: string; cropY: string } => {
  return {
    cropH: Number(area?.height).toString(),
    cropW: Number(area?.width).toString(),
    cropX: Number(area?.x).toString(),
    cropY: Number(area?.y).toString(),
  };
};
