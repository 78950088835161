export function validateEmail(email: string): boolean {
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
    email
  );
}

export function validateCharacters(
  stringToValidate: string,
  charactersExpectedToFind: string[],
  allRequired = false
): boolean {
  if (!allRequired) {
    return charactersExpectedToFind.some((char) =>
      stringToValidate.includes(char)
    );
  }

  let hasCharacters = true;
  charactersExpectedToFind.forEach((char) => {
    if (!hasCharacters) {
      return;
    }
    hasCharacters = stringToValidate.includes(char);
  });
  return hasCharacters;
}

export function validateMobile(mobile: string): boolean {
  return /^04[0-9]{8}$/i.test(mobile);
}

export function validateAustralianUserMobile(mobile: string): boolean {
  return /^(04|\+614)[0-9]{8}$/i.test(mobile);
}

export function validateProtocol(url: string): boolean {
  return /^(http(s)?:\/\/)/gi.test(url);
}

export function validateUrl(url: string): boolean {
  return /^(http(s)?:\/\/)[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,12}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(
    url
  );
}

export function isFormikValid(formik: {
  touched: unknown;
  errors: unknown;
}): boolean {
  return (
    Object.keys(formik.touched).length && !Object.keys(formik.errors).length
  );
}

export function formikHasErrors(formik: { errors: unknown }): boolean {
  return !Object.keys(formik.errors).length;
}

export function hasDateExpired(expiryDate: Date = new Date()): boolean {
  return new Date() >= expiryDate;
}
