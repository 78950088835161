import { css } from '@emotion/react';
import { theme } from 'global';

export const table = css`
  padding: 24px 16px;
  > tr > th,
  > tr > td {
    height: 64px;
    vertical-align: top;
  }
  > tr > th {
    font-weight: 500;
    text-align: right;
    @media screen and (max-width: ${theme.breaks.sm}px) {
      max-width: 125px;
    }
  }
  > tr > td:first-of-type {
    display: flex;
  }
  > tr:last-of-type {
    & th,
    td {
      height: 42px;
    }
    & > td:first-of-type > span::after {
      display: none;
    }
  }
`;

export const timeline = css`
  margin: 0 12px;
  ::before {
    content: '\\25EF';
    font-size: 21px;
    z-index: 3;
  }
  ::after {
    content: '';
    display: inline-block;
    height: 1px;
    width: 46px;
    background-color: ${theme.colors.primary.dark};
    transform: rotate(90deg);
    transform-origin: left center;
    position: absolute;
    margin-left: -11px;
    margin-top: 20px;
    z-index: 0;
  }
`;

export const date = css`
  max-width: 160px;
`;
