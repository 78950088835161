import { useFetch } from 'hooks';
import { MerchantUser, UserRole } from 'types';
import { ManageUserCommand } from 'types/commands';
import { GetUsersQuery } from 'types/queries';

interface UserFunctions {
  resetGetRequests: () => void;
  getUsers: (
    query: GetUsersQuery
  ) => Promise<{ hasMore: boolean; users: MerchantUser[] }>;
  getAllRoles: () => Promise<{ roles: UserRole[] }>;
  createUser: (payload: ManageUserCommand) => Promise<unknown>;
  updateUser: (payload: ManageUserCommand) => Promise<unknown>;
  deleteUser: (email: string) => Promise<unknown>;
  concatUniqueUsers: (
    users: MerchantUser[],
    usersToAdd: MerchantUser[]
  ) => MerchantUser[];
}

export const useUserFunctions = (): UserFunctions => {
  const { get, post, put } = useFetch();
  let controller = new AbortController();

  function resetGetRequests(): void {
    controller.abort();
    controller = new AbortController();
  }

  async function getUsers(
    query: GetUsersQuery
  ): Promise<{ hasMore: boolean; users: MerchantUser[] }> {
    let url = `/usermanagement?take=${query.take}&skip=${query.skip}`;

    if (query.searchValue) {
      url = `${url}&searchTerm=${query.searchValue}`;
    }

    return get(url, controller.signal)
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        if (res.status === 204) {
          return null;
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  async function getAllRoles(): Promise<{ roles: UserRole[] }> {
    return get('/usermanagement/roles')
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        if (res.status === 204) {
          return null;
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  async function createUser(payload: ManageUserCommand): Promise<unknown> {
    return post('/usermanagement', payload, controller.signal)
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  async function updateUser(payload: ManageUserCommand): Promise<unknown> {
    return put('/usermanagement', payload, controller.signal)
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        throw new Error('Something went wrong');
      })
      .catch((err) => {
        throw err;
      });
  }

  async function deleteUser(email: string): Promise<unknown> {
    const payload = { email };

    return post('/usermanagement/delete-user', payload, controller.signal)
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        if (res.status === 401) {
          return null;
        }
        throw new Error(res.message);
      })
      .catch((err) => {
        throw err;
      });
  }

  function concatUniqueUsers(
    users: MerchantUser[],
    usersToAdd: MerchantUser[] = []
  ): MerchantUser[] {
    return users.concat(
      usersToAdd?.filter(
        (userToAdd: MerchantUser) =>
          !users.some((item) => item.id === userToAdd.id)
      ) ?? []
    );
  }

  return {
    resetGetRequests,
    getUsers,
    getAllRoles,
    createUser,
    updateUser,
    deleteUser,
    concatUniqueUsers,
  };
};

export default useUserFunctions;
