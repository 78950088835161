/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import { Tooltip as ZipTooltip } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { useCookies, useMerchantData } from 'contexts';
import { Constants, css } from 'global';
import { FC } from 'react';

import { TooltipProps } from './TooltipProps';

const Tooltip: FC<TooltipProps> = ({
  content,
  dismissable,
  interactive,
  children,
  id,
  ...props
}) => {
  const { currentUserId } = useMerchantData();
  const { setCookie } = useCookies();
  const handleClose = (): void =>
    setCookie(`${Constants.cookies.tooltip}${id}-${currentUserId}`, 'false');

  const tooltipContent = (
    <MuiGrid
      id={`${id}-content-dismissable`}
      container
      css={css.flex('row', 'space-between')}
    >
      <MuiGrid item xs={11}>
        {content}
      </MuiGrid>
      <MuiGrid item xs={1}>
        <Icons.Close
          css={css.icon(true, '20px', '20px')}
          onClick={handleClose}
        />
      </MuiGrid>
    </MuiGrid>
  );
  return (
    <ZipTooltip
      id={`${id}-tooltip`}
      content={dismissable ? tooltipContent : content}
      interactive={dismissable ?? interactive}
      {...props}
    >
      <div id={`${id}-child`} css={css.flex}>
        {children}
      </div>
    </ZipTooltip>
  );
};

export default Tooltip;
