/** @jsxImportSource @emotion/react */
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import { TextFields } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Tooltip } from 'components';
import { useSnackbar } from 'contexts';
import { css, theme } from 'global';
import { FC, useEffect, useState } from 'react';
import { CliInviteCustomer, CustomerToCharge } from 'types';
import { useCustomerFunctions, useOrderFunctions } from 'utils';
import * as styles from './InstoreCodeValidator.styles';
import { InstoreCodeValidatorProps } from './InstoreCodeValidatorModuleProps';

const InstoreCodeValidator: FC<InstoreCodeValidatorProps> = ({
  disabled = false,
  setResponse,
  cliInviteCustomer,
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();
  const CustomerFunctions = useCustomerFunctions();
  const [inStoreCode, setInStoreCode] = useState<number>(0);
  const [inStoreCodeValidating, setInStoreCodeValidating] = useState<boolean>();
  const [inStoreCodeEndIcon, setInStoreCodeEndIcon] = useState<JSX.Element>();

  async function validateInStoreToken(): Promise<void> {
    setInStoreCodeEndIcon(
      <MuiCircularProgress size={18} thickness={5} color="primary" />
    );

    let result;
    let errorMsg = 'Something went wrong validating the instore code.';
    if (cliInviteCustomer) {
      result = await CustomerFunctions.getCliInviteCustomerByToken(
        inStoreCode.toString()
      ).catch((err) => {
        switch (err.message) {
          case 'InvalidProduct':
            errorMsg =
              'Customer must generate the code on a Zip Money account.';
            break;
          case 'InvalidToken':
            errorMsg = 'Code has expired, please generate a new code.';
            break;
        }
      });
    } else {
      result = await OrderFunctions.validateInStoreToken(inStoreCode);
    }

    if (result?.isValidToken) {
      setResponse(result as CustomerToCharge);
      setInStoreCodeEndIcon(
        <Icons.CheckCircle
          width="20"
          height="20"
          fill={theme.colors.primary.main}
        />
      );
    } else if (result?.consumerId > 0) {
      setResponse(result as CliInviteCustomer);
      setInStoreCodeEndIcon(
        <Icons.CheckCircle
          width="20"
          height="20"
          fill={theme.colors.primary.main}
        />
      );
    } else {
      setInStoreCodeEndIcon(
        <Icons.Close width="20" height="20" fill={theme.colors.danger} />
      );
      Snackbar.error(errorMsg);
    }

    setInStoreCodeValidating(false);
  }

  useEffect(() => {
    if (inStoreCode?.toString()?.length === 6 && !inStoreCodeValidating) {
      validateInStoreToken();
    } else {
      setInStoreCodeEndIcon(null);
      setResponse(null);
    }
  }, [inStoreCode]);

  return (
    <>
      <div css={styles.textfieldTitle}>
        <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Customer instore code</h4>
        <Tooltip
          content={
            <>
              <p css={css.noTopMrgn}>
                This code is generated by the customer on their mobile phone
                when logged into their Zip account.
              </p>
              <p css={css.noBtmMrgn}>
                To see how the customer can generate this code, click{' '}
                <a
                  href="https://help.zip.co/hc/en-us/articles/360001577935-How-do-I-shop-instore-"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </>
          }
          placement="left"
          enterTouchDelay={20}
          leaveTouchDelay={3000}
          interactive
          maxWidth={300}
          arrow
          dense
        >
          <Icons.InformationCircle width="20" height="20" />
        </Tooltip>
      </div>
      <TextFields.Outlined
        id="instoreCode"
        type="tel"
        label="Enter code from Zip customer"
        css={[styles.textfield]}
        value={inStoreCode}
        onChange={(e): void =>
          e?.target?.value?.length > 6
            ? null
            : setInStoreCode(Math.abs(Number(e?.target?.value)))
        }
        endIcon={inStoreCodeEndIcon}
        disabled={disabled}
      />
    </>
  );
};

export default InstoreCodeValidator;
