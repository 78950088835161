/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs, TextFields } from '@zip/business-components';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Address, AddressErrors } from 'types';
import * as styles from './AddressManualDialog.styles';
import { AddressManualDialogProps } from './AddressManualDialogProps';

const AddressManualDialog: FC<AddressManualDialogProps> = ({
  open = false,
  address,
  toggleOpen,
  onSuccess,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  function handleClose(): void {
    formik.resetForm();
    toggleOpen(false);
  }

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    await onSuccess(formik.values);
    setIsSubmitting(false);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      unitNumber: address?.unitNumber,
      streetNumber: address?.streetNumber,
      streetName: address?.streetName,
      suburb: address?.suburb,
      state: address?.state ?? '',
      postcode: address?.postcode,
      country: address?.country,
    },
    onSubmit: () => handleSubmit(),
    validate: (values: Address) => {
      const errors: AddressErrors = {};

      if (!values.streetNumber) {
        errors.streetNumber = 'Required';
      }
      if (!values.streetName) {
        errors.streetName = 'Required';
      }
      if (!values.suburb) {
        errors.suburb = 'Required';
      }
      if (!values.state) {
        errors.state = 'Required';
      }
      if (!values.postcode) {
        errors.postcode = 'Required';
      } else if (!/^[0-9]{4}$/i.test(values.postcode)) {
        errors.postcode = 'Invalid postcode';
      }
      if (!values.country) {
        errors.country = 'Required';
      }

      return errors;
    },
  });

  const stateOptions = [
    { value: 'ACT', label: 'ACT' },
    { value: 'QLD', label: 'QLD' },
    { value: 'NSW', label: 'NSW' },
    { value: 'NT', label: 'NT' },
    { value: 'SA', label: 'SA' },
    { value: 'TAS', label: 'TAS' },
    { value: 'VIC', label: 'VIC' },
    { value: 'WA', label: 'WA' },
  ];

  useEffect(() => {
    if (address) {
      formik.setValues({ ...address }, true);
    }
  }, [address]);

  return (
    <Dialogs.Basic
      onClose={handleClose}
      open={open}
      title="Enter address manually"
      actions={
        <>
          <Buttons.Text onClick={handleClose}>Cancel</Buttons.Text>

          <Buttons.Primary
            type="submit"
            disabled={
              Boolean(Object.keys(formik.errors).length) || !formik.dirty
            }
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Confirm address
          </Buttons.Primary>
        </>
      }
      {...props}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextFields.Outlined
          type="text"
          name="unitNumber"
          label="Unit no."
          css={styles.textfield}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.unitNumber}
          error={formik.touched.unitNumber && Boolean(formik.errors.unitNumber)}
          helperText="Optional"
        />
        <TextFields.Outlined
          type="text"
          name="streetNumber"
          label="Street number"
          css={styles.textfield}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.streetNumber}
          error={
            formik.touched.streetNumber && Boolean(formik.errors.streetNumber)
          }
          helperText={formik.touched.streetNumber && formik.errors.streetNumber}
        />
        <TextFields.Outlined
          type="text"
          name="streetName"
          label="Street"
          css={styles.textfield}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.streetName}
          error={formik.touched.streetName && Boolean(formik.errors.streetName)}
          helperText={formik.touched.streetName && formik.errors.streetName}
        />
        <TextFields.Outlined
          type="text"
          name="suburb"
          label="Suburb"
          css={styles.textfield}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.suburb}
          error={formik.touched.suburb && Boolean(formik.errors.suburb)}
          helperText={formik.touched.suburb && formik.errors.suburb}
        />
        <div css={styles.halfWidth}>
          <TextFields.Select
            name="state"
            label="State"
            css={[styles.select, styles.stateSelect]}
            options={stateOptions}
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              value: formik.values.state,
            }}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
          <TextFields.Outlined
            type="text"
            name="postcode"
            label="Postcode"
            css={[styles.textfield]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postcode}
            error={formik.touched.postcode && Boolean(formik.errors.postcode)}
            helperText={formik.touched.postcode && formik.errors.postcode}
          />
        </div>
        <TextFields.Outlined
          type="text"
          name="country"
          label="Country"
          css={styles.textfield}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.country}
          error={formik.touched.country && Boolean(formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}
        />
      </form>
    </Dialogs.Basic>
  );
};

export default AddressManualDialog;
