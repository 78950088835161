import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  padding: 32px 32px 16px;
  min-height: 325px;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 24px 16px 8px;
  }
`;

export const title = css`
  margin: 0 0 4px;

  color: ${theme.colors.primary.dark};
  > div {
    margin-left: 4px;
  }
`;

export const icon = css`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
