import { css } from '@emotion/react';

import { theme } from 'global';

export const denseButton = (minWidth) => css`
  background-color: ${theme.colors.primary.lightest};
  border-radius: ${theme.radii.md};
  height: fit-content;
  font-size: ${theme.fontSizes.body3};
  font-weight: ${theme.fontWeights.medium};
  line-height: ${theme.lineHeights.body3};
  padding: 8px 14px;
  color: ${theme.colors.primary.medium};
  justify-content: center;
  margin-left: 16px;
  min-width: ${minWidth ?? 'fit-content'};

  &:first-of-type {
    margin: 0;
  }

  &:active,
  :focus,
  :hover {
    background-color: ${theme.colors.primary.lightest};
  }

  &.Mui-disabled,
  :hover.Mui-disabled {
    background-color: ${theme.colors.neutral[100]};
    color: ${theme.colors.neutral[600]};
  }
`;

export const denseButtonIcon = css`
  width: 13px;
  height: 13px;
  margin-right: 6px;
`;

export const label = css``;
