import { css } from '@emotion/react';
import { lighten } from '@material-ui/core';
import { theme } from 'global';

export const listItem = (selected) => css`
  justify-content: flex-start;
  padding: 16px;
  margin: 8px auto;
  color: ${selected ? theme.colors.primary.medium : theme.colors.neutral[600]};
  background-color: ${selected
    ? `${lighten(theme.colors.primary.medium, 0.92)} !important`
    : 'transparent'};
  border-radius: ${theme.radii.lg};
  &:hover {
    background-color: ${theme.colors.neutral[50]};
  }
`;

export const listItemIcon = (open, selected) => css`
  margin-right: ${open ? '12px' : '0'};
  color: ${selected ? theme.colors.primary.medium : theme.colors.neutral[600]};
`;

export const listItemText = (open) => css`
  display: ${open ? 'inherit' : 'none'};
`;

export const accordian = css`
  box-shadow: unset;
  margin: 8px auto;
  border-radius: ${theme.radii.lg};

  &.Mui-expanded {
    margin: 8px auto !important;
  }
`;

export const accordianSummary = (selected, expanded) => css`
  color: ${selected || expanded
    ? theme.colors.primary.medium
    : theme.colors.neutral[600]};
  font-weight: ${expanded ? 500 : 400};
  background-color: ${selected || expanded
    ? `${lighten(theme.colors.primary.medium, 0.92)} !important`
    : 'transparent'};
  padding: 0px 16px;
  border-radius: ${theme.radii.lg};
  &:hover {
    background-color: ${theme.colors.neutral[50]};
  }
  & > div.MuiAccordionSummary-content {
    margin: 0;
    padding: 16px 0px;
    &.Mui-expanded {
      margin: 0;
    }
  }
  & > div.MuiIconButton-root {
    color: ${selected || expanded
      ? theme.colors.primary.medium
      : theme.colors.neutral[600]};
  }
  & > div.MuiAccordionSummary-expandIcon {
    margin: 10px -10px 0 0;
  }
`;

export const accordianDetails = css`
  background-color: transparent;
  display: block;
  padding: 0 0 0 16px;
`;
