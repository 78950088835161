import { css } from '@emotion/react';
import { css as globalCss, theme } from 'global';

export const profileStatus = css`
  margin-bottom: 12px;
`;

export const alert = css`
  margin-bottom: 40px;
`;

export const textArea = css`
  margin: 10px 0 16px !important;

  & > .MuiInputBase-root {
    height: 130px;
    > .MuiInputBase-input {
      height: 105px !important;
    }
  }
`;

export const storeTileContainer = css`
  border: 1px solid ${theme.colors.neutral[200]};
  background: ${theme.colors.neutral[50]};
  height: 256px;
  width: 320px;
  overflow: hidden;
  margin: 0 0 8px;

  > div {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 50%;
      height: 50%;
      z-index: 1;
    }
  }
`;
