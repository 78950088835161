/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { useSnackbar } from 'contexts';
import { OrderStatus, PageAction } from 'enums';
import { FC, useState } from 'react';
import { logEvent, useErrorHandler, useOrderFunctions } from 'utils';
import { CancelOrderModuleProps } from './CancelOrderModuleProps';

const operationRequestStatuses = new Set<OrderStatus>([
  OrderStatus.NotStarted,
  OrderStatus.InProgress,
  OrderStatus.UnderReview,
  OrderStatus.ContractPending,
]);

const CancelOrderModule: FC<CancelOrderModuleProps> = ({
  open,
  toggleOpen,
  orderToAction,
  onSuccess,
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();
  const { handleError } = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = (): void => toggleOpen(false);

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const isOperationRequest = operationRequestStatuses.has(
      orderToAction?.status
    );
    const pageAction = isOperationRequest
      ? PageAction.cancelOperationRequest
      : PageAction.cancelOrder;
    try {
      logEvent(pageAction, { orderToAction });
      if (isOperationRequest) {
        await OrderFunctions.cancelOperationRequest(
          orderToAction?.operationRequestId
        );
      } else {
        await OrderFunctions.cancelOrder(orderToAction?.operationRequestId);
      }
      Snackbar.success('Order was cancelled successfully');
      handleClose();
      onSuccess();
    } catch (err) {
      handleError('An error occurred processing your request.', pageAction, {
        orderToAction,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialogs.Basic
      id="cancelOrderDialog"
      open={open}
      title="Cancel order"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            id="submitButton"
            onClick={handleSubmit}
            loading={isSubmitting}
          >
            Cancel order
          </Buttons.Primary>

          <Buttons.Text id="cancelButton" onClick={handleClose}>
            Cancel
          </Buttons.Text>
        </>
      }
    >
      <p>
        {orderToAction?.status === OrderStatus.PartiallyCaptured
          ? 'Are you sure you wish to cancel the remaining amount outstanding for order '
          : 'Are you sure you wish to cancel order '}
        <strong>{orderToAction?.orderReference}</strong>?
      </p>
    </Dialogs.Basic>
  );
};

export default CancelOrderModule;
