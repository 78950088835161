/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import { useMerchantData } from 'contexts';
import { FeatureFlag, MerchantClassSettingType } from 'enums';
import { FC } from 'react';
import CompletedOrdersModule from '../completed-orders';
import CustomerOverviewModule from '../customer-overview';
import GrossSalesModule from '../gross-sales';
import OrdersToReviewModule from '../orders-to-review';
import QuickActionListModule from '../quick-action-list';
import RecentOrdersModule from '../recent-orders';
import * as styles from './DashboardOverview.styles';
import SendGuideModule from 'modules/send-guide';
import { useDecision } from '@optimizely/react-sdk';

const DashboardOverviewModule: FC = () => {
  const {
    checkMerchantDashboardSetting,
    getMerchantDashboardSettingValue,
    getMerchantUniqueId,
  } = useMerchantData();
  const authAndCapEnabled = checkMerchantDashboardSetting(
    MerchantClassSettingType.AuthoriseBeforeCapture
  );
  const categoryMst = getMerchantDashboardSettingValue(
    MerchantClassSettingType.ShowPlanToPurchase
  );
  const category = categoryMst && categoryMst?.toLowerCase();
  const [planToPurchaseFeature] = useDecision(
    FeatureFlag.EnablePlanToPurchase,
    {
      autoUpdate: true,
    }
  );
  const showSendGuide =
    planToPurchaseFeature?.enabled &&
    (category === 'vet' || category === 'dental');

  return (
    <>
      <QuickActionListModule />
      <MuiGrid container spacing={2}>
        <MuiGrid
          item
          xs={12}
          css={showSendGuide ? styles.moduleContainer(false) : null}
        >
          {showSendGuide && (
            <SendGuideModule
              category={category}
              merchantUniqueId={getMerchantUniqueId()}
            />
          )}
          <GrossSalesModule />
        </MuiGrid>
        <div css={styles.moduleContainer(authAndCapEnabled)}>
          {authAndCapEnabled && <OrdersToReviewModule />}
          <CompletedOrdersModule />
          <CustomerOverviewModule />
          <RecentOrdersModule />
        </div>
      </MuiGrid>
    </>
  );
};

export default DashboardOverviewModule;
