export enum EcommercePlatform {
  BigCommerce = 'bigcommerce',
  Magento = 'magento',
  Magento2 = 'magento 2',
  Neto = 'neto',
  OpenCart = 'opencart',
  Prestashop = 'prestashop',
  Shopify = 'shopify',
  Woocommerce = 'woocommerce',
  Other = 'other',
}

export const EcommercePlatformUrlSuffixes: Record<
  EcommercePlatform,
  { integrationUrlSuffix: string; bpiUrlSuffix: string }
> = {
  [EcommercePlatform.BigCommerce]: {
    integrationUrlSuffix: 'bigcommerce',
    bpiUrlSuffix: 'bigcommerce-enable-the-zip-on-site-messaging',
  },
  [EcommercePlatform.Magento]: {
    integrationUrlSuffix: 'magento-1',
    bpiUrlSuffix: 'magento-1-enable-the-zip-on-site-messaging',
  },
  [EcommercePlatform.Magento2]: {
    integrationUrlSuffix: 'magento-2',
    bpiUrlSuffix: 'magento-2-enable-the-zip-on-site-messaging',
  },
  [EcommercePlatform.Neto]: {
    integrationUrlSuffix: 'neto',
    bpiUrlSuffix: 'neto-enable-the-zip-on-site-messaging',
  },
  [EcommercePlatform.OpenCart]: {
    integrationUrlSuffix: 'opencart',
    bpiUrlSuffix: 'opencart-enable-the-zip-on-site-messaging',
  },
  [EcommercePlatform.Prestashop]: {
    integrationUrlSuffix: 'prestashop',
    bpiUrlSuffix: 'prestashop-enable-the-zip-on-site-messaging',
  },
  [EcommercePlatform.Shopify]: {
    integrationUrlSuffix: 'shopify-install',
    bpiUrlSuffix: 'installing-the-zip-assets-1',
  },
  [EcommercePlatform.Woocommerce]: {
    integrationUrlSuffix: 'woocommerce',
    bpiUrlSuffix: 'woocommerce-enable-the-zip-on-site-messaging',
  },
  [EcommercePlatform.Other]: {
    integrationUrlSuffix: 'other-platforms',
    bpiUrlSuffix: 'best-practice-implementation',
  },
};
