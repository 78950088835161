/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { PageAction } from 'enums';
import { FC, useState } from 'react';
import { logError, logEvent, useUserFunctions } from 'utils';
import { DeleteUserModuleProps } from './DeleteUserModuleProps';
import { useSnackbar } from 'contexts';

const DeleteUserModule: FC<DeleteUserModuleProps> = ({
  open,
  toggleOpen,
  user,
  onSuccess,
}) => {
  const Snackbar = useSnackbar();
  const UserFunctions = useUserFunctions();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = (): void => toggleOpen(false);

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting) {
      return;
    }

    logEvent(PageAction.deleteUser, { user });
    setIsSubmitting(true);
    try {
      const response = await UserFunctions.deleteUser(user?.email);
      if (response === null) {
        Snackbar.error(
          'You are not authorised to delete this user, please contact Zip to delete this user'
        );
      } else {
        Snackbar.success('User was deleted successfully');
        handleClose();
        onSuccess();
      }
    } catch (err) {
      logError(PageAction.deleteUser, err, { user });
      Snackbar.error('An error occurred processing your request');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialogs.Basic
      id="deleteUserDialog"
      open={open}
      title="Delete user"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            id="submitButton"
            onClick={handleSubmit}
            loading={isSubmitting}
          >
            Delete user
          </Buttons.Primary>

          <Buttons.Text id="cancelButton" onClick={handleClose}>
            Cancel
          </Buttons.Text>
        </>
      }
    >
      <p>
        Are you sure you wish to delete the user:{' '}
        <span className="fs-mask">{user?.email}</span>?
      </p>
    </Dialogs.Basic>
  );
};

export default DeleteUserModule;
