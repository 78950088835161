import { SerializedStyles, css } from '@emotion/react';
import { Theme } from '@zip/theme';

export const container = css`
  margin: 0;
`;

export const stepper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  padding: 1rem;
`;

export const stepButton =
  (isActive: boolean) =>
  (theme: Theme): SerializedStyles =>
    css`
      width: 0.5rem;
      height: 0.5rem;
      padding: unset;
      background-color: ${isActive
        ? theme.colors.primary.medium
        : theme.colors.neutral[200]};
      border: none;
      border-radius: 1rem;
      cursor: pointer;
    `;
