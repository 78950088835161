import { css } from '@emotion/react';
import { theme } from 'global';

export const setupList = css`
  margin: 0;
  padding: 0 0 0 20px;
`;

export const tooltip = css`
  width: fit-content;
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`;

export const zipBusinessCheckbox = css`
  margin-top: 16px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin-top: 32px;
  }
`;
