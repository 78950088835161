/** @jsxImportSource @emotion/react */
import { Buttons, TextFields, Widgets } from '@zip/business-components';
import { DirectDebitDialog, Divider, FileUpload } from 'components';
import { useMerchantData } from 'contexts';
import { PageAction, PageRoute } from 'enums';
import { useFormik } from 'formik';
import { css } from 'global';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ApplicationModuleProps,
  SettlementDetails,
  SettlementDocument,
  SubmitSettlementDetailsCommand,
  SubmitSettlementDetailsErrors,
} from 'types';
import { Validators, logEvent } from 'utils';

import * as styles from './SettlementDetails.styles';

const SettlementDetailsModule: FC<ApplicationModuleProps> = ({
  applicationData = null,
  callback,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirectDebitDialogOpen, setIsDirectDebitDialogOpen] =
    useState<boolean>(false);

  const history = useHistory();

  const { getMerchantProfile, getProfileData } = useMerchantData();

  const handleSkipButton = async (): Promise<void> => {
    history.push(PageRoute.Home, {
      applicationComplete: true,
      settlementDetailsSkipped: true,
    });
    logEvent(PageAction.skipSettlementDetails);
    await getMerchantProfile();
    await getProfileData();
  };

  // 8MB
  const fileSizeLimit = 8388608;

  const formik = useFormik({
    initialValues: {
      bankCode: '',
      accountNumber: '',
      accountName: '',
      email: applicationData?.directorDetails?.contactEmail,
      bankStatement: null,
      directDebitAgreement: false,
    },
    onSubmit: async (formValues) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      logEvent(PageAction.submitSettlementDetails);
      const settlementModule: {
        settlementDetails: SettlementDetails;
        settlementDocument: SettlementDocument;
      } = {
        settlementDetails: {
          bankCode: formValues.bankCode,
          accountNumber: formValues.accountNumber,
          accountName: formValues.accountName,
          email: formValues.email,
        },
        settlementDocument: {
          bankStatement: formValues.bankStatement[0],
          directDebitAgreement: formValues.directDebitAgreement,
        },
      };

      await callback(settlementModule);
      setIsLoading(false);
    },
    validate: (values: SubmitSettlementDetailsCommand) => {
      const errors: SubmitSettlementDetailsErrors = {};
      if (!values.directDebitAgreement) {
        errors.directDebitAgreement = 'Required';
      }

      if (!values?.bankStatement || values.bankStatement?.length < 1) {
        errors.bankStatement = 'Required';
      } else if (
        values?.bankStatement?.length > 0 &&
        values.bankStatement[0]?.size > fileSizeLimit
      ) {
        values.bankStatement.splice(0);
        errors.bankStatement = 'Maximum file size is 8MB';
      }

      if (!values.bankCode) {
        errors.bankCode = 'Required';
      } else if (!/^[0-9]{6}$/i.test(values.bankCode)) {
        errors.bankCode = 'Invalid BSB';
      }
      if (!values.accountNumber) {
        errors.accountNumber = 'Required';
      } else if (values.accountNumber.length > 12) {
        errors.accountNumber = 'Account number must not exceed 12 digits';
      } else if (!/[0-9]{3,12}/i.test(values.accountNumber)) {
        errors.accountNumber = 'Invalid account number';
      }

      if (!values.accountName) {
        errors.accountName = 'Required';
      } else if (values.accountName?.length > 50) {
        errors.accountName = 'Account name must not exceed 50 characters';
      }

      if (!values.email) {
        errors.email = 'Required';
      } else if (!Validators.validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      } else if (values.email.length > 80) {
        errors.email = 'Email must not exceed 80 characters';
      }

      return errors;
    },
  });

  return (
    <div>
      <h1>Settlement</h1>
      <p>
        Tell us where you'd like to get paid so we can deposit the funds from
        your Zip orders.
      </p>
      <form
        onSubmit={async (e): Promise<void> => {
          setIsLoading(true);
          formik.handleSubmit(e);
          setIsLoading(false);
        }}
      >
        <TextFields.Outlined
          type="tel"
          className="fs-mask"
          name="bankCode"
          label="BSB number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.bankCode}
          error={formik.touched.bankCode && Boolean(formik.errors.bankCode)}
          helperText={formik.touched.bankCode && formik.errors.bankCode}
          placeholder="123456"
          fullWidth
        />
        <TextFields.Outlined
          type="tel"
          className="fs-mask"
          name="accountNumber"
          label="Account number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.accountNumber}
          error={
            formik.touched.accountNumber && Boolean(formik.errors.accountNumber)
          }
          helperText={
            formik.touched.accountNumber && formik.errors.accountNumber
          }
          placeholder="12345678"
          fullWidth
        />
        <TextFields.Outlined
          type="text"
          className="fs-mask"
          name="accountName"
          label="Bank account name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.accountName}
          error={
            formik.touched.accountName && Boolean(formik.errors.accountName)
          }
          helperText={formik.touched.accountName && formik.errors.accountName}
          placeholder="Jordan Smith"
          fullWidth
        />
        <TextFields.Outlined
          type="email"
          name="email"
          label="Email"
          className="fs-mask"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={
            (formik.touched.email && formik.errors.email) ??
            'This is where your settlement invoices will go'
          }
          placeholder="jordan.smith@example.com"
          fullWidth
        />
        <Divider css={styles.divider} />
        <h3>Upload bank statement</h3>
        <p>
          Upload the first page of a bank statement or a screenshot of your
          banking app showing the account and BSB number of the settlement bank
          details given.
        </p>
        <FileUpload
          name="bankStatement"
          label="Upload bank statement"
          handleChange={(value): void => {
            formik.setFieldValue('bankStatement', value);
          }}
          onBlur={(): void => {
            formik.setFieldTouched('bankStatement');
          }}
          error={
            formik.touched.bankStatement && Boolean(formik.errors.bankStatement)
          }
        />
        <Divider css={styles.divider} />
        <Widgets.Checkbox
          label={
            <p css={[css.noBtmMrgn, css.noTopMrgn]}>
              I authorise the direct debit and agree to the terms of the{' '}
              <a
                role="button"
                tabIndex={0}
                onClick={(e): void => {
                  e.preventDefault();
                  setIsDirectDebitDialogOpen(true);
                }}
              >
                Direct Debit Request and the Direct Debit Service Agreement
              </a>
            </p>
          }
          name="directDebitAgreement"
          checked={formik.values.directDebitAgreement}
          value={formik.values.directDebitAgreement}
          onChange={(e, checked): void => {
            formik.setFieldValue('directDebitAgreement', checked);
          }}
          onBlur={formik.handleBlur}
        />
        <p>
          Direct debit only occurs when a refund is placed. This tick box is{' '}
          <strong>not</strong> related to any service or transactional fees when
          using Zip.
        </p>
        <div css={css.onboardingConfirmButton}>
          <Buttons.Primary
            type="submit"
            disabled={
              Boolean(!Object.keys(formik.touched).length) ||
              Boolean(Object.keys(formik.errors).length)
            }
            loading={isLoading}
          >
            Save
          </Buttons.Primary>

          <Buttons.Text onClick={handleSkipButton}>Skip for later</Buttons.Text>
        </div>
      </form>

      <DirectDebitDialog
        open={isDirectDebitDialogOpen}
        toggleOpen={setIsDirectDebitDialogOpen}
      />
    </div>
  );
};

export default SettlementDetailsModule;
