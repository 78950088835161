import { css } from '@emotion/react';

import { css as globalCss, theme } from 'global';

export const titleBar = css`
  ${globalCss.titleBar};
  justify-content: flex-start;

  @media screen and (max-width: ${theme.breaks.md}px) {
    justify-content: space-between;
  }
`;

export const editLink = css`
  display: flex;
  height: fit-content;
  margin-left: 16px;

  & > * {
    color: ${theme.colors.neutral[600]};
  }
`;

export const editIcon = css`
  width: 20px;
  height: 20px;
  margin-right: 9px;
`;

export const quickActionsContainer = css`
  overflow-x: auto;

  @media screen and (max-width: ${theme.breaks.sm}px) {
    width: auto;
    flex-wrap: nowrap;
  }
`;

export const draggableRow = css`
  background-color: ${theme.colors.white};
`;

export const fullWidthCell = css`
  width: 100%;
`;
