/** @jsxImportSource @emotion/react */
import {
  Drawer as MuiDrawer,
  Grid as MuiGrid,
  Hidden as MuiHidden,
} from '@material-ui/core';
import { Buttons, Loading, TextFields } from '@zip/business-components';
import { IconDefinition } from '@zip/react-icons/dist/cjs/types';
import * as Icons from '@zip/react-icons/fearless';
import { DrawerPost } from 'components';
import { HelpCentreCategories, PageAction, PageRoute } from 'enums';
import { Constants, css, isProduction, isSandbox, theme } from 'global';
import { useFetch, useScrollTop } from 'hooks';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HelpCentreArticle, HelpCentreCategory } from 'types';
import { logEvent } from 'utils';
import * as styles from './HelpCentre.styles';
import { HelpCentreProps } from './HelpCentreModuleProps';

const HelpCentreModule: FC<HelpCentreProps> = ({
  isOpen = false,
  setIsOpen,
}) => {
  const { get } = useFetch();
  const { scrollTop, scrollProps } = useScrollTop();

  const handleClose = (): void => setIsOpen(false);

  const [selectedCategory, setSelectedCategory] =
    useState<HelpCentreCategory>();
  const [articles, setArticles] = useState<HelpCentreArticle[]>([]);
  const [isLoadingArticles, setIsLoadingArticles] = useState<boolean>(false);

  function parseBody(html): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    const newBody = tmp.textContent || tmp.innerText || '';
    const maxLength = 50;
    const addEllipsis = newBody?.length > maxLength;
    return `${newBody.substring(0, maxLength).trim()}${
      addEllipsis ? '...' : ''
    }`;
  }

  async function getCategory(id): Promise<void> {
    setIsLoadingArticles(true);
    const response = await get(`/help?CategoryId=${id}`).then((res) =>
      res?.json()
    );

    setArticles(
      response?.articles?.map((article) => ({
        ...article,
        body: parseBody(article.body),
      }))
    );
    setIsLoadingArticles(false);
  }

  const handleCategoryClick = (category: HelpCentreCategory): void => {
    logEvent(PageAction.openedHelpCentreCategory, {
      categoryId: category.id,
      categoryName: category.name,
    });
    getCategory(category.id);
    setSelectedCategory(category);
  };

  const [categories, setCategories] = useState<HelpCentreCategory[]>([]);

  async function getCategories(): Promise<void> {
    setIsLoadingArticles(true);
    const response: { categories: HelpCentreCategory[] } = await get(
      '/help/categories'
    ).then((res) => res?.json());

    function getCategoryIcon(categoryId): FC<IconDefinition> {
      switch (categoryId) {
        case HelpCentreCategories.AccountUpdates:
          return Icons.Briefcase;
        case HelpCentreCategories.Application:
          return !isProduction && Icons.Application;
        case HelpCentreCategories.Customers:
          return Icons.UserMultiple;
        case HelpCentreCategories.Dashboard:
          return Icons.DeviceLaptop;
        case HelpCentreCategories.Marketing:
          return Icons.Megaphone;
        case HelpCentreCategories.Orders:
          return Icons.ShoppingCart;
        case HelpCentreCategories.Training:
          return Icons.Book;
        default:
          return null;
      }
    }

    if (!isProduction) {
      // set MerchantApplication category to top of list when relevant
      response?.categories?.sort((a) =>
        a.id === HelpCentreCategories.Application ? -1 : 0
      );
    }

    setCategories(
      response?.categories?.map((category) => ({
        ...category,
        icon: getCategoryIcon(category.id),
      }))
    );
    setIsLoadingArticles(false);
  }

  const [helpSearchValue, setHelpSearchValue] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      logEvent(PageAction.openedHelpCentre);
      getCategories();
    }
  }, [isOpen]);

  const handleReset = (): void => {
    setSelectedCategory(null);
    setArticles([]);
    setHelpSearchValue(null);
  };

  const handleBack = (): void => {
    if (selectedCategory) {
      handleReset();
    } else {
      handleClose();
    }
  };

  const searchArticlesDebounced = useMemo(
    () =>
      _.debounce(async (searchTerm: string) => {
        if (!searchTerm) {
          setArticles([]);
          getCategories();
          return;
        }
        setIsLoadingArticles(true);
        const response: { articles: HelpCentreArticle[] } = await get(
          `/help?searchValue=${searchTerm}`
        ).then((res) => (res.status === 200 ? res?.json() : []));

        setArticles(
          response?.articles?.map((article) => ({
            ...article,
            body: parseBody(article.body),
          }))
        );
        setIsLoadingArticles(false);
      }, 420),
    []
  );

  const handleSearchChange = (e): void => {
    const searchTerm = e?.target?.value ?? '';
    setHelpSearchValue(searchTerm);
    searchArticlesDebounced(searchTerm);
  };

  const handleClearSearch = (): void => {
    setArticles([]);
    setHelpSearchValue(null);
    getCategories();
  };

  const { push } = useHistory();

  const handleSetupGuideClick = (): void => {
    logEvent(PageAction.openedSetupGuideHC);
    push(PageRoute.SetupGuide);
    setIsOpen(false);
  };

  return (
    <MuiDrawer
      id="helpDrawer"
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      css={styles.drawer}
      transitionDuration={250}
    >
      {/* Mobile */}
      <MuiHidden smUp>
        <MuiGrid container css={styles.mobileTitlebar}>
          <MuiGrid item xs={2}>
            <Icons.ChevronLeft
              fill={theme.colors.primary.dark}
              onClick={handleBack}
              css={css.closeIcon}
            />
          </MuiGrid>
          <MuiGrid
            container
            item
            xs={8}
            justifyContent="center"
            alignItems="center"
          >
            <p className="heading2" css={[css.noMargin, css.ellipsisOverflow]}>
              {selectedCategory?.name || 'Help centre'}
            </p>
          </MuiGrid>
          <MuiGrid item xs={2} />
        </MuiGrid>
      </MuiHidden>
      <div css={styles.headerContainer(!!selectedCategory)}>
        {/* Desktop */}
        <MuiHidden xsDown>
          <div css={css.titleBar}>
            <p className="heading1" css={css.noMargin}>
              Help centre
            </p>
            <Icons.Close
              fill={theme.colors.primary.dark}
              onClick={handleClose}
              css={css.closeIcon}
            />
          </div>
          {selectedCategory && (
            <div id="breadcrumbs" css={styles.breadcrumbs}>
              <Buttons.Text
                type="button"
                onClick={handleReset}
                css={styles.helpCentreCrumb}
              >
                Help centre
              </Buttons.Text>
              {'> '}
              {selectedCategory.name}
            </div>
          )}
        </MuiHidden>
        {!selectedCategory && (
          <TextFields.Outlined
            id="helpSearch"
            placeholder="Search"
            fullWidth
            css={styles.helpSearch}
            value={helpSearchValue}
            onChange={handleSearchChange}
            startIcon={
              <Icons.Search
                width={20}
                height={20}
                fill={theme.colors.neutral[500]}
              />
            }
            endIcon={
              helpSearchValue && (
                <Icons.Close css={css.clearIcon} onClick={handleClearSearch} />
              )
            }
          />
        )}
      </div>
      <div
        id="helpArticleList"
        css={styles.helpArticleList(!scrollTop)}
        {...scrollProps}
      >
        {!isSandbox && !selectedCategory && !articles.length && (
          <div
            css={styles.setupGuide}
            role="button"
            onClick={handleSetupGuideClick}
          >
            <div css={css.flex}>
              <img src={Constants.assets.illustrations.rocket} alt="Rocket" />
              <div>
                <h1 className="heading3">
                  Setup guide
                  <Icons.ArrowRight css={css.icon(true, '20px', '20px')} />
                </h1>
                <p className="body2">
                  Our setup guide will help you get started.
                </p>
              </div>
            </div>
          </div>
        )}
        {articles?.length < 1 &&
          !isLoadingArticles &&
          categories?.map(
            (category) =>
              category.icon && (
                <DrawerPost
                  id={`helpArticleListItem-${category.id}`}
                  key={category.id}
                  title={category.name}
                  description={category.description}
                  Icon={category.icon}
                  onClick={(): void => handleCategoryClick(category)}
                />
              )
          )}
        {!isLoadingArticles && (
          <>
            {articles?.length > 0 &&
              articles?.map((article) => (
                <DrawerPost
                  key={article.id}
                  id={`helpArticleListItem-${article.id}`}
                  title={article.title}
                  description={article.body}
                  url={article.url}
                  onClick={(): void =>
                    logEvent(PageAction.openedHelpCentreArticle, {
                      articleId: article.id,
                      articleTitle: article.title,
                    })
                  }
                />
              ))}
            <div css={styles.helpCentreLink}>
              <Buttons.Text
                onClick={(): void => {
                  if (selectedCategory) {
                    logEvent(PageAction.openedHelpCentreCategoryPage, {
                      categoryId: selectedCategory.id,
                      categoryName: selectedCategory.name,
                    });
                    window.open(
                      `${Constants.helpCenterBaseUrl}/categories/${selectedCategory?.id}`
                    );
                    return;
                  }
                  logEvent(PageAction.openedHelpCentreMainPage);
                  window.open(Constants.helpCenterBaseUrl);
                }}
              >
                Search all help articles <Icons.ArrowSquareOut />
              </Buttons.Text>
            </div>
          </>
        )}
        {isLoadingArticles && (
          <div css={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
    </MuiDrawer>
  );
};

export default HelpCentreModule;
