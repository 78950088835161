import { css } from '@emotion/react';
import { theme } from 'global';

export const radioGroup = css`
  margin: 4px 0 16px;
  & > .MuiFormControlLabel-root:not(:last-of-type) {
    margin-right: 24px;
  }
`;

export const textfield = css`
  margin: 0 0 24px;
`;

export const instoreCodeEndIcon = css`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: default;
`;

export const validatedProduct = css`
  margin: 0 4px 0 0;
  color: ${theme.colors.primary.medium};
`;

export const loadingPrompt = css`
  text-align: center;
  min-width: 320px;
  max-width: 320px;
  margin: 8px auto;
`;
