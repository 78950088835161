import { css } from '@emotion/react';
import { theme } from 'global';

export const label = css`
  cursor: inherit;
  padding: 2px 8px;
  background-color: ${theme.colors.primary.lightest};
  color: ${theme.colors.primary.medium};
  border-radius: ${theme.radii.md};
`;
