import { css } from '@emotion/react';
import { theme } from 'global';

export const list = css`
  > li > div > svg > rect {
    fill: ${theme.colors.primary.lighter};
  }

  > li > div > svg > path {
    fill: ${theme.colors.primary.medium};
  }
`;
