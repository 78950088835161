export enum ProductClassification {
  ZipPay = 0,
  ZipMoney = 1,
  ZipBusinessTradePlus = 2,
  ZipBusinessTrade = 3,
  ZipPlus = 4,
}

export const classificationsToShow = new Set([
  ProductClassification.ZipPay,
  ProductClassification.ZipMoney,
  ProductClassification.ZipPlus,
]);
