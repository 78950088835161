export enum MessageConfigType {
  OrderAuthorised = 'MerchantBranchShoppingCartStatusAuthorised',
  OrderCancelled = 'MerchantBranchShoppingCartStatusCancelled',
  OrderCompleted = 'MerchantBranchShoppingCartStatusCompleted',
  OrderExpiring = 'MerchantBranchAuthChargeExpiring',
  OrderRefunded = 'MerchantBranchShoppingCartStatusRefunded',
  CustomerApproved = 'MerchantBranchCreditProfileStateTypeApproved',
  CustomerUnderReview = 'MerchantBranchShoppingCartStatusUnderReview',
  CustomerDeclined = 'MerchantBranchShoppingCartStatusDeclined',
}
