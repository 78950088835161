import { SerializedStyles, css } from '@emotion/react';
import { Theme } from '@zip/theme';

const horizontalBreak = 675;

export const container =
  (backgroundColor: string, carouselItem: boolean) =>
  (theme: Theme): SerializedStyles =>
    css`
      height: ${carouselItem ? '100%' : 'auto'};
      border: 1px solid ${theme.colors.neutral[200]};
      border-radius: ${theme.radii.lg};
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      position: relative;
      background-color: ${backgroundColor};

      :not(:first-of-type) {
        margin-top: 1.5rem;
      }

      &:hover {
        cursor: pointer;
      }
    `;

export const content = css`
  padding: 1.5rem;
  min-width: 320px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const textButtonCopy = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const imageContainer = (
  backgroundColor: string,
  imgAlign: string = 'center'
): SerializedStyles => css`
  position: relative;
  min-width: 35%;

  display: flex;
  align-items: ${imgAlign};

  @media screen and (max-width: ${horizontalBreak}px) {
    min-width: unset;
  }

  ::before {
    content: ' ';
    position: absolute;
    left: -1.25rem;
    width: calc(100% + 4rem);
    height: 100%;
    background-color: ${backgroundColor};
    transform: skew(10deg);

    @media screen and (max-width: ${horizontalBreak}px) {
      left: -3.5rem;
      width: 6rem;
    }
  }
`;

export const image = (imgPadding: string = '0'): SerializedStyles => css`
  z-index: 3;
  position: absolute;
  left: 10%;
  right: 10%;
  margin: 0 auto;
  padding: ${imgPadding};
  max-height: 100%;
  max-width: calc(100% - calc(2 * ${imgPadding}));

  @media screen and (max-width: ${horizontalBreak}px) {
    display: none;
  }
`;
