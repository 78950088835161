export enum PageRoute {
  Application = '/apply',
  Account = '/account',
  Campaigns = '/campaigns',
  Customers = '/customers',
  CliInvite = '/account-limit-increase',
  Home = '/',
  Disbursement = '/disbursement-reports',
  Integration = '/integration',
  InviteCustomer = '/invite-customer',
  Settings = '/settings',
  Marketing = '/marketing',
  Notifications = '/notifications',
  Orders = '/orders',
  CreateOrder = '/orders/create',
  ExpiringOrders = '/orders/status/expires-soon',
  PosMaterials = '/pos-materials',
  Offers = '/offers',
  RepaymentCalculator = '/repayment-calculator',
  Reports = '/reports',
  SetupGuide = '/get-started',
  StoreDirectory = '/store-directory',
  Tools = '/tools',
  Users = '/users',
}
