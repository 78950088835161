/** @jsxImportSource @emotion/react */
import {
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Buttons,
  Labels,
  Spinner,
  Tables,
  Tooltip,
  Widgets,
} from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { BasePage, PageActions } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import {
  MerchantClassSettingType,
  MessageConfigId,
  MessageConfigType,
  PageAction,
} from 'enums';
import { useFormik } from 'formik';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { PageProps } from 'types';
import { logError } from 'utils';

import { Constants, css } from 'global';
import * as styles from './NotificationSettingsPage.styles';

const NotificationSettingsPage: FC<PageProps> = () => {
  const { get, post } = useFetch();
  const Snackbar = useSnackbar();
  const { merchantCountry, checkMerchantDashboardSetting } = useMerchantData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  // Content settings
  const enableSmsNotifications = checkMerchantDashboardSetting(
    MerchantClassSettingType.EnableSmsNotifications
  );
  const shouldShowExpiresSoonTag =
    new Date() <= Constants.expiryDate.expiresSoonTag;

  const columns = ['Notification status', 'Email'];
  if (enableSmsNotifications) {
    columns.push('SMS message');
  }

  const notificationsInitialData = {
    customerUnderReview: {
      messageConfigId: MessageConfigId.CustomerUnderReview,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.CustomerUnderReview,
    },
    customerDeclined: {
      messageConfigId: MessageConfigId.CustomerDeclined,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.CustomerDeclined,
    },
    customerApproved: {
      messageConfigId: MessageConfigId.CustomerApproved,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.CustomerApproved,
    },

    orderAuthorised: {
      messageConfigId: MessageConfigId.OrderAuthorised,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.OrderAuthorised,
    },
    orderCancelled: {
      messageConfigId: MessageConfigId.OrderCancelled,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.OrderCancelled,
    },
    orderCompleted: {
      messageConfigId: MessageConfigId.OrderCompleted,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.OrderCompleted,
    },
    orderRefunded: {
      messageConfigId: MessageConfigId.OrderRefunded,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.OrderRefunded,
    },
    orderExpiring: {
      messageConfigId: MessageConfigId.OrderExpiring,
      countryId: merchantCountry,
      emailNotificationActive: false,
      smsNotificationActive: false,
      type: MessageConfigType.OrderExpiring,
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: notificationsInitialData,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);

      const payload = {
        notificationSettings: Object.values(formValues),
      };

      const result = await post('/NotificationSetting/update', payload)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error('Something went wrong');
          }
          return res.json();
        })
        .catch((err) => {
          logError(PageAction.updateNotificationSettings, err, payload);
          Snackbar.error('An error occured processing your request.');
        });

      setIsSubmitting(false);

      if (result.isSuccess) {
        Snackbar.success('Notification settings updated successfully');
      } else {
        Snackbar.error(
          'An error occurred updating your settings. Please try again'
        );
      }
    },
  });

  function updateForm(messageConfigs): void {
    const customerUnderReviewConfig =
      messageConfigs.find(
        (x) => x.type === MessageConfigType.CustomerUnderReview
      ) || notificationsInitialData.customerUnderReview;
    const customerDeclinedConfig =
      messageConfigs.find(
        (x) => x.type === MessageConfigType.CustomerDeclined
      ) || notificationsInitialData.customerDeclined;
    const customerApprovedConfig =
      messageConfigs.find(
        (x) => x.type === MessageConfigType.CustomerApproved
      ) || notificationsInitialData.customerApproved;

    const orderAuthorisedConfig =
      messageConfigs.find(
        (x) => x.type === MessageConfigType.OrderAuthorised
      ) || notificationsInitialData.orderAuthorised;
    const orderCancelledConfig =
      messageConfigs.find((x) => x.type === MessageConfigType.OrderCancelled) ||
      notificationsInitialData.orderCancelled;
    const orderCompletedConfig =
      messageConfigs.find((x) => x.type === MessageConfigType.OrderCompleted) ||
      notificationsInitialData.orderCompleted;
    const orderRefundedConfig =
      messageConfigs.find((x) => x.type === MessageConfigType.OrderRefunded) ||
      notificationsInitialData.orderRefunded;
    const orderExpiringConfig =
      messageConfigs.find((x) => x.type === MessageConfigType.OrderExpiring) ||
      notificationsInitialData.orderRefunded;

    const formData = {
      customerUnderReview: customerUnderReviewConfig,
      customerDeclined: customerDeclinedConfig,
      customerApproved: customerApprovedConfig,
      orderAuthorised: orderAuthorisedConfig,
      orderCancelled: orderCancelledConfig,
      orderCompleted: orderCompletedConfig,
      orderRefunded: orderRefundedConfig,
      orderExpiring: orderExpiringConfig,
    };

    Object.keys(formData).forEach(
      (key) => formData[key] === undefined && delete formData[key]
    );

    formik.setValues(formData);
  }

  function handleMasterSwitchChange(checked, notification): void {
    formik.setFieldValue(`${notification}.emailNotificationActive`, checked);
    formik.setFieldValue(`${notification}.smsNotificationActive`, checked);
  }

  useEffect(() => {
    async function getNotificationSettings(): Promise<void> {
      setIsLoading(true);
      try {
        const response = await get('/NotificationSetting');
        if (response.status === 200) {
          const payload = await response?.json();
          updateForm(payload.messageConfigs);
          return;
        }
        if (response.status !== 204) {
          throw new Error('Something went wrong');
        }
      } catch (err) {
        logError(PageAction.fetchNotificationSettings, err);

        if (err instanceof Error) {
          Snackbar.error('An error occured processing your request.');
        }
      } finally {
        setIsLoading(false);
      }
    }
    getNotificationSettings();
  }, []);

  return (
    <BasePage title="Notifications" hasActions>
      {isLoading && <Spinner />}
      {!isLoading && (
        <form onSubmit={formik.handleSubmit}>
          <div>
            <h2>Order notifications</h2>
            <p className="body2">
              Get notified via email{' '}
              {enableSmsNotifications && <>and/or SMS message </>}
              when an order status is updated.
            </p>
            <Tables.Custom columns={columns} css={styles.table}>
              <MuiTableRow>
                <MuiTableCell>
                  {enableSmsNotifications && (
                    <Widgets.Checkbox
                      checked={
                        formik.values.orderAuthorised.emailNotificationActive &&
                        formik.values.orderAuthorised.smsNotificationActive
                      }
                      onChange={(e, checked): void =>
                        handleMasterSwitchChange(checked, 'orderAuthorised')
                      }
                      label=""
                    />
                  )}
                  Authorised
                </MuiTableCell>
                <MuiTableCell align="center">
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="orderAuthorised.emailNotificationActive"
                    checked={
                      formik.values.orderAuthorised.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
                {enableSmsNotifications && (
                  <MuiTableCell>
                    <Widgets.Checkbox
                      css={styles.checkbox}
                      name="orderAuthorised.smsNotificationActive"
                      checked={
                        formik.values.orderAuthorised.smsNotificationActive
                      }
                      onChange={formik.handleChange}
                      label=""
                    />
                  </MuiTableCell>
                )}
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>
                  {enableSmsNotifications && (
                    <Widgets.Checkbox
                      checked={
                        formik.values.orderCompleted.emailNotificationActive &&
                        formik.values.orderCompleted.smsNotificationActive
                      }
                      onChange={(e, checked): void =>
                        handleMasterSwitchChange(checked, 'orderCompleted')
                      }
                      label=""
                    />
                  )}
                  Completed
                </MuiTableCell>
                <MuiTableCell>
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="orderCompleted.emailNotificationActive"
                    checked={
                      formik.values.orderCompleted.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
                {enableSmsNotifications && (
                  <MuiTableCell>
                    <Widgets.Checkbox
                      css={styles.checkbox}
                      name="orderCompleted.smsNotificationActive"
                      checked={
                        formik.values.orderCompleted.smsNotificationActive
                      }
                      onChange={formik.handleChange}
                      label=""
                    />
                  </MuiTableCell>
                )}
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>
                  {enableSmsNotifications && (
                    <Widgets.Checkbox
                      checked={
                        formik.values.orderRefunded.emailNotificationActive &&
                        formik.values.orderRefunded.smsNotificationActive
                      }
                      onChange={(e, checked): void =>
                        handleMasterSwitchChange(checked, 'orderRefunded')
                      }
                      label=""
                    />
                  )}
                  Refunded
                </MuiTableCell>
                <MuiTableCell>
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="orderRefunded.emailNotificationActive"
                    checked={
                      formik.values.orderRefunded.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
                {enableSmsNotifications && (
                  <MuiTableCell>
                    <Widgets.Checkbox
                      css={styles.checkbox}
                      name="orderRefunded.smsNotificationActive"
                      checked={
                        formik.values.orderRefunded.smsNotificationActive
                      }
                      onChange={formik.handleChange}
                      label=""
                    />
                  </MuiTableCell>
                )}
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>
                  {enableSmsNotifications && (
                    <Widgets.Checkbox
                      checked={
                        formik.values.orderCancelled.emailNotificationActive &&
                        formik.values.orderCancelled.smsNotificationActive
                      }
                      onChange={(e, checked): void =>
                        handleMasterSwitchChange(checked, 'orderCancelled')
                      }
                      label=""
                    />
                  )}
                  Cancelled
                </MuiTableCell>
                <MuiTableCell>
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="orderCancelled.emailNotificationActive"
                    checked={
                      formik.values.orderCancelled.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
                {enableSmsNotifications && (
                  <MuiTableCell>
                    <Widgets.Checkbox
                      css={styles.checkbox}
                      name="orderCancelled.smsNotificationActive"
                      checked={
                        formik.values.orderCancelled.smsNotificationActive
                      }
                      onChange={formik.handleChange}
                      label=""
                    />
                  </MuiTableCell>
                )}
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>
                  <div css={styles.flexCellContent}>
                    Expires soon
                    <Tooltip
                      variant="primary"
                      arrow
                      interactive
                      maxWidth={288}
                      placement={isMobile ? 'top' : 'bottom-start'}
                      content={
                        <>
                          <p className="body1" css={css.noMargin}>
                            Order expiry notifications
                          </p>
                          <p className="body4" css={css.noBtmMrgn}>
                            Turn these notifications on to get an email when you
                            have authorised or partially captured orders
                            expiring within 30 days.
                          </p>
                        </>
                      }
                    >
                      <span>
                        <Icons.InformationCircle width={24} height={24} />
                      </span>
                    </Tooltip>
                    {shouldShowExpiresSoonTag && (
                      <Labels.Basic
                        variant="primary"
                        className="body3"
                        label="NEW"
                      />
                    )}
                  </div>
                </MuiTableCell>
                <MuiTableCell>
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="orderExpiring.emailNotificationActive"
                    checked={
                      formik.values.orderExpiring.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
              </MuiTableRow>
            </Tables.Custom>
          </div>
          <div css={styles.customerTable}>
            <h2>Customer notifications</h2>
            <p className="body2">
              Get notified when a customer&apos;s application is updated with
              the specific status via email
              {enableSmsNotifications && <> and/or SMS message.</>}
            </p>
            <Tables.Custom columns={columns} css={styles.table}>
              <MuiTableRow>
                <MuiTableCell>
                  {enableSmsNotifications && (
                    <Widgets.Checkbox
                      checked={
                        formik.values.customerApproved
                          .emailNotificationActive &&
                        formik.values.customerApproved.smsNotificationActive
                      }
                      onChange={(e, checked): void =>
                        handleMasterSwitchChange(checked, 'customerApproved')
                      }
                      label=""
                    />
                  )}
                  Approved
                </MuiTableCell>
                <MuiTableCell>
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="customerApproved.emailNotificationActive"
                    checked={
                      formik.values.customerApproved.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
                {enableSmsNotifications && (
                  <MuiTableCell>
                    <Widgets.Checkbox
                      css={styles.checkbox}
                      name="customerApproved.smsNotificationActive"
                      checked={
                        formik.values.customerApproved.smsNotificationActive
                      }
                      onChange={formik.handleChange}
                      label=""
                    />
                  </MuiTableCell>
                )}
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>
                  {enableSmsNotifications && (
                    <Widgets.Checkbox
                      checked={
                        formik.values.customerUnderReview
                          .emailNotificationActive &&
                        formik.values.customerUnderReview.smsNotificationActive
                      }
                      onChange={(e, checked): void =>
                        handleMasterSwitchChange(checked, 'customerUnderReview')
                      }
                      label=""
                    />
                  )}
                  Submitted
                </MuiTableCell>
                <MuiTableCell>
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="customerUnderReview.emailNotificationActive"
                    checked={
                      formik.values.customerUnderReview.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
                {enableSmsNotifications && (
                  <MuiTableCell>
                    <Widgets.Checkbox
                      css={styles.checkbox}
                      name="customerUnderReview.smsNotificationActive"
                      checked={
                        formik.values.customerUnderReview.smsNotificationActive
                      }
                      onChange={formik.handleChange}
                      label=""
                    />
                  </MuiTableCell>
                )}
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>
                  {enableSmsNotifications && (
                    <Widgets.Checkbox
                      checked={
                        formik.values.customerDeclined
                          .emailNotificationActive &&
                        formik.values.customerDeclined.smsNotificationActive
                      }
                      onChange={(e, checked): void =>
                        handleMasterSwitchChange(checked, 'customerDeclined')
                      }
                      label=""
                    />
                  )}
                  Declined
                </MuiTableCell>
                <MuiTableCell>
                  <Widgets.Checkbox
                    css={styles.checkbox}
                    name="customerDeclined.emailNotificationActive"
                    checked={
                      formik.values.customerDeclined.emailNotificationActive
                    }
                    onChange={formik.handleChange}
                    label=""
                  />
                </MuiTableCell>
                {enableSmsNotifications && (
                  <MuiTableCell>
                    <Widgets.Checkbox
                      css={styles.checkbox}
                      name="customerDeclined.smsNotificationActive"
                      checked={
                        formik.values.customerDeclined.smsNotificationActive
                      }
                      onChange={formik.handleChange}
                      label=""
                    />
                  </MuiTableCell>
                )}
              </MuiTableRow>
            </Tables.Custom>
          </div>

          <PageActions>
            <Buttons.Primary
              type="submit"
              disabled={Boolean(Object.keys(formik.errors).length)}
              loading={isSubmitting}
            >
              Save
            </Buttons.Primary>
          </PageActions>
        </form>
      )}
    </BasePage>
  );
};

export default NotificationSettingsPage;
