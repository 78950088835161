/** @jsxImportSource @emotion/react */
import { Buttons } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { PageAction } from 'enums';
import { Constants, css, isSandbox } from 'global';
import { FC, useState } from 'react';
import { logEvent } from 'utils';

import CampaignDates from '../campaign-dates';
import * as styles from './Campaign.styles';
import { CampaignProps } from './CampaignProps';

export const Campaign: FC<CampaignProps> = ({ campaign }) => {
  const [anchorEl, setAnchorEl] = useState();

  const campaignImage = campaign.media?.find((media) =>
    media.contentType.includes('image/')
  );

  const submitButton = campaign.children.find((child) =>
    child.content?.toLowerCase()?.includes('offer')
  );
  const assetsButton = campaign.children.find((child) =>
    child.content?.toLowerCase()?.includes('asset')
  );

  const handleSubmitClick = (): void => {
    if (!submitButton?.url) {
      return;
    }
    logEvent(PageAction.openedZipCampaign, {
      campaignId: campaign.id,
      campaignTitle: campaign.title,
    });
    window.open(`${submitButton.url}/?utm_source=${Constants.utmTags.source}`);
  };
  const handleAssetsClick = (): void => {
    if (!assetsButton?.url) {
      return;
    }
    logEvent(PageAction.openedZipCampaignAssets, {
      campaignId: campaign.id,
      campaignTitle: campaign.title,
    });
    window.open(assetsButton?.url);
  };

  const handleOpenDates = (e): void => setAnchorEl(e.currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  const isSubmissionClosed = campaign?.timeline?.close
    ? new Date(campaign?.timeline?.close) <= new Date()
    : true;

  return (
    <div css={styles.container}>
      <div css={styles.imageContainer}>
        <img
          src={campaignImage?.url}
          alt={campaignImage?.title}
          css={styles.image}
        />
      </div>
      <div css={styles.contentContainer}>
        <div id="copy-container">
          <p className="heading2" css={css.noTopMrgn}>
            {campaign?.title}
          </p>
          <div
            onClick={handleOpenDates}
            role="button"
            tabIndex={0}
            css={styles.viewDates(!!anchorEl)}
          >
            <Icons.Calendar /> View dates
          </div>
          <CampaignDates
            anchorEl={anchorEl}
            onClose={handleClose}
            campaign={campaign}
          />
          <p className="body2">{campaign?.description}</p>
        </div>
        <div css={styles.actions}>
          <Buttons.Secondary
            disabled={isSubmissionClosed || isSandbox}
            onClick={handleSubmitClick}
          >
            {isSubmissionClosed ? 'Closed' : 'Submit offer'}
          </Buttons.Secondary>
          <Buttons.Text
            disabled={!assetsButton?.url || isSandbox}
            onClick={handleAssetsClick}
          >
            {assetsButton?.content || 'Assets'}
          </Buttons.Text>
        </div>
      </div>
    </div>
  );
};

export default Campaign;
