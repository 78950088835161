import { css } from '@emotion/react';
import { theme } from 'global';

export const calculator = css`
  background-color: rgba(237, 230, 255, 0.4);
  > .MuiAccordion-root {
    background-color: transparent;
  }

  @media only screen and (min-width: ${theme.breaks.md}px) {
    border-top-right-radius: ${theme.radii.lg};
  }
`;

export const smallScreenMargin = css`
  @media only screen and (max-width: ${theme.breaks.xs}px) {
    margin: 0 16px;
    padding: 32px 16px !important;
  }
`;

export const spacer = css`
  background-color: ${theme.colors.neutral[0]};
`;
