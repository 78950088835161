import { css } from '@emotion/react';

import { css as globalCss, theme } from 'global';

export const noticeBanner = (dense) => css`
  padding: ${dense ? '16px' : '32px'};
  overflow: hidden;
  background-color: transparent;
  border: 1px solid ${theme.colors.neutral[200]};
  h2,
  p {
    max-width: 640px;
    color: inherit;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: ${dense ? '8px' : '16px'};
  }
`;

function getPromoBackgroundColourVariant(variant) {
  switch (variant) {
    case 'consumer':
      return theme.colors.consumer.lightest;
    case 'grease-lightning':
      return theme.colors.electric;
    default:
      return theme.colors.white;
  }
}

export const noticeBannerContainer = css`
  display: flex;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
  }
`;

export const promoBanner = (variant) => css`
  padding: 24px;
  overflow: hidden;
  background-color: ${getPromoBackgroundColourVariant(variant)};
  width: 100%;
  border-radius: ${theme.radii.lg};
  display: flex;

  @media screen and (max-width: ${theme.breaks.md}px) {
    padding: 16px;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: unset;
    display: block;
  }
`;

export const imageContainer = (backgroundColor) => css`
  display: flex;
  background-color: ${backgroundColor};
  max-width: 180px;

  > img {
    margin: auto;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    max-width: unset;
    max-height: 120px;
    position: relative;
  }
`;

export const promoImage = css`
  display: flex;
  justify-content: stretch;
  min-width: 135px;
  min-height: 135px;
  object-fit: cover;
  border-top-left-radius: ${theme.radii.lg};
  border-bottom-left-radius: ${theme.radii.lg};
  user-select: none;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    max-height: 120px;
    min-height: 120px;
    min-width: unset;
    margin: 0 auto;
    object-fit: contain;
    border-top-right-radius: ${theme.radii.lg};
    border-bottom-left-radius: unset;
  }
`;

export const copyContainer = css`
  margin-left: 24px;
  width: 100%;

  h2,
  p {
    max-width: 680px;
    color: inherit;
  }

  @media screen and (max-width: ${theme.breaks.md}px) {
    margin-left: 16px;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin-left: unset;
    padding: 16px;
  }
`;

export const titleBar = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  user-select: none;

  h1 {
    margin-bottom: 0px;
  }
`;

export const promoCloseIcon = css`
  position: absolute;
  top: 16px;
  right: 16px;

  ${globalCss.closeIcon}
`;

export const bodyCopy = css`
  margin: 16px 0px 0px;
`;
