/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid, Paper as MuiPaper } from '@material-ui/core';
import { Loading, Select, Selectable } from '@zip/business-components';
import { useMerchantData } from 'contexts';
import { DateRange, PageAction } from 'enums';
import { Constants, css } from 'global';
import { FC, useEffect, useState } from 'react';
import { logError, splitTitleCase, useReportFunctions } from 'utils';
import * as styles from './CompletedOrders.styles';

const CompletedOrdersModule: FC = () => {
  const { merchantIdentity, profileData, profileDataIsLoading } =
    useMerchantData();
  const ReportFunctions = useReportFunctions();
  const { getNumberOfDays } = ReportFunctions;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateRanges] = useState<Selectable[]>(
    Object.keys(DateRange).map((dateRange) => ({
      label: splitTitleCase(dateRange),
      value: dateRange.toLowerCase(),
    }))
  );
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    DateRange.Last7Days.toLowerCase()
  );

  const handleDateRangeChange = (newDateRange): void =>
    setSelectedDateRange(newDateRange);

  const [completedOrders, setCompletedOrders] = useState<number>(0);

  function getStartDate(): Date {
    const startDate = new Date();
    startDate.setUTCDate(
      startDate.getDate() - getNumberOfDays(selectedDateRange)
    );
    startDate.setUTCHours(0, 0, 0, 0);
    return startDate;
  }

  useEffect(() => {
    const abortController = new AbortController();
    const getOrdersToReviewData = async (): Promise<void> => {
      setIsLoading(true);
      const endDate = new Date();
      endDate.setUTCDate(endDate.getDate() + 1);
      endDate.setUTCHours(0, 0, 0, 0);
      const query = {
        startDate: getStartDate(),
        endDate,
      };
      await ReportFunctions.fetchCompletedOrders(query, abortController.signal)
        .then((response) => {
          setCompletedOrders(response?.orderCounts?.Completed as number);
          setIsLoading(false);
        })
        .catch((err) => {
          if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
            logError(PageAction.fetchOrdersToReview, err, query);
            setCompletedOrders(null);
            setIsLoading(false);
          }
        });
    };

    if (profileData && !profileDataIsLoading) {
      getOrdersToReviewData();
    }

    return (): void => abortController.abort();
  }, [selectedDateRange, merchantIdentity]);

  return (
    <MuiPaper elevation={0} css={styles.container}>
      <MuiGrid container spacing={0} justifyContent="space-between">
        <div>
          <p className="body2" css={[styles.title, css.noMargin]}>
            Completed orders
          </p>
          {isLoading ? (
            <Loading />
          ) : (
            <p className="title4" css={css.noMargin}>
              {completedOrders ?? 'N/A'}
            </p>
          )}
        </div>

        <Select.Basic
          id="customerOverview-dateRangeDropdown"
          options={dateRanges}
          onChange={(e): void => handleDateRangeChange(e.target.value)}
          value={selectedDateRange}
        />
      </MuiGrid>
    </MuiPaper>
  );
};

export default CompletedOrdersModule;
