/** @jsxImportSource @emotion/react */
import { Global, ThemeProvider } from '@zip/business-components';
import {
  AnalyticsProvider,
  Auth0Provider,
  CookiesProvider,
  MerchantDataProvider,
  NavigationProvider,
  PageTracking,
  SnackbarProvider,
} from 'contexts';
import { theme } from 'global';
import Layout from 'global/layout';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

const App: FC = () => (
  <AnalyticsProvider>
    <PageTracking>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={`${window.location.origin}/callback`}
      >
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <Global />
            <SnackbarProvider>
              <MerchantDataProvider>
                <BrowserRouter>
                  <NavigationProvider>
                    <Layout />
                  </NavigationProvider>
                </BrowserRouter>
              </MerchantDataProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </CookiesProvider>
      </Auth0Provider>
    </PageTracking>
  </AnalyticsProvider>
);

export default App;
