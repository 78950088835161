import { css } from '@emotion/react';
import { theme } from 'global';

export const ownershipInputs = css`
  display: flex;
  gap: 16px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const statusCard = css`
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: ${theme.radii.lg};
  padding: 15px 40px 20px 25px;
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;

  > p {
    margin-bottom: 24px;
  }

  > .MuiButtonBase-root {
    width: 80%;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    > .MuiButtonBase-root {
      width: 60%;
    }
  }
}
`;

export const select = css`
  width: 312px;
  height: 50px;
  radius: 4px;
`;
