import { css } from '@emotion/react';
import { theme } from 'global';

export const card = (hasError) => css`
  border: 2px solid
    ${hasError ? theme.colors.danger : theme.colors.primary.medium};
  border-radius: ${theme.radii.lg};
  padding: 0;
  text-align: center;
`;

export const uploadButton = css`
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding: 16px;

  > .MuiButton-label {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

export const uploadIcon = css`
  background-color: ${theme.colors.primary.lightest};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: unset;
  border-radius: 24px;
  box-shadow: none;
`;

export const list = css`
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  cursor: default;
`;

export const listItem = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 47%;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    width: 100%;
  }

  &:first-of-type {
    margin-top: 0;
  }
  &:nth-of-type(2) {
    @media screen and (min-width: ${theme.breaks.xs + 1}px) {
      margin-top: 0;
    }
  }
`;

export const label = (hasError) => css`
  color: ${hasError ? theme.colors.danger : theme.colors.primary.medium};
  margin-bottom: 0;
  max-width: 100%;
`;

export const imageContainer = css`
  display: flex;
`;

export const removeImageIcon = css`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const imagePreview = css`
  max-width: 75%;
  max-height: 100px;
`;

export const fileName = css`
  color: ${theme.colors.body};
  margin-bottom: 0;
  max-width: 100%;
`;

export const caption = css`
  text-align: center;
  margin-top: 24px;
`;
