/** @jsxImportSource @emotion/react */
import { Hidden as MuiHidden } from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';
import { Loading } from '@zip/business-components';
import { InfiniteScrollTable, SummaryCards, TableRows } from 'components';
import { useMerchantData } from 'contexts';
import {
  FeatureFlag,
  MerchantClassSettingType,
  OrderStatus,
  PageAction,
} from 'enums';
import { Constants } from 'global';
import _ from 'lodash';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import { OrderSummary } from 'types';
import { logError, useOrderFunctions } from 'utils';

import * as styles from './OrdersTable.styles';
import { OrdersTableModuleProps } from './OrdersTableModuleProps';
import { OrdersTableRef } from './OrdersTableRef';

const OrdersTableModule = forwardRef((props: OrdersTableModuleProps, ref) => {
  const {
    searchValue,
    fromDate,
    toDate,
    searchType,
    setSelectedOrderId,
    setSelectedProductDetail,
    selectedOrderId,
    getOrderActions,
  } = props;
  const { merchantIdentity, checkMerchantDashboardSetting } = useMerchantData();
  const { status } = useParams<{ status: string }>();
  const { push, location } = useHistory();
  const showReceipt = checkMerchantDashboardSetting(
    MerchantClassSettingType.ShowReceiptInOrdersTable
  );
  const [expiresSoonDecision] = useDecision(FeatureFlag.EnableExpiresSoon);
  const showExpiresSoon = useMemo(
    () =>
      expiresSoonDecision?.enabled &&
      status === 'expires-soon' &&
      !checkMerchantDashboardSetting(
        MerchantClassSettingType.ExcludeFromAuthChargeExpiry
      ),
    [status]
  );
  const showExpiresSoonExplainer =
    (showExpiresSoon && expiresSoonDecision?.variables?.showExplainer) ?? false;

  const [orders, setOrders] = useState<OrderSummary[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const OrderFunctions = useOrderFunctions();

  // Set pageSize to be responsive to window height
  const rowHeightPx = 56;
  const pageSize = Math.round(window.innerHeight / rowHeightPx) + 2;

  const columns = [
    'Date',
    'Customer',
    'Amount',
    'Order ID',
    showReceipt ? 'Receipt' : 'Reference',
    'Status',
    'Action',
  ];

  if (showExpiresSoon) {
    columns.splice(1, 0, 'Expires in');
  }

  const getOrdersDebounced = useMemo(
    () =>
      _.debounce(async (query) => {
        setIsLoading(true);

        const doConcat: boolean = query.skip > 0;

        if (!doConcat) {
          setOrders([]);
        }

        await OrderFunctions.getOrders(query)
          .then((res) => {
            setOrders(
              doConcat
                ? OrderFunctions.concatUniqueOrders(orders, res?.orders)
                : res?.orders
            );
            setHasMore(res?.hasMore);
            setIsLoading(false);
          })
          .catch((err) => {
            if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
              logError(PageAction.searchOrders, err, query);
              setHasMore(false);
              setIsLoading(false);
            }
            setOrders([]);
          });
      }, 420),
    [orders, merchantIdentity]
  );

  const getOrders = (page = 0): void => {
    let query = {
      searchValue,
      fromDate,
      toDate,
      searchType,
      status,
      statuses: [],
      sortBy: 'OrderDate',
      orderBy: 'Descending',
      take: pageSize,
      skip: pageSize * page,
    };

    if (showExpiresSoon) {
      query = {
        ...query,
        status: null,
        statuses: [OrderStatus.Authorised, OrderStatus.PartiallyCaptured],
        orderBy: 'Ascending',
      };
    }

    getOrdersDebounced(query);
  };

  const handlePageChange = (event, newPage: number): void =>
    setCurrentPage(newPage);

  const handleOrderRowClick = (order: OrderSummary): void => {
    if (selectedOrderId === order.id) {
      return;
    }
    setSelectedOrderId(order.id);
    setSelectedProductDetail(order.productDetails);
    push(
      !selectedOrderId
        ? `${location.pathname}/${order.id}`
        : `${location.pathname.replace(`/${selectedOrderId}`, `/${order.id}`)}`
    );
  };

  useEffect(() => {
    getOrders(0);
    return (): void => OrderFunctions.resetGetRequests();
  }, [
    searchValue,
    fromDate,
    toDate,
    searchType,
    status,
    merchantIdentity,
    showExpiresSoon,
  ]);

  useEffect(() => {
    getOrders(currentPage);
    return (): void => OrderFunctions.resetGetRequests();
  }, [currentPage]);

  useImperativeHandle(
    ref,
    (): OrdersTableRef => ({
      getOrders,
    })
  );

  return (
    <>
      <MuiHidden smDown>
        <InfiniteScrollTable
          columns={selectedOrderId ? columns.slice(0, -1) : columns}
          css={styles.ordersTable(showExpiresSoon && showExpiresSoonExplainer)}
          stickyHeader
          length={orders?.length}
          loadMore={(): void => handlePageChange(null, currentPage + 1)}
          hasMore={hasMore}
          isLoading={isLoading}
          endMessage="No more orders found matching the specified filters"
          emptyMessage="No orders found matching the specified filters"
        >
          {orders &&
            orders?.map((order) => (
              <TableRows.Order
                key={order.id}
                order={order}
                showExpiry={showExpiresSoon}
                onClick={(): void => handleOrderRowClick(order)}
                selected={selectedOrderId === order?.id}
                sidebarOpen={!!selectedOrderId}
                actions={getOrderActions(order)}
              />
            ))}
        </InfiniteScrollTable>
      </MuiHidden>
      <MuiHidden mdUp>
        <InfiniteScroll
          dataLength={orders?.length}
          next={(): void => handlePageChange(null, currentPage + 1)}
          hasMore={hasMore}
          endMessage={
            !isLoading &&
            orders?.length > 0 && (
              <p style={{ textAlign: 'center' }}>
                No more orders found matching the specified filters
              </p>
            )
          }
          loader={<Loading />}
          scrollableTarget="content"
        >
          {orders &&
            orders?.map((order) => (
              <SummaryCards.Order
                key={order.id}
                order={order}
                onClick={(): void => handleOrderRowClick(order)}
              />
            ))}
          {!isLoading && orders?.length < 1 && (
            <p style={{ textAlign: 'center' }}>
              No orders found matching the specified filters
            </p>
          )}
        </InfiniteScroll>
      </MuiHidden>
    </>
  );
});

export default OrdersTableModule;
