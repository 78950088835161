/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import * as Icons from '@zip/react-icons/fearless';
import { Tile } from 'components';
import { useMerchantData } from 'contexts';
import {
  FeatureFlag,
  MerchantClassSettingType,
  MerchantStatus,
  PageRoute,
  PermissionEnum,
} from 'enums';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InviteCustomerDialogModule from '../invite-customer';
import { useDecision } from '@optimizely/react-sdk';
import SendGuideModule from 'modules/send-guide';
import * as styles from './DashboardTiles.styles';

const DashboardTilesModule: FC = () => {
  const {
    checkPermission,
    getMerchantStatus,
    checkPermissions,
    checkMerchantDashboardSetting,
    getMerchantDashboardSettingValue,
    getMerchantUniqueId,
  } = useMerchantData();

  const { push } = useHistory();

  const [isInviteCustomerDialogOpen, setIsInviteCustomerDialogOpen] =
    useState<boolean>(false);

  const categoryMst = getMerchantDashboardSettingValue(
    MerchantClassSettingType.ShowPlanToPurchase
  );
  const category = categoryMst && categoryMst?.toLowerCase();
  const [planToPurchaseFeature] = useDecision(
    FeatureFlag.EnablePlanToPurchase,
    {
      autoUpdate: true,
    }
  );
  const showSendGuide =
    planToPurchaseFeature?.enabled &&
    (category === 'vet' || category === 'dental');

  return (
    <>
      <MuiGrid container spacing={3}>
        {checkPermission(PermissionEnum.CreateOrderExecute) && (
          <MuiGrid item xs={12} sm={6} md={4} xl={3}>
            <Tile
              id="createOrderTile"
              Icon={Icons.Plus}
              title="Create order"
              subtitle="Create a new order instore or remotely for new and existing customers"
              onClick={(): void => push(PageRoute.CreateOrder)}
              disabled={
                getMerchantStatus() === MerchantStatus.Disabled ||
                !checkPermission(PermissionEnum.CreateOrderExecute)
              }
              fullWidth
            />
          </MuiGrid>
        )}
        {checkPermission(PermissionEnum.CreateInviteExecute) && (
          <Tile
            id="inviteCustomerTile"
            Icon={Icons.Application}
            title="Invite customer"
            subtitle="Send an invitation to a new customer for pre-approval"
            onClick={(): void => setIsInviteCustomerDialogOpen(true)}
            disabled={
              getMerchantStatus() === MerchantStatus.Disabled ||
              !checkPermission(PermissionEnum.CreateInviteExecute)
            }
          />
        )}
        {checkMerchantDashboardSetting(
          MerchantClassSettingType.CLIInvitationEnabled
        ) &&
          checkPermission(PermissionEnum.CreateCLIInvitationExecute) && (
            <Tile
              id="limitIncreaseInviteTile"
              Icon={Icons.ArrowUp}
              title="Credit limit increase"
              subtitle="Invite an existing Zip Money customer for an credit limit increase"
              path={`${PageRoute.Tools}${PageRoute.CliInvite}`}
            />
          )}
        {checkPermission(PermissionEnum.OrdersRead) && (
          <Tile
            id="viewOrdersTile"
            Icon={Icons.CurrencyDollarSimple}
            title="Order search"
            subtitle="Find a customer’s order"
            path={PageRoute.Orders}
          />
        )}
        {checkPermission(PermissionEnum.CustomersRead) && (
          <Tile
            id="viewCustomersTile"
            Icon={Icons.UserMultiple}
            title="Customer search"
            subtitle="Find customers you've invited or transacted with"
            path={PageRoute.Customers}
          />
        )}
        {checkPermissions([
          PermissionEnum.ApiIntegrationRead,
          PermissionEnum.DisbursementsRead,
          PermissionEnum.UserManagementExecute,
          PermissionEnum.NotificationsUpdate,
          PermissionEnum.StoreProfileUpdate,
          PermissionEnum.StoreCategoryUpdate,
          PermissionEnum.StoreAdvertising,
        ]) && (
          <Tile
            id="viewSettingsTile"
            Icon={Icons.Settings}
            title="Settings"
            subtitle="Manage integration, users, notifications"
            path={PageRoute.Settings}
          />
        )}
      </MuiGrid>

      <MuiGrid css={styles.moduleContainer}>
        {showSendGuide && (
          <SendGuideModule
            category={category}
            merchantUniqueId={getMerchantUniqueId()}
          />
        )}
      </MuiGrid>

      <InviteCustomerDialogModule
        open={isInviteCustomerDialogOpen}
        onClose={(): void => setIsInviteCustomerDialogOpen(false)}
      />
    </>
  );
};

export default DashboardTilesModule;
