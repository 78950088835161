/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxImportSource @emotion/react */
import { Buttons, Spinner, Tag } from '@zip/business-components';
import {
  BasicButton,
  Divider,
  EditStoreCategoriesDialog,
  PageActions,
} from 'components';
import { useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { css, isSandbox } from 'global';
import { useFetch, usePrevious } from 'hooks';
import { FC, useCallback, useEffect, useState } from 'react';
import { MarketingCategory } from 'types';
import { logError, logEvent } from 'utils';

import * as styles from './StoreCategories.styles';

const StoreCategoriesModule: FC = () => {
  const { get, post } = useFetch();
  const Snackbar = useSnackbar();
  const [isLoadingPrimaryCategories, setIsLoadingPrimaryCategories] =
    useState<boolean>(false);
  const [isSubmittingPrimaryCategories, setIsSubmittingPrimaryCategories] =
    useState<boolean>(false);
  const [primaryCategories, setPrimaryCategories] =
    useState<MarketingCategory[]>();
  const [isValid, setIsValid] = useState<boolean>(true);

  const [categoryToEdit, setCategoryToEdit] = useState<MarketingCategory>();
  const [selectedPrimaryCategories, setSelectedPrimaryCategories] = useState<
    number[]
  >([]);

  const handleDialogClose = (): void => setCategoryToEdit(null);

  const handleUpdateCategories = (updatedCategories: number[]): void => {
    categoryToEdit?.childCategories?.forEach((category, cIndex) => {
      category?.childCategories?.forEach((subCategory, scIndex) => {
        categoryToEdit.childCategories[cIndex].childCategories[
          scIndex
        ].isSelected =
          updatedCategories?.length >= 1 &&
          updatedCategories?.includes(subCategory?.id);
      });
    });

    const indexToUpdate = primaryCategories?.findIndex(
      (primaryCategory) => primaryCategory?.id === categoryToEdit?.id
    );
    const primaryCategoriesToSet = primaryCategories?.slice();
    primaryCategoriesToSet[indexToUpdate] = categoryToEdit;

    setPrimaryCategories(primaryCategoriesToSet);
    setCategoryToEdit(null);
  };

  const handleClearCategory = (categoryToClear: MarketingCategory): void => {
    const primaryCategoriesToSet = primaryCategories.slice();
    primaryCategories?.forEach((primaryCategory, pcIndex) =>
      primaryCategory?.childCategories?.forEach((category, cIndex) => {
        category?.childCategories?.some((subCategory, scIndex) => {
          if (subCategory.id === categoryToClear.id) {
            primaryCategoriesToSet[pcIndex].childCategories[
              cIndex
            ].childCategories[scIndex].isSelected = false;
            return true;
          }
          return false;
        });
      })
    );
    setPrimaryCategories(primaryCategoriesToSet);
  };

  useEffect(() => {
    const getCategories = async (): Promise<void> => {
      try {
        setIsLoadingPrimaryCategories(true);
        const res = await get('/marketingcategories');
        const response = await res?.json();

        logEvent(PageAction.fetchStoreCategories);

        setPrimaryCategories(response?.categories ?? []);
      } catch (err) {
        logError(PageAction.fetchStoreCategories, err);
        Snackbar.error('Something went wrong loading your store categories.');
      } finally {
        setIsLoadingPrimaryCategories(false);
      }
    };

    getCategories();
  }, []);

  const getSelectedCategories = useCallback(
    (categoryToSearch?: MarketingCategory): MarketingCategory[] => {
      const itemsToReturn: MarketingCategory[] = [];

      if (categoryToSearch) {
        categoryToSearch?.childCategories?.forEach((category) => {
          category?.childCategories?.forEach((subCategory) => {
            if (!subCategory.isSelected) {
              return;
            }
            itemsToReturn.push(subCategory);
          });
        });
      } else {
        const primariesToReturn: number[] = [];
        primaryCategories?.forEach((primaryCategory) => {
          primaryCategory?.childCategories?.forEach((category) => {
            category?.childCategories?.forEach((subCategory) => {
              if (!subCategory.isSelected) {
                return;
              }
              itemsToReturn.push(subCategory);
              primariesToReturn.push(primaryCategory.id);
            });
          });
        });
      }

      return itemsToReturn;
    },
    [primaryCategories]
  );

  const getSelectedChildrenCount = (
    primaryCategoryToSearch?: MarketingCategory
  ): number => {
    const categoriesToReturn: number[] = [];

    if (!primaryCategoryToSearch) {
      primaryCategories?.forEach((primaryCategory) => {
        primaryCategory?.childCategories?.forEach((category) => {
          category?.childCategories?.forEach((childCategory) => {
            childCategory.isSelected &&
              categoriesToReturn.push(childCategory.id);
          });
        });
      });

      return categoriesToReturn.length;
    }

    primaryCategoryToSearch?.childCategories?.forEach((category) => {
      category?.childCategories?.forEach((childCategory) => {
        childCategory.isSelected && categoriesToReturn.push(category.id);
      });
    });

    return categoriesToReturn.length;
  };

  const handlePrimaryCategoryClick = (category: MarketingCategory): void => {
    if (
      selectedPrimaryCategories?.length >= 3 &&
      !selectedPrimaryCategories.includes(category.id)
    ) {
      return;
    }
    setCategoryToEdit(category);
  };

  const handleSaveCategories = async (): Promise<void> => {
    try {
      setIsSubmittingPrimaryCategories(true);
      const selectedSubCategories: number[] = [];
      primaryCategories.forEach((category) => {
        category?.childCategories?.forEach((parent) => {
          parent?.childCategories?.forEach(
            (childCategory) =>
              childCategory.isSelected &&
              selectedSubCategories.push(childCategory.id)
          );
        });
      });

      logEvent(PageAction.submitStoreCategories, { selectedSubCategories });

      await post('/marketingcategories', { categories: selectedSubCategories });

      Snackbar.success('Store categories was updated successfully.');
    } catch (err) {
      logError(PageAction.submitStoreCategories, err);
      Snackbar.error('An error has occured. Please try again.');
    } finally {
      setIsSubmittingPrimaryCategories(false);
    }
  };

  useEffect(() => {
    const selectedPrimaries: number[] = [];
    primaryCategories?.forEach((category) =>
      category?.childCategories?.forEach((parent) => {
        parent?.childCategories?.forEach(
          (childCategory) =>
            childCategory.isSelected && selectedPrimaries.push(category.id)
        );
      })
    );
    setSelectedPrimaryCategories([...new Set(selectedPrimaries)]);
  }, [primaryCategories]);

  const previousPrimaryCategories = usePrevious(primaryCategories) ?? null;

  useEffect(() => {
    if (
      !primaryCategories ||
      primaryCategories?.length < 1 ||
      previousPrimaryCategories === primaryCategories
    ) {
      return;
    }
    const maxLimit = 30;

    if (getSelectedChildrenCount() > maxLimit) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [primaryCategories]);

  return (
    <>
      <p className="body2">
        Select the categories and subcategories that are most relevant to the
        products and services your business provides. Choose up to a max. of 30
        subcategories that fall into 3 primary categories.
      </p>
      <p className="heading2">
        Primary categories{' '}
        <span css={styles.headingCount(true)}>
          {selectedPrimaryCategories?.length}/3
        </span>
      </p>
      {isLoadingPrimaryCategories && <Spinner />}
      {!isLoadingPrimaryCategories && (
        <>
          {primaryCategories?.map((category) => (
            <BasicButton
              key={category.id}
              onClick={(): void => handlePrimaryCategoryClick(category)}
              count={getSelectedChildrenCount(category)}
              disabled={
                selectedPrimaryCategories?.length >= 3 &&
                !selectedPrimaryCategories.includes(category.id)
              }
            >
              {category.name}
            </BasicButton>
          ))}
          <p className="heading2">
            Selected sub-categories{' '}
            <span css={styles.headingCount(isValid)}>
              {getSelectedChildrenCount()}/30
            </span>
          </p>
          {!isValid && (
            <p css={styles.headingCount(false)}>
              Maximum limit exceeded, please review your selected options.
            </p>
          )}
          {(selectedPrimaryCategories?.length > 0 &&
            selectedPrimaryCategories?.map((primaryCategoryId, i) => {
              const primaryCategory = primaryCategories?.find(
                (searchCategory) => searchCategory.id === primaryCategoryId
              );
              return (
                <div>
                  <div css={css.flex('row', 'flex-start', 'baseline')}>
                    <p className="body1">{primaryCategory.name}</p>
                    <span css={css.badge}>
                      {getSelectedChildrenCount(primaryCategory)}
                    </span>
                  </div>
                  <div>
                    {getSelectedCategories(primaryCategory).map(
                      (selectedCategory) => (
                        <Tag
                          key={selectedCategory.id}
                          label={selectedCategory.name}
                          onDelete={(): void =>
                            handleClearCategory(selectedCategory)
                          }
                          css={styles.categoryTag}
                        />
                      )
                    )}
                  </div>
                  <Divider
                    bottom={i < selectedPrimaryCategories?.length - 1 ? 24 : 0}
                  />
                </div>
              );
            })) || (
            <p className="body2">
              No categories selected. After you select you first primary
              category and sub-categories, they will appear here.
            </p>
          )}
        </>
      )}

      <PageActions>
        <Buttons.Primary
          type="submit"
          disabled={
            isSandbox ||
            !isValid ||
            !primaryCategories ||
            primaryCategories?.length < 1
          }
          loading={isSubmittingPrimaryCategories || isLoadingPrimaryCategories}
          onClick={handleSaveCategories}
        >
          Save
        </Buttons.Primary>
      </PageActions>

      <EditStoreCategoriesDialog
        primaryCategory={categoryToEdit}
        onClose={handleDialogClose}
        onSave={handleUpdateCategories}
      />
    </>
  );
};

export default StoreCategoriesModule;
