/** @jsxImportSource @emotion/react */
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Buttons, Divider, Menu, Spinner } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { useMerchantData, useSnackbar } from 'contexts';
import {
  MerchantClassSettingType,
  OrderStatus,
  PageAction,
  PageRoute,
  PermissionEnum,
  ProductClassification,
} from 'enums';
import { Constants, css } from 'global';
import { useScrollTop } from 'hooks';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OrderDetails, OrderSummary, TransactionHistory } from 'types';
import {
  currencyFormatter,
  dateFormatter,
  formatNumberOfDays,
  logError,
  logEvent,
  productFormatter,
  statusFormatter,
  useOrderFunctions,
} from 'utils';
import SummaryCards from '../summary-card';
import UpdateReferenceDialog from '../update-reference';
import { CustomerSidebarProps, OrderSidebarProps } from './SidebarProps';
import * as styles from './Sidebars.styles';
import { Tooltip } from 'components';

export const Order = forwardRef((props: OrderSidebarProps, ref) => {
  const { getMerchantDashboardSettingValue } = useMerchantData();
  const { onClose, orderId, onSuccess, getOrderActions, productDetails } =
    props;
  const { success } = useSnackbar();
  const { scrollTop, scrollProps } = useScrollTop();
  const OrderFunctions = useOrderFunctions();
  const { checkPermission } = useMerchantData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { breakpoints } = useTheme();
  const [order, setOrder] = useState<OrderDetails>();
  const [isEditReferenceOpen, setIsEditReferenceOpen] =
    useState<boolean>(false);

  const orderStatusesToShowContactInfoFor: string[] = [
    OrderStatus.NotStarted,
    OrderStatus.InProgress,
    OrderStatus.Expired,
  ];
  const showOrderContactInfo = orderStatusesToShowContactInfoFor.includes(
    order?.orderStatus?.toLowerCase()
  );

  const spaceReferenceTitle = useMediaQuery(breakpoints.only('md'));

  function onReferenceSuccess(): void {
    if (!orderId) {
      setOrder(null);
      return;
    }
    onSuccess();
  }

  const handleOpenEditReference = (): void => setIsEditReferenceOpen(true);

  const getOrderDetails = (orderIdToGet: number): void => {
    setIsLoading(true);
    setOrder(null);
    OrderFunctions.resetGetRequests();

    logEvent(PageAction.fetchOrder, { orderIdToGet });
    OrderFunctions.getOrderDetails(orderIdToGet)
      .then((res) => {
        setOrder(res ?? null);
        setIsLoading(!res);
      })
      .catch((err) => {
        logError(PageAction.fetchOrder, err, { orderIdToGet });
        setOrder(null);
        setIsLoading(false);
      });
  };

  const getInterestFreePeriodText = (
    productClassification: ProductClassification,
    interestFreePeriod: number
  ): string => {
    switch (productClassification) {
      case ProductClassification.ZipPay:
        return 'Interest free always';
      case ProductClassification.ZipPlus:
        return 'No interest or low interest';
      case ProductClassification.ZipMoney:
      default:
        return `${interestFreePeriod} months`;
    }
  };

  const getCustomOrDefaultExpiryPeriod = (
    productClassification: ProductClassification
  ): string => {
    const formattedProduct = productFormatter(productClassification);

    let customExpiryPeriodFromMST = null;
    switch (productClassification) {
      case ProductClassification.ZipPay:
        customExpiryPeriodFromMST = getMerchantDashboardSettingValue(
          MerchantClassSettingType.CustomZipPayAuthChargeExpiryInDays
        );
        break;
      case ProductClassification.ZipPlus:
        customExpiryPeriodFromMST = getMerchantDashboardSettingValue(
          MerchantClassSettingType.CustomZipPlusAuthChargeExpiryInDays
        );
        break;
      case ProductClassification.ZipMoney:
        customExpiryPeriodFromMST = getMerchantDashboardSettingValue(
          MerchantClassSettingType.CustomZipMoneyAuthChargeExpiryInDays
        );
        break;
    }

    return customExpiryPeriodFromMST
      ? `${customExpiryPeriodFromMST} days`
      : `${Constants.productDefaults[formattedProduct].orderExpiryPeriodInMonths} months`;
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId);
    } else {
      setOrder(null);
    }
  }, [orderId]);

  useImperativeHandle(ref, () => ({
    getOrderDetails,
  }));

  return (
    !!orderId && (
      <>
        <MuiPaper
          elevation={0}
          id="sidebarContainer"
          css={styles.container(!!orderId)}
        >
          <MuiGrid container justifyContent="space-between" alignItems="center">
            <MuiGrid item>
              <Icons.ChevronLeft
                id="sidebarBackIcon"
                onClick={onClose}
                css={styles.chevron}
              />
            </MuiGrid>
            <MuiGrid item>
              {order && (
                <Menu
                  id="sidebarMenu"
                  label="Actions"
                  options={getOrderActions(order)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 52 }}
                  onClick={(): void =>
                    logEvent(PageAction.openOrderActions, {
                      fromDetails: true,
                    })
                  }
                />
              )}
            </MuiGrid>
          </MuiGrid>
          {isLoading && <Spinner width={150} height={132} />}
          {order && !isLoading && (
            <>
              <div>
                <p className="title3 fs-mask" css={styles.title}>
                  {order?.fullName}
                </p>
                <p className="heading2" css={styles.status}>
                  <span>{statusFormatter(order?.orderStatus)}</span>
                </p>
              </div>
              <div css={styles.details(!scrollTop)} {...scrollProps}>
                {order?.expireInDays >= 0 && order?.expireInDays < 30 && (
                  <>
                    <MuiGrid container css={styles.gridContainer}>
                      <MuiGrid item xs={12}>
                        <MuiGrid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <MuiGrid item css={styles.orderExpiry}>
                            <p className="body1">
                              This order expires{' '}
                              {order?.expireInDays > 0 ? 'in' : ''}{' '}
                              {formatNumberOfDays(
                                order?.expireInDays
                              ).toLowerCase()}
                            </p>
                            <p className="body2">
                              Your outstanding amount of{' '}
                              {currencyFormatter(order?.amountOwing)} will be
                              cancelled on{' '}
                              {dateFormatter(
                                order?.expiryDateTime.toString(),
                                'day',
                                false
                              )}{' '}
                              if left uncaptured.
                            </p>
                          </MuiGrid>
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>

                    <Divider />
                  </>
                )}
                <MuiGrid container css={styles.gridContainer}>
                  <MuiGrid item xs={6} md={12} lg={6}>
                    <MuiGrid
                      container
                      justifyContent={
                        spaceReferenceTitle ? 'space-between' : 'flex-start'
                      }
                      alignItems="center"
                    >
                      <MuiGrid item>
                        <p className="body1">Order reference</p>
                      </MuiGrid>
                      {checkPermission(PermissionEnum.EditOrderReference) && (
                        <MuiGrid item css={styles.buttonContainer}>
                          <Buttons.Text
                            id="editReferenceButton"
                            data-testid="edit-reference"
                            onClick={handleOpenEditReference}
                          >
                            Edit
                          </Buttons.Text>
                        </MuiGrid>
                      )}
                      <MuiGrid item xs={12}>
                        <p className="body2" css={styles.orderReference}>
                          {order?.orderReference}{' '}
                          {order?.orderReference && (
                            <Icons.Copy
                              css={{ cursor: 'pointer' }}
                              onClick={async (): Promise<void> => {
                                await navigator.clipboard.writeText(
                                  order?.orderReference
                                );
                                success('Copied order reference to clipboard');
                              }}
                            />
                          )}
                        </p>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                  {order?.branchName && (
                    <MuiGrid item xs={6} md={12} lg={6}>
                      <p className="body1">Branch</p>
                      <p className="body2">{order?.branchName}</p>
                    </MuiGrid>
                  )}
                  <MuiGrid item xs={6} md={12} lg={6}>
                    <p className="body1">Order date</p>
                    <p className="body2">
                      {dateFormatter(order?.orderDate, 'day', false)}
                    </p>
                  </MuiGrid>
                  {order?.expiryDateTime && (
                    <MuiGrid item xs={6} sm={12} lg={6}>
                      <p className="body1" css={styles.expiryDate}>
                        Expiry date{' '}
                        <Tooltip
                          content={
                            <div css={styles.tooltipContent}>
                              <p className="body1">Order expiry date</p>
                              <p className="body4">
                                For{' '}
                                {productFormatter(order?.productClassification)}
                                , orders expire{' '}
                                {getCustomOrDefaultExpiryPeriod(
                                  order?.productClassification
                                )}{' '}
                                from the date of authorisation. Upon expiry, any
                                outstanding amount will be cancelled. Please
                                visit the{' '}
                                <a
                                  href={Constants.helpArticles.expiryFaq}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Help Centre
                                </a>{' '}
                                to learn more.
                              </p>
                            </div>
                          }
                          placement="bottom"
                          enterDelay={20}
                          leaveDelay={1000}
                          interactive
                          maxWidth={240}
                          arrow
                          dense
                          variant="primary"
                          css={styles.tooltipIcon}
                        >
                          <Icons.InformationCircle width="16" height="16" />
                        </Tooltip>
                      </p>
                      <p className="body2">
                        {dateFormatter(
                          order?.expiryDateTime.toString(),
                          'day',
                          false
                        )}
                      </p>
                    </MuiGrid>
                  )}
                  <MuiGrid item xs={6} md={12} lg={6}>
                    <p className="body1">Zip receipt number</p>
                    <p className="body2">
                      {orderId}{' '}
                      <Icons.Copy
                        css={{ cursor: 'pointer' }}
                        onClick={async (): Promise<void> => {
                          await navigator.clipboard.writeText(
                            orderId?.toString()
                          );
                          success('Copied Zip receipt number to clipboard');
                        }}
                      />
                    </p>
                  </MuiGrid>
                  <MuiGrid item xs={6} md={12} lg={6}>
                    <p className="body1">Order ID</p>
                    <p className="body2">
                      {order?.merchantOrderId}{' '}
                      <Icons.Copy
                        css={{ cursor: 'pointer' }}
                        onClick={async (): Promise<void> => {
                          await navigator.clipboard.writeText(
                            order?.merchantOrderId
                          );
                          success('Copied order ID to clipboard');
                        }}
                      />
                    </p>
                  </MuiGrid>
                  {order?.merchantUserName && (
                    <MuiGrid item xs={6} sm={12} lg={6}>
                      <p className="body1">Sales rep</p>
                      <p className="body2">{order?.merchantUserName}</p>
                    </MuiGrid>
                  )}
                </MuiGrid>

                <Divider />

                <MuiGrid container css={styles.gridContainer}>
                  {showOrderContactInfo ? (
                    <MuiGrid item xs={6} sm={12} lg={6}>
                      <p className="body1">Mobile</p>
                      <p className="body2 fs-mask">{order?.mobilePhone}</p>
                    </MuiGrid>
                  ) : (
                    <MuiGrid item xs={6} sm={12} lg={6}>
                      <p className="body1">Amount outstanding</p>
                      <p className="body2">
                        {currencyFormatter(order?.amountOwing)}
                      </p>
                    </MuiGrid>
                  )}
                  <MuiGrid item xs={6} sm={12} lg={6}>
                    <p className="body1">Interest free period</p>
                    <p className="body2" css={styles.interestFreePeriod}>
                      {getInterestFreePeriodText(
                        order?.productClassification,
                        order?.interestFreePeriod
                      )}
                      {order?.productClassification ===
                        ProductClassification.ZipPlus && (
                        <Tooltip
                          content={
                            <div css={styles.tooltipContent}>
                              <p className="body1">Interest</p>
                              <p className="body4">
                                Interest free when the customer{"'"}s end of
                                month balance is under{' '}
                                {currencyFormatter(
                                  Constants.productDefaults['Zip Plus']
                                    .interestFreeThreshold,
                                  false
                                )}
                                . And a low rate of{' '}
                                {
                                  Constants.productDefaults['Zip Plus']
                                    .interestRate.percent
                                }
                                % p.a. when it{"'"}s over{' '}
                                {currencyFormatter(
                                  Constants.productDefaults['Zip Plus']
                                    .interestFreeThreshold,
                                  false
                                )}
                                .
                              </p>
                            </div>
                          }
                          placement="bottom"
                          enterDelay={20}
                          leaveDelay={1000}
                          interactive
                          maxWidth={240}
                          arrow
                          dense
                          variant="primary"
                          css={styles.tooltipIcon}
                        >
                          <Icons.InformationCircle width="16" height="16" />
                        </Tooltip>
                      )}
                    </p>
                  </MuiGrid>
                  {showOrderContactInfo ? (
                    <MuiGrid item xs={12}>
                      <p className="body1">Email address</p>
                      <p className="body2 fs-mask">{order?.email}</p>
                    </MuiGrid>
                  ) : (
                    <>
                      <MuiGrid item xs={6} sm={12} lg={6}>
                        <p className="body1">Amount paid</p>
                        <p className="body2">
                          {currencyFormatter(order?.amountPaid)}
                        </p>
                      </MuiGrid>
                      <MuiGrid item xs={6} sm={12} lg={6}>
                        <p className="body1">Amount refunded</p>
                        <p className="body2">
                          {currencyFormatter(order?.refundedAmount)}
                        </p>
                      </MuiGrid>
                    </>
                  )}
                </MuiGrid>

                {order?.brand && (
                  <>
                    <Divider />

                    <MuiGrid container css={styles.gridContainer}>
                      <p className="body1">Product details</p>
                      <MuiGrid item xs={12}>
                        <p className="body1">Brand</p>
                        <p className="body2">{order?.brand}</p>
                      </MuiGrid>
                      {productDetails && (
                        <>
                          <MuiGrid item xs={12}>
                            <p className="body1">Product name</p>
                            <p className="body2">
                              {productDetails?.productName}
                            </p>
                          </MuiGrid>
                          <MuiGrid item xs={12}>
                            <p className="body1">Serial number</p>
                            <p className="body2">
                              {productDetails?.serialNumber}
                            </p>
                          </MuiGrid>
                          <MuiGrid item xs={12}>
                            <p className="body1">Model number</p>
                            <p className="body2">
                              {productDetails?.modelNumber}
                            </p>
                          </MuiGrid>
                        </>
                      )}
                    </MuiGrid>
                  </>
                )}

                {order?.transactionHistoryItems?.length > 0 && (
                  <>
                    <Divider />

                    <MuiGrid container css={styles.gridContainer}>
                      <p className="body1" css={css.noTopMrgn}>
                        Actions
                      </p>
                      {order.transactionHistoryItems
                        ?.filter(
                          (action) =>
                            typeof action.transactionType === 'string' &&
                            !String(action.transactionType).includes('Fee') &&
                            !action.isCancelledForCapturedTransaction
                        )
                        .map((action) => (
                          <MuiGrid
                            item
                            xs={12}
                            key={action.id}
                            css={styles.action}
                          >
                            <MuiGrid container justifyContent="space-between">
                              <MuiGrid item className="body1">
                                {action.transactionType}
                              </MuiGrid>
                              <MuiGrid item className="body1">
                                {currencyFormatter(action.amount)}
                              </MuiGrid>
                            </MuiGrid>
                            <div className="body2">
                              {dateFormatter(action.timeStamp, 'full')}
                            </div>
                          </MuiGrid>
                        ))}
                      {order?.hasExpired && (
                        <MuiGrid item xs={12} css={styles.orderExpired}>
                          <p className="body3">Order has expired</p>
                        </MuiGrid>
                      )}
                    </MuiGrid>
                  </>
                )}
              </div>
            </>
          )}
        </MuiPaper>
        <UpdateReferenceDialog
          id="updateReferenceDialog"
          toggleOpen={setIsEditReferenceOpen}
          open={isEditReferenceOpen}
          reference={order?.orderReference}
          operationRequestId={orderId}
          onSuccess={onReferenceSuccess}
        />
      </>
    )
  );
});

export const Customer = forwardRef((props: CustomerSidebarProps, ref) => {
  const { onClose, consumerId } = props;
  const { push } = useHistory();
  const { scrollTop, scrollProps } = useScrollTop();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<OrderSummary[]>([]);

  const OrderFunctions = useOrderFunctions();

  const getCustomerOrders = (consumerIdToGet: number): void => {
    setIsLoading(true);
    setOrders([]);
    logEvent(PageAction.fetchCustomerOrders, {
      consumerIdToGet,
    });

    OrderFunctions.getCustomerOrders(consumerIdToGet)
      .then((res) => (res ? setOrders(res?.orders) : setOrders([])))
      .catch((err) => {
        logError(PageAction.fetchCustomerOrders, err, { consumerIdToGet });
        setOrders([]);
      })
      .finally(() => setIsLoading(false));
  };

  function redirectToOrder(orderId): void {
    if (orderId) {
      push({
        pathname: PageRoute.Orders,
        state: { orderId },
      });
    }
  }

  useEffect(() => {
    if (consumerId) {
      getCustomerOrders(consumerId);
    } else {
      setOrders([]);
    }
  }, [consumerId]);

  useImperativeHandle(ref, () => ({
    getCustomerOrders,
  }));

  return (
    !!consumerId && (
      <MuiPaper
        elevation={0}
        id="sidebarContainer"
        css={styles.container(!!consumerId)}
      >
        <MuiGrid container justifyContent="space-between" alignItems="center">
          <MuiGrid item>
            <Icons.ChevronLeft
              id="sidebarBackIcon"
              onClick={onClose}
              css={styles.chevron}
            />
          </MuiGrid>
        </MuiGrid>
        {isLoading && <Spinner width={150} height={132} />}

        {!isLoading && orders?.length < 1 && (
          <div>
            <p className="body2" css={styles.noResponse}>
              No orders processed with this customer on your merchant account
            </p>
          </div>
        )}
        {!isLoading && orders?.length > 0 && (
          <>
            <div>
              <p className="title3 fs-mask" css={styles.title}>
                {orders[0]?.firstName
                  ? `${orders[0]?.firstName} ${orders[0].lastName}`
                  : orders[0].lastName}
              </p>
            </div>
            <div css={styles.details(!scrollTop)} {...scrollProps}>
              <MuiGrid container css={styles.gridContainer}>
                {orders?.map((order) => (
                  <MuiGrid item xs={12}>
                    <SummaryCards.Order
                      order={order}
                      onClick={(): void => redirectToOrder(order.id)}
                    />
                  </MuiGrid>
                ))}
              </MuiGrid>
            </div>
          </>
        )}
      </MuiPaper>
    )
  );
});
