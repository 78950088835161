import { css } from '@emotion/react';
import { theme } from 'global';

export const ownershipInputs = css`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
    gap: 0px;

    > .MuiFormControl-root:first-of-type {
      margin: 0px 0px 16px;
    }
  }
`;
