import { CustomerStatus } from 'enums';
import { Constants } from 'global';
import { useFetch } from 'hooks';
import { CliInviteCustomer, CliRequest, CustomerDetails } from 'types';
import { GetCreditLimitIncreasesQuery, GetCustomersQuery } from 'types/queries';

interface CustomerFunctions {
  resetGetRequests: () => void;
  getCustomers: (query: GetCustomersQuery) => Promise<{
    hasMore: boolean;
    customers: CustomerDetails[];
  }>;
  getCliInviteCustomerByToken: (token: string) => Promise<CliInviteCustomer>;
  sendCliInvite: (
    consumerId: number,
    merchantAccountTypeId: number
  ) => Promise<void>;
  getCliInvites: (
    query: GetCreditLimitIncreasesQuery
  ) => Promise<{ creditLimitInvitations: CliRequest[]; hasMore: boolean }>;
  concatUniqueCustomers: (
    customers: CustomerDetails[],
    customersToAdd: CustomerDetails[]
  ) => CustomerDetails[];
  concatUniqueCliRequest: (
    customers: CliRequest[],
    customersToAdd: CliRequest[]
  ) => CliRequest[];
}

export const useCustomerFunctions = (): CustomerFunctions => {
  const { get, post } = useFetch();
  let controller = new AbortController();

  function resetGetRequests(): void {
    controller.abort();
    controller = new AbortController();
  }

  async function getCustomers(
    query: GetCustomersQuery
  ): Promise<{ hasMore: boolean; customers: CustomerDetails[] }> {
    let url: string;
    switch (query.status) {
      case CustomerStatus.Active:
        url = `/Customer/active?take=${query.take}&skip=${query.skip}${
          query.searchValue
            ? `&searchType=${query.searchType}&searchValue=${query.searchValue}`
            : ''
        }`;
        break;
      default:
        url = `/Customer/invited?take=${query.take}&skip=${query.skip}${
          query.status ? `&status=${query.status}` : ''
        }${query.searchValue ? `&searchValue=${query.searchValue}` : ''}${
          query.searchType ? `&searchType=${query.searchType}` : ''
        }`;
    }

    const controllerSignal = controller.signal;

    return get(url, controllerSignal)
      .catch((err) => {
        if (controllerSignal.aborted) {
          throw new Error(Constants.errors.abortedSignal);
        }
        throw err;
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Something went wrong loading customers');
        }

        if (res.status === 200) {
          return res?.json();
        }

        return { customers: [], hasMore: false };
      })
      .catch((err) => {
        throw err;
      });
  }

  async function getCliInviteCustomerByToken(
    token: string
  ): Promise<CliInviteCustomer> {
    return get(
      `/customer/cli-invitation-detail?token=${token}`,
      controller.signal
    ).then(async (res) => {
      switch (res.status) {
        case 200:
          return res.json();
        case 400:
          const json = await res.json();
          throw new Error(json?.error?.code);
        default:
          throw new Error('Something went wrong');
      }
    });
  }

  async function sendCliInvite(
    consumerId: number,
    merchantAccountTypeId: number
  ): Promise<void> {
    await post(
      '/customer/send-cli-invitation',
      {
        consumerId: Number(consumerId),
        merchantAccountTypeId: Number(merchantAccountTypeId),
      },
      controller.signal
    ).then((res) => {
      if (res.status === 201 || res.status === 200) {
        return;
      }
      throw new Error('Something went wrong');
    });
  }

  async function getCliInvites(
    query: GetCreditLimitIncreasesQuery
  ): Promise<{ creditLimitInvitations: CliRequest[]; hasMore: boolean }> {
    const url = `/customer/search-cli-invitations?take=${query.take}&skip=${
      query.skip
    }${query.status ? `&status=${query.status}` : ''}${
      query.searchValue ? `&query=${query.searchValue}` : ''
    }`;

    const controllerSignal = controller.signal;

    const response = await get(url, controllerSignal)
      .catch((err) => {
        if (controllerSignal.aborted) {
          throw new Error(Constants.errors.abortedSignal);
        }

        throw err;
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Something went wrong');
        }
        if (res.status === 200) {
          return res?.json();
        }
        return null;
      });

    return response;
  }

  function concatUniqueCustomers(
    customers: CustomerDetails[],
    customersToAdd: CustomerDetails[] = []
  ): CustomerDetails[] {
    return customers.concat(
      customersToAdd?.filter(
        (customerToAdd: CustomerDetails) =>
          !customers.some(
            (item) => item.consumerId === customerToAdd.consumerId
          )
      ) ?? []
    );
  }

  function concatUniqueCliRequest(
    customers: CliRequest[],
    customersToAdd: CliRequest[] = []
  ): CliRequest[] {
    return customers.concat(
      customersToAdd?.filter(
        (customerToAdd: CliRequest) =>
          !customers.some((item) => item.id === customerToAdd.id)
      ) ?? []
    );
  }

  return {
    resetGetRequests,
    getCustomers,
    getCliInviteCustomerByToken,
    sendCliInvite,
    getCliInvites,
    concatUniqueCustomers,
    concatUniqueCliRequest,
  };
};

export default useCustomerFunctions;
