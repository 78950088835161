import { css } from '@emotion/react';

import { theme, css as globalCss } from 'global';

export const container = (isOpen) => css`
  width: ${isOpen ? 520 : 0}px;
  margin-left: 32px;
  overflow: hidden;
  z-index: 5;
  height: 100%;

  display: flex;
  flex-direction: column;

  & > div {
    padding: 0 24px;
    &:first-of-type {
      padding: 24px 24px 0;
    }
    &:last-of-type {
      padding: 0 24px 24px;
    }
  }

  @media screen and (max-width: ${theme.breaks.lg - 1}px) {
    width: ${isOpen ? 332 : 0}px;
    margin-left: 16px;
  }

  @media screen and (max-width: ${theme.breaks.md - 1}px) {
    width: 100%;
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: unset;
  }
`;

export const chevron = css`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const noResponse = css`
  max-width: 360px;
  margin: 14px auto;
  text-align: center;
`;

export const gridContainer = css`
  margin: 16px 0;

  &:first-of-type {
    margin: 0 0 16px;
  }

  &:last-of-type {
    margin: 24px 0 0;
  }
`;

export const title = css`
  margin: 20px 0 24px;

  ${globalCss.ellipsisOverflow}
`;

export const status = css`
  margin: 0;

  > span {
    color: ${theme.colors.neutral[500]};
  }
`;

export const details = (showBorder) => css`
  margin: 10px 0 0;
  height: calc(100% - 173px);
  overflow-y: auto;
  border-top: ${showBorder
    ? `1px solid ${theme.colors.neutral[200]}`
    : 'unset'};
`;

export const buttonContainer = css`
  margin-left: 12px;
`;

export const orderExpiry = css`
  > p:first-of-type {
    margin-bottom: 4px;
  }

  > p:last-of-type {
    margin-top: 0px;
  }
`;

export const expiryDate = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const orderExpired = css`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.neutral[0]};
  border-radius: 4px;
  padding: 6px 16px;

  > p {
    color: ${theme.colors.neutral[400]};

    ${globalCss.noMargin}
  }
`;

export const orderReference = css`
  max-width: calc(100% - 12px);

  ${globalCss.noMargin}
  ${globalCss.ellipsisOverflow}
`;

export const action = css`
  margin: 14px 0;

  &:last-of-type {
    margin: 14px 0 0;
  }

  > span {
    margin: 8px 0 0;
  }
`;

export const interestFreePeriod = css`
  display: inline-flex;

  ${globalCss.noMargin}
  ${globalCss.ellipsisOverflow}
`;

export const tooltipContent = css`
  > p {
    margin: 0;
  }

  > p:first-of-type {
    margin-bottom: 8px;
  }

  & a {
    color: ${theme.colors.white};
    text-decoration: underline;
  }
`;

export const tooltipIcon = css`
  margin-left: 4px;
  padding-top: 1px;
  color: ${theme.colors.primary.medium};
`;
