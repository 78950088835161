export const hasAuth0Error = (expectedError: string): boolean => {
  return (
    expectedError.toLowerCase() ===
    decodeURI(window.location.search)
      ?.split('&')
      ?.find((param) => param.includes('error_description'))
      ?.replace('error_description=', '')
      ?.toLowerCase()
  );
};
