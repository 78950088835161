import { css } from '@emotion/react';

import { theme } from 'global';

export const draggableRow = css`
  background-color: ${theme.colors.white};
  & > .MuiTableCell-root {
    padding: 16px 8px;
    color: ${theme.colors.neutral[600]};
    &:first-of-type {
      padding: 16px 8px 16px 0px;
    }
  }
`;
