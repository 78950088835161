/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid, useTheme } from '@material-ui/core';
import { BasePage } from 'components';
import { PageRoute, ProductClassification } from 'enums';
import { CreateOrderModule, RepaymentsCalculatorModule } from 'modules';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  CustomerToCharge,
  GetRepaymentsQuoteQuery,
  PageProps,
  RepaymentsQuoteResponse,
} from 'types';
import { useOrderFunctions } from 'utils';
import * as styles from './CreateOrderPage.styles';
import _ from 'lodash';

export const CreateOrderPage: FC<PageProps> = ({ innerDrawerCallback }) => {
  const { breakpoints } = useTheme();

  useEffect(() => {
    // close inner drawer on opening page on desktop per design
    if (window.innerWidth > breakpoints.values.md) {
      innerDrawerCallback();
    }
  }, []);

  const [customerToCharge, setCustomerToCharge] = useState<CustomerToCharge>();
  const [repaymentsQuoteQuery, setRepaymentsQuoteQuery] =
    useState<GetRepaymentsQuoteQuery>();
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>();
  const [repaymentsQuote, setRepaymentQuote] =
    useState<RepaymentsQuoteResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formHasError, setFormHasError] = useState<boolean>();
  const [selectedProductClassification, setSelectedProductClassification] =
    useState<ProductClassification>(ProductClassification.ZipMoney);

  const { quote, resetGetRequests } = useOrderFunctions();

  const getRepaymentQuote = useMemo(
    () =>
      _.debounce(
        async (query: GetRepaymentsQuoteQuery) => {
          if (
            !(
              query?.interestFreeMonths >= 0 &&
              query?.price &&
              query?.price > 0
            )
          ) {
            resetGetRequests();
            setRepaymentQuote({});
            setIsLoading(false);
            return null;
          }

          resetGetRequests();
          const res = await quote(query);
          setRepaymentQuote(res ?? {});
          setIsLoading(false);
        },
        420,
        { leading: false, trailing: true }
      ),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    getRepaymentQuote(repaymentsQuoteQuery);
  }, [repaymentsQuoteQuery]);

  return (
    <BasePage
      title="Create order"
      breadcrumbs={[{ pageName: 'Orders', pageRoute: PageRoute.Orders }]}
      fullWidth
    >
      <MuiGrid container spacing={8}>
        <MuiGrid item xs={12} md={6} css={styles.smallScreenMargin}>
          <CreateOrderModule
            customerToCharge={customerToCharge}
            updateCustomerToCharge={setCustomerToCharge}
            repaymentsQuoteCallback={setRepaymentsQuoteQuery}
            isNewCustomerCallback={setIsNewCustomer}
            formHasErrorCallback={setFormHasError}
            selectedProductClassificationCallback={
              setSelectedProductClassification
            }
          />
        </MuiGrid>
        <MuiGrid
          item
          xs={12}
          md={6}
          css={[styles.calculator, styles.smallScreenMargin]}
        >
          <RepaymentsCalculatorModule
            orderPrice={repaymentsQuoteQuery?.price}
            repaymentsQuote={repaymentsQuote}
            customerToCharge={customerToCharge}
            selectedProductClassification={selectedProductClassification}
            isLoading={isLoading}
            isNewCustomer={isNewCustomer}
            formHasError={formHasError}
          />
        </MuiGrid>
        {/* Spacer for Create order button */}
        <MuiGrid item xs={12} css={styles.spacer} />
      </MuiGrid>
    </BasePage>
  );
};

export default CreateOrderPage;
