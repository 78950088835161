/** @jsxImportSource @emotion/react */
import { Accordion, Alert } from '@zip/business-components';
import { BasePage, IntegrationDetails } from 'components';
import { useMerchantData } from 'contexts';
import { Constants, css, isSandbox } from 'global';
import { FC, useRef } from 'react';
import * as styles from './IntegrationsPage.styles';

interface IntegrationDetailRef {
  refresh: () => void;
}

const IntegrationsPage: FC = () => {
  const { isMerchantDisabled } = useMerchantData();

  const webRef = useRef<IntegrationDetailRef>();
  const instoreRef = useRef<IntegrationDetailRef>();

  const refreshAllDetails = (): void => {
    webRef?.current?.refresh();
    instoreRef?.current?.refresh();
  };

  return (
    <BasePage title="Integration details">
      {isMerchantDisabled && (
        <>
          <p css={css.noTopMrgn}>
            Your account is currently <strong>disabled</strong> and not able to
            integrate.
          </p>
          <p css={css.noBtmMrgn}>
            Please contact our support team for further assistance.
          </p>
        </>
      )}
      {!isMerchantDisabled && (
        <>
          <p css={[css.noBtmMrgn, css.noTopMrgn]}>
            The below details allow you to activate Zip payments for websites
            and apps.
            <br />
            Head over to the{' '}
            <a
              href="https://developers.zip.co/docs/ecommerce-platforms"
              target="_blank"
              rel="noopener noreferrer"
              className="body2"
            >
              developer portal
            </a>{' '}
            for instructions on getting integrated.
          </p>
          {isSandbox && (
            <Alert variant="error" css={styles.sandboxAlert}>
              These are test credentials and will not transact with live
              customers.
              <br />
              To test with a demo customer account, follow{' '}
              <a
                href={Constants.developerPortal.testCustomer}
                target="_blank"
                rel="noopener noreferrer"
                className="body2"
              >
                this guide
              </a>
              .
            </Alert>
          )}
          <Accordion
            content={[
              {
                summary: 'Website integration details',
                details: (
                  <IntegrationDetails.Web
                    ref={webRef}
                    callback={refreshAllDetails}
                  />
                ),
                defaultExpanded: true,
              },
            ]}
          />
          <Accordion
            css={styles.accordion}
            content={[
              {
                summary: 'Instore integration details',
                details: <IntegrationDetails.Instore ref={instoreRef} />,
              },
            ]}
          />
        </>
      )}
    </BasePage>
  );
};

export default IntegrationsPage;
