/** @jsxImportSource @emotion/react */
import { useFeature } from '@optimizely/react-sdk';
import {
  Buttons,
  Dialogs,
  Selectable,
  TextFields,
} from '@zip/business-components';
import { useMerchantData, useSnackbar } from 'contexts';
import {
  DialogCloseReason,
  FeatureFlag,
  MerchantClassSettingType,
  PageAction,
  ProductClassification,
} from 'enums';
import { FormikErrors, useFormik } from 'formik';
import { css } from 'global';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import {
  InviteCustomerCommand,
  InviteCustomerErrors,
  MerchantAccountType,
} from 'types';
import {
  Validators,
  currencyFormatter,
  logEvent,
  productFormatter,
  selectFormatter,
} from 'utils';

import * as styles from './InviteCustomerDialog.styles';
import { InviteCustomerDialogModuleProps } from './InviteCustomerDialogModuleProps';

const InviteCustomerDialogModule: FC<InviteCustomerDialogModuleProps> = ({
  onClose,
  ...props
}) => {
  const { post } = useFetch();
  const Snackbar = useSnackbar();
  const {
    branches,
    merchantIdentity,
    checkMerchantDashboardSetting,
    merchantAccountTypes,
    getCurrentSelectedBranch,
  } = useMerchantData();

  // Experiments
  const [isZipPlusEnabled] = useFeature(FeatureFlag.ZipPlusInviteCustomer);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountLimitsToDisplay, setAccountLimitsToDisplay] = useState<
    Selectable[]
  >([{ label: '', value: '' }]);
  const [branchesToDisplay, setBranchesToDisplay] = useState<Selectable[]>([]);

  const handleClose = (): void => {
    onClose({}, DialogCloseReason.BackdropClick);
    formik.resetForm();
  };

  async function submitInviteCustomer(
    values: InviteCustomerCommand
  ): Promise<void> {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const payload = {
      ...values,
      accountTypeId: values?.accountTypeId && Number(values?.accountTypeId),
    };
    let isSuccessful = false;
    await post('/customer/invite', payload)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Something went wrong');
        }
        handleClose();
        isSuccessful = true;
        Snackbar.success('Invite sent successfully');
      })
      .catch((err) => Snackbar.error(err.message));

    logEvent(PageAction.createInvite, {
      branchId: payload.branchId,
      accountTypeId: payload.accountTypeId,
      isSuccessful,
    });
    setIsLoading(false);
  }

  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,
      emailAddress: null,
      mobilePhone: null,
      branchId: getCurrentSelectedBranch(),
      accountTypeId: '',
    },
    onSubmit: async (values) => {
      submitInviteCustomer(values);
    },
    validate: (values: InviteCustomerCommand) => {
      const errors: InviteCustomerErrors = {};

      if (!values.firstName) {
        errors.firstName = 'Required';
      } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.firstName)) {
        errors.firstName = 'Name contains invalid characters';
      } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.firstName)) {
        errors.firstName = 'Name must contain at least 2 letters';
      }
      if (!values.lastName) {
        errors.lastName = 'Required';
      } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.lastName)) {
        errors.lastName = 'Name contains invalid characters';
      } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.lastName)) {
        errors.lastName = 'Name must contain at least 2 letters';
      }

      if (!values.emailAddress) {
        errors.emailAddress = 'Required';
      } else if (!Validators.validateEmail(values.emailAddress)) {
        errors.emailAddress = 'Invalid email address';
      }

      if (!values.mobilePhone) {
        errors.mobilePhone = 'Required';
      } else if (!Validators.validateMobile(values.mobilePhone)) {
        errors.mobilePhone = 'Invalid mobile number';
      }

      if (branchesToDisplay?.length > 1 && !values.branchId) {
        errors.branchId = 'Required';
      }

      if (
        checkMerchantDashboardSetting(
          MerchantClassSettingType.CreateInviteAccountLimitSelector
        ) &&
        values?.accountTypeId?.length < 1
      ) {
        errors.accountTypeId = 'Required';
      }

      return errors;
    },
  });

  useEffect(() => {
    // Look for ZipMoney and ZipPlus accountTypes, otherwise use ZipPay
    let availableAccountTypes: MerchantAccountType[] = merchantAccountTypes
      ?.slice()
      ?.filter(
        (accountType) =>
          accountType.productClassification ===
            ProductClassification.ZipMoney ||
          (isZipPlusEnabled &&
            accountType.productClassification === ProductClassification.ZipPlus)
      );
    if (availableAccountTypes?.length < 1) {
      availableAccountTypes = merchantAccountTypes
        ?.slice()
        ?.filter(
          (accountType) =>
            accountType.productClassification === ProductClassification.ZipPay
        );
    }

    const accountTypesToSet = selectFormatter(
      availableAccountTypes?.map((accountType) => ({
        ...accountType,
        formattedLimit: isZipPlusEnabled
          ? `${currencyFormatter(accountType.limit)} - ${productFormatter(
              accountType.productClassification
            )}`
          : currencyFormatter(accountType.limit),
      })),
      'formattedLimit',
      'accountTypeId'
    );

    setAccountLimitsToDisplay(accountTypesToSet);
  }, [merchantAccountTypes]);

  useEffect(() => {
    if (branches) {
      const filteredBranches =
        branches?.filter((branch) => branch.id > 0) ?? [];

      setBranchesToDisplay(selectFormatter(filteredBranches, 'name', 'id'));
      formik.setFieldValue(
        'branchId',
        getCurrentSelectedBranch() ?? filteredBranches[0]?.id?.toString()
      );
    }
  }, [merchantIdentity]);

  return (
    <Dialogs.Basic
      title="Invite customer"
      onClose={handleClose}
      {...props}
      mobileFullscreen
      spaceActions
      actions={
        <>
          <Buttons.Primary
            id="inviteCustomerButton"
            type="submit"
            onClick={(): void => formik.handleSubmit()}
            disabled={Boolean(
              Object.keys(formik.errors).length ||
                !formik.isValid ||
                !formik.dirty
            )}
            loading={isLoading}
          >
            Send invite
          </Buttons.Primary>
          <Buttons.Text id="inviteCustomerCancel" onClick={handleClose}>
            Cancel
          </Buttons.Text>
        </>
      }
    >
      <form onSubmit={(): void => formik.handleSubmit()}>
        {branchesToDisplay?.length > 1 && (
          <div>
            <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Branch</h4>
            <TextFields.Select
              name="branchId"
              css={styles.textfield}
              options={branchesToDisplay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.branchId}
              error={formik.touched.branchId && Boolean(formik.errors.branchId)}
              helperText={formik.touched.branchId && formik.errors.branchId}
            />
          </div>
        )}

        {checkMerchantDashboardSetting(
          MerchantClassSettingType.CreateInviteAccountLimitSelector
        ) && (
          <div>
            <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Account limit</h4>
            <TextFields.Select
              id="accountTypeId"
              css={styles.textfield}
              options={accountLimitsToDisplay}
              label="Select the customer's account limit"
              onChange={(
                e
              ): Promise<void> | Promise<FormikErrors<InviteCustomerCommand>> =>
                formik.setFieldValue('accountTypeId', e.target.value)
              }
              onBlur={formik.handleBlur}
              value={formik.values.accountTypeId}
              error={
                formik.touched.accountTypeId &&
                Boolean(formik.errors.accountTypeId)
              }
              helperText={
                formik.touched.accountTypeId && formik.errors.accountTypeId
              }
            />
          </div>
        )}

        <div>
          <div css={styles.textfieldTitle}>
            <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>First name</h4>
          </div>
          <TextFields.Outlined
            type="text"
            name="firstName"
            label="Enter customer's first name"
            css={styles.textfield}
            className="fs-mask"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </div>

        <div>
          <div css={styles.textfieldTitle}>
            <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Last name</h4>
          </div>
          <TextFields.Outlined
            type="text"
            name="lastName"
            label="Enter customer's surname"
            css={styles.textfield}
            className="fs-mask"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </div>

        <div>
          <div css={styles.textfieldTitle}>
            <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Email</h4>
          </div>
          <TextFields.Outlined
            type="email"
            name="emailAddress"
            label="Enter customer's email"
            css={styles.textfield}
            className="fs-mask"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}
            error={
              formik.touched.emailAddress && Boolean(formik.errors.emailAddress)
            }
            helperText={
              formik.touched.emailAddress && formik.errors.emailAddress
            }
          />
        </div>

        <div>
          <div css={styles.textfieldTitle}>
            <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Mobile</h4>
          </div>
          <TextFields.Outlined
            type="tel"
            name="mobilePhone"
            label="Enter customer's mobile"
            css={styles.textfield}
            className="fs-mask"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mobilePhone}
            error={
              formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)
            }
            helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
          />
        </div>
      </form>
    </Dialogs.Basic>
  );
};

export default InviteCustomerDialogModule;
