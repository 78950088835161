import { css } from '@emotion/react';
import { lighten } from '@material-ui/core';

import { theme } from 'global';

export const drawer = (open) => css`
  width: ${open ? '280px' : '72px'};
  transition: width 250ms;
  background-color: ${theme.colors.white};
  flex-shrink: 0;
  white-space: nowrap;
  align-items: space-between;
  z-index: 1201 !important;
  & > .MuiPaper-root {
    padding-top: 0;
    top: unset;
    overflow-x: hidden;
    width: ${open ? '280px' : '72px'};
    height: 100%;
    transition: width 250ms;
    box-shadow: unset;
  }

  @media screen and (max-width: ${theme.breaks.md}px) {
    width: 0;
    & > .MuiPaper-root {
      width: ${open ? '100%' : '0px'};
      position: fixed;
      left: 0;
      right: 0;
      z-index: 1220;
    }
  }
`;

export const innerDrawer = (open) => css`
  width: ${open ? '208px' : '0px'};
  transition: width 250ms;
  flex-shrink: 0;
  white-space: nowrap;
  & > .MuiPaper-root {
    margin-left: 71px;
    width: ${open ? '209px' : '0px'};
    height: 100%;
    padding-top: 0;
    top: unset;
    overflow-x: hidden;
    transition: ${open ? 'none' : 'width 250ms'};
    box-shadow: unset;
  }

  @media screen and (max-width: ${theme.breaks.md}px) {
    width: 0;
    & > .MuiPaper-root {
      margin: 0;
      width: ${open ? '100%' : '0px'};
      position: fixed;
      left: 0;
      right: 0;
      z-index: 1220;
      transition: width 250ms;
    }
  }
`;

export const toolbar = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  height: 70px;
`;

export const list = css`
  padding: 0 8px;
  font-size: 1em;
  line-height: 1.24em;
  letter-spacing: -0.3px;
`;

export const flexList = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const profile = (selected) => css`
  color: ${selected ? theme.colors.primary.medium : theme.colors.neutral[600]};
  font-weight: ${selected ? 500 : 400};
`;

export const drawerItem = (open) => css`
  @media screen and (max-width: ${theme.breaks.summary}px) {
    display: flex;
    flex-direction: ${open ? 'row' : 'column'};
  }
`;

export const drawerText = (open) => css`
  @media screen and (min-width: ${theme.breaks.md +
    1}px) and (max-width: ${theme.breaks.summary}px) {
    display: inherit;
    font-size: ${open ? '1em' : '0.76em'};
  }
`;

export const listItemHeading = css`
  font-weight: 500;
  color: ${theme.colors.primary.dark};
`;

export const innerListItemText = css`
  font-weight: 400;
`;

export const divider = css`
  margin: 0 1rem;
`;

export const listItem = (selected) => css`
  justify-content: flex-start;
  padding: 1rem;
  margin: 8px auto;
  color: ${selected ? theme.colors.primary.medium : theme.colors.neutral[600]};
  background-color: ${selected
    ? `${lighten(theme.colors.primary.medium, 0.92)} !important`
    : 'transparent'};
  border-radius: ${theme.radii.md};
  &:hover {
    background-color: ${theme.colors.neutral[50]};
  }
`;

export const listItemIcon = (open, selected) => css`
  margin-right: ${open ? '12px' : '0'};
  color: ${selected ? theme.colors.primary.medium : theme.colors.neutral[600]};
`;

export const listItemText = (open, hasTag) => css`
  display: ${open ? 'inherit' : 'none'};
  ${hasTag ? 'flex: 0 0 auto;' : 'flex: 1 1 auto;'}
`;

export const accordian = css`
  box-shadow: unset;
  margin: 8px auto;
  border-radius: ${theme.radii.lg};
`;
