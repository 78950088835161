import { css } from '@emotion/react';

import { theme } from 'global';

export const pageContainer = (hasActions, fullHeight) => css`
  display: flex;
  flex-direction: column;
  margin-bottom: ${hasActions ? 64 : 0}px;
  height: ${fullHeight ? 'calc(100% - var(--header-height))' : 'auto'};
  position: relative;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin-bottom: ${hasActions ? 80 : 0}px;
  }
`;

export const title = css`
  margin: 0 0 24px;
  user-select: none;

  @media screen and (max-width: ${theme.breaks.sm}px) {
    margin: 0 0 1rem;
  }
`;

export const banner = css`
  margin: -1rem 0px 1rem;
`;

export const sandboxAlert = css`
  margin: 0px 0px 1rem;
`;

export const paper = (fullHeight) => css`
  height: ${fullHeight ? '100%' : 'auto'};
  border-radius: ${theme.radii.lg};

  & > div {
    padding: 2rem;

    @media screen and (max-width: ${theme.breaks.xs}px) {
      padding: 1.5rem 1rem;
    }
  }
`;

export const container = (fullWidth, fullHeight, flex, flexCol) => css`
  max-width: ${fullWidth ? '100%' : '704px'};
  display: ${flex ? 'flex' : 'block'};
  flex-direction: ${flexCol ? 'column' : 'row'};
  height: ${fullHeight ? '100%' : 'auto'};
`;

export const breadcrumbs = css`
  margin: 0px 0px 1rem;
  user-select: none;
`;

export const currentPageBreadcrumb = css`
  color: inherit;
  cursor: default;
  margin-left: 4px;
  &:hover {
    text-decoration: none;
  }
`;
