import { css } from '@emotion/react';
import { theme } from 'global';

export const articleContainer = css`
  display: inline-flex;
  flex-direction: column;
  width: calc(33.33% - 16px);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  }

  @media screen and (min-width: ${theme.breaks.md + 1}px) {
    &:not(:first-of-type) {
      margin-left: 24px;
    }
  }

  @media screen and (max-width: ${theme.breaks.md}px) {
    width: calc(50% - 16px);
    margin-bottom: 16px;
  }

  @media screen and (min-width: ${theme.breaks.xs + 1}px) {
    &:nth-of-type(2) {
      margin-left: 16px;
    }
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    width: 100%;
  }
`;

export const contentContainer = css`
  padding: 16px;
  overflow: hidden;
  background-color: ${theme.colors.white};
  width: 100%;
  border-bottom-left-radius: ${theme.radii.lg};
  border-bottom-right-radius: ${theme.radii.lg};

  h4,
  p {
    max-width: 640px;
    color: inherit;
    margin-bottom: 8px;
  }

  p {
    margin: 0px 0px 8px;
  }

  p.caption {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 12px;
    line-height: 18px;

    > svg {
      width: 16px;
      height: 16px;
    }
  }

  p:last-of-type {
    margin: 8px 0px 0px;
  }
`;

export const imageContainer = css`
  overflow: hidden;
  aspect-ratio: 2/1;
  border-top-left-radius: ${theme.radii.lg};
  border-top-right-radius: ${theme.radii.lg};
`;

export const articleImage = css`
  width: 100%;
  aspect-ratio: 2/1;
`;
