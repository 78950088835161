/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import {
  Buttons,
  Dialogs,
  Selectable,
  TextFields,
  Widgets,
} from '@zip/business-components';
import { MultiSelect, RoleDescriptionDialog } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import { MerchantRoleGuid, PageAction } from 'enums';
import { useFormik } from 'formik';
import { collections, css } from 'global';
import { FC, useEffect, useState } from 'react';
import { ManageUserCommand, ManageUserCommandErrors } from 'types/commands';
import { logError, selectFormatter, useUserFunctions, Validators } from 'utils';
import { isFormikValid } from 'utils/validators';
import { UpdateUserModuleProps } from './UpdateUserModuleProps';

const UpdateUserModule: FC<UpdateUserModuleProps> = ({
  user,
  roles = [],
  open,
  toggleOpen,
  onSuccess,
}) => {
  const Snackbar = useSnackbar();
  const UserFunctions = useUserFunctions();
  const { branches, isCompanyUser } = useMerchantData();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isRoleDialogOpen, setIsRoleDialogOpen] = useState<boolean>(false);

  function handleClose(): void {
    toggleOpen(false);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues: {
      id: user?.userProfileId,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      staffRefCode: user?.staffRefCode,
      mobilePhone: user?.mobilePhone,
      roleId: user?.roleId,
      isCompanyUser: user?.userType.toString() === 'Company',
      branchIdList:
        user?.branchIdList?.map((branchId) => branchId.toString()) ?? [],
    },
    onSubmit: async (values) => {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      try {
        const payload = {
          ...values,
          branchIdList: collections.roles.rolesWithNoBranch.includes(
            values.roleId as MerchantRoleGuid
          )
            ? null
            : values.branchIdList,
        };
        await UserFunctions.updateUser(payload);
        Snackbar.success('User was updated successfully');
        handleClose();
        onSuccess();
      } catch (err) {
        logError(PageAction.updateUser, err, { values });
        Snackbar.error('An error occurred updating the user.');
      } finally {
        setIsSubmitting(false);
      }
    },
    validate: (values: ManageUserCommand) => {
      const errors: ManageUserCommandErrors = {};
      const maxCharacterLimit = 100;

      if (!values.firstName) {
        errors.firstName = 'Required';
      } else if (values.firstName.length > maxCharacterLimit) {
        errors.firstName = 'Name must not exceed 100 characters';
      } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.firstName)) {
        errors.firstName = 'Name contains invalid characters';
      } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.firstName)) {
        errors.firstName = 'Name must contain at least 2 letters';
      }

      if (!values.lastName) {
        errors.lastName = 'Required';
      } else if (values.lastName.length > maxCharacterLimit) {
        errors.lastName = 'Name must not exceed 100 characters';
      } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.lastName)) {
        errors.lastName = 'Name contains invalid characters';
      } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.lastName)) {
        errors.lastName = 'Name must contain at least 2 letters';
      }

      if (!values.roleId) {
        errors.roleId = 'Required';
      }
      if (
        !values.branchIdList.length &&
        !collections.roles.rolesWithNoBranch.includes(
          values.roleId as MerchantRoleGuid
        )
      ) {
        errors.branchIdList = 'Required';
      }

      if (!values.email) {
        errors.email = 'Required';
      } else if (!Validators.validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      }

      if (
        values.mobilePhone &&
        !Validators.validateAustralianUserMobile(values.mobilePhone)
      ) {
        errors.mobilePhone = 'Invalid mobile number';
      }

      return errors;
    },
  });

  useEffect(() => {
    if (open && user) {
      formik.setValues({
        id: user?.userProfileId,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        staffRefCode: user?.staffRefCode,
        mobilePhone: user?.mobilePhone,
        roleId: user?.roleId,
        isCompanyUser: user?.userType.toString() === 'Company',
        branchIdList:
          user?.branchIdList?.map((branchId) => branchId.toString()) ?? [],
      });
    }
  }, [open, user]);

  const roleOptions: Selectable[] = selectFormatter(
    roles?.filter((role) => {
      if (role?.name?.includes('Admin')) {
        return false;
      }

      if (formik?.values?.isCompanyUser) {
        return collections.roles.rolesWithNoBranch.includes(
          role.roleGuid as MerchantRoleGuid
        );
      }

      return true;
    }),
    'name',
    'roleGuid'
  );

  return (
    <Dialogs.Basic
      id="updateUserDialog"
      open={open}
      title="Update user"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            type="submit"
            loading={isSubmitting}
            onClick={(): void => formik.handleSubmit()}
            disabled={!isFormikValid(formik)}
          >
            Update user
          </Buttons.Primary>

          <Buttons.Text onClick={handleClose}>Cancel</Buttons.Text>
        </>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <p css={css.noTopMrgn}>
          Enter the new details for the user you wish to update.
        </p>

        <MuiGrid container spacing={2}>
          <MuiGrid item xs={12}>
            <TextFields.Outlined
              type="email"
              name="email"
              label="Email"
              css={[css.noMargin]}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <TextFields.Outlined
              type="text"
              name="firstName"
              label="First name"
              css={[css.noMargin]}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              fullWidth
            />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <TextFields.Outlined
              type="text"
              name="lastName"
              label="Last name"
              css={[css.noMargin]}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              fullWidth
            />
          </MuiGrid>
          {isCompanyUser && (
            <MuiGrid item xs={12} css={{ padding: '0px 8px !important' }}>
              <Widgets.Checkbox
                label="Company user"
                onChange={(e, isChecked): void => {
                  formik.setFieldValue('isCompanyUser', isChecked);
                }}
                checked={formik.values.isCompanyUser}
                disabled
              />
            </MuiGrid>
          )}
          <MuiGrid item xs={12}>
            <TextFields.Select
              name="roleId"
              label="Role"
              css={[css.noMargin]}
              options={roleOptions}
              error={formik.touched.roleId && Boolean(formik.errors.roleId)}
              helperText={
                <>
                  {formik.touched.roleId && formik.errors.roleId && (
                    <span>${formik.errors.roleId}</span>
                  )}
                  <Buttons.Text
                    onClick={(): void => setIsRoleDialogOpen(true)}
                    className="body4"
                    css={{ float: 'right' }}
                  >
                    Learn more about roles
                  </Buttons.Text>
                </>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.roleId}
              fullWidth
            />
          </MuiGrid>
          <MuiGrid item xs={12}>
            <MultiSelect
              name="branchIdList"
              label="Branches"
              css={[css.noMargin]}
              disabled={collections.roles.rolesWithNoBranch?.includes(
                formik.values.roleId as MerchantRoleGuid
              )}
              options={selectFormatter(
                branches.filter((branch) => branch.id > 0),
                'name',
                'id'
              )}
              error={
                formik.touched.branchIdList &&
                Boolean(formik.errors.branchIdList)
              }
              helperText={
                formik.touched.branchIdList && formik.errors.branchIdList
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.branchIdList ?? []}
              fullWidth
            />
          </MuiGrid>
          <MuiGrid item xs={12} sm={6}>
            <TextFields.Outlined
              type="text"
              name="staffRefCode"
              label="Staff ID"
              css={[css.noMargin]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.staffRefCode}
              error={
                formik.touched.staffRefCode &&
                Boolean(formik.errors.staffRefCode)
              }
              helperText={
                'Not required' ??
                (formik.touched.staffRefCode && formik.errors.staffRefCode)
              }
              fullWidth
            />
          </MuiGrid>
          <MuiGrid item xs={12} sm={6}>
            <TextFields.Outlined
              type="tel"
              name="mobilePhone"
              label="Mobile"
              css={[css.noMargin]}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobilePhone}
              error={
                formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)
              }
              helperText={
                (formik.touched.mobilePhone && formik.errors.mobilePhone) ??
                'Not required'
              }
              fullWidth
            />
          </MuiGrid>
        </MuiGrid>
      </form>

      <RoleDescriptionDialog
        open={isRoleDialogOpen}
        onClose={(): void => setIsRoleDialogOpen(false)}
      />
    </Dialogs.Basic>
  );
};

export default UpdateUserModule;
