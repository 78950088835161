/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs, TextFields } from '@zip/business-components';
import { useMerchantData, useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { useFormik } from 'formik';
import { Constants } from 'global';
import { useFetch } from 'hooks';
import { FC, useState } from 'react';
import { CreateApiKeyCommand, CreateApiKeyErrors } from 'types/commands';
import { logError, selectFormatter } from 'utils';
import { CreateApiKeyDialogModuleProps } from './CreateApiKeyDialogModuleProps';

const CreateApiKeyDialogModule: FC<CreateApiKeyDialogModuleProps> = ({
  ...props
}) => {
  const { branches } = useMerchantData();
  const { post } = useFetch();
  const { error } = useSnackbar();

  const [isCreatingApiKey, setIsCreatingApiKey] = useState<boolean>(false);

  const branchOptions = selectFormatter(
    branches?.filter((x) => x.id != Constants.branchId.allBranches),
    'name',
    'id'
  );

  const formik = useFormik({
    initialValues: {
      apiKeyName: '',
      deviceRefCode: '',
      branchId: Constants.branchId.webStore,
    },
    onSubmit: (values) => {
      setIsCreatingApiKey(true);
      const payload = {
        ...values,
        branchId: Number(values.branchId) < 0 ? null : Number(values.branchId),
      };
      post('/integration/api-keys', payload)
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          props.onSuccess();
          handleClose();
        })
        .catch((err) => {
          error('Something went wrong');
          logError(PageAction.createApiKey, err);
        })
        .finally(() => {
          setIsCreatingApiKey(false);
        });
    },
    validate: (values: CreateApiKeyCommand) => {
      const errors: CreateApiKeyErrors = {};
      const maxCharacterLimit = 100;

      if (!values.apiKeyName) {
        errors.apiKeyName = 'Required';
      } else if (values.apiKeyName.length > maxCharacterLimit) {
        errors.apiKeyName = 'Name must not exceed 100 characters';
      } else if (!/^[a-zA-Z0-9'\s-]{1,}$/.test(values.apiKeyName)) {
        errors.apiKeyName = 'Name contains invalid characters';
      } else if (!/^[a-zA-Z0-9'\s-]{2,}$/.test(values.apiKeyName)) {
        errors.apiKeyName = 'Name must contain at least 2 letters';
      }

      if (!values.branchId) {
        errors.branchId = 'Required';
      }

      if (values.deviceRefCode) {
        if (values.deviceRefCode.length > maxCharacterLimit) {
          errors.deviceRefCode = 'DeviceRefCode must not exceed 100 characters';
        } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.deviceRefCode)) {
          errors.deviceRefCode = 'DeviceRefCode contains invalid characters';
        } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.deviceRefCode)) {
          errors.deviceRefCode =
            'DeviceRefCode must contain at least 2 letters';
        }
      }

      return errors;
    },
  });

  const handleClose = (): void => {
    props.onClose(null, null);
    formik.resetForm();
  };

  return (
    <Dialogs.Basic
      title="Create API key"
      spaceActions
      actions={
        <>
          <Buttons.Primary
            id="createApiKeyButton"
            disabled={
              !Boolean(Object.keys(formik.touched).length) ||
              Boolean(Object.keys(formik.errors).length)
            }
            loading={isCreatingApiKey}
            onClick={(): void => formik.handleSubmit()}
          >
            Create API key
          </Buttons.Primary>
          <Buttons.Text onClick={handleClose}>Cancel</Buttons.Text>
        </>
      }
      {...props}
      onClose={handleClose}
    >
      <TextFields.Basic
        name="apiKeyName"
        label="Name"
        onChange={(e): void => {
          formik.handleChange(e);
          formik.handleBlur(e);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.apiKeyName}
        error={formik.touched.apiKeyName && Boolean(formik.errors.apiKeyName)}
        helperText={formik.touched.apiKeyName && formik.errors.apiKeyName}
        fullWidth
      />
      <TextFields.Select
        name="branchId"
        options={branchOptions}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.branchId}
        error={formik.touched.branchId && Boolean(formik.errors.branchId)}
        helperText={
          (formik.touched.branchId && formik.errors.branchId) ||
          'Select the branch'
        }
        fullWidth
      />
      <TextFields.Basic
        name="deviceRefCode"
        label="Device Ref Code"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.deviceRefCode}
        error={
          formik.touched.deviceRefCode && Boolean(formik.errors.deviceRefCode)
        }
        helperText={
          (formik.touched.deviceRefCode && formik.errors.deviceRefCode) ||
          'Not required'
        }
        fullWidth
      />
    </Dialogs.Basic>
  );
};

export default CreateApiKeyDialogModule;
