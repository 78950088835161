import { css } from '@emotion/react';

import { theme } from 'global';

export const appbar = css`
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.neutral[200]};
  height: var(--header-height);
`;

export const toolbar = css`
  padding: 14px 24px;
  min-height: var(--header-height);

  @media screen and (max-width: ${theme.breaks.md}px) {
    padding: 1rem 20px;
  }
`;

export const logo = css`
  user-select: none;
`;
