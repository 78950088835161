/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/ban-types */
import { setGroup, setUserId, track } from '@amplitude/analytics-browser';
import { isInitialized } from '@fullstory/browser';
import { identifyFullStoryUser } from '@zip/analytics-core';
import { PageAction } from 'enums';
import { isProduction, isSandbox } from 'global';

declare global {
  interface Window {
    newrelic: unknown;
  }
}

const environmentsToNotExposeErrors = new Set(['prod', 'sand']);
const environmentsToLogActions = new Set(['prod', 'sand']);

function injectBaseAttributes(record: unknown): object {
  return {
    ...(record as object),
    env: process.env.REACT_APP_ENVIRONMENT,
    host: window.location.host,
    // align with other attribute definitions
    page_path: window.location.pathname,
    ...(!!window?.matchMedia('(prefers-color-scheme: light)')?.matches && {
      // align with other attribute definitions
      prefers_color_scheme: window?.matchMedia('(prefers-color-scheme: light)')
        ?.matches
        ? 'light'
        : 'dark',
    }),
  };
}

export function setAmplitudeIdentity(
  userId: string,
  merchantId?: number
): void {
  if (!environmentsToLogActions.has(process.env.REACT_APP_ENVIRONMENT)) {
    return;
  }
  setUserId(userId);
  if (merchantId) {
    setGroup('merchantId', merchantId?.toString());
  }
}

export function setIdentities(
  userId: string,
  merchantId?: number,
  email?: string
): void {
  setAmplitudeIdentity(userId, merchantId);
  if ((isProduction || isSandbox) && isInitialized()) {
    identifyFullStoryUser(userId, { merchantId, email });
  }
}

export function logError(
  pageAction: PageAction,
  error: unknown,
  attributes?: object
): void {
  if (environmentsToNotExposeErrors.has(process.env.REACT_APP_ENVIRONMENT)) {
    const attributesToLog = injectBaseAttributes(attributes);
    // TODO: introduce Dynatrace equivalent
    // window?.newrelic?.addPageAction(pageAction, {
    //   type: 'error',
    //   error: (error as Error).message,
    //   attributes: JSON.stringify(attributesToLog),
    // });
  } else {
    console.error(error);
  }
}

export function logEvent(pageAction: PageAction, attributes?: object): void {
  if (!environmentsToLogActions.has(process.env.REACT_APP_ENVIRONMENT)) {
    return;
  }
  const attributesToLog = injectBaseAttributes(attributes);
  track(pageAction, { ...attributesToLog });
  // TODO: introduce Dynatrace equivalent
  // window?.newrelic?.addPageAction(pageAction, {
  //   type: 'info',
  //   attributes: JSON.stringify(attributesToLog),
  // });
}

export function amplitudePageView(attributes?: object): void {
  if (!environmentsToLogActions.has(process.env.REACT_APP_ENVIRONMENT)) {
    return;
  }
  track('web_page_view', {
    page_path: window?.location?.pathname,
    env: process.env.REACT_APP_ENVIRONMENT,
    host: window?.location?.hostname,
    referrer: document?.referrer,
    prefers_color_scheme: window?.matchMedia('(prefers-color-scheme: light)')
      ?.matches
      ? 'light'
      : 'dark',
    // @ts-ignore
    error: window?.ampError ? window.ampError : 'none',
    ...attributes,
  });
}
