import { SerializedStyles, css } from '@emotion/react';
import { theme } from 'global';

const frequencyTileContainerShrinkDownSize = '272px';

export const base = css`
  p {
    margin: 0;
  }
  .heading2 {
    margin-bottom: 16px;
    width: 100%;
  }
  .body1 {
    margin-bottom: 16px;
  }
  .body2 {
    margin: 16px 0;
  }
  opacity: unset;
`;

export const emptyState = css`
  margin: 0 auto;
  max-width: 349px;
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > img {
    margin: 48px auto 24px;
  }
`;

export const frequencyTile = css`
  container-type: inline-size;

  @container (max-width: ${frequencyTileContainerShrinkDownSize}) {
    .MuiGrid-item {
      width: 100%;
      margin-right: 0;
    }
  }
`;

export const minimumMonthlyPayment = css`
  margin-bottom: 16px;
  margin-right: 16px;
  width: calc(55% - 8px);
`;

export const monthlyAccountFee = css`
  margin-bottom: 16px;
  width: calc(45% - 8px);
`;

export const tabs = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${theme.colors.neutral[600]};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 24px;
  > .MuiTabs-scroller > .MuiTabs-indicator {
    background-color: transparent;
  }
  > span > .MuiTab-wrapper {
    width: unset;
  }
  &.MuiTabs-root {
    min-height: 37px;
    height: 37px;
  }
`;

export const frequencyTab = css`
  background-color: ${theme.colors.background};
  color: ${theme.colors.neutral[600]};
  font-family: Sharp Grotesk;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.5px;
  display: flex;
  text-transform: unset;
  width: 33.33333%;
  min-width: 33.33333%;
  &.Mui-selected {
    background-color: ${theme.colors.primary.dark};
    color: ${theme.colors.background};
  }
  &:not(:first-of-type) {
    border-left: solid 1px ${theme.colors.neutral[600]};
  }
  > .MuiTab-wrapper {
    width: unset;
  }
  @container (max-width: ${frequencyTileContainerShrinkDownSize}) {
    > span {
      display: block;
      font-size: 0px;
    }
    > span:first-letter {
      font-size: 14px;
    }
  }
`;

export const whiteCard = (
  borderRadius: 'sm' | 'md' | 'lg'
): SerializedStyles => css`
  background-color: ${theme.colors.background};
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: ${theme.radii[borderRadius]};
  padding: 16px;
  .body1 {
    color: ${theme.colors.neutral[500]};
  }
  .body4 {
    color: ${theme.colors.neutral[600]};
  }
  .heading1 {
    color: ${theme.colors.neutral[600]};
  }
`;

export const zipMoneyFirstTwoCards = (
  isNewCustomer: boolean
): SerializedStyles => css`
  container-type: inline-size;

  .MuiGrid-item {
    justify-content: space-between;
    display: flex;
    flex-flow: column;
    margin-bottom: 16px;
  }

  .MuiGrid-item:last-of-type {
    width: calc(50% - 8px);
  }
  
  .MuiGrid-item:first-of-type {
    width: ${isNewCustomer ? 'calc(50% - 8px)' : '100%'};
    ${isNewCustomer && 'margin-right: 16px;'}
  }

  @container (max-width: 300px) {
    .MuiGrid-item {
      width: 100% !IMPORTANT;
      margin-right: 0 !IMPORTANT;
    }
`;

export const balanceWhiteCard = css`
  justify-content: space-between;
  display: flex;
  flex-flow: column;

  > p:first-of-type {
    display: inline-flex;
  }
`;

export const tooltipContent = css`
  > p {
    margin: 0;
  }

  > p:first-of-type {
    margin-bottom: 8px;
  }
`;

export const tooltipIcon = css`
  margin-left: 4px;
  padding-top: 1px;
  color: ${theme.colors.primary.medium};
`;

export const zPlusDialogLink = css`
  cursor: pointer;
`;

export const disclaimer = css`
  background-color: transparent;
  color: ${theme.colors.neutral[500]};
  font-family: Sharp Grotesk;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-top: 8px;

  > .MuiAccordion-root {
    background-color: transparent;
  }

  > .MuiAccordion-root > .MuiAccordionSummary-root {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`;

export const zipMoneyAccountFee = css`
  padding: 12px 16px;
  > span {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
`;

export const divider = css`
  margin-top: 24px;
  margin-bottom: 24px;
  background: ${theme.colors.neutral[300]};
  height: 1px;
  width: 100%;
`;

export const fade = css`
  color: ${theme.colors.neutral[300]};
`;

export const mask = css`
  content: '';
  color: transparent !important;
  top: 0;
  bottom: 0;
  left: 7px;
  right: 0;
  border-radius: 2px;
  margin: 3px 0;
  background: linear-gradient(
    90deg,
    ${theme.colors.neutral[200]} 0%,
    ${theme.colors.neutral[300]} 128.58%
  );
  animation: shimmer 2s infinite linear;
  z-index: 1;

  > p {
    color: transparent !important;
  }

  @keyframes shimmer {
    0% 50% 100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(1px);
    }
    75% {
      transform: translateX(-1px);
    }
  }
`;

export const productDescription = css`
  margin: 0px;

  > div {
    margin: 8px 0px 0px 0px;
  }
`;

export const infoAlert = css`
  background: ${theme.colors.primary.lightest};
  color: ${theme.colors.primary.dark}!important;
  align-items: center;
  margin-bottom: 16px;
  div:first-of-type {
    margin-right: 16px;
  }
`;

export const upgradeAlert = css`
  background: ${theme.colors.infoLight};
  color: ${theme.colors.secondary}!important;
  align-items: center;
  margin-bottom: 16px;
  div:first-of-type {
    margin-right: 16px;
  }
`;

export const productHeader = css`
  height: 32px;
  margin-bottom: 8px;
`;
