/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs, Labels } from '@zip/business-components';
import { useMerchantData } from 'contexts';
import {
  FeatureFlag,
  ProductClassification,
  classificationsToShow,
} from 'enums';
import { Constants, css } from 'global';
import _ from 'lodash';
import { FC } from 'react';
import { MerchantProduct } from 'types';
import { currencyFormatter, productFormatter } from 'utils';

import { useFeature } from '@optimizely/react-sdk';
import * as styles from './InterestFreePeriodDialog.styles';

const classificationOrder = {
  'Zip Money': 3,
  'Zip Plus': 2,
  'Zip Pay': 1,
};

const InterestFreePeriodDialog: FC<{
  open: boolean;
  customerIsNewAndRemote: boolean;
  onClose: () => void;
}> = ({ open, customerIsNewAndRemote, onClose }) => {
  // Experiments
  const [isZipPlusEnabled] = useFeature(FeatureFlag.EnableZipPlus);

  const { merchantProductsForDisplay } = useMerchantData();

  const getInterestFreeMonths = (product: MerchantProduct): string => {
    switch (product.productClassification) {
      case ProductClassification.ZipPay:
        return 'always';
      case ProductClassification.ZipMoney:
      default:
        return product.interestFreeMonths.toString();
    }
  };

  const uniqueProducts = _(merchantProductsForDisplay)
    .uniqBy((x) => x.productClassification)
    .map((x) => x.productClassification)
    .filter((x) => {
      // TODO: Remove to enable Zip Plus past dev
      // Filter out zipplus product only if zipplus is disabled and if the customer is new for remote order
      // TODO: for future Z+ work once it's enabled, we need to remove this feature flag & logic once acqui is live for new zip plus customer
      if (
        !isZipPlusEnabled &&
        customerIsNewAndRemote &&
        x == ProductClassification.ZipPlus
      ) {
        return false;
      }

      // hide Zip Business products as they may be enabled to facilitate refunds
      if (classificationsToShow.has(x)) {
        return true;
      }

      return false;
    })
    .sortBy((x) => classificationOrder[productFormatter(x)])
    .value();

  return (
    <Dialogs.Basic
      id="interestFreePeriodDialog"
      onClose={onClose}
      open={open}
      title="Interest free periods"
      actions={<Buttons.Primary onClick={onClose}>Got it</Buttons.Primary>}
    >
      <p className="body2" css={css.noTopMrgn}>
        This is the period of time a customer has to pay off their purchase
        without any owing any interest.
      </p>
      <p className="body2" css={css.noBtmMrgn}>
        The tables below list the interest free periods that you are able to
        offer your customers, and the price range required for the purchase to
        be eligible.
      </p>

      {uniqueProducts?.length >= 1 &&
        uniqueProducts?.map((product) => {
          const isZipPlus = product === ProductClassification.ZipPlus;
          const merchantHasPromo = !!merchantProductsForDisplay.find(
            (mp) => mp.promotion
          );
          return (
            <div css={styles.productsContainer}>
              <span css={styles.logoContainer}>
                <img
                  src={
                    Constants.assets.productLogos[productFormatter(product)]
                      ?.light
                  }
                  alt={productFormatter(product)}
                  height={14}
                />
                {isZipPlus && (
                  <Labels.Basic
                    variant="primary"
                    className="caption1"
                    label="NEW"
                    style={{ marginLeft: 8, padding: '4px 16px' }}
                  />
                )}
              </span>
              <table css={styles.ifpTable(merchantHasPromo)}>
                <thead>
                  <tr>
                    <th id="months" className="body3">
                      Months interest free
                    </th>
                    <th id="min" className="body3">
                      Min. price
                    </th>
                    <th id="max" className="body3">
                      Max price
                    </th>
                    {merchantHasPromo && <th id="promotion" />}
                  </tr>
                </thead>
                <tbody>
                  {merchantProductsForDisplay
                    ?.filter((x) => x.productClassification === product)
                    ?.map((merchantProduct) => (
                      <tr
                        key={merchantProduct.creditProductId}
                        id="period"
                        className="body2"
                      >
                        <td id="months">
                          {getInterestFreeMonths(merchantProduct)}
                        </td>
                        <td id="min">
                          {currencyFormatter(
                            merchantProduct.transactionLimitMin
                          )}
                        </td>
                        <td id="max">
                          {currencyFormatter(
                            merchantProduct.transactionLimitMax
                          )}
                        </td>
                        {merchantHasPromo && (
                          <td id="promotion">
                            {merchantProduct.promotion && (
                              <Labels.Basic
                                variant="primary"
                                className="caption1"
                                label="PROMO"
                                css={styles.promotionLabel}
                              />
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          );
        })}

      {/* TBC: Self serve/request flow */}
      {/* <p className="body2">
        If you wish to make alterations or enquire about the interest-free
        periods you currently offer,{' '}
        <Buttons.Text className="body2">click here</Buttons.Text>.
      </p> */}
    </Dialogs.Basic>
  );
};

export default InterestFreePeriodDialog;
