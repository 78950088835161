import { useMerchantData } from 'contexts';
import { MerchantClassSettingType, OrderSearchType } from 'enums';
import { Constants } from 'global';
import { useFetch } from 'hooks';
import moment from 'moment';
import {
  ChargeDetails,
  CreateOrderCommand,
  CustomerToCharge,
  OrderDetails,
  OrderSummary,
  RemoteOrderDetails,
  RepaymentsQuoteResponse,
} from 'types';
import { GetOrdersQuery, GetRepaymentsQuoteQuery } from 'types/queries';

interface OrderFunctions {
  resetGetRequests: () => void;
  getOrders: (query: GetOrdersQuery) => Promise<any>;
  getOrderDetails: (operationRequestId: number) => Promise<any>;
  getCustomerOrders: (consumerId: number) => Promise<any>;
  completeOrder: (
    branchId: number,
    operationRequestId: number,
    reference: string,
    amount: number
  ) => Promise<any>;
  cancelOrder: (operationRequestId: number) => Promise<any>;
  refundOrder: (
    operationRequestId: number,
    branchId: number,
    amount: number,
    comment: string
  ) => Promise<any>;
  validateInStoreToken: (token: string | number) => Promise<CustomerToCharge>;
  createInstoreOrder: (
    payAmount: number,
    interestFreeMonths: number,
    token: string,
    branchId: number,
    refCode: string,
    staffActor: { refCode: string; name: string },
    capture: boolean,
    metadata?: unknown
  ) => Promise<ChargeDetails>;
  checkChargeStatus: (operationRequestId: number) => Promise<any>;
  updateOrderReference: (
    operationRequestId: number,
    orderReference: string
  ) => Promise<any>;
  removeOrder: (operationRequestId: number) => Promise<any>;
  cancelOperationRequest: (operationRequestId: number) => Promise<any>;
  resendOrder: (operationRequestId: number) => Promise<any>;
  concatUniqueOrders: (
    orders: OrderSummary[],
    ordersToAdd: OrderSummary[]
  ) => OrderSummary[];
  createCharge: (
    command: CreateOrderCommand,
    interestFreeMonths: number,
    metadata?: Record<string, string>
  ) => Promise<ChargeDetails>;
  sendOrder: (
    amount: CreateOrderCommand,
    metadata?: Record<string, string>
  ) => Promise<any>;
  quote: (query: GetRepaymentsQuoteQuery) => Promise<RepaymentsQuoteResponse>;
}

export const useOrderFunctions = (): OrderFunctions => {
  const { get, post } = useFetch();
  const { currentUserId, currentUserName, checkMerchantDashboardSetting } =
    useMerchantData();
  let controller = new AbortController();

  function resetGetRequests(): void {
    controller.abort();
    controller = new AbortController();
  }

  async function getOrders(query: GetOrdersQuery): Promise<any> {
    const queryParams = new URLSearchParams();

    if (query.searchValue) {
      queryParams.append('searchValue', query.searchValue);
      if (query.searchType !== OrderSearchType.NoFilter) {
        queryParams.append('searchType', query.searchType);
      }
    }

    if (query.status) {
      queryParams.append('status', query.status);
    }

    if (query.statuses?.length > 0) {
      query.statuses.map((x) => queryParams.append('statuses', x));
    }

    if (query.fromDate) {
      queryParams.append(
        'fromDate',
        moment(query.fromDate).format('yyyy-MM-DD')
      );
    }

    if (query.toDate) {
      queryParams.append('toDate', moment(query.toDate).format('yyyy-MM-DD'));
    }

    queryParams.append('sortBy', query.sortBy);
    queryParams.append('orderBy', query.orderBy);
    queryParams.append('take', query.take.toString());
    queryParams.append('skip', query.skip.toString());

    const controllerSignal = controller.signal;

    return get(
      `/order/search?${decodeURIComponent(queryParams.toString())}`,
      controllerSignal
    )
      .catch((err) => {
        if (controllerSignal.aborted) {
          throw new Error(Constants.errors.abortedSignal);
        }
        throw err;
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Something went wrong loading orders');
        }

        if (res.status === 200) {
          return res?.json();
        }

        return { orders: [] };
      })
      .catch((err) => {
        throw err;
      });
  }

  async function getOrderDetails(
    operationRequestId: number
  ): Promise<OrderDetails> {
    const usedSignal = controller.signal;
    return get(
      `/Order/details?OperationRequestId=${operationRequestId}`,
      usedSignal
    )
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        if (res.status === 204) {
          throw new Error(
            `No order details found for operationRequestId: ${operationRequestId}`
          );
        }
        return null;
      })
      .catch((err) => {
        if (!usedSignal.aborted) {
          throw err;
        }
      });
  }

  async function getCustomerOrders(consumerId: number): Promise<any> {
    return get(
      `/Order/customer-orders?consumerId=${consumerId}`,
      controller.signal
    )
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        return null;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function completeOrder(
    branchId: number,
    operationRequestId: number,
    reference: string,
    amount: number
  ): Promise<unknown> {
    const payload = {
      branchId,
      operationRequestId,
      reference,
      amount,
    };
    return post(`/order/complete`, payload)
      .then(async (res) => {
        if (!res.ok) {
          throw new Error(res?.error?.message);
        }

        const result = await res?.json();
        if (!result.isSuccess) {
          throw new Error(res?.reason);
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  async function cancelOrder(operationRequestId: number): Promise<unknown> {
    const payload = {
      operationRequestId,
    };
    return post(`/order/cancel`, payload)
      .then(async (res) => {
        if (!res.ok) {
          throw new Error(res?.error?.message);
        }

        const result = await res?.json();
        if (!result.isSuccess) {
          throw new Error(result?.reason);
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  async function refundOrder(
    operationRequestId: number,
    branchId: number,
    amount: number,
    comment: string
  ): Promise<any> {
    const payload = {
      operationRequestId,
      branchId,
      amount,
      comment,
    };
    return post(`/order/refund`, payload)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res?.error?.message);
        }

        if (res?.status === 200) {
          return res?.json();
        }

        return null;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function validateInStoreToken(
    token: string | number
  ): Promise<CustomerToCharge> {
    return post(`/instoretoken/validate`, { token }, controller.signal).then(
      (res) => res?.json()
    );
  }

  async function createInstoreOrder(
    payAmount: number,
    interestFreeMonths: number,
    token: string,
    branchId: number,
    refCode: string,
    staffActor: { refCode: string; name: string },
    capture: boolean,
    metadata?: unknown
  ): Promise<ChargeDetails> {
    const payload = {
      refCode,
      payAmount,
      originator: {
        staffActor,
        locationId: branchId,
        deviceRefCode: 'DASHBOARDVNEXT',
      },
      accountIdentifier: {
        method: 'Token',
        value: token,
      },
      interestFreeMonths,
      capture,
      metadata,
    };

    return post(`/order/create`, payload, controller.signal)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res?.json();
      })
      .catch((err) => {
        throw err;
      });
  }

  async function checkChargeStatus(
    operationRequestId: number
  ): Promise<ChargeDetails> {
    return get(
      `/operationrequest/purchase-request?OperationRequestId=${operationRequestId}`,
      controller.signal
    )
      .then((res) => res?.json())
      .catch((err) => {
        throw err;
      });
  }

  async function updateOrderReference(
    operationRequestId: number,
    orderReference: string
  ): Promise<any> {
    const payload = {
      operationRequestId,
      orderReference,
    };
    return post(`/order/reference`, payload, controller.signal)
      .then((res) => {
        if (res.status === 200) {
          return null;
        }
        throw res;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function removeOrder(operationRequestId: number): Promise<any> {
    const payload = {
      operationRequestId,
    };
    return post(`/order/remove`, payload)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res?.error?.message);
        }

        return null;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function cancelOperationRequest(
    operationRequestId: number
  ): Promise<any> {
    const payload = {
      operationRequestId,
    };
    return post(`/operationrequest/cancel`, payload)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res?.error?.message);
        }

        return null;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function resendOrder(operationRequestId: number): Promise<any> {
    const payload = {
      operationRequestId,
    };
    return post(`/order/resend-invitation`, payload)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res?.error?.message);
        }

        if (res.status === 200) {
          return res?.json();
        }

        return null;
      })
      .catch((err) => {
        throw err;
      });
  }

  function concatUniqueOrders(
    orders: OrderSummary[],
    ordersToAdd: OrderSummary[] = []
  ): OrderSummary[] {
    return orders.concat(
      ordersToAdd?.filter(
        (orderToAdd: OrderSummary) =>
          !orders.some((item) => item.id === orderToAdd.id)
      ) ?? []
    );
  }

  // Order Unification
  async function createCharge(
    command: CreateOrderCommand,
    interestFreeMonths: number,
    metadata?: Record<string, string>
  ): Promise<ChargeDetails> {
    const payload = {
      refCode: command.reference,
      payAmount: command.price,
      originator: {
        staffActor: { refCode: currentUserId, name: currentUserName },
        locationId: Number(command.branch),
        deviceRefCode: 'DASHBOARDVNEXT',
      },
      accountIdentifier: {
        method: 'Token',
        value: command.instoreCode,
      },
      interestFreeMonths,
      capture: !checkMerchantDashboardSetting(
        MerchantClassSettingType.AuthoriseBeforeCapture
      ),
      metadata,
    };

    return post(`/order/create`, payload, controller.signal)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res?.json();
      })
      .catch((err) => {
        throw err;
      });
  }

  async function sendOrder(
    command: CreateOrderCommand,
    metadata?: Record<string, string>
  ): Promise<ChargeDetails> {
    const payload = {
      amount: command.price,
      branchId: Number(command.branch),
      creditProductId: Number(command.creditProduct),
      emailAddress: command.email,
      mobilePhone: command.mobile,
      reference: command.reference || new Date().valueOf(),
      lastName: command.lastName,
      metadata,
    };

    return post(`/order/remote-order`, payload, controller.signal)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res?.json();
      })
      .catch((err) => {
        throw err;
      });
  }

  async function quote(
    query: GetRepaymentsQuoteQuery
  ): Promise<RepaymentsQuoteResponse> {
    let url = `/order/quote?InterestFreeMonths=${query.interestFreeMonths}&Price=${query.price}`;

    if (query.accountId) {
      url = `${url}&AccountId=${query.accountId}`;
    }

    return get(url, controller.signal)
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        return null;
      })
      .catch((err) => {
        if (!controller.signal.aborted) {
          throw err;
        }
      });
  }

  return {
    resetGetRequests,
    getOrders,
    getOrderDetails,
    getCustomerOrders,
    completeOrder,
    cancelOrder,
    refundOrder,
    validateInStoreToken,
    createInstoreOrder,
    checkChargeStatus,
    updateOrderReference,
    removeOrder,
    cancelOperationRequest,
    resendOrder,
    concatUniqueOrders,
    createCharge,
    sendOrder,
    quote,
  };
};

export default useOrderFunctions;
