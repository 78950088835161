/** @jsxImportSource @emotion/react */
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { Alert, Buttons, Spinner } from '@zip/business-components';
import { DemoBanner, Drawer, DrawerRef, Header } from 'components';
import { useAuth0, useCookies, useMerchantData, useSnackbar } from 'contexts';
import {
  MerchantClassSettingType,
  MerchantProfileStepStatus,
  MerchantRoleGuid,
  PageAction,
  PageRoute,
  PermissionEnum,
} from 'enums';
import { Constants, css } from 'global';
import { useScrollBottom } from 'hooks';
import { HelpCentreModule } from 'modules';
import {
  AccountPage,
  ApplicationPage,
  CampaignsPage,
  CliInvitePage,
  CreateOrderPage,
  CustomersPage,
  DashboardPage,
  DisbursementPage,
  IntegrationsPage,
  MarketingPage,
  NotificationSettingsPage,
  OrdersPage,
  PosMaterialsPage,
  OffersPage,
  SettingsPage,
  SetupGuidePage,
  StoreDirectoryPage,
  UsersPage,
} from 'pages';
import { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { hasAuth0Error, logError } from 'utils';
import { isProduction, isSandbox } from '../constants';
import { theme } from '../theme';

import * as styles from './Layout.styles';

const optimizelyClient = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
});

const Layout = (): EmotionJSX.Element => {
  const { scrollBottom, scrollProps } = useScrollBottom();
  const { loading, accessToken, isAuthenticated, loginWithRedirect, logout } =
    useAuth0();
  const { clearAuthentication } = useCookies();

  const Snackbar = useSnackbar();

  const {
    getMerchantProfile,
    getProfileData,
    checkPermission,
    checkPermissions,
    checkMerchantDashboardSetting,
    hasRole,
    isAdmin,
    profileDataIsLoading,
    profileData,
    merchantProfile,
    merchantIdentity,
    hasPublicId,
    isUnlinkedUser,
    isLinkingUser,
    needsToReauth,
  } = useMerchantData();

  const history = useHistory<{ applicationComplete: string }>();

  // ## Content conditions ##
  const passwordHasExpired = hasAuth0Error(Constants.errors.passwordHasExpired);
  const suspiciousLogin = hasAuth0Error(Constants.errors.suspiciousLogin);

  const isMissingAuth =
    !loading &&
    !isAuthenticated &&
    !profileDataIsLoading &&
    !passwordHasExpired &&
    !suspiciousLogin &&
    !isUnlinkedUser() &&
    !isLinkingUser();

  const isMissingUserProfile =
    !loading &&
    !profileDataIsLoading &&
    (isUnlinkedUser() || isLinkingUser() || needsToReauth());

  /*
   * `profileData.permissions` is required to display certain routes
   *   and therefore required for deeplinking
   */
  const shouldShowContent =
    !loading &&
    isAuthenticated &&
    !profileDataIsLoading &&
    profileData?.permissions != null &&
    ((!isProduction && hasPublicId) || profileData) &&
    !passwordHasExpired &&
    !suspiciousLogin &&
    !isUnlinkedUser() &&
    !isLinkingUser() &&
    !needsToReauth();

  const shouldForceApplicationView =
    merchantProfile &&
    !merchantProfile?.profile?.submitted &&
    merchantProfile?.contact !== MerchantProfileStepStatus.Completed &&
    !history?.location?.state?.applicationComplete;

  /*
   * ## Initial load ##
   *
   * On component load this section will trigger a wait for Auth0 to
   *   authenticate and load, once completed it will then call Zip APIs
   *   for the required account data via initialLoad()
   *
   * If after 3 seconds (3000ms) the component has not been unloaded it
   *   will trigger setShowCacheHelper to the value of `loading`
   */
  const [showCacheHelper, setShowCacheHelper] = useState<boolean>(false);

  async function initialLoad(): Promise<void> {
    try {
      await getMerchantProfile();
      await getProfileData();
      setShowCacheHelper(false);
    } catch (err) {
      logError(PageAction.initialLoad, err);
      Snackbar.error('An error has occurred. Please try again.');
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => setShowCacheHelper(loading), 3000);

    if (!loading && isAuthenticated && accessToken) {
      initialLoad();
    }

    return (): void => clearTimeout(timer);
  }, [loading, isAuthenticated, accessToken]);

  // ## Drawer management ##
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(
    window.innerWidth > theme.breaks.md + 1
  );
  const [isInnerDrawerOpen, setIsInnerDrawerOpen] = useState<boolean>(false);

  const openDrawer = (): void => {
    setIsDrawerOpen(true);
    setIsInnerDrawerOpen(false);
  };
  const closeDrawer = (): void => {
    setIsDrawerOpen(false);
    setIsInnerDrawerOpen(false);
  };
  const openInnerDrawer = (): void => {
    setIsDrawerOpen(false);
    setIsInnerDrawerOpen(true);
  };

  const drawer = useRef<DrawerRef>();

  const resetInnerSelectedMenuItem = (): void => {
    drawer.current.resetInnerSelectedMenuItem();
  };

  // ## Help centre ##
  const [isHelpCentreOpen, setIsHelpCentreOpen] = useState<boolean>(false);

  const openHelpCentre = (): void => setIsHelpCentreOpen(true);

  // ## JSX ##
  return (
    <>
      <Header
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        isDrawerOpen={isDrawerOpen || isInnerDrawerOpen}
        openHelpCentre={openHelpCentre}
      />

      {(loading || profileDataIsLoading) && (
        <div css={styles.loading}>
          <Spinner />

          {showCacheHelper && (
            <Alert variant="info" css={styles.cacheHelper}>
              Taking a while?{' '}
              <Buttons.Text
                onClick={(): void => clearAuthentication()}
                className="body2"
                css={styles.textButton}
              >
                Click here
              </Buttons.Text>{' '}
              to retry
            </Alert>
          )}
        </div>
      )}

      {isMissingAuth && loginWithRedirect({})}

      {isMissingUserProfile && (
        <div id="content" css={styles.main}>
          <div css={styles.fullPageComm}>
            <div className="title2">
              {isUnlinkedUser() ? 'Unregistered user' : 'Almost ready'}
            </div>
            <p className="heading4">
              {isUnlinkedUser()
                ? 'Your user account has not been set up. Please contact your account administrator.'
                : "You're set up but not quite ready to go, we just need you to re-authenticate to finalise your account set up."}
            </p>
            <Buttons.Primary onClick={logout}>
              {isUnlinkedUser() ? 'Log out' : "Let's go"}
            </Buttons.Primary>
          </div>
        </div>
      )}

      {!loading && passwordHasExpired && (
        <div id="content" css={styles.main}>
          <div css={styles.fullPageComm}>
            <Alert variant="error" css={styles.loginErrorAlert}>
              <p className="body2" css={css.noTopMrgn}>
                You haven't used Zip in a while. For security purposes, we've
                sent you an email requesting password reset.
              </p>
              <Buttons.Text
                onClick={(): void => logout()}
                className="body2"
                css={{ float: 'right' }}
              >
                Back to login
              </Buttons.Text>
            </Alert>
          </div>
        </div>
      )}

      {!loading && suspiciousLogin && (
        <div id="content" css={styles.main}>
          <div css={styles.fullPageComm}>
            <Alert variant="error" css={styles.loginErrorAlert}>
              <p className="body2" css={css.noTopMrgn}>
                We have detected suspicious login behavior and further attempts
                will be blocked. Please contact the administrator.
              </p>
              <Buttons.Text
                onClick={(): void => logout()}
                className="body2"
                css={{ float: 'right' }}
              >
                Back to login
              </Buttons.Text>
            </Alert>
          </div>
        </div>
      )}

      {shouldShowContent && (
        <OptimizelyProvider
          optimizely={optimizelyClient}
          user={{
            id: profileData?.userProfile?.userProfileId,
            attributes: {
              merchantId:
                profileData?.merchantData?.merchantId ??
                merchantIdentity?.merchantId,
              companyId:
                profileData?.merchantData?.companyId ??
                merchantIdentity?.companyId ??
                null,
            },
          }}
        >
          {!isProduction && !shouldForceApplicationView && <DemoBanner />}
          <div
            id="body-container"
            css={styles.bodyContainer(isDrawerOpen || isInnerDrawerOpen)}
          >
            <Drawer
              isDrawerOpen={isDrawerOpen}
              isInnerDrawerOpen={isInnerDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              setIsInnerDrawerOpen={setIsInnerDrawerOpen}
            />
            <main css={styles.main} {...scrollProps}>
              <Switch>
                <Route path="/" exact>
                  <DashboardPage />
                  {shouldForceApplicationView && (
                    <Redirect
                      from="*"
                      to={{
                        pathname: PageRoute.Application,
                        state: { redirected: true },
                      }}
                    />
                  )}
                </Route>

                {(!isProduction ||
                  merchantProfile?.settlement !==
                    MerchantProfileStepStatus.Completed) && (
                  <Route path={[PageRoute.Application]} exact>
                    <ApplicationPage />
                  </Route>
                )}

                {!isSandbox && (
                  <Route path={[PageRoute.SetupGuide]} exact>
                    <SetupGuidePage openHelpCentre={openHelpCentre} />
                  </Route>
                )}

                <Route exact path={PageRoute.CreateOrder}>
                  {checkPermission(PermissionEnum.CreateOrderExecute) ? (
                    <CreateOrderPage
                      innerDrawerCallback={openDrawer}
                      innerSelectedItemReset={resetInnerSelectedMenuItem}
                    />
                  ) : (
                    <Redirect from="*" to={PageRoute.Orders} />
                  )}
                </Route>

                <Redirect
                  from={`${PageRoute.Orders}/expires-soon`}
                  to={PageRoute.ExpiringOrders}
                />
                <Route
                  exact
                  path={[
                    PageRoute.Orders,
                    `${PageRoute.Orders}/:orderId`,
                    `${PageRoute.Orders}/status/:status`,
                    `${PageRoute.Orders}/status/:status/:orderId`,
                  ]}
                >
                  {(checkPermission(PermissionEnum.OrdersRead) && (
                    <OrdersPage
                      innerDrawerCallback={openInnerDrawer}
                      innerSelectedItemReset={resetInnerSelectedMenuItem}
                    />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route
                  exact
                  path={[
                    PageRoute.Customers,
                    `${PageRoute.Customers}/consumer/:consumerId`,
                    `${PageRoute.Customers}/status/:status`,
                    `${PageRoute.Customers}/status/:status/consumer/:consumerId`,
                  ]}
                >
                  {(checkPermission(PermissionEnum.CustomersRead) && (
                    <CustomersPage
                      innerDrawerCallback={openInnerDrawer}
                      innerSelectedItemReset={resetInnerSelectedMenuItem}
                    />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route
                  path={[
                    `${PageRoute.Customers}/cli-invite`,
                    `${PageRoute.Customers}/cli-invite/status/:status`,
                  ]}
                  exact
                >
                  {(checkPermission(PermissionEnum.CustomersRead) &&
                    ((checkMerchantDashboardSetting(
                      MerchantClassSettingType.CLIInvitationEnabled
                    ) && (
                      <CustomersPage
                        innerDrawerCallback={openInnerDrawer}
                        innerSelectedItemReset={resetInnerSelectedMenuItem}
                      />
                    )) || <Redirect from="*" to={PageRoute.Customers} />)) || (
                    <Redirect from="*" to={PageRoute.Home} />
                  )}
                </Route>

                <Route
                  exact
                  path={`${PageRoute.Tools}${PageRoute.RepaymentCalculator}`}
                >
                  {checkPermission(PermissionEnum.CreateOrderExecute) &&
                  checkMerchantDashboardSetting(
                    MerchantClassSettingType.EnableRepaymentCalculator
                  ) ? (
                    <Redirect from="*" to={PageRoute.CreateOrder} />
                  ) : (
                    <Redirect from="*" to={PageRoute.Home} />
                  )}
                </Route>

                <Route path={`${PageRoute.Tools}${PageRoute.CliInvite}`} exact>
                  {(checkPermission(PermissionEnum.CreateInviteExecute) &&
                    checkMerchantDashboardSetting(
                      MerchantClassSettingType.CLIInvitationEnabled
                    ) && <CliInvitePage />) || (
                    <Redirect from="*" to={PageRoute.Home} />
                  )}
                </Route>

                <Route path={PageRoute.Settings} exact>
                  {(checkPermissions([
                    PermissionEnum.NotificationsUpdate,
                    PermissionEnum.ApiIntegrationRead,
                    PermissionEnum.ReportsRead,
                    PermissionEnum.CustomS3ReportRead,
                    PermissionEnum.UserManagementExecute,
                  ]) && <SettingsPage />) || (
                    <Redirect from="*" to={PageRoute.Home} />
                  )}
                </Route>

                <Route exact path={`${PageRoute.Settings}${PageRoute.Account}`}>
                  {(!isSandbox &&
                    (isAdmin || hasRole(MerchantRoleGuid.FranchiseManager)) && (
                      <AccountPage />
                    )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route
                  exact
                  path={`${PageRoute.Settings}${PageRoute.Notifications}`}
                >
                  {(checkPermission(PermissionEnum.NotificationsUpdate) && (
                    <NotificationSettingsPage />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route
                  exact
                  path={`${PageRoute.Settings}${PageRoute.Integration}`}
                >
                  {(checkPermission(PermissionEnum.ApiIntegrationRead) && (
                    <IntegrationsPage />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route
                  exact
                  path={`${PageRoute.Settings}${PageRoute.Disbursement}`}
                >
                  {(checkPermissions([
                    PermissionEnum.ReportsRead,
                    PermissionEnum.CustomS3ReportRead,
                  ]) && <DisbursementPage />) || (
                    <Redirect from="*" to={PageRoute.Home} />
                  )}
                </Route>

                <Route path={`${PageRoute.Settings}${PageRoute.Users}`} exact>
                  {(checkPermission(PermissionEnum.UserManagementExecute) && (
                    <UsersPage />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route path={PageRoute.Marketing} exact>
                  {(checkPermissions([
                    PermissionEnum.StoreAdvertising,
                    PermissionEnum.StoreProfileRead,
                    PermissionEnum.StoreProfileUpdate,
                    PermissionEnum.StoreCategoryRead,
                    PermissionEnum.StoreCategoryUpdate,
                  ]) && <MarketingPage />) || (
                    <Redirect from="*" to={PageRoute.Home} />
                  )}
                </Route>

                <Route
                  exact
                  path={`${PageRoute.Marketing}${PageRoute.PosMaterials}`}
                >
                  {(checkPermission(PermissionEnum.StoreAdvertising) && (
                    <PosMaterialsPage />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route
                  exact
                  path={`${PageRoute.Marketing}${PageRoute.StoreDirectory}`}
                >
                  {(checkPermission(PermissionEnum.StoreAdvertising) && (
                    <StoreDirectoryPage scrollBottom={scrollBottom} />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route
                  exact
                  path={`${PageRoute.Marketing}${PageRoute.Campaigns}`}
                >
                  {(checkPermission(PermissionEnum.StoreAdvertising) && (
                    <CampaignsPage />
                  )) || <Redirect from="*" to={PageRoute.Home} />}
                </Route>

                <Route path={`${PageRoute.Marketing}${PageRoute.Offers}`} exact>
                  <OffersPage />
                </Route>

                {!profileDataIsLoading && (
                  <Redirect from="*" to={PageRoute.Home} />
                )}
              </Switch>
            </main>
            <HelpCentreModule
              isOpen={isHelpCentreOpen}
              setIsOpen={setIsHelpCentreOpen}
            />
          </div>
        </OptimizelyProvider>
      )}
    </>
  );
};

export default Layout;
