export const Flag: React.FC = () => (
  <svg
    width="17"
    height="10"
    viewBox="0 0 17 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_819_2943)">
      <path
        d="M16.0668 0H0.933152C0.418841 0 0 0.354167 0 0.789062V9.20833C0 9.64323 0.418841 9.9974 0.933152 9.9974H16.0638C16.5781 9.9974 16.9969 9.64062 16.9969 9.20833V0.789062C17 0.354167 16.5781 0 16.0668 0Z"
        fill="#00008B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.94909 5.97388L5.26014 6.79679L6.21793 6.4895L5.65126 7.21346L6.53206 7.65356L5.51267 7.72909L5.65434 8.58846L4.94909 7.95825L4.24075 8.58846L4.38242 7.72909L3.36304 7.65356L4.24383 7.21346L3.67717 6.4895L4.63496 6.79679L4.94909 5.97388Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2921 7.45825L13.44 7.84888L13.8957 7.70304L13.6278 8.04679L14.0467 8.25773L13.5601 8.29419L13.6278 8.70304L13.2921 8.40356L12.9564 8.70304L13.0211 8.29419L12.5376 8.25773L12.9564 8.04679L12.6854 7.70304L13.1412 7.84888L13.2921 7.45825Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2921 1.34644L13.44 1.73706L13.8957 1.59123L13.6278 1.93498L14.0467 2.14591L13.5601 2.18237L13.6278 2.59123L13.2921 2.29175L12.9564 2.59123L13.0211 2.18237L12.5376 2.14591L12.9564 1.93498L12.6854 1.59123L13.1412 1.73706L13.2921 1.34644Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.164 3.82812L11.3149 4.22135L11.7707 4.07552L11.4996 4.41927L11.9185 4.63021L11.435 4.66406L11.4996 5.07292L11.164 4.77344L10.8283 5.07292L10.896 4.66406L10.4094 4.63021L10.8283 4.41927L10.5603 4.07552L11.0161 4.22135L11.164 3.82812Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7003 3.21606L15.8512 3.60929L16.307 3.46346L16.036 3.80721L16.4549 4.01815L15.9713 4.05461L16.036 4.46086L15.7003 4.16398L15.3646 4.46086L15.4324 4.05461L14.9458 4.01815L15.3646 3.80721L15.0967 3.46346L15.5525 3.60929L15.7003 3.21606Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.376 5.05737L14.493 5.30216L14.8041 5.32039L14.5669 5.48966L14.6408 5.74748L14.376 5.60946L14.1111 5.74748L14.185 5.48966L13.9448 5.32039L14.259 5.30216L14.376 5.05737Z"
        fill="white"
      />
      <path
        d="M0.0708333 0.486979L8.57699 4.08333H9.65797V3.6276L1.08098 0H0.933152C0.548188 0 0.2125 0.200521 0.0708333 0.486979ZM9.65797 0V0.455729L1.08098 4.08333H0V3.6276L8.57699 0H9.65797Z"
        fill="white"
      />
      <path
        d="M4.02518 0V4.08333H5.63587V0H4.02518ZM0 1.36198V2.72396H9.65797V1.36198H0Z"
        fill="white"
      />
      <path
        d="M0 1.63281V2.45052H9.65797V1.63281H0ZM4.34547 0V4.08333H5.3125V0H4.34547Z"
        fill="#FF0000"
      />
      <path
        d="M0 4.08333L3.2183 2.72135H3.93895L0.720652 4.08333H0ZM0.147826 0.367188C0.20942 0.286458 0.286413 0.216146 0.375725 0.158854L3.22138 1.36198H2.50072L0.147826 0.367188ZM5.71902 1.36198L8.93732 0H9.65797L6.43659 1.36198H5.71902ZM9.65797 4.08333L6.43659 2.72135H7.15725L9.65489 3.77865V4.08333H9.65797Z"
        fill="#FF0000"
      />
    </g>
    <defs>
      <clipPath id="clip0_819_2943">
        <rect width="17" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
