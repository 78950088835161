import * as Icons from '@zip/react-icons/fearless';
import { InnerMenuItem, MenuItem } from 'components';
import {
  CliRequestStatus,
  CustomerStatus,
  MerchantClassSettingType,
  MerchantProfileStepStatus,
  MerchantRoleGuid,
  OrderStatus,
  PageRoute,
  PermissionEnum,
} from 'enums';
import { Constants, isProduction, isSandbox, theme } from 'global';
import { FC, createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { amplitudePageView, statusFormatter } from 'utils';
import { useMerchantData } from './MerchantData.context';

interface NavigationContextProps {
  menuItems: MenuItem[];
  selectedItems: { menuItem: MenuItem; innerMenuItem?: InnerMenuItem };
  setSelectedItems: (newItems: {
    menuItem: MenuItem;
    innerMenuItem?: InnerMenuItem;
  }) => void;
}

export const NavigationContext =
  createContext<NavigationContextProps>(undefined);
export const useNavigation = (): NavigationContextProps =>
  useContext(NavigationContext);

export const NavigationProvider: FC = ({ children }) => {
  const { regex } = Constants;
  const history = useHistory();
  const {
    profileData,
    checkPermission,
    checkPermissions,
    checkMerchantDashboardSetting,
    hasRole,
    isAdmin,
    hasPublicId,
    merchantProfile,
    merchantCreationTimeStamp,
    daysSinceMerchantCreation,
  } = useMerchantData();

  const isMerchantProfileSubmitted =
    !hasPublicId ||
    (!isProduction &&
      (merchantProfile?.profile?.submitted ||
        (merchantProfile?.contact === MerchantProfileStepStatus.Completed &&
          merchantProfile?.settlement !==
            MerchantProfileStepStatus.Completed)));

  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    {
      text: 'Home',
      value: 'home',
      icon: Icons.Home,
      openDrawer: true,
      openInnerDrawer: false,
      path: PageRoute.Home,
    },
  ]);
  const [selectedItems, setSelectedItems] = useState<{
    menuItem: MenuItem;
    innerMenuItem?: InnerMenuItem;
  }>();

  const orderStatuses: InnerMenuItem[] = Object.keys(OrderStatus)
    .filter((status) => status.toLowerCase() != OrderStatus.Expired)
    .map((status) => ({
      text: statusFormatter(status),
      value: status,
      path: `${PageRoute.Orders}/status/${status.toLowerCase()}`,
    }));

  const cliRequestStatusEnumAsArray: InnerMenuItem[] = Object.keys(
    CliRequestStatus
  ).map((status) => {
    const isAllCliInvites =
      status.toLowerCase() === CliRequestStatus.AllCliRequests;
    const statusUrl = isAllCliInvites
      ? `${PageRoute.Customers}/cli-invite`
      : `${PageRoute.Customers}/cli-invite/status/${status.toLowerCase()}`;
    return {
      text: statusFormatter(status),
      value: `cli-${status}`,
      path: statusUrl,
      autoSelectOnOpen: isAllCliInvites,
    };
  });

  const customerStatusEnumAsArray: InnerMenuItem[] = Object.keys(CustomerStatus)
    .filter((status) => status.toLowerCase() !== CustomerStatus.Active)
    .map((status) => {
      const isAllInvitedCustomers =
        status.toLowerCase() === CustomerStatus.AllInvitedCustomers;
      const statusUrl = isAllInvitedCustomers
        ? PageRoute.Customers
        : `${PageRoute.Customers}/status/${status.toLowerCase()}`;
      return {
        text: statusFormatter(status),
        value: status,
        path: statusUrl,
        autoSelectOnOpen: isAllInvitedCustomers,
      };
    });

  const checkRoute = (pathname: string): void => {
    // cancel if we're doing auth0 callback
    if (pathname?.includes('callback')) {
      return;
    }

    // remove useParam variables, e.g. /consumer/:consumerId
    const path = pathname.replace(regex.useParam, '');

    let menuItem =
      menuItems?.find((item) => path === item.path) ??
      menuItems?.find(
        // e.g. ['', 'orders', 'status', 'completed']
        (item) => path?.split('/')[1] === item.path?.substring(1)
      );
    /**
     * This is to cover the case where someone navigates to something not in our defined PageRoutes
     * we exit without action so we don't potentially overwrite any existing items in selectedItems
     * while allowing defined PageRoutes to overwrite, e.g. PageRoute.SetupGuide which has no menuItem
     */
    if (!menuItem && Object.values<string>(PageRoute).includes(path)) {
      menuItem = {
        path: '',
        value: '',
        openDrawer: true,
        openInnerDrawer: false,
      };
    }
    if (!menuItem) {
      return;
    }

    let innerMenuItem =
      menuItem?.children &&
      menuItem?.children?.find((child) => path === child?.path);
    /**
     * This is to support expandable items where the path is the same as
     * a child of the currently matched innerMenuItem
     * e.g. New customers -> All invited
     */
    if (innerMenuItem && innerMenuItem?.children) {
      innerMenuItem =
        innerMenuItem?.children?.find((child) => path === child?.path) ??
        innerMenuItem;
    }
    /**
     * This is to support expandable items where the path is not the same
     * as the intended child to be selected,
     * e.g. New customers -> In progress
     */
    if (!innerMenuItem && menuItem?.children) {
      menuItem.children?.some((child) => {
        innerMenuItem = child?.children?.find((item) => path === item?.path);
        return !!innerMenuItem;
      });
    }
    setSelectedItems({ menuItem, innerMenuItem });
  };

  function handleRouteChange(pathname: string): void {
    checkRoute(pathname);
    amplitudePageView({
      merchantCreationTimeStamp,
      daysSinceMerchantCreation,
    });
  }

  // on page change
  useEffect(() => {
    const unlisten = history.listen((loc) => handleRouteChange(loc.pathname));

    return (): void => unlisten();
  }, [history, menuItems]);

  // on menuItems change
  useEffect(() => checkRoute(history.location.pathname), [menuItems]);

  // on initial load
  useEffect(
    () =>
      amplitudePageView({
        merchantCreationTimeStamp,
        daysSinceMerchantCreation,
      }),
    []
  );

  // set up menuItems
  useEffect(() => {
    // Add `Home` MenuItem
    const newMenuItems: MenuItem[] = [
      {
        text: 'Home',
        value: 'home',
        icon: Icons.Home,
        openDrawer: true,
        openInnerDrawer: false,
        path: PageRoute.Home,
      },
    ];

    // Add `Orders` MenuItem
    if (
      (isProduction || isMerchantProfileSubmitted) &&
      checkPermission(PermissionEnum.OrdersRead)
    ) {
      const ordersItem = {
        text: 'Orders',
        value: 'orders',
        icon: Icons.CurrencyDollarSimple,
        openDrawer: false,
        openInnerDrawer: true,
        path: PageRoute.Orders,
        children: [
          {
            text: 'All orders',
            value: '',
            path: PageRoute.Orders,
            autoSelectOnOpen: true,
          },
          ...orderStatuses,
        ],
      };

      if (
        !checkMerchantDashboardSetting(
          MerchantClassSettingType.ExcludeFromAuthChargeExpiry
        )
      ) {
        ordersItem.children.push({
          text: 'Expires soon',
          value: 'expires-soon',
          path: PageRoute.ExpiringOrders,
          divider: 'top',
          tag: 'NEW',
          tagExpiry: Constants.expiryDate.expiresSoonTag,
        });
      }
      newMenuItems.push(ordersItem);
    }

    // Add `Customers` MenuItem
    if (
      (isProduction || isMerchantProfileSubmitted) &&
      checkPermission(PermissionEnum.CustomersRead)
    ) {
      newMenuItems.push({
        text: 'Customers',
        value: 'customers',
        icon: Icons.UserMultiple,
        openDrawer: false,
        openInnerDrawer: true,
        path: PageRoute.Customers,
        children: checkMerchantDashboardSetting(
          MerchantClassSettingType.CLIInvitationEnabled
        )
          ? [
              {
                text: 'New customers',
                value: 'new',
                path: PageRoute.Customers,
                children: customerStatusEnumAsArray,
                autoSelectOnOpen: window.innerWidth > theme.breaks.md,
              },
              {
                text: 'Credit limit requests',
                value: 'cli',
                path: `${PageRoute.Customers}/cli-invite`,
                children: cliRequestStatusEnumAsArray,
                autoSelectOnOpen: window.innerWidth > theme.breaks.md,
              },
            ]
          : customerStatusEnumAsArray,
      });
    }

    // Add `Marketing` MenuItem
    const hasMarketing = checkPermissions([
      PermissionEnum.StoreAdvertising,
      PermissionEnum.StoreCategoryRead,
      PermissionEnum.StoreCategoryUpdate,
      PermissionEnum.StoreProfileRead,
      PermissionEnum.StoreProfileUpdate,
    ]);
    if ((isProduction || isMerchantProfileSubmitted) && hasMarketing) {
      const marketingMenuItem: MenuItem = {
        text: 'Marketing',
        value: 'marketing',
        icon: Icons.Megaphone,
        openDrawer: false,
        openInnerDrawer: true,
        path: PageRoute.Marketing,
        children: [],
      };

      // Add `Marketing` children
      if (checkPermission(PermissionEnum.StoreAdvertising)) {
        const childrenToAdd = [
          {
            text: 'Zip campaigns',
            value: 'campaigns',
            path: `${PageRoute.Marketing}${PageRoute.Campaigns}`,
          },
          {
            text: 'POS materials',
            value: 'pos-materials',
            path: `${PageRoute.Marketing}${PageRoute.PosMaterials}`,
          },
        ];
        marketingMenuItem.children.push(...childrenToAdd);
        if (
          !checkMerchantDashboardSetting(
            MerchantClassSettingType.UseCompanyMarketing
          ) &&
          checkPermissions([
            PermissionEnum.StoreCategoryRead,
            PermissionEnum.StoreCategoryUpdate,
            PermissionEnum.StoreProfileRead,
            PermissionEnum.StoreProfileUpdate,
          ])
        ) {
          marketingMenuItem.children.push({
            text: 'Store directory',
            value: 'store-directory',
            path: `${PageRoute.Marketing}${PageRoute.StoreDirectory}`,
          });
        }
        marketingMenuItem.children.push({
          text: 'Offers',
          value: 'offers',
          path: `${PageRoute.Marketing}${PageRoute.Offers}`,
          tag: 'NEW',
          tagExpiry: Constants.expiryDate.offers,
        });
      }
      newMenuItems.push(marketingMenuItem);
    }

    // Set `Settings` MenuItem
    const adminMenuItem = {
      text: 'Settings',
      value: 'settings',
      icon: Icons.Settings,
      openDrawer: false,
      openInnerDrawer: true,
      path: PageRoute.Settings,
      children: [],
    };

    if (!isSandbox && (isAdmin || hasRole(MerchantRoleGuid.FranchiseManager))) {
      const accountMenuItem: InnerMenuItem = {
        text: 'Account',
        value: 'account',
        path: `${PageRoute.Settings}${PageRoute.Account}`,
        tag: 'NEW',
        tagExpiry: Constants.expiryDate.accountSettings,
        children: [],
      };
      adminMenuItem.children.push(accountMenuItem);
    }

    if (checkPermission(PermissionEnum.NotificationsUpdate)) {
      const notificationsMenuItem: InnerMenuItem = {
        text: 'Notifications',
        value: 'notifications',
        path: `${PageRoute.Settings}${PageRoute.Notifications}`,
        children: [],
      };
      adminMenuItem.children.push(notificationsMenuItem);
    }

    if (
      checkPermissions([
        PermissionEnum.ApiIntegrationRead,
        PermissionEnum.ApiIntegrationUpdate,
        PermissionEnum.ApiIntegrationCreate,
      ])
    ) {
      const integrationMenuItem: InnerMenuItem = {
        text: 'Integration',
        value: 'integration',
        path: `${PageRoute.Settings}${PageRoute.Integration}`,
        children: [],
      };
      adminMenuItem.children.push(integrationMenuItem);
    }

    if (checkPermission(PermissionEnum.UserManagementExecute)) {
      const usersMenuItem: InnerMenuItem = {
        text: 'Users',
        value: 'users',
        path: `${PageRoute.Settings}${PageRoute.Users}`,
        children: [],
      };
      adminMenuItem.children.push(usersMenuItem);
    }

    if (
      checkPermissions([
        PermissionEnum.ReportsRead,
        PermissionEnum.CustomS3ReportRead,
      ])
    ) {
      const disbursementMenuItem: InnerMenuItem = {
        text: 'Disbursement',
        value: 'disbursement',
        path: `${PageRoute.Settings}${PageRoute.Disbursement}`,
        children: [],
      };
      adminMenuItem.children.push(disbursementMenuItem);
    }

    if (
      (isProduction || isMerchantProfileSubmitted) &&
      adminMenuItem?.children?.length
    ) {
      newMenuItems.push(adminMenuItem);
    }

    // Add `Setup guide` MenuItem
    if (!isSandbox) {
      newMenuItems.push({
        text: 'Setup Guide',
        value: 'setup-guide',
        icon: Icons.Notebook,
        openDrawer: true,
        openInnerDrawer: false,
        path: PageRoute.SetupGuide,
      });
    }

    setMenuItems(newMenuItems);
  }, [profileData, window.innerWidth]);

  return (
    <NavigationContext.Provider
      value={{
        menuItems,
        selectedItems,
        setSelectedItems,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
