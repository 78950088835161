/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Buttons } from '@zip/business-components';
import { CopyButtonProps } from './CopyButtonProps';

const CopyButton: React.FC<CopyButtonProps> = ({
  value,
  preButtonText,
  postButtonText,
  variant,
  postOnClick,
}) => {
  const [buttonText, setButtonText] = useState<string>(preButtonText);

  useEffect(() => {
    if (buttonText === postButtonText) {
      const timer = setTimeout(() => {
        setButtonText(preButtonText);
      }, 5000);

      return (): void => clearTimeout(timer);
    }
  }, [buttonText]);

  const handleClick = (): void => {
    navigator.clipboard.writeText(value);
    setButtonText(postButtonText);
    postOnClick();
  };

  return (
    <>
      {variant === 'primary' && (
        <Buttons.Primary
          id="copy-button"
          aria-label="copy-button"
          onClick={handleClick}
          style={{ height: 44, padding: 0, margin: 8, minWidth: 92 }}
        >
          {buttonText}
        </Buttons.Primary>
      )}
      {variant === 'text' && (
        <Buttons.Text
          id="copy-button"
          aria-label="copy-button"
          onClick={handleClick}
          style={{
            height: 44,
            padding: 0,
            margin: '8px 0px',
            minWidth: 55,
            fontSize: 11,
            letterSpacing: 0.4,
            fontWeight: 500,
            textDecoration: 'underline',
          }}
        >
          {buttonText}
        </Buttons.Text>
      )}
    </>
  );
};

export default CopyButton;
