export enum OrderSearchType {
  NoFilter = 'NoFilter',
  OrderId = 'OrderId',
  MerchantOrderId = 'MerchantOrderId',
  Reference = 'OrderReference',
  ZipTransactionReceipt = 'ZipTransactionReceipt',
  FullName = 'FullName',
  FirstName = 'FirstName',
  LastName = 'LastName',
  AccountNumber = 'AccountNumber',
  EmailAddress = 'EmailAddress',
  MobileNumber = 'MobileNumber',
}
