/** @jsxImportSource @emotion/react */
import { Spinner } from '@zip/business-components';
import { BasePage, Tabs } from 'components';
import { PageAction } from 'enums';
import { css } from 'global';
import { useFetch } from 'hooks';
import { StoreCategoriesModule, StoreProfileModule } from 'modules';
import { FC, useEffect, useState } from 'react';
import { PageProps, StoreProfileResponse } from 'types';
import { getCroppedImgSrc, logError } from 'utils';

enum TabOption {
  Profile = 'profile',
  Categories = 'categories',
}

const StoreDirectoryPage: FC<PageProps> = () => {
  const { get } = useFetch();
  const [selectedTab, setSelectedTab] = useState<string>(TabOption.Profile);
  const [storeProfile, setStoreProfile] = useState<StoreProfileResponse>();
  const [isLoadingInitialProfile, setIsLoadingInitialProfile] =
    useState<boolean>(true);

  const handleTabChange = (event, newTab): void => setSelectedTab(newTab);

  const abortController = new AbortController();
  const fetchStoreProfile = async (): Promise<void> => {
    const response: StoreProfileResponse = await get('/storeprofile')
      .catch((err) => {
        if (abortController.signal.aborted) {
          return;
        }
        throw err;
      })
      .then((res) => {
        if (res.status === 200) {
          return res?.json();
        }
        return null;
      })
      .catch((err) => {
        logError(PageAction.fetchStoreProfile, err);
        setIsLoadingInitialProfile(false);
      });

    if (
      response?.merchantProfileDetails?.profileImageUrl &&
      response?.merchantProfileDetails?.logoImageUrl
    ) {
      response.merchantProfileDetails.profileImageUrl = await getCroppedImgSrc(
        response.merchantProfileDetails.profileImageUrl
      );
      response.merchantProfileDetails.logoImageUrl = await getCroppedImgSrc(
        response.merchantProfileDetails.logoImageUrl,
        true
      );
    }

    setStoreProfile(response);
    setIsLoadingInitialProfile(false);
  };

  useEffect(() => {
    fetchStoreProfile();

    return (): void => abortController.abort();
  }, []);

  return (
    <>
      <BasePage
        title="Store directory"
        hasActions
        sandboxAlert="Submitting a store tile can be completed once your application is approved"
      >
        <p className="body2" css={css.noTopMrgn}>
          To ensure customers will find you on Zip store directory, you will
          need to set up your store profile and select store categories.{' '}
          <a
            href="https://help.zip.co/hc/en-us/articles/360001805016-How-can-customers-find-me-"
            target="_blank"
            rel="noreferrer noopenner"
            className="body2"
          >
            Learn more
          </a>
        </p>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          tabs={Object.keys(TabOption).map((tab) => ({
            label: `Store ${tab.toLowerCase()}`,
            value: TabOption[tab],
          }))}
          content={{
            [TabOption.Profile]: (
              <>
                {isLoadingInitialProfile ? (
                  <Spinner />
                ) : (
                  <StoreProfileModule
                    storeProfile={storeProfile}
                    refreshStoreProfile={fetchStoreProfile}
                  />
                )}
              </>
            ),
            [TabOption.Categories]: <StoreCategoriesModule />,
          }}
        />
      </BasePage>
    </>
  );
};

export default StoreDirectoryPage;
