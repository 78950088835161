import { useState } from 'react';
import { UseScrollBaseProps } from './useScrollBase.model';

interface UseScrollTopProps extends UseScrollBaseProps {
  scrollTop: boolean;
}

export const useScrollTop = (): UseScrollTopProps => {
  const [scrollTop, setScrollTop] = useState(true);

  const onScroll = (event): void => {
    setScrollTop(event.target.scrollTop === 0);
  };
  return { scrollTop, scrollProps: { onScroll } };
};
