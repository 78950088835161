/** @jsxImportSource @emotion/react */
import { Buttons, List } from '@zip/business-components';
import { BulletEmphasised } from '@zip/react-icons/fearless';
import { BasePage } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { css, isSandbox } from 'global';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { PageProps } from 'types';
import { logError, logEvent } from 'utils';

import * as styles from './PosMaterialsPage.styles';

const PosMaterialsPage: FC<PageProps> = () => {
  const { get } = useFetch();
  const Snackbar = useSnackbar();
  const { merchantIdentity } = useMerchantData();
  const [posMaterialUrl, setPosMaterialUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = (): void => {
    if (isLoading || !posMaterialUrl) {
      return;
    }

    logEvent(PageAction.openPosVendorUrl);
    window.open(posMaterialUrl);
  };

  useEffect(() => {
    const abortSignal = new AbortController();
    if (merchantIdentity && !isSandbox) {
      setIsLoading(true);
      logEvent(PageAction.fetchPosVendorUrl, merchantIdentity);
      get('/marketing/pos-materials-url', abortSignal.signal)
        .then(async (res) => {
          setPosMaterialUrl(
            res.status === 204 ? null : (await res?.json())?.url
          );
        })
        .catch((err) => {
          if (!abortSignal.signal.aborted) {
            logError(PageAction.fetchPosVendorUrl, err);
            Snackbar.error(
              'Something went wrong generating your unique link, please try again'
            );
            setPosMaterialUrl(null);
          }
        })
        .finally(() => setIsLoading(false));
    }

    return (): void => abortSignal.abort();
  }, [merchantIdentity]);

  return (
    <BasePage
      title="Instore POS materials"
      sandboxAlert="Zip POS can be ordered once your application is approved"
    >
      <p css={[css.noTopMrgn]}>
        As a Zip partner, we want to support your business online and instore.
        <br />
        By displaying Zip POS materials in your physical locations, customers
        know they can use Zip at checkout.
      </p>
      <p>
        Just like adding our online marketing assets to your site, having
        visibility instore drives:
        <List
          items={[
            {
              primary: 'higher conversions',
              icon: BulletEmphasised,
            },
            {
              primary: 'higher average basket sizes',
              icon: BulletEmphasised,
            },
            {
              primary: 'increased repeat transactions',
              icon: BulletEmphasised,
            },
          ]}
          css={styles.list}
        />
      </p>
      <Buttons.Primary
        loading={isLoading}
        disabled={!posMaterialUrl || isSandbox}
        onClick={handleClick}
      >
        Order now
      </Buttons.Primary>
    </BasePage>
  );
};

export default PosMaterialsPage;
