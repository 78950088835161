export enum State {
  ACT = 'ACT',
  QLD = 'QLD',
  NSW = 'NSW',
  NT = 'NT',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}
