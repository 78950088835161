/** @jsxImportSource @emotion/react */
import { Alert, Buttons, Dialogs } from '@zip/business-components';
import { useSnackbar } from 'contexts';
import { HttpStatusCode, OrderStatus, PageAction } from 'enums';
import { useFetch } from 'hooks';
import { FC, useState } from 'react';
import { logError, logEvent } from 'utils';
import { RemindCustomerModuleProps } from './RemindCustomerModuleProps';

const handledCodes = [
  HttpStatusCode.Ok,
  HttpStatusCode.MultiStatus,
  HttpStatusCode.TooManyRequests,
];

const RemindCustomerModule: FC<RemindCustomerModuleProps> = ({
  open,
  toggleOpen,
  orderToAction,
  onSuccess,
}) => {
  const { post } = useFetch();
  const { success, error } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = (): void => toggleOpen(false);

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting) {
      return;
    }

    logEvent(PageAction.remindCustomer, { orderToAction });
    setIsSubmitting(true);
    try {
      const res = await post('/order/prompt-customer', {
        operationRequestId: orderToAction?.operationRequestId,
      });
      const { status } = res;

      if (!handledCodes.includes(status)) {
        throw new Error();
      }

      if (status === HttpStatusCode.TooManyRequests) {
        error(
          'This customer has already been reminded today. Please try again tomorrow.'
        );
        return;
      }

      const messageTypes: string[] = [];
      const response: { emailSuccess: boolean; smsSuccess: boolean } =
        await res.json();
      response.emailSuccess && messageTypes.push('email');
      response.smsSuccess && messageTypes.push('SMS');

      success(`We have reminded the customer by ${messageTypes.join(' and ')}`);
      handleClose();
      onSuccess();
    } catch (err) {
      logError(PageAction.remindCustomer, err, { orderToAction });
      error('Something went wrong, please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialogs.Basic
      id="promptDialog"
      open={open}
      title="Remind customer"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            id="submitButton"
            onClick={handleSubmit}
            loading={isSubmitting}
          >
            Confirm
          </Buttons.Primary>
          <Buttons.Text id="cancelButton" onClick={handleClose}>
            Cancel
          </Buttons.Text>
        </>
      }
    >
      <p className="body2">
        {orderToAction?.status === OrderStatus.UnderReview
          ? 'The customer has been prompted to provide further details regarding their application.'
          : "The customer has been approved, but hasn't accepted their contract yet."}
      </p>

      <p className="body2">
        Would you like to send a reminder for order{' '}
        <strong>{orderToAction?.orderReference}</strong>?
      </p>
      <Alert variant="info">This may only be sent once a day</Alert>
    </Dialogs.Basic>
  );
};

export default RemindCustomerModule;
