import { useAuth0 } from 'contexts';
import {
  ContactTypeEnum,
  FileTypes,
  IdDocumentType,
  LocalStorageKey,
} from 'enums';
import { useFetch } from 'hooks';
import jwtDecode from 'jwt-decode';
import {
  Contact,
  DirectorDetails,
  DirectorDetailsResponse,
  DirectorDocument,
  SettlementDetails,
  SettlementDocument,
} from 'types';

interface MerchantProfileFunctions {
  resetGetRequests: () => void;
  submitDirectorDetails: (directorDetails: DirectorDetails) => Promise<any>;
  getDirectorDetails: () => Promise<DirectorDetails>;
  submitDirectorDocument: (directorDocument: DirectorDocument) => Promise<void>;
  submitPrimaryContact: (
    primaryContact: Contact,
    isDirector?: boolean
  ) => Promise<any>;
  getPrimaryContact: () => Promise<{
    directorDetails: DirectorDetails;
    primaryContactDetails: Contact;
  }>;
  submitSettlementDetails: (
    settlementDetails: SettlementDetails
  ) => Promise<any>;
  getSettlementDetails: () => Promise<SettlementDetails>;
  submitSettlementDocument: (
    settlementDocument: SettlementDocument
  ) => Promise<any>;
  getPublicId: () => Promise<string>;
  getPublicIdClaim: () => Promise<string>;
  getContactFromClaims: () => Contact;
}

export const useMerchantProfile = (): MerchantProfileFunctions => {
  const { get, post, put } = useFetch();
  const { accessToken } = useAuth0();
  let controller = new AbortController();

  let publicId: string = null;

  function resetGetRequests(): void {
    controller.abort();
    controller = new AbortController();
  }

  async function submitDirectorDetails(
    directorDetails: DirectorDetails
  ): Promise<any> {
    const documentType = Object.keys(IdDocumentType).find(
      (key) => IdDocumentType[key] === directorDetails.documentType
    );

    const payload = {
      ...directorDetails,
      documentType,
    };

    const idMatrixCount = sessionStorage.getItem(LocalStorageKey.IdMatrixCount);
    const idCheck =
      idMatrixCount === null ||
      (idMatrixCount !== null && parseInt(idMatrixCount, 10) < 5);

    return put(
      `/${await getPublicId()}/director`,
      { ...payload, idCheck },
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    ).then((res) => {
      if (!res.ok) {
        throw new Error();
      }
      return res.json();
    });
  }

  async function getDirectorDetails(): Promise<DirectorDetails> {
    return get(
      `/${await getPublicId()}/director`,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    ).then(async (res) => {
      if (!res.ok) {
        throw new Error();
      }
      const response = (await res.json()) as DirectorDetailsResponse;

      const doctype = Object.keys(IdDocumentType).find(
        (key) =>
          Object.keys(IdDocumentType).indexOf(key) ===
          Number(response.documentType)
      );

      const directorDetails = {
        ...response,
        documentType: IdDocumentType[doctype],
        licenseAddress:
          response.streetNumber &&
          `${response.unitNumber ? `${response.unitNumber}/` : ''}${
            response.streetNumber
          } ${response.streetName}, ${response.suburb} ${response.state} ${
            response.postcode
          }, Australia`,
        country: 'Australia',
      };

      return directorDetails;
    });
  }

  async function submitDirectorDocument(
    directorDocument: DirectorDocument
  ): Promise<void> {
    const documentType = Object.keys(IdDocumentType).find(
      (key) => IdDocumentType[key] === directorDocument.documentType
    );

    let directorDocumentId;

    if (!directorDocument.id) {
      const upload = await getUploadUrl(
        FileTypes.identity,
        directorDocument.document.name
      );
      await uploadFile(directorDocument.document, upload.url);
      const notify = await notifyDocumentUploaded(
        FileTypes.identity,
        directorDocument.document.name,
        documentType
      );
      directorDocumentId = notify.documentId;
    }

    const payload = {
      ...directorDocument,
      id: directorDocument.id ?? directorDocumentId,
      documentType,
    };
    return put(
      `/${await getPublicId()}/director-documents`,
      payload,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    );
  }

  async function submitPrimaryContact(
    primaryContact: Contact,
    isDirector = false
  ): Promise<any> {
    const payload = {
      ...primaryContact,
      isPrimary: true,
      contactType: isDirector
        ? ContactTypeEnum.director
        : ContactTypeEnum.contact,
    };
    return put(
      `/${await getPublicId()}/contact-details`,
      payload,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    );
  }

  async function getPrimaryContact(): Promise<{
    directorDetails: DirectorDetails;
    primaryContactDetails: Contact;
  }> {
    return get(
      `/${await getPublicId()}/contact-details`,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    ).then((res) => {
      if (!res.ok) {
        throw new Error();
      }
      return res.json();
    });
  }

  async function submitSettlementDetails(
    settlementDetails: SettlementDetails
  ): Promise<any> {
    return put(
      `/${await getPublicId()}/settlement-details`,
      settlementDetails,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    );
  }

  async function getSettlementDetails(): Promise<SettlementDetails> {
    return get(
      `/${await getPublicId()}/settlement-details`,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    ).then((res) => {
      if (!res.ok) {
        throw new Error();
      }
      return res.json() as SettlementDetails;
    });
  }

  async function submitSettlementDocument(
    settlementDocument: SettlementDocument
  ): Promise<any> {
    const result = await getUploadUrl(
      FileTypes.bank,
      settlementDocument.bankStatement.name
    );

    await uploadFile(settlementDocument.bankStatement, result.url);
    await notifyDocumentUploaded(
      FileTypes.bank,
      settlementDocument.bankStatement.name,
      'BankStatement'
    );

    return put(
      `/${await getPublicId()}/settlement-document`,
      settlementDocument,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    );
  }

  async function getPublicId(): Promise<string> {
    if (!publicId) {
      publicId = await getPublicIdClaim();
    }

    return publicId;
  }

  async function getPublicIdClaim(): Promise<string> {
    const claims = await decodeToken(accessToken);
    if (!claims) {
      return null;
    }

    return claims[`${process.env.REACT_APP_AUTH0_AUDIENCE}publicId`];
  }

  function getContactFromClaims(): Contact {
    if (!accessToken) {
      return null;
    }
    const claims = jwtDecode(accessToken);

    const claimsContact: Contact = {
      fullName: `${
        claims[`${process.env.REACT_APP_AUTH0_AUDIENCE}firstName`]
      } ${claims[`${process.env.REACT_APP_AUTH0_AUDIENCE}lastName`]}`,
      contactNumber:
        claims[`${process.env.REACT_APP_AUTH0_AUDIENCE}phoneNumber`],
      contactEmail: claims[`${process.env.REACT_APP_AUTH0_AUDIENCE}email`],
    };

    return claimsContact;
  }

  async function decodeToken(jwt: string): Promise<unknown> {
    const decoded = jwtDecode(jwt);
    return decoded;
  }

  async function getUploadUrl(
    fileType: FileTypes,
    filename: string
  ): Promise<any> {
    return get(
      `/${await getPublicId()}/${fileType}-documents/${filename}/upload-url`,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    ).then((res) => {
      if (!res.ok) {
        throw new Error();
      }
      return res.json();
    });
  }

  async function uploadFile(file: File, url: string): Promise<any> {
    return fetch(url, {
      body: file,
      method: 'PUT',
      signal: controller.signal,
    }).catch((err) => {
      throw err;
    });
  }

  async function notifyDocumentUploaded(
    fileType: FileTypes,
    filename: string,
    documentType: string
  ): Promise<any> {
    const payload = {
      filename,
      documentType,
    };

    return post(
      `/${await getPublicId()}/${fileType}-documents/`,
      payload,
      controller.signal,
      process.env.REACT_APP_MERCHANT_PROFILE_API_URL
    ).then((res) => {
      if (!res.ok) {
        throw new Error();
      }
      return res.json();
    });
  }

  return {
    resetGetRequests,
    submitDirectorDetails,
    getDirectorDetails,
    submitDirectorDocument,
    submitPrimaryContact,
    getPrimaryContact,
    submitSettlementDetails,
    getSettlementDetails,
    submitSettlementDocument,
    getPublicId,
    getPublicIdClaim,
    getContactFromClaims,
  };
};

export default useMerchantProfile;
