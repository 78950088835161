/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs, TextFields } from '@zip/business-components';
import { useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { FC, useEffect, useState } from 'react';
import { logError, logEvent, useOrderFunctions } from 'utils';
import { UpdateReferenceDialogProps } from './UpdateReferenceDialogProps';

const UpdateReferenceDialog: FC<UpdateReferenceDialogProps> = ({
  open = true,
  toggleOpen,
  reference = '',
  operationRequestId,
  onSuccess,
  ...props
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();
  const [orderReference, setOrderReference] = useState<string>(reference);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = (): void => toggleOpen(false);

  async function handleSubmit(): Promise<void> {
    setIsLoading(true);
    try {
      await OrderFunctions.updateOrderReference(
        operationRequestId,
        orderReference
      );
      Snackbar.success('Order reference was updated successfully');
      if (onSuccess) {
        onSuccess();
      }
      handleClose();
    } catch (err) {
      logError(PageAction.updateOrderReference, err, {
        operationRequestId,
        orderReference,
      });
      Snackbar.error(
        'Something went wrong updating the order reference. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => setOrderReference(reference), [reference]);

  return (
    <Dialogs.Basic
      open={open}
      title="Update reference"
      onClose={handleClose}
      actions={
        <>
          <Buttons.Text id="cancelButton" onClick={handleClose}>
            Cancel
          </Buttons.Text>
          <Buttons.Primary
            id="updateButton"
            onClick={(): void => {
              logEvent(PageAction.updateOrderReference, {
                prevRef: reference,
                newRef: orderReference,
              });
              handleSubmit();
            }}
            loading={isLoading}
          >
            Update
          </Buttons.Primary>
        </>
      }
      {...props}
    >
      <TextFields.Outlined
        id="orderReference"
        type="text"
        value={orderReference}
        label="Reference"
        onChange={(e): void => setOrderReference(e?.target?.value)}
        fullWidth
      />
    </Dialogs.Basic>
  );
};

export default UpdateReferenceDialog;
