/** @jsxImportSource @emotion/react */
import * as Icons from '@zip/react-icons/fearless';
import { Tooltip } from 'components';
import { Constants } from 'global';
import { FC } from 'react';
import * as styles from './DemoBanner.styles';

const DemoBanner: FC = () => {
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
  const branchName = process.env.REACT_APP_BRANCH_NAME;

  return (
    <div css={styles.container}>
      {isDev ? (
        branchName
      ) : (
        <>
          This is a demo account{' '}
          <Tooltip
            content={
              <>
                You are logged into a demo account, once your application is
                approved you will be able to log into your{' '}
                <a
                  href={Constants.dashboard.production}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  live dashboard
                </a>
                .
              </>
            }
            placement="bottom"
            maxWidth={220}
            arrow
            interactive
            dense
          >
            <Icons.InformationCircle css={styles.icon} />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default DemoBanner;
