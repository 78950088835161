import { css } from '@emotion/react';
import { lighten } from '@material-ui/core';
import { theme } from 'global';

const backgroundColor = (selected, shouldHighlight) => {
  if (selected) {
    return lighten(theme.colors.primary.medium, 0.92);
  }
  if (shouldHighlight) {
    // 1A suffix is 10% opacity
    return `${theme.colors.primary.lightest}1A`;
  }

  return 'inherit';
};

export const orderRow = (selected, shouldHighlight) => css`
  padding: 16px 0;
  background-color: ${backgroundColor(selected, shouldHighlight)};
  cursor: pointer;

  :hover {
    background-color: ${backgroundColor(selected, shouldHighlight)};
  }

  ${shouldHighlight &&
  `> td:first-of-type {
    ::before {
      content: ' ';
      float: left;
      margin-top: 0.35rem;
      margin-left: -20px;
      border-radius: ${theme.radii.lg};
      width: 8px;
      height: 8px;
      background-color: ${theme.colors.primary.lighter};
      
      @media screen and (max-width: 1395px) {
        margin-left: -12px;
      }
    }
  }`}
`;

export const statusInfoIcon = css`
  width: 15px;
  height: 15px;
  vertical-align: sub;
`;
