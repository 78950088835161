import { SerializedStyles, css } from '@emotion/react';
import { theme } from 'global';

export const productsContainer = css`
  margin: 24px 0 0;
`;

export const mmpTable = css`
  width: 100%;
  margin: 16px 0 24px;
  border-top: 1px solid ${theme.colors.neutral[100]};
  border-spacing: 0px 8px;

  > thead > tr > th {
    text-align: left;
    padding: 8px 4px;

    :first-of-type {
      padding-left: 16px;
    }
    :last-of-type {
      text-align: right;
      padding-right: 16px;
    }
  }

  > tbody > tr {
    background-color: ${theme.colors.neutral[0]};

    > td {
      padding: 4px;
    }

    > td:first-of-type {
      padding-left: 16px;
      width: 60%;
    }

    > td:last-of-type {
      text-align: right;
      padding-right: 16px;
      width: 40%;
    }
  }
`;

export const marginTop = (px: number): SerializedStyles => css`
  margin-top: ${px}px!important;
`;

export const marginBottom = (px: number): SerializedStyles => css`
  margin-bottom: ${px}px!important;
`;
