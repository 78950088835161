import { css } from '@emotion/react';

import { theme } from 'global';

export const contentContainer = css`
  max-width: 370px;
  margin: 0 auto;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    overflow-x: hidden;
  }
`;

export const storeTileContainer = css`
  border: 1px solid ${theme.colors.neutral[200]};
  background: ${theme.colors.neutral[50]};
  height: 256px;
  width: 320px;
  overflow: hidden;
  margin: 0 auto 8px;

  > div {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 50%;
      height: 50%;
      z-index: 1;
    }
  }
`;

export const backgroundPlaceholder = css`
  width: 100%;
  max-width: 304px;
  aspect-ratio: 5 / 4;
  border: 1px solid ${theme.colors.neutral[100]};
  background-color: ${theme.colors.white};
  margin: 0 auto 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 74px;
    height: 54px;
    fill: ${theme.colors.neutral[100]};
  }
`;

export const uploadButtonContainer = css`
  text-align: center;
`;

export const cropLogoContainer = css`
  position: relative;
`;

export const previewContainer = (backgroundImg) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 304px;
  aspect-ratio: 5 / 4;
  border: 1px solid ${theme.colors.neutral[100]};
  margin: 0 auto 16px;
  background-image: url(${backgroundImg});
  background-size: cover;
  overflow: hidden;
`;

export const storeTileActions = css`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom-left-radius: ${theme.radii.lg};
  border-bottom-right-radius: ${theme.radii.lg};
`;
