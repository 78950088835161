import { fearless } from '@zip/theme';

export const theme = {
  ...fearless,
  breaks: {
    xs: 600,
    sm: 768,
    md: 960,
    summary: 1237,
    lg: 1440,
    xl: 1920,
  },
  colors: {
    ...fearless.colors,
    boxshadow: 'rgba(12, 0, 51, 0.1)',
    danger: '#B00020',
    info: '#0D5ED9',
    success: '#1F7A39',
    warning: '#995700',
    consumer: fearless.colors.primary,
    electric: '#DCF995',
  },
  maxTextWidth: '640px',
};

export default theme;
