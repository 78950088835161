import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  padding: 32px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 121px;
  color: ${theme.colors.primary.dark};

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 24px 16px;
    min-height: 99px;
  }

  & > p {
    text-align: center;

    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: ${theme.colors.neutral[500]};

    margin: 16px 0 2px;
  }
`;

export const title = css`
  user-select: none;
`;
