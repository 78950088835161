/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import { Buttons, Dialogs, Spinner, Widgets } from '@zip/business-components';
import { FC, useCallback, useEffect, useState } from 'react';
import * as styles from './EditStoreCategoriesDialog.styles';
import { EditStoreCategoriesDialogProps } from './EditStoreCategoriesDialogProps';

/**
 *  Why the reduce and forEach nestings occur:
 *
 *  The model for MarketingCategory is:
 *     {
 *       id: number,
 *       name: string,
 *       parentId: number,
 *       isSelected: boolean,
 *       childCategories?: MarketingCategory[],
 *     }
 *  At the primaryCategory level there will be 2 layers to the childCategories.
 *  The reduce here allows us to flatten it into an object to for use with the
 *   checkboxes - this is because we can generate checkboxes dynamically but
 *   they then require the state value's model to be static.
 *  As such, we're required to flatten arrays into objects at each level, hence
 *   why there is a reduce within a reduce.
 *
 *  Unfortunately, this means that when we want to loop through to update or check
 *   the values dynamically we need to then use Object.keys() to generate us an
 *   Array that we can loop through to update/check. Again, as we have 2 levels
 *   of children, this means we are subject to having an Object.keys().forEach
 *   within another Object.keys().forEach. That being said, either way we'd need
 *   to nest a .forEach() regardless as we have an array within an array.
 */

const EditStoreCategoriesDialog: FC<EditStoreCategoriesDialogProps> = ({
  primaryCategory,
  onSave,
  ...props
}) => {
  const [values, setValues] = useState(
    primaryCategory?.childCategories?.reduce((categories, category) => {
      categories[category.name] = category?.childCategories?.reduce(
        (subCategories, subCategory) => {
          subCategories[subCategory.name] = subCategory.isSelected;
          return subCategories;
        },
        {}
      );
      return categories;
    }, {})
  );

  const handleSaveClick = (): void => {
    const itemsToReturn: number[] = [];
    Object.keys(values)?.forEach((category) => {
      Object.keys(values?.[category])?.forEach(
        (subCategory) =>
          values?.[category]?.[subCategory] &&
          itemsToReturn.push(
            primaryCategory?.childCategories
              ?.find((categoryToSearch) => categoryToSearch.name === category)
              ?.childCategories?.find(
                (subCategoryToSearch) =>
                  subCategoryToSearch.name === subCategory
              )?.id
          )
      );
    });
    onSave(itemsToReturn);
  };

  const handleClearAllClick = (): void => {
    const valuesToSet = { ...values };
    Object.keys(valuesToSet).forEach((category) => {
      valuesToSet[category] &&
        Object.keys(valuesToSet[category]).forEach((subCategory) => {
          valuesToSet[category][subCategory] = false;
        });
    });
    setValues(valuesToSet);
  };

  const handleCheckParentValue = (category: string): boolean =>
    values &&
    values[category] &&
    !Object.keys(values[category])?.some(
      (subCategory) => !values[category][subCategory]
    );

  const handleParentChange = (category: string, isChecked: boolean): void => {
    const valuesToSet = { ...values };
    values &&
      values[category] &&
      Object.keys(values[category]).forEach((subCategory) => {
        valuesToSet[category][subCategory] = isChecked;
      });
    setValues(valuesToSet);
  };

  const handleCheckChildValue = (
    category: string,
    subCategory: string
  ): boolean => values?.[category]?.[subCategory];

  const handleChildChange = useCallback(
    (category: string, subCategory: string, isChecked: boolean): void => {
      setValues({
        ...values,
        [category]: {
          ...values[category],
          [subCategory]: isChecked,
        },
      });
    },
    [values]
  );

  const getSelectedChildrenCount = (): number => {
    if (!values) {
      return 0;
    }

    const categoriesToReturn: string[] = [];

    Object.keys(values)?.forEach((category) => {
      values &&
        values[category] &&
        Object.keys(values[category])?.forEach(
          (subCategory) =>
            values[category][subCategory] &&
            categoriesToReturn.push(subCategory)
        );
    });

    return categoriesToReturn?.length;
  };

  useEffect(() => {
    setValues(
      primaryCategory?.childCategories?.reduce((categories, category) => {
        categories[category.name] = category?.childCategories?.reduce(
          (subCategories, subCategory) => {
            subCategories[subCategory.name] = subCategory.isSelected;
            return subCategories;
          },
          {}
        );
        return categories;
      }, {})
    );
  }, [primaryCategory?.childCategories]);

  return (
    <Dialogs.Basic
      title={primaryCategory?.name}
      subtitle="Select sub-categories"
      mobileFullscreen
      spaceActions
      actions={
        <>
          <Buttons.Primary onClick={handleSaveClick}>
            Save ({getSelectedChildrenCount()})
          </Buttons.Primary>
          <Buttons.Text onClick={handleClearAllClick}>Clear all</Buttons.Text>
        </>
      }
      open={!!primaryCategory}
      {...props}
    >
      {!values && <Spinner data-testid="spinner" />}
      {values &&
        Object.keys(values)?.map((category) => (
          <div key={category} css={styles.categoryContainer}>
            <div css={styles.categoryHeading}>
              <Widgets.Checkbox
                label=""
                checked={handleCheckParentValue(category)}
                onChange={(e, checked): void =>
                  handleParentChange(category, checked)
                }
              />
              <span>
                <span className="heading2">{category}</span>{' '}
                <span className="body2">(select all)</span>
              </span>
            </div>
            <MuiGrid container spacing={1}>
              {values?.[category] &&
                Object.keys(values[category])?.map((subCategory) => (
                  <MuiGrid
                    item
                    xs={12}
                    sm={6}
                    key={subCategory}
                    css={styles.subCategory}
                  >
                    <Widgets.Checkbox
                      label=""
                      checked={handleCheckChildValue(category, subCategory)}
                      onChange={(e, checked): void =>
                        handleChildChange(category, subCategory, checked)
                      }
                    />
                    <span className="body2">{subCategory}</span>
                  </MuiGrid>
                ))}
            </MuiGrid>
          </div>
        ))}
    </Dialogs.Basic>
  );
};

export default EditStoreCategoriesDialog;
