import { css } from '@emotion/react';

import { theme, css as globalCss } from 'global';

export const drawer = css`
  width: 0px;
  background-color: ${theme.colors.white};
  flex-shrink: 0;
  align-items: space-between;
  z-index: 1301 !important;
  & > .MuiPaper-root {
    padding-top: unset;
    top: unset;
    overflow-x: hidden;
    width: 460px;
    height: 100vh;
    transition: width 250ms;
    box-shadow: unset;
    border-left: 1px solid ${theme.colors.neutral[200]};
  }

  @media screen and (max-width: ${theme.breaks.md}px) {
    width: 0;
    & > .MuiPaper-root {
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 1301;
    }
  }

  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
    mix-blend-mode: normal;
    backdrop-filter: blur(4px);
  }
`;

export const headerContainer = (categorySelected) => css`
  padding: 32px 32px 24px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 16px 16px 24px;
    display: ${categorySelected ? 'none' : 'block'};
  }
`;

export const mobileTitlebar = css`
  padding: 20px 16px;
  border-bottom: 1px solid ${theme.colors.neutral[200]};
  user-select: none;
  ${globalCss.flex()};

  & > .MuiGrid-root {
    height: 24px;
  }
`;

export const breadcrumbs = css`
  display: inline-table;
  flex-flow: row wrap;
  user-select: none;
`;

export const helpCentreCrumb = css`
  margin-right: 4px;
  font-weight: 400;
  text-align: left;
`;

export const helpSearch = css`
  margin: 12px 0 0 !important;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin: 0 !important;
  }
`;

export const setupGuide = css`
  border-bottom: 1px solid ${theme.colors.neutral[200]};

  > div {
    padding: 24px 16px;
    border: 1px solid ${theme.colors.neutral[200]};
    margin: 0px 24px 24px;
    border-radius: ${theme.radii.lg};
    cursor: pointer;

    > div {
      padding-left: 16px;
      > h1.heading3 {
        display: flex;
        margin: 0px 0px 8px;
        > svg {
          margin-left: 8px;
        }
      }
      > p.body2 {
        margin: 0px;
      }
    }

    &:hover {
      background-color: ${theme.colors.neutral[50]};
    }

    @media screen and (max-width: ${theme.breaks.xs}px) {
      margin: 0px 24px 16px;
    }
  }
`;

export const helpArticleList = (isScrolledFromTop) => css`
  overflow-y: auto;
  overflow-x: hidden;
  border-top: ${isScrolledFromTop
    ? `1px solid ${theme.colors.neutral[200]}`
    : 'unset'};
`;

export const helpCentreLink = css`
  padding: 24px 32px;
  text-align: center;
  user-select: none;
`;

export const loading = css`
  width: 100%;
  padding: 24px 32px;
`;
