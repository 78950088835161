/** @jsxImportSource @emotion/react */
import { FC } from 'react';

import * as styles from './PageActions.styles';

export const PageActions: FC = ({ children }) => (
  <section id="form-actions" css={styles.container}>
    {children}
  </section>
);
