/** @jsxImportSource @emotion/react */
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import { Buttons } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { useMerchantData } from 'contexts';
import { PageAction } from 'enums';
import { css, theme } from 'global';
import { FC } from 'react';
import { logEvent } from 'utils';

import * as styles from './DashboardPromo.styles';
import { DashboardPromoProps } from './DashboardPromoProps';

const defaults = {
  backgroundColour: theme.colors.white,
  imgBackgroundColour: 'transparent',
};

const DashboardPromo: FC<DashboardPromoProps> = ({
  promo,
  carouselItem = false,
}): JSX.Element => {
  const {
    merchantIdentity,
    currentUserId,
    merchantCreationTimeStamp,
    daysSinceMerchantCreation,
  } = useMerchantData();

  const handleClick = (): void => {
    let url = promo.actionLink;
    if (promo.addUserUtmTags) {
      url += url.includes('?') ? '&' : '?';
      url += `md-upid=${currentUserId}}&md-mid=${merchantIdentity.merchantId}`;
    }
    logEvent(PageAction.clickedContentfulAction, {
      promoId: promo.id,
      carouselItemNo: promo.carouselOrder,
      pageItemNo: promo.pageOrder,
      carouselItem,
      merchantCreationTimeStamp,
      daysSinceMerchantCreation,
    });
    window.open(url, promo.actionNewTab ? '_blank' : '_self').focus();
  };

  const getAction = (): JSX.Element => {
    switch (promo.actionLinkVariant) {
      case 'primary':
        return (
          <Buttons.Primary onClick={handleClick}>
            {promo.actionCopy}
          </Buttons.Primary>
        );
      case 'secondary':
        return (
          <Buttons.Secondary onClick={handleClick}>
            {promo.actionCopy}
          </Buttons.Secondary>
        );
      case 'text':
        return (
          <Buttons.Text onClick={handleClick} css={styles.textButtonCopy}>
            {promo.actionCopy}
            <Icons.ArrowRight css={css.icon(true, '20px', '20px')} />
          </Buttons.Text>
        );
    }
  };

  return (
    <div
      id={`offer-${promo.id}`}
      css={styles.container(
        promo.backgroundColour ?? defaults.backgroundColour,
        carouselItem
      )}
      onClick={handleClick}
    >
      <div css={styles.content}>
        <div>
          <p className="heading2" css={css.noTopMrgn}>
            {promo.title}
          </p>
          <p className="body2">{promo.content}</p>
        </div>
        {getAction()}
      </div>
      <div
        css={styles.imageContainer(
          promo.imgBackgroundColour ?? defaults.imgBackgroundColour,
          promo.imgAlign
        )}
      >
        <img
          src={String(promo.img.fields.file.url)}
          css={styles.image(promo.imgPadding)}
        />
      </div>
    </div>
  );
};

export const DashboardPromoSkeleton: FC = (): JSX.Element => (
  <div
    id={`offer-skeleton`}
    css={styles.container(defaults.backgroundColour, false)}
  >
    <div css={styles.content}>
      <p className="heading2" css={css.noTopMrgn}>
        <MuiSkeleton variant="text" />
      </p>
      <p className="body2">
        <MuiSkeleton variant="text" />
      </p>
    </div>
    <div css={styles.imageContainer(defaults.imgBackgroundColour)}>
      <MuiSkeleton
        variant="rect"
        style={{ height: '80%', margin: '0.75rem 1rem 0.75rem auto' }}
      />
    </div>
  </div>
);

export default DashboardPromo;
