export enum ProfileSettingFormType {
  NewBranchCompanyOwned = 'NewBranchCompanyOwned',

  NewBranchNonCompanyOwned = 'NewBranchNonCompanyOwned',

  UpdateMerchantDetails = 'UpdateMerchantDetails',

  UpdateBranchDetails = 'UpdateBranchDetails',

  ChangeMerchantOwnership = 'ChangeMerchantOwnership',

  ChangeBranchOwnership = 'ChangeBranchOwnership',
}
