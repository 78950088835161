/** @jsxImportSource @emotion/react */
import { Hidden as MuiHidden } from '@material-ui/core';
import {
  Buttons,
  Confirm,
  Dialogs,
  Labels,
  Loading,
} from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { useMerchantData, useSnackbar } from 'contexts';
import { PageAction, PermissionEnum } from 'enums';
import { css, theme } from 'global';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { IntegrationDetail, UpdateApiKeyCommand } from 'types';
import { logError } from 'utils';
import DenseButton from '../dense-button';
import * as styles from './IntegrationKey.styles';
import {
  BasicIntegrationKeyProps,
  BranchIntegrationKeyProps,
} from './IntegrationKeyProps';

export const Basic: FC<BasicIntegrationKeyProps> = ({ value, loading }) => {
  const [isKeyVisible, setIsKeyVisible] = useState<boolean>(false);
  const [isKeyCopied, setIsKeyCopied] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const hideKey = (): void => setIsKeyVisible(false);

  const showKey = (): void => setIsKeyVisible(true);

  useEffect(() => {
    let timer;
    if (isKeyCopied) {
      timer = setTimeout(() => setIsKeyCopied(false), 3000);
    }
    return (): void => clearTimeout(timer);
  }, [isKeyCopied]);

  return (
    <>
      <div css={styles.container}>
        <div css={styles.content}>
          <span css={styles.keyContainer}>
            <span css={styles.key} className="fs-mask">
              {loading && <Loading />}
              {!loading && isKeyVisible ? value : '•'.repeat(value?.length)}
            </span>
          </span>
          <div css={styles.actions}>
            {isKeyVisible ? (
              <>
                <MuiHidden xsDown>
                  <Buttons.Text css={styles.actionIcon()} onClick={hideKey}>
                    <Icons.EyeStrike
                      width="22"
                      height="22"
                      fill={theme.colors.primary.medium}
                    />
                    <span css={styles.iconLabel}>Hide</span>
                  </Buttons.Text>
                </MuiHidden>
                <MuiHidden smUp>
                  <DenseButton
                    ButtonIcon={Icons.EyeStrike}
                    onClick={hideKey}
                    minWidth="120px"
                  >
                    Hide
                  </DenseButton>
                </MuiHidden>
              </>
            ) : (
              <>
                <MuiHidden xsDown>
                  <Buttons.Text css={styles.actionIcon()} onClick={showKey}>
                    <Icons.Eye
                      width="22"
                      height="22"
                      fill={theme.colors.primary.medium}
                    />
                    <span css={styles.iconLabel}>Show</span>
                  </Buttons.Text>
                </MuiHidden>
                <MuiHidden smUp>
                  <DenseButton
                    ButtonIcon={Icons.Eye}
                    onClick={(): void => setIsDialogOpen(true)}
                    minWidth="120px"
                  >
                    Show
                  </DenseButton>
                </MuiHidden>
              </>
            )}
            <MuiHidden xsDown>
              <Buttons.Text
                css={styles.actionIcon(isKeyCopied)}
                onClick={(): void => {
                  if (!isKeyCopied) {
                    navigator.clipboard.writeText(value);
                    setIsKeyCopied(true);
                  }
                }}
              >
                {(isKeyCopied && (
                  <>
                    <Icons.Check
                      width="18"
                      height="18"
                      fill={theme.colors.neutral[500]}
                    />
                    <span css={styles.iconLabel}>Copied</span>
                  </>
                )) || (
                  <>
                    <Icons.Copy
                      width="18"
                      height="18"
                      fill={theme.colors.primary.medium}
                    />
                    <span css={styles.iconLabel}>Copy</span>
                  </>
                )}
              </Buttons.Text>
            </MuiHidden>
            <MuiHidden smUp>
              <DenseButton
                ButtonIcon={isKeyCopied ? Icons.Check : Icons.Copy}
                onClick={(): void => {
                  if (!isKeyCopied) {
                    navigator.clipboard.writeText(value);
                    setIsKeyCopied(true);
                  }
                }}
                minWidth="120px"
                disabled={isKeyCopied}
              >
                {isKeyCopied ? 'Copied' : 'Copy'}
              </DenseButton>
            </MuiHidden>
          </div>
        </div>
      </div>

      <Dialogs.Basic
        open={isDialogOpen}
        title="Key"
        onClose={(): void => setIsDialogOpen(false)}
        actions={
          <>
            <Buttons.Primary
              onClick={(): void => {
                navigator.clipboard.writeText(value);
                setIsKeyCopied(true);
                setIsDialogOpen(false);
              }}
              fullWidth
            >
              Copy
            </Buttons.Primary>
          </>
        }
      >
        <h3
          style={{
            maxWidth: 320,
            overflowWrap: 'break-word',
          }}
          className="fs-mask"
        >
          {value}
        </h3>
      </Dialogs.Basic>
    </>
  );
};

export const Branch: FC<BranchIntegrationKeyProps> = ({
  branch,
  loading,
  onUpdate = undefined,
  ...props
}) => {
  const {
    branchName = 'Web store',
    locationId,
    isActive,
    integrationDetails,
  } = branch;
  const hasIntegrationDetails = integrationDetails?.length > 0;
  return (
    <>
      <div css={styles.container} {...props}>
        <div css={styles.labelText(hasIntegrationDetails)}>
          {!loading && (
            <span>
              <strong>{branchName}</strong>
              {locationId && (
                <>
                  <br />
                  Location ID: {locationId}
                </>
              )}
            </span>
          )}
          {isActive != null && (
            <span>
              Branch status:{' '}
              <Labels.Basic
                variant={isActive ? 'success' : 'error'}
                label={isActive ? 'Active' : 'Disabled'}
              />
            </span>
          )}
        </div>
        {hasIntegrationDetails &&
          integrationDetails?.map((key) => (
            <Key integrationDetail={key} onUpdate={onUpdate} />
          ))}
        {loading && <Loading />}
      </div>
    </>
  );
};

interface KeyProps {
  integrationDetail: IntegrationDetail;
  isDisableable?: boolean;
  onUpdate?: () => void;
}

const Key: FC<KeyProps> = ({
  integrationDetail,
  isDisableable = true,
  onUpdate = undefined,
}) => {
  const { apiKey, isActive, name } = integrationDetail;
  const keyName = (name?.toLowerCase() !== 'web store' && name) ?? null;
  const { checkPermission } = useMerchantData();
  const { put } = useFetch();
  const { error, success } = useSnackbar();

  const [isKeyVisible, setIsKeyVisible] = useState<boolean>(false);
  const [isKeyCopied, setIsKeyCopied] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isUpdatingApiKey, setIsUpdatingApiKey] = useState<boolean>(false);

  const hideKey = (): void => setIsKeyVisible(false);

  const showKey = (): void => setIsKeyVisible(true);

  const handleUpdateClick = (): void => {
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirm = (): void => {
    setIsUpdatingApiKey(true);
    const payload: UpdateApiKeyCommand = {
      id: integrationDetail.id,
      active: !integrationDetail.isActive,
      apiKeyName: integrationDetail.name,
      deviceRefCode: integrationDetail.deviceRefCode,
    };

    put('/integration/api-keys', payload)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        setIsConfirmationDialogOpen(false);
        success('API key successfully updated');
        if (typeof onUpdate != 'undefined') {
          onUpdate();
        }
      })
      .catch((err) => {
        error('Something went wrong');
        logError(PageAction.createApiKey, err);
      })
      .finally(() => {
        setIsUpdatingApiKey(false);
      });
  };

  useEffect(() => {
    let timer;
    if (isKeyCopied) {
      timer = setTimeout(() => setIsKeyCopied(false), 3000);
    }
    return (): void => clearTimeout(timer);
  }, [isKeyCopied]);

  return (
    <>
      <div css={styles.content}>
        <span css={styles.keyContainer}>
          {keyName && (
            <p className="body3" css={css.noTopMrgn}>
              {keyName}
            </p>
          )}
          <span css={styles.key} className="fs-mask">
            {isKeyVisible ? apiKey : '•'.repeat(apiKey?.length)}
          </span>
        </span>
        <span css={styles.keyActionsContainer}>
          <span css={styles.keyManagement}>
            <Labels.Basic
              variant={isActive ? 'success' : 'error'}
              label={isActive ? 'Active' : 'Disabled'}
              css={styles.keyStatus}
            />
            {isDisableable &&
              checkPermission(PermissionEnum.ApiIntegrationUpdate) && (
                <MuiHidden smUp>
                  <DenseButton
                    ButtonIcon={isActive ? Icons.Close : Icons.Check}
                    onClick={handleUpdateClick}
                    minWidth="120px"
                    css={{ marginLeft: '16px !important' }}
                  >
                    {isActive ? 'Disable' : 'Enable'}
                  </DenseButton>
                </MuiHidden>
              )}
          </span>
          <div css={styles.actions}>
            {isKeyVisible ? (
              <>
                <MuiHidden xsDown>
                  <Buttons.Text css={styles.actionIcon()} onClick={hideKey}>
                    <Icons.EyeStrike
                      width="22"
                      height="22"
                      fill={theme.colors.primary.medium}
                    />
                    <span css={styles.iconLabel}>Hide</span>
                  </Buttons.Text>
                </MuiHidden>
                <MuiHidden smUp>
                  <DenseButton
                    ButtonIcon={Icons.EyeStrike}
                    onClick={hideKey}
                    minWidth="120px"
                  >
                    Hide
                  </DenseButton>
                </MuiHidden>
              </>
            ) : (
              <>
                <MuiHidden xsDown>
                  <Buttons.Text css={styles.actionIcon()} onClick={showKey}>
                    <Icons.Eye
                      width="22"
                      height="22"
                      fill={theme.colors.primary.medium}
                    />
                    <span css={styles.iconLabel}>Show</span>
                  </Buttons.Text>
                </MuiHidden>
                <MuiHidden smUp>
                  <DenseButton
                    ButtonIcon={Icons.Eye}
                    onClick={(): void => setIsDialogOpen(true)}
                    minWidth="120px"
                  >
                    Show
                  </DenseButton>
                </MuiHidden>
              </>
            )}
            <MuiHidden xsDown>
              <Buttons.Text
                css={styles.actionIcon(isKeyCopied)}
                onClick={(): void => {
                  if (!isKeyCopied) {
                    navigator.clipboard.writeText(apiKey);
                    setIsKeyCopied(true);
                  }
                }}
              >
                {(isKeyCopied && (
                  <>
                    <Icons.Check
                      width="18"
                      height="18"
                      fill={theme.colors.neutral[500]}
                    />
                    <span css={styles.iconLabel}>Copied</span>
                  </>
                )) || (
                  <>
                    <Icons.Copy
                      width="18"
                      height="18"
                      fill={theme.colors.primary.medium}
                    />
                    <span css={styles.iconLabel}>Copy</span>
                  </>
                )}
              </Buttons.Text>
            </MuiHidden>
            <MuiHidden smUp>
              <DenseButton
                ButtonIcon={isKeyCopied ? Icons.Check : Icons.Copy}
                onClick={(): void => {
                  if (!isKeyCopied) {
                    navigator.clipboard.writeText(apiKey);
                    setIsKeyCopied(true);
                  }
                }}
                minWidth="120px"
                disabled={isKeyCopied}
              >
                {isKeyCopied ? 'Copied' : 'Copy'}
              </DenseButton>
            </MuiHidden>

            {isDisableable &&
              checkPermission(PermissionEnum.ApiIntegrationUpdate) && (
                <MuiHidden xsDown>
                  <Buttons.Text
                    css={styles.actionIcon()}
                    onClick={handleUpdateClick}
                  >
                    {isActive ? (
                      <>
                        <Icons.Close
                          width="22"
                          height="22"
                          fill={theme.colors.primary.medium}
                        />
                        <span css={styles.iconLabel}>Disable</span>
                      </>
                    ) : (
                      <>
                        <Icons.Check
                          width="22"
                          height="22"
                          fill={theme.colors.primary.medium}
                        />
                        <span css={styles.iconLabel}>Enable</span>
                      </>
                    )}
                  </Buttons.Text>
                </MuiHidden>
              )}
          </div>
        </span>
      </div>

      <Dialogs.Basic
        open={isDialogOpen}
        title="Key"
        onClose={(): void => setIsDialogOpen(false)}
        actions={
          <>
            <Buttons.Primary
              onClick={(): void => {
                navigator.clipboard.writeText(apiKey);
                setIsKeyCopied(true);
                setIsDialogOpen(false);
              }}
              fullWidth
            >
              Copy
            </Buttons.Primary>
          </>
        }
      >
        <h3
          style={{
            maxWidth: 320,
            overflowWrap: 'break-word',
          }}
          className="fs-mask"
        >
          {apiKey}
        </h3>
      </Dialogs.Basic>

      <Confirm
        open={isConfirmationDialogOpen}
        title="Are you sure?"
        onClose={(): void => setIsConfirmationDialogOpen(false)}
        actions={
          <Buttons.Primary
            id="confirmButton"
            loading={isUpdatingApiKey}
            onClick={handleConfirm}
          >
            {isActive ? 'Disable' : 'Enable'} API key
          </Buttons.Primary>
        }
      />
    </>
  );
};
