import { css } from '@emotion/react';

import { theme } from 'global';

export const headerDropdowns = css`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${theme.breaks.sm}px) {
    display: none;
  }
`;

export const merchantDropdown = css`
  margin-right: 8px !important;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin-right: 0 !important;
  }
`;
