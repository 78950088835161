import { SerializedStyles, css } from '@emotion/react';
import { theme } from 'global';

export const productsContainer = css`
  margin: 24px 0 0;
`;

export const logoContainer = css`
  display: flex;
  align-items: center;
  height: 24px;
`;

export const ifpTable = (
  merchantHasPromotion: boolean
): SerializedStyles => css`
  width: 100%;
  margin: 16px 0 20px;
  border-top: 1px solid ${theme.colors.neutral[200]};
  border-spacing: 0px 4px;
  table-layout: fixed;

  > * #promotion {
    width: ${merchantHasPromotion ? 87 : 0}px;
    padding: 4px;
  }

  > * #max {
    width: 120px;
  }

  > * #months {
    width: 30%;
    text-align: left;
  }

  > thead > tr > th {
    text-align: right;
    padding: 4px;
  }

  > tbody > tr.body2 {
    background-color: ${theme.colors.neutral[0]};

    > td {
      padding: 6px 4px;
      min-height: 32px;
      text-align: right;
    }

    > td#months {
      padding: 6px 6px 6px 14px;
    }
  }
`;

export const promotionLabel = css`
  padding: 4px 16px;
  user-select: none;
`;
