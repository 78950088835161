/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import { Buttons, TextFields } from '@zip/business-components';
import { Divider } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import { PageAction, ProfileSettingFormType } from 'enums';
import { useFormik } from 'formik';
import { useFetch } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { dateToIso, logEvent, useErrorHandler } from 'utils';
import { filterBranch, getBranchNamebyId } from 'utils/filters';
import { isFormikValid, validateEmail } from 'utils/validators';

import * as styles from './UpdateBranchDetailsSingleBranch.styles';

const BranchDetails: FC = () => {
  const { branches, getMerchantAbn, isAdmin } = useMerchantData();
  const { handleError } = useErrorHandler();
  const [isSendingOwnershipForm, setIsSendingOwnershipForm] =
    useState<boolean>(false);
  const [hasSentOwnershipForm, setHasSentOwnershipForm] =
    useState<boolean>(false);
  const [
    isLoadingUpdateBranchDetailsForm,
    setIsLoadingUpdateBranchDetailsForm,
  ] = useState<boolean>(false);
  const [
    isLoadingAddMerchantBranchDetailsForm,
    setIsLoadingAddMerchantBranchDetailsForm,
  ] = useState<boolean>(false);
  const [
    isLoadingAddFranchiseBranchDetailsForm,
    setIsLoadingAddFranchiseBranchDetailsForm,
  ] = useState<boolean>(false);
  const [
    hasOpenedUpdateBranchDetailsForm,
    setHasOpenedUpdateBranchDetailsForm,
  ] = useState<boolean>(false);
  const [singleBranch, setSingleBranch] = useState<any>(
    filterBranch(branches)[0]
  );

  const { get, post } = useFetch();
  const { success, error } = useSnackbar();

  const getForm = async (
    url: string,
    pageAction: PageAction,
    logMetadata: any
  ): Promise<{ url: string }> => {
    let entityType: string;
    switch (pageAction) {
      case PageAction.openedUpdateBranchForm:
        entityType = 'Branch';
        break;
      case PageAction.openedNewBranchForm:
      case PageAction.openedNewBranchesForm:
      case PageAction.openedUpdateMerchantForm:
        entityType = 'Merchant';
    }
    return await get(url).then((res) => {
      let errorMessage: string;
      switch (res.status) {
        case 200:
          logEvent(pageAction, {
            url,
            ...logMetadata,
          });
          return res.json();
        case 409: // Missing ExternalId
          errorMessage = `${entityType} is mis-configured. Contact our support team to update`;
          break;
        case 422: // Disabled or VirtualBranch
          errorMessage = `Detail updates are not supported for this type of ${entityType.toLowerCase()}. Contact our support team to update`;
          break;
        default:
          errorMessage = 'Something went wrong when generating the form';
      }
      handleError(errorMessage, pageAction, {
        url,
        ...logMetadata,
      });
    });
  };

  const handleUpdateBranchDetails = async (): Promise<void> => {
    setIsLoadingUpdateBranchDetailsForm(true);
    const url = `/form?type=${ProfileSettingFormType.UpdateBranchDetails}&branchId=${singleBranch.id}`;
    const { url: formUrl } = await getForm(
      url,
      PageAction.openedUpdateBranchForm,
      { branchId: singleBranch.id, isFranchise: false }
    );

    setHasOpenedUpdateBranchDetailsForm(true);
    window.open(formUrl, '_blank');
    setIsLoadingUpdateBranchDetailsForm(false);
  };

  const handleAddMerchantBranchDetails = async (): Promise<void> => {
    setIsLoadingAddMerchantBranchDetailsForm(true);
    const url = `/form?type=${ProfileSettingFormType.NewBranchCompanyOwned}`;
    const { url: formUrl } = await getForm(
      url,
      PageAction.openedNewBranchForm,
      { isFranchise: false }
    );
    window.open(formUrl, '_blank');
    setIsLoadingAddMerchantBranchDetailsForm(false);
  };

  const handleAddFranchiseBranchDetails = async (): Promise<void> => {
    setIsLoadingAddFranchiseBranchDetailsForm(true);
    const url = `/form?type=${ProfileSettingFormType.NewBranchNonCompanyOwned}`;
    const { url: formUrl } = await getForm(
      url,
      PageAction.openedNewBranchForm,
      { isFranchise: true }
    );
    window.open(formUrl, '_blank');
    setIsLoadingAddFranchiseBranchDetailsForm(false);
  };

  const handleOwnershipForm = async (): Promise<void> => {
    if (!isFormikValid(changeOfOwnershipFormik)) {
      error('Email or date is invalid');
      return;
    }

    setIsSendingOwnershipForm(true);

    const payload = {
      settlementDate: dateToIso(changeOfOwnershipFormik.values.date),
      newOwnerEmail: changeOfOwnershipFormik.values.email,
      branchId: singleBranch.id,
    };

    await post('/form/send-change-ownership-form', payload).then((res) => {
      if (res.status === 200) {
        logEvent(PageAction.sendBranchOwnershipForm, {
          settlementDate: payload.settlementDate,
          branchId: payload.branchId,
          multipleBranches: false,
        });
        setHasSentOwnershipForm(true);
        success(
          'We have sent a form for the new owner to complete. Hang tight!'
        );
      } else {
        handleError(
          'Something went wrong sending the change of ownership form',
          PageAction.sendBranchOwnershipForm,
          { ...payload, multipleBranches: false }
        );
      }
    });

    setIsSendingOwnershipForm(false);
  };

  const changeOfOwnershipFormik = useFormik({
    initialValues: {
      email: '',
      date: new Date(),
    },
    onSubmit: () => handleOwnershipForm(),
    validate: (values) => {
      const errors: { branch?: string; email?: string; date?: string } = {};

      if (!values.email) {
        errors.email = 'Email is required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Email is not valid';
      }

      if (!values.date) {
        errors.date = 'Date is required';
      }

      return errors;
    },
  });

  useEffect(() => {
    setSingleBranch(filterBranch(branches)[0]);
  }, [branches]);

  return (
    <div>
      <h3>Branch details</h3>
      {hasOpenedUpdateBranchDetailsForm ? (
        <>
          <p>
            Once your form has been submitted, please allow 1-2 business days
            for this information to be verified by our team.
          </p>
          <p>Once the request is completed, we'll inform you via email.</p>
          <Buttons.Secondary css={{ marginBottom: 32 }} disabled>
            Updated
          </Buttons.Secondary>
        </>
      ) : (
        <>
          <p css={{ marginBottom: 24 }}>
            Update any recent changes to an individual branch to stay informed
            and get paid on time, including:
          </p>
          <ul css={{ marginBottom: 24 }}>
            <li>Branch ABN</li>
            <li>Branch name & contact details</li>
            <li>Business billing address</li>
            <li>Bank settlement details</li>
          </ul>
          <Buttons.Secondary
            css={{ marginBottom: 32 }}
            onClick={handleUpdateBranchDetails}
            loading={isLoadingUpdateBranchDetailsForm}
            disabled={hasOpenedUpdateBranchDetailsForm}
          >
            Update details
          </Buttons.Secondary>
        </>
      )}

      <Divider top={32} bottom={32} />

      {isAdmin ? (
        <>
          <h3>Add new branch</h3>
          <p css={{ marginBottom: 24 }}>
            Branches can be added to your account in two ways:
          </p>

          <MuiGrid container spacing={3}>
            <MuiGrid item xs={12} sm={6}>
              <div css={styles.statusCard}>
                <p>
                  New branch under the existing merchant ABN {getMerchantAbn()}
                </p>
                <Buttons.Secondary
                  onClick={handleAddMerchantBranchDetails}
                  loading={isLoadingAddMerchantBranchDetailsForm}
                  css={{ marginTop: '22px' }}
                >
                  Add merchant branch
                </Buttons.Secondary>
              </div>
            </MuiGrid>
            <MuiGrid item xs={12} sm={6}>
              <div css={styles.statusCard}>
                <p>
                  New franchise branch under different ownership details (with a
                  new ABN)
                </p>
                <Buttons.Secondary
                  onClick={handleAddFranchiseBranchDetails}
                  loading={isLoadingAddFranchiseBranchDetailsForm}
                >
                  Add franchise branch
                </Buttons.Secondary>
              </div>
            </MuiGrid>
          </MuiGrid>

          <p css={{ marginBottom: 24 }}>
            Please make sure to complete the correct form to ensure
            disbursements are sent to the relevant entity.
          </p>
        </>
      ) : (
        <>
          <h3>Add franchise branch</h3>
          <p css={{ marginBottom: 24 }}>
            If you have additional store locations and you would like to add
            under your current franchise agreement, you can create another
            branch by applying below:
          </p>
          <Buttons.Secondary
            css={{ marginBottom: 32 }}
            onClick={handleAddFranchiseBranchDetails}
            loading={isLoadingAddFranchiseBranchDetailsForm}
          >
            Add branch
          </Buttons.Secondary>
          <p>
            If you have a new business and it is registered under a different
            entry, you will need to apply for a separate merchant account.
          </p>
        </>
      )}

      <Divider top={32} bottom={32} />

      <h3>Change of ownership</h3>
      {hasSentOwnershipForm ? (
        <>
          <p>
            The form has been sent to{' '}
            <strong className="fs-mask">
              {changeOfOwnershipFormik.values.email}
            </strong>{' '}
            for confirmation of details and transfer of account permissions for
            the{' '}
            <strong>
              {getBranchNamebyId(parseInt(singleBranch.id), branches)}
            </strong>{' '}
            branch.
          </p>
          <p>
            No account details will be updated before the change of ownership
            date.
          </p>
        </>
      ) : (
        <>
          <p>
            To update branch ownership details, provide the new owner's email
            and date of ownership change. We will send the new owner a form to
            confirm their details and transfer account permissions.
          </p>
        </>
      )}

      <form onSubmit={changeOfOwnershipFormik.handleSubmit}>
        {!hasSentOwnershipForm && (
          <div css={styles.ownershipInputs}>
            <TextFields.Outlined
              name="email"
              label="New owner's email"
              className="fs-mask"
              fullWidth
              error={
                Boolean(changeOfOwnershipFormik.touched.email) &&
                !!changeOfOwnershipFormik.errors.email
              }
              helperText={
                Boolean(changeOfOwnershipFormik.touched.email) &&
                changeOfOwnershipFormik.errors.email
              }
              value={changeOfOwnershipFormik.values.email}
              onChange={changeOfOwnershipFormik.handleChange}
              onBlur={changeOfOwnershipFormik.handleBlur}
            />
            <TextFields.Date
              name="date"
              label="First date of new ownership"
              fullWidth
              value={changeOfOwnershipFormik.values.date}
              onChange={(value): void => {
                // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
                changeOfOwnershipFormik.setFieldValue(
                  'date',
                  value?.format('YYYY-MM-DDT00:00:00') ?? null
                );
              }}
              onBlur={changeOfOwnershipFormik.handleBlur}
            />
          </div>
        )}

        <div>
          <Buttons.Secondary
            type="submit"
            loading={isSendingOwnershipForm}
            disabled={
              !isFormikValid(changeOfOwnershipFormik) || hasSentOwnershipForm
            }
          >
            {hasSentOwnershipForm ? 'Sent' : 'Send form'}
          </Buttons.Secondary>
        </div>
      </form>
    </div>
  );
};

export default BranchDetails;
