/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { FC } from 'react';
import * as styles from './DirectDebitDialog.styles';

interface DirectDebitDialogProps {
  open?: boolean;
  toggleOpen: (isOpen: boolean) => void;
}

const DirectDebitDialog: FC<DirectDebitDialogProps> = ({
  open = false,
  toggleOpen,
}) => {
  const handleClose = (): void => toggleOpen(false);

  return (
    <Dialogs.Basic
      onClose={handleClose}
      open={open}
      title="Direct debit"
      actions={
        <Buttons.Primary onClick={handleClose} className="close">
          Close
        </Buttons.Primary>
      }
      id="directDebitAgreementDialog"
    >
      <div css={styles.sectionMrgnBtm}>
        <p css={styles.copy}>
          Direct Debit is an automated process that allows Zip to take payments
          from your nominated bank account. From time to time obligations may
          arise under the Merchant Services Agreement requiring payment to be
          made by you to us. By authorising this Direct Debit Request and
          signing this agreement, you give permission for us to debit funds from
          your nominated bank account for amounts owing pursuant to the Merchant
          Services Agreement.
        </p>
      </div>
      <div css={styles.sectionMrgnBtm}>
        <h2>Direct Debit Request</h2>
        <div>
          <p css={styles.copy}>
            Request and Authority to debit the account named to pay ZipMoney
            Payments Pty Ltd ABN 58 164 440 993 Australian Credit Licence Number
            441878 (&quot;Zip&quot;)
          </p>
        </div>

        <table css={styles.table}>
          <tbody>
            <tr css={styles.tr}>
              <th css={styles.label}>Request and Authority to Debit</th>
              <td css={styles.td}>
                <p css={styles.copy}>
                  You request and authorise Zip to arrange, through its own
                  financial institution, for any amount Zip may debit or charge
                  your nominated account (details provided by you).
                </p>
                <p css={styles.copy}>
                  This debit or charge will be made through the Bulk Electronic
                  Clearing System (BECS) from your account held at the financial
                  institution you have nominated above and will be subject to
                  the terms and conditions of the Direct Debit Request Service
                  Agreement.
                </p>
              </td>
            </tr>
            <tr css={styles.tr}>
              <th css={styles.label}>Acknowledgement</th>
              <td css={styles.td}>
                <p css={styles.copy}>
                  By authorising this Direct Debit Request you acknowledge
                  having read and understood the terms and conditions governing
                  the debit arrangements between you and Zip as set out in this
                  Request and in your Direct Debit Request Service Agreement.
                </p>
              </td>
            </tr>
            <tr css={styles.tr}>
              <th css={styles.label}>Payment Details</th>
              <td css={styles.td}>
                <p css={styles.copy}>
                  This authorisation is for all payments owing under the Zip
                  Merchant Services Agreement.
                </p>
              </td>
            </tr>
            <tr css={styles.tr}>
              <th css={styles.label}>Electronic Signature</th>
              <td css={styles.td}>
                <p css={styles.copy}>
                  You authorise and agree to the terms of this Direct Debit
                  Request and the Direct Debit Service Agreement.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div css={styles.sectionMrgnBtm}>
        <h2>Direct Debit Service Agreement</h2>
        <div css={styles.sectionMrgnBtm}>
          <p css={styles.copy}>
            This is your Direct Debit Service Agreement with{' '}
            <strong>ZipMoney Payments Pty Ltd</strong>, ABN 58 164 440 993
            Australian Credit Licence Number 441878. It explains what your
            obligations are when undertaking a Direct Debit arrangement with us.
            It also details what our obligations are to you as your Direct Debit
            provider. Please keep this agreement for future reference. It forms
            part of the terms and conditions of your Direct Debit Request (DDR)
            and should be read in conjunction with your DDR authorisation.
          </p>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>1. Definitions</div>
          <p css={styles.copy}>
            <span css={styles.label}>account</span> means the account held at
            your financial institution from which we are authorised to arrange
            for funds to be debited.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>agreement</span> means this Direct Debit
            Request Service Agreement between you and us.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>banking day</span> means a day other than a
            Saturday or a Sunday or a public holiday.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>debit day</span> means the day that payment
            by you to us is due.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>debit payment</span> means a particular
            transaction where a debit is made.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>direct debit request</span> means the
            Direct Debit Request between us and you.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>us</span> or{' '}
            <span css={styles.label}>we</span> means{' '}
            <strong>ZipMoney Payments Pty Ltd</strong>, (the Debit User) you
            have authorised by requesting a Direct Debit Request.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>you</span> means the customer who has
            signed or authorised by other means the Direct Debit Request.
          </p>
          <p css={styles.copy}>
            <span css={styles.label}>your financial institution</span> means the
            financial institution nominated by you on the DDR at which the
            account is maintained.
          </p>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>2. Debiting your account</div>
          <ol css={styles.listAlpha}>
            <li css={[styles.copy, styles.listItem]}>
              By signing a Direct Debit Request or by providing us with a valid
              instruction, you have authorised us to arrange for funds to be
              debited from your account. You should refer to the Direct Debit
              Request and this agreement for the terms of the arrangement
              between us and you.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              Signing electronically: You consent and agree to signing a Direct
              Debit Request or providing us with a valid instruction
              electronically.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              We will only arrange for funds to be debited from your account as
              authorised in the Direct Debit Request, or, We will only arrange
              for funds to be debited from your account if we have sent to the
              address nominated by you in the Direct Debit Request, a billing
              advice which specifies the amount payable by you to us and when it
              is due, or on alternate terms proposed by You and accepted by Us.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              If the debit day falls on a day that is not a banking day, we may
              direct your financial institution to debit your account on the
              following banking day. If you are unsure about which day your
              account has or will be debited you should ask your financial
              institution.
            </li>
          </ol>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>3. Amendments by us</div>
          <p css={styles.copy}>
            We may vary any details of this agreement or a Direct Debit Request
            at any time by giving you at least fourteen (14) days written
            notice.
          </p>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>4. Amendments by you</div>
          <ol css={styles.listAlpha}>
            <li css={[styles.copy, styles.listItem]}>
              You may change*, stop or defer a debit payment, or terminate this
              agreement by providing us with at least 5 days notification by
              writing to: ZipMoney Payments Pty Ltd, 7/180 George Street,
              Sydney, NSW, 2000, AU.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              Or by telephoning us on (02) 8294 2345 during business hours, or
              through the Customer Centre at https://zip.co; or arranging it
              through your own financial institution, which is required to act
              promptly on your instructions.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              <strong>Note</strong>: in relation to the above reference to
              &lsquo;change&#39;, your financial institution may
              &lsquo;change&#39; your debit payment only to the extent of
              advising us, ZipMoney Payments Pty Ltd of your new account
              details.
            </li>
          </ol>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>5. Your obligations</div>
          <ol css={styles.listAlpha}>
            <li css={[styles.copy, styles.listItem]}>
              It is your responsibility to ensure that there are sufficient
              clear funds available in your account to allow a debit payment to
              be made in accordance with the Direct Debit Request.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              If there are insufficient clear funds in your account to meet a
              debit payment:
              <ol type="i">
                <li css={[styles.copy, styles.listItem]}>
                  you may be charged a fee and/or interest by your financial
                  institution;
                </li>
                <li css={[styles.copy, styles.listItem]}>
                  you may also incur fees or charges imposed or incurred by us;
                  and
                </li>
                <li css={[styles.copy, styles.listItem]}>
                  you must arrange for the debit payment to be made by another
                  method or arrange for sufficient clear funds to be in your
                  account by an agreed time so that we can process the debit
                  payment.
                </li>
              </ol>
            </li>
            <li css={[styles.copy, styles.listItem]}>
              You should check your account statement to verify that the amounts
              debited from your account are correct.
            </li>
          </ol>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>6. Dispute</div>
          <ol css={styles.listAlpha}>
            <li css={[styles.copy, styles.listItem]}>
              If you believe that there has been an error in debiting your
              account, you should notify us directly on (02) 8294 2345 or via
              email at hello@care.zip.co and confirm that notice in writing with
              us as soon as possible so that we can resolve your query more
              quickly. Alternatively you can take it up directly with your
              financial institution.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              If we conclude as a result of our investigations that your account
              has been incorrectly debited we will respond to your query by
              arranging for your financial institution to adjust your account
              (including interest and charges) accordingly. We will also notify
              you in writing of the amount by which your account has been
              adjusted.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              If we conclude as a result of our investigations that your account
              has not been incorrectly debited we will respond to your query by
              providing you with reasons and any evidence for this finding in
              writing.
            </li>
          </ol>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>7. Accounts</div>
          <p css={styles.copy}>You should check:</p>
          <ol css={styles.listRoman}>
            <li css={[styles.copy, styles.listItem]}>
              with your financial institution whether direct debiting is
              available from your account as direct debiting is not available on
              all accounts offered by financial institutions.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              your account details which you have provided to us are correct by
              checking them against a recent account statement; and
            </li>
            <li css={[styles.copy, styles.listItem]}>
              with your financial institution before completing the Direct Debit
              Request if you have any queries about how to complete the Direct
              Debit Request.
            </li>
          </ol>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>8. Confidentiality</div>
          <ol css={styles.listAlpha}>
            <li css={[styles.copy, styles.listItem]}>
              We will keep any information (including your account details) in
              your Direct Debit Request confidential. We will make reasonable
              efforts to keep any such information that we have about you secure
              and to ensure that any of our employees or agents who have access
              to information about you do not make any unauthorised use,
              modification, reproduction or disclosure of that information.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              We will only disclose information that we have about you:
              <ol css={styles.listRoman}>
                <li css={[styles.copy, styles.listItem]}>
                  to the extent specifically required by law; or
                </li>
                <li css={[styles.copy, styles.listItem]}>
                  for the purposes of this agreement (including disclosing
                  information in connection with any query or claim).
                </li>
              </ol>
            </li>
            <li css={[styles.copy, styles.listItem]}>
              For security and compliance reasons debit card details are not
              stored by us. We store the information as a secure token only,
              which represents your card number, and this secure token is used
              when processing your payments as per this Direct Debit Request
              Service Agreement.
            </li>
          </ol>
        </div>
        <div css={styles.sectionMrgnBtm}>
          <div css={styles.subsectionHeader}>9. Notice</div>
          <ol css={styles.listAlpha}>
            <li css={[styles.copy, styles.listItem]}>
              If you wish to notify us in writing about anything relating to
              this agreement, you should write to: 7/180 George Street, Sydney,
              NSW, 2000, AU.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              We will notify you by sending a notice via email as per the
              electronic consents you have provided, or, in the ordinary post to
              the address you have given us in the Direct Debit Request.
            </li>
            <li css={[styles.copy, styles.listItem]}>
              Any notice will be deemed to have been received on the third
              banking day after posting.
            </li>
          </ol>
        </div>
      </div>
    </Dialogs.Basic>
  );
};

export default DirectDebitDialog;
