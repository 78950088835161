/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxImportSource @emotion/react */
import { Hidden as MuiHidden } from '@material-ui/core';
import { Buttons, TextFields } from '@zip/business-components';
import { AddressLookup, RadioGroup } from 'components';
import { IdDocumentType, PageAction } from 'enums';
import { useFormik } from 'formik';
import { Constants, css } from 'global';
import { ChangeEventHandler, FC, useState } from 'react';
import {
  ApplicationModuleProps,
  DirectorDetails,
  DirectorDetailsErrors,
} from 'types';
import { Validators, formatOneLineAddress, logEvent } from 'utils';
import * as styles from './DirectorDetails.styles';

const DirectorDetailsModule: FC<ApplicationModuleProps> = ({
  callback,
  applicationData,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function formatValues(values: DirectorDetails): DirectorDetails {
    const trimmedValues: DirectorDetails = {
      documentType: values.documentType,
    };

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        trimmedValues[key] = values[key]?.trim();
      }
    });

    switch (trimmedValues.documentType) {
      case IdDocumentType.Passport:
        const {
          licenseState,
          licenseNumber,
          licenseAddress,
          licenseCardNumber,
          ...passportValues
        } = trimmedValues;
        return passportValues;
      case IdDocumentType.DriverLicense:
        const { passportNumber, address, ...licenceValues } = trimmedValues;
        return licenceValues;
      default:
        return trimmedValues;
    }
  }

  const formik = useFormik({
    initialValues: {
      documentType:
        applicationData?.directorDetails?.documentType ??
        IdDocumentType.NoOption,
      firstName: applicationData?.directorDetails?.firstName,
      middleName: applicationData?.directorDetails?.middleName,
      lastName: applicationData?.directorDetails?.lastName,
      dateOfBirth: applicationData?.directorDetails?.dateOfBirth ?? null,
      licenseState: applicationData?.directorDetails?.licenseState ?? '',
      licenseNumber: applicationData?.directorDetails?.licenseNumber,
      licenseCardNumber: applicationData?.directorDetails?.licenseCardNumber,
      licenseAddress: applicationData?.directorDetails?.licenseAddress,
      unitNumber: applicationData?.directorDetails?.unitNumber,
      streetNumber: applicationData?.directorDetails?.streetNumber,
      streetName: applicationData?.directorDetails?.streetName,
      suburb: applicationData?.directorDetails?.suburb,
      postcode: applicationData?.directorDetails?.postcode,
      state: applicationData?.directorDetails?.state,
      country: applicationData?.directorDetails?.country,
      address: applicationData?.directorDetails?.address,
      passportNumber: applicationData?.directorDetails?.passportNumber,
      contactEmail: applicationData?.directorDetails?.contactEmail,
      contactNumber: applicationData?.directorDetails?.contactNumber,
    },
    onSubmit: async (values) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      logEvent(PageAction.submitDirectorDetails);
      const valuesToSubmit = formatValues(values);
      await callback(valuesToSubmit);
      setIsLoading(false);
    },
    validate: (values: DirectorDetails) => {
      const errors: DirectorDetailsErrors = {};

      if (!values.documentType) {
        errors.documentType = 'Required';
      }
      if (!values.firstName) {
        errors.firstName = 'Required';
      } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.firstName)) {
        errors.firstName = 'Name contains invalid characters';
      } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.firstName)) {
        errors.firstName = 'Name must contain at least 2 letters';
      } else if (!/^[a-zA-Z'\s-]{2,100}$/.test(values.firstName)) {
        errors.firstName = 'Name cannot exceed 100 letters';
      }
      if (!values.lastName) {
        errors.lastName = 'Required';
      } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.lastName)) {
        errors.lastName = 'Name contains invalid characters';
      } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.lastName)) {
        errors.lastName = 'Name must contain at least 2 letters';
      } else if (!/^[a-zA-Z'\s-]{2,100}$/.test(values.lastName)) {
        errors.lastName = 'Name cannot exceed 100 letters';
      }
      if (!values.dateOfBirth) {
        errors.dateOfBirth = 'Required';
      } else if (values?.dateOfBirth === 'Invalid date') {
        errors.dateOfBirth = 'Invalid date';
      } else if (
        new Date(values?.dateOfBirth) < Constants.formValidation.minBirthDate
      ) {
        errors.dateOfBirth = 'Must be born after 1st Jan 1900';
      }

      const addressIncomplete =
        !values.streetNumber ||
        !values.streetName ||
        !values.suburb ||
        !values.postcode ||
        !values.state ||
        !values.country;

      if (values.documentType === IdDocumentType.DriverLicense) {
        if (!values.licenseState) {
          errors.licenseState = 'Required';
        }
        if (!values.licenseNumber) {
          errors.licenseNumber = 'Required';
        } else if (values.licenseNumber.length > 10) {
          errors.licenseNumber = 'Maximum length is 10 characters';
        } else if (Validators.validateCharacters(values.licenseNumber, [' '])) {
          errors.licenseNumber = 'Enter without spaces';
        }
        if (!values.licenseCardNumber) {
          errors.licenseCardNumber = 'Required';
        } else if (values.licenseCardNumber.length > 10) {
          errors.licenseCardNumber = 'Maximum length is 10 characters';
        } else if (
          Validators.validateCharacters(values.licenseCardNumber, [' '])
        ) {
          errors.licenseCardNumber = 'Enter without spaces';
        }
        if (addressIncomplete) {
          errors.licenseAddress = 'Required';
        }
      }

      if (values.documentType === IdDocumentType.Passport) {
        if (addressIncomplete) {
          errors.address = 'Required';
        }
        if (!values.passportNumber) {
          errors.passportNumber = 'Required';
        } else if (values.passportNumber.length > 20) {
          errors.passportNumber = 'Passport number cannot exceed 20 characters';
        }
      }

      if (!values.contactEmail) {
        errors.contactEmail = 'Required';
      } else if (!Validators.validateEmail(values.contactEmail)) {
        errors.contactEmail = 'Invalid email address';
      } else if (values.contactEmail.length > 80) {
        errors.contactEmail = 'Email must not exceed 80 characters';
      }

      if (!values.contactNumber) {
        errors.contactNumber = 'Required';
      } else if (!Validators.validateMobile(values.contactNumber)) {
        errors.contactNumber = 'Invalid mobile number';
      } else if (values.contactNumber.length > 15) {
        errors.contactNumber = 'Mobile must not exceed 15 characters';
      }

      return errors;
    },
  });

  const idDocumentTypes = [
    { value: IdDocumentType.DriverLicense, label: 'Driver licence' },
    { value: IdDocumentType.Passport, label: 'Passport' },
  ];

  const radioIdDocumentTypes = [
    {
      value: IdDocumentType.DriverLicense,
      label: (
        <>
          <img
            src={Constants.assets.pictorials.driverLicence}
            alt="Driver licence"
          />
          <br />
          <p css={styles.radioGroupItem}>Driver licence</p>
        </>
      ),
    },
    {
      value: IdDocumentType.Passport,
      label: (
        <>
          <img src={Constants.assets.pictorials.passport} alt="Passport" />
          <br />
          <p css={styles.radioGroupItem}>Passport</p>
        </>
      ),
    },
  ];

  const licenseStateOptions = [
    { value: 'ACT', label: 'ACT' },
    { value: 'QLD', label: 'QLD' },
    { value: 'NSW', label: 'NSW' },
    { value: 'NT', label: 'NT' },
    { value: 'SA', label: 'SA' },
    { value: 'TAS', label: 'TAS' },
    { value: 'VIC', label: 'VIC' },
    { value: 'WA', label: 'WA' },
  ];

  return (
    <div>
      <h1 css={css.pageTitle}>Director details</h1>
      <p css={[css.noBtmMrgn, css.noTopMrgn]}>
        It’s important for us to verify the identity of the director for your
        application.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <MuiHidden smUp>
            <TextFields.Select
              name="documentType"
              label="Choose ID document type"
              css={[styles.textfield, styles.select, styles.idDocType]}
              options={idDocumentTypes}
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  MenuListProps: {
                    disablePadding: true,
                  },
                },
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                value: formik.values.documentType,
              }}
              error={
                formik.touched.documentType &&
                Boolean(formik.errors.documentType)
              }
              helperText={
                formik.touched.documentType && formik.errors.documentType
              }
            />
          </MuiHidden>
          <MuiHidden xsDown>
            <RadioGroup
              name="documentType"
              options={radioIdDocumentTypes}
              label="Choose ID document type"
              valueChanged={formik.handleChange}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.documentType}
              error={
                formik.touched.documentType &&
                Boolean(formik.errors.documentType)
              }
              helperText={
                formik.touched.documentType && formik.errors.documentType
              }
              useLabelAsIcon
              css={styles.idDocType}
            />
          </MuiHidden>
        </div>
        {formik.values.documentType && (
          <>
            <div>
              <h4 css={[css.noBtmMrgn, css.noTopMrgn]}>Director full name</h4>
              <p css={styles.p}>
                Details must match{' '}
                {formik.values.documentType === IdDocumentType.DriverLicense
                  ? `drivers license`
                  : `passport`}{' '}
                exactly.
              </p>
            </div>
            <TextFields.Outlined
              type="text"
              name="firstName"
              label="First name"
              css={styles.textfield}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextFields.Outlined
              type="text"
              name="middleName"
              label="Middle name"
              css={styles.textfield}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.middleName}
              error={
                formik.touched.middleName && Boolean(formik.errors.middleName)
              }
              helperText={
                (formik.touched.middleName && formik.errors.middleName) ??
                'Add middle name if it appears on the ID document'
              }
            />
            <TextFields.Outlined
              type="text"
              name="lastName"
              label="Surname"
              css={styles.textfield}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />

            {formik.values.documentType === IdDocumentType.DriverLicense && (
              <>
                <div>
                  <h4 css={[css.noBtmMrgn, styles.newSection]}>
                    Driver license details
                  </h4>
                  <p css={styles.p}>
                    Details must match driver license exactly.
                  </p>
                </div>
                <TextFields.Date
                  KeyboardButtonProps={{
                    'aria-label': 'open calendar',
                  }}
                  css={styles.textfield}
                  className="fs-mask"
                  id="date-of-birth"
                  fullWidth
                  label="Date of birth"
                  name="dateOfBirth"
                  onChange={(value): void => {
                    // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
                    formik.setFieldValue(
                      'dateOfBirth',
                      value?.format('YYYY-MM-DDT00:00:00') ?? null
                    );
                  }}
                  value={formik.values.dateOfBirth}
                  error={
                    formik.touched.dateOfBirth &&
                    Boolean(formik.errors.dateOfBirth)
                  }
                  helperText={
                    formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  }
                  disableToolbar={false}
                  onBlur={formik.handleBlur}
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'date']}
                  autoOk
                  placeholder="DD/MM/YYYY"
                  minDate={Constants.formValidation.minBirthDate}
                />
                <div css={styles.licenceDetails}>
                  <TextFields.Select
                    name="licenseState"
                    label="State"
                    css={[styles.select, styles.licenceStateSelect]}
                    options={licenseStateOptions}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      },
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      value: formik.values.licenseState,
                    }}
                    error={
                      formik.touched.licenseState &&
                      Boolean(formik.errors.licenseState)
                    }
                    helperText={
                      formik.touched.licenseState && formik.errors.licenseState
                    }
                  />
                  <TextFields.Outlined
                    type="text"
                    name="licenseNumber"
                    label="Licence number"
                    css={styles.textfield}
                    className="fs-mask"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.licenseNumber}
                    error={
                      formik.touched.licenseNumber &&
                      Boolean(formik.errors.licenseNumber)
                    }
                    helperText={
                      formik.touched.licenseNumber &&
                      formik.errors.licenseNumber
                    }
                  />
                </div>
                <TextFields.Outlined
                  type="text"
                  name="licenseCardNumber"
                  label="Licence card number"
                  css={styles.textfield}
                  className="fs-mask"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.licenseCardNumber}
                  error={
                    formik.touched.licenseCardNumber &&
                    Boolean(formik.errors.licenseCardNumber)
                  }
                  helperText={
                    (formik?.touched?.licenseCardNumber &&
                      formik?.errors?.licenseCardNumber) ||
                    'This is different to your licence number'
                  }
                />
                <AddressLookup
                  label="Residential address"
                  name="licenseAddress"
                  handleChange={(
                    e: ChangeEventHandler<
                      HTMLTextAreaElement | HTMLInputElement
                    >,
                    newAddress
                  ): void => {
                    if (!newAddress) {
                      return;
                    }
                    formik.setValues({
                      ...formik.values,
                      unitNumber: newAddress?.unitNumber,
                      streetNumber: newAddress?.streetNumber,
                      streetName: newAddress?.streetName,
                      suburb: newAddress?.suburb,
                      postcode: newAddress?.postcode,
                      state: newAddress?.state,
                      country: newAddress?.country,
                      licenseAddress: formatOneLineAddress(newAddress),
                    });
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.licenseAddress}
                  error={
                    formik.touched.licenseAddress &&
                    Boolean(formik.errors.licenseAddress)
                  }
                  helperText={
                    formik.touched.licenseAddress &&
                    formik.errors.licenseAddress
                  }
                />
              </>
            )}

            {formik.values.documentType === IdDocumentType.Passport && (
              <>
                <div>
                  <h4 css={[css.noBtmMrgn, styles.newSection]}>
                    Passport details
                  </h4>
                  <p css={styles.p}>Details must match passport exactly.</p>
                </div>
                <TextFields.Outlined
                  type="text"
                  name="passportNumber"
                  label="Passport number"
                  css={styles.textfield}
                  className="fs-mask"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passportNumber}
                  error={
                    formik.touched.passportNumber &&
                    Boolean(formik.errors.passportNumber)
                  }
                  helperText={
                    formik.touched.passportNumber &&
                    formik.errors.passportNumber
                  }
                />
                <TextFields.Date
                  KeyboardButtonProps={{
                    'aria-label': 'open calendar',
                  }}
                  css={styles.textfield}
                  className="fs-mask"
                  id="date-of-birth"
                  fullWidth
                  label="Date of birth"
                  name="dateOfBirth"
                  // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
                  onChange={(value): void => {
                    formik.setFieldValue(
                      'dateOfBirth',
                      value?.format('YYYY-MM-DDT00:00:00') ?? null
                    );
                  }}
                  value={formik.values.dateOfBirth}
                  error={
                    formik.touched.dateOfBirth &&
                    Boolean(formik.errors.dateOfBirth)
                  }
                  helperText={
                    formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  }
                  disableToolbar={false}
                  onBlur={formik.handleBlur}
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'date']}
                  autoOk
                  placeholder="DD/MM/YYYY"
                />
              </>
            )}
            <div>
              <h4 css={[css.noBtmMrgn, styles.newSection]}>
                Director contact details
              </h4>
              <p />
            </div>
            {formik.values.documentType === IdDocumentType.Passport && (
              <AddressLookup
                label="Residential address"
                name="address"
                handleChange={(
                  e: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
                  newAddress
                ): void => {
                  if (!newAddress) {
                    return;
                  }
                  formik.setValues({
                    ...formik.values,
                    unitNumber: newAddress?.unitNumber,
                    streetNumber: newAddress?.streetNumber,
                    streetName: newAddress?.streetName,
                    suburb: newAddress?.suburb,
                    postcode: newAddress?.postcode,
                    state: newAddress?.state,
                    country: newAddress?.country,
                    address: formatOneLineAddress(newAddress),
                  });
                }}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            )}
            <TextFields.Outlined
              type="email"
              name="contactEmail"
              label="Email"
              css={styles.textfield}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactEmail}
              error={
                formik.touched.contactEmail &&
                Boolean(formik.errors.contactEmail)
              }
              helperText={
                formik.touched.contactEmail && formik.errors.contactEmail
              }
            />
            <TextFields.Outlined
              type="tel"
              name="contactNumber"
              label="Mobile"
              css={styles.textfield}
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactNumber}
              error={
                formik.touched.contactNumber &&
                Boolean(formik.errors.contactNumber)
              }
              helperText={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
            />
            <p css={[css.subtext, styles.consentText]}>
              By submitting, I give consent to this information being checked
              with the document issuer or official record holder.
            </p>

            <div css={css.onboardingConfirmButton}>
              <Buttons.Primary
                type="submit"
                disabled={Boolean(Object.keys(formik.errors).length)}
                loading={isLoading}
              >
                Submit
              </Buttons.Primary>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default DirectorDetailsModule;
