import { css } from '@emotion/react';

export const description = css`
  margin: 0px 0px 16px;
`;

export const button = css`
  display: flex;

  > svg {
    margin-left: 8px;
  }
`;
