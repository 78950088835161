/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { FC, useState } from 'react';
import { logError, logEvent, useOrderFunctions } from 'utils';

import * as styles from './AuthoriseAndCaptureDialog.styles';
import { AuthoriseAndCaptureDialogProps } from './AuthoriseAndCaptureDialogProps';

export const AuthoriseAndCaptureDialog: FC<AuthoriseAndCaptureDialogProps> = ({
  open = false,
  receiptNumber,
  orderReference,
  branchId,
  amountOwing,
  onSuccess,
}) => {
  const Snackbar = useSnackbar();
  const { completeOrder } = useOrderFunctions();

  const [isCompletingOrder, setIsCompletingOrder] = useState<boolean>(false);

  const completeAuthorisedOrder = async (): Promise<void> => {
    if (isCompletingOrder) {
      return;
    }

    setIsCompletingOrder(true);
    logEvent(PageAction.completeOrder, {
      receiptNumber,
      reference: orderReference,
      source: 'auth-and-cap',
    });

    try {
      await completeOrder(
        Number(branchId),
        receiptNumber,
        orderReference,
        amountOwing
      );
      Snackbar.success(
        `Order was processed successfully with receipt number: ${receiptNumber}`
      );
      onSuccess();
    } catch (err) {
      logError(PageAction.completeOrder, err, {
        branch: Number(branchId),
        receiptNumber,
        reference: orderReference,
        amount: amountOwing,
      });
      Snackbar.warning(
        'Something unexpected has occurred. Please verify your action was completed or try again.'
      );
    }
    setIsCompletingOrder(false);
  };

  return (
    <Dialogs.Basic
      id="authoriseAndCaptureDialog"
      onClose={(): void => null}
      open={open}
      showClose={false}
    >
      <div css={styles.authPrompt}>
        <h3>
          Receipt number: <span id="receiptNumber">{receiptNumber}</span>
          <br />
          Reference: <span id="orderReference">{orderReference}</span>
        </h3>
        <h4>Great, did the customer receive their item?</h4>
        <div css={styles.authActions}>
          <Buttons.Primary
            id="completeAuthorisedOrderButton"
            onClick={completeAuthorisedOrder}
            loading={isCompletingOrder}
          >
            Customer received item
          </Buttons.Primary>
          <br />
          <Buttons.Secondary id="keepOrderAuthorisedButton" onClick={onSuccess}>
            Customer will receive item later
          </Buttons.Secondary>
        </div>
        <p>
          <strong>What&#39;s the difference?</strong>
          <br />
          By clicking &#39;Customer received item&#39; you are confirming the
          order has been dispatched or the customer has received the goods or
          services which they are paying for.
        </p>
        <p>
          Selecting &#39;Customer will receive item later&#39; tells us to hold
          onto the funds until the customer receives the item. You&#39;ll have
          an option to release the funds later.
        </p>
      </div>
    </Dialogs.Basic>
  );
};

export default AuthoriseAndCaptureDialog;
