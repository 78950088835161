/** @jsxImportSource @emotion/react */
import { Button as MuiButton } from '@material-ui/core';
import { theme } from 'global';
import { FC } from 'react';
import * as styles from './DenseButton.styles';
import { DenseButtonProps } from './DenseButtonProps';

const DenseButton: FC<DenseButtonProps> = ({
  ButtonIcon,
  children,
  disabled,
  minWidth,
  ...props
}) => {
  return (
    <MuiButton
      type="submit"
      css={styles.denseButton(minWidth)}
      disableRipple
      disabled={disabled}
      {...props}
    >
      {ButtonIcon && (
        <ButtonIcon
          fill={
            disabled ? theme.colors.neutral[600] : theme.colors.primary.medium
          }
          css={styles.denseButtonIcon}
        />
      )}
      <span css={styles.label}>{children}</span>
    </MuiButton>
  );
};

export default DenseButton;
