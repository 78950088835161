import { css } from '@emotion/react';

import { theme } from 'global';

export const usersTable = css`
  min-height: calc(100vh - 345px);
  max-height: calc(100vh - 345px);

  @media screen and (max-width: ${theme.breaks.lg - 1}px) {
    min-height: calc(100vh - 313px);
    max-height: calc(100vh - 313px);
  }

  @media screen and (max-width: ${theme.breaks.sm}px) {
    max-height: unset;
    min-height: unset;
  }
`;
