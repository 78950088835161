/** @jsxImportSource @emotion/react */
import {
  Buttons,
  Dialogs,
  Spinner,
  TextFields,
} from '@zip/business-components';
import { PriceInput } from 'components';
import { useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { css } from 'global';
import { FC, useEffect, useState } from 'react';
import { OrderDetails } from 'types';
import {
  currencyFormatter,
  logError,
  logEvent,
  useOrderFunctions,
} from 'utils';
import { RefundOrderModuleProps } from './RefundOrderModuleProps';

const RefundOrderModule: FC<RefundOrderModuleProps> = ({
  open = true,
  toggleOpen,
  orderToAction,
  onSuccess,
  ...props
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();

  const [orderDetails, setOrderDetails] = useState<OrderDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [amount, setAmount] = useState<number>();
  const [amountHelperText, setAmountHelperText] = useState<string>();
  const [comment, setComment] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = (): void => {
    setComment('');
    toggleOpen(false);
  };

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      logEvent(PageAction.refundOrder, { orderToAction, amount });
      const result = await OrderFunctions.refundOrder(
        orderToAction.operationRequestId,
        orderToAction.branchId,
        amount,
        comment
      );
      if (result.isSuccess) {
        Snackbar.success('Refund was processed successfully');
        handleClose();
        onSuccess();
      } else {
        Snackbar.error(
          result?.reason?.includes('Account not found.')
            ? 'Unable to process the refund as the customer has since closed their account.'
            : `An error occurred processing your request.`
        );
      }
    } catch (err) {
      logError(PageAction.refundOrder, err, {
        operationRequestId: orderToAction.operationRequestId,
        branchId: orderToAction.branchId,
        amount,
        comment,
      });
      Snackbar.error(`An error occurred processing your request.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let helperText;
    if (amount > Math.abs(+parseFloat(orderDetails?.amountPaid?.toFixed(2)))) {
      helperText = `Maximum refund amount allowed is ${currencyFormatter(
        orderDetails?.amountPaid
      )}`;
    } else if (amount <= 0) {
      helperText = 'Refund amount must be greater than $0';
    }
    setAmountHelperText(helperText);
  }, [amount, orderDetails]);

  useEffect(() => {
    const getOrderDetails = async (): Promise<void> => {
      setIsLoading(true);
      const details = await OrderFunctions.getOrderDetails(
        orderToAction.operationRequestId
      );
      setOrderDetails(details);
      setAmount(
        Math.abs(
          +parseFloat(
            (details?.amountPaid - details?.refundedAmount).toFixed(2)
          )
        )
      );
      setIsLoading(false);
    };

    if (!open) {
      return;
    }

    getOrderDetails();
  }, [open, orderToAction]);

  return (
    <Dialogs.Basic
      id="refundOrderDialog"
      open={open}
      title="Refund order"
      onClose={handleClose}
      spaceActions
      actions={
        !isLoading && (
          <>
            <Buttons.Primary
              id="submitButton"
              disabled={amount <= 0 || orderDetails?.amountPaid === 0}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Refund order
            </Buttons.Primary>

            <Buttons.Text id="cancelButton" onClick={handleClose}>
              Cancel
            </Buttons.Text>
          </>
        )
      }
      {...props}
    >
      {isLoading && (
        <div css={css.loadingPrompt}>
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          {orderDetails?.refundedAmount < orderDetails?.amountPaid && (
            <>
              <p className="body2">
                Process refund for order{' '}
                <strong>{orderToAction?.orderReference}</strong>
              </p>
              <PriceInput
                id="refundAmount"
                label="Amount to refund"
                value={amount}
                onChange={(e): void =>
                  setAmount(Math.abs(+parseFloat(e?.target?.value).toFixed(2)))
                }
                error={Boolean(amountHelperText)}
                helperText={amountHelperText}
                max={orderDetails?.amountPaid}
                fullWidth
              />
              <TextFields.Outlined
                id="refundComment"
                type="text"
                label="Comment about the refund"
                value={comment}
                onChange={(e): void => setComment(e?.target?.value)}
                fullWidth
              />
            </>
          )}

          {orderDetails?.refundedAmount >= orderDetails?.amountPaid && (
            <>
              <p>
                Order <strong>{orderToAction.orderReference}</strong> has
                already been fully refunded.
              </p>
              <p>You are unable to refund any further amount.</p>
            </>
          )}
        </>
      )}
    </Dialogs.Basic>
  );
};

export default RefundOrderModule;
