import { css } from '@emotion/react';

export const link = css`
  font-size: inherit !important;
  font-weight: inherit !important;
  vertical-align: unset;
`;

export const helperText = css`
  > .MuiFormHelperText-root {
    margin-bottom: -20px;
  }
`;
