/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { useMerchantData } from 'contexts';
import { ProductClassification } from 'enums';
import { Constants } from 'global';
import { FC } from 'react';
import { currencyFormatter, productFormatter } from 'utils';
import * as styles from './ZipPlusMMPDialog.styles';

const ZipPlusMMPDialog: FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const { merchantAccountTypes } = useMerchantData();

  const zipPlusAccountTypes = merchantAccountTypes
    .filter((x) => x.productClassification === ProductClassification.ZipPlus)
    .sort((x) => x.limit);

  return (
    <Dialogs.Basic
      id="zipPlusMMPDialog"
      onClose={onClose}
      open={open}
      title="Minimum monthly repayment"
      actions={<Buttons.Primary onClick={onClose}>Got it</Buttons.Primary>}
    >
      <p className="body2" css={styles.marginTop(12)}>
        This is the minimum repayment a customer has to pay each month.
      </p>
      <p className="body2">
        The table below lists Zip Plus credit limits and their minimum monthly
        repayment.
      </p>

      <div css={styles.productsContainer}>
        <span>
          <img
            src={
              Constants.assets.productLogos[
                productFormatter(ProductClassification.ZipPlus)
              ].light
            }
            alt={productFormatter(ProductClassification.ZipPlus)}
            height={14}
          />
        </span>
        <table css={styles.mmpTable}>
          <thead>
            <tr>
              <th className="body3">Credit limit</th>
              <th className="body3">Minimum repayment</th>
            </tr>
          </thead>
          <tbody>
            {zipPlusAccountTypes?.map((merchantAccountTypes) => (
              <tr key={merchantAccountTypes.accountTypeId}>
                <td className="body1">
                  {currencyFormatter(merchantAccountTypes.limit, false)}
                </td>
                <td className="body2">
                  {currencyFormatter(
                    merchantAccountTypes.minimumRepayment,
                    false
                  )}
                  <span className="caption2">/month</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <p className="body2" css={styles.marginBottom(12)}>
        For more information on Zip Plus, visit{' '}
        <a href="https://zip.co/au/zip-plus" target="_blank">
          https://zip.co/au/zip-plus
        </a>
      </p>
    </Dialogs.Basic>
  );
};

export default ZipPlusMMPDialog;
