/** @jsxImportSource @emotion/react */
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';
import * as Icons from '@zip/react-icons/fearless';
import { forwardRef, useImperativeHandle, useState } from 'react';

import * as styles from './Accordion.styles';
import { AccordionProps } from './AccordionProps';

export const Accordion = forwardRef((props: AccordionProps, ref) => {
  const {
    summary,
    details,
    showExpandIcon = true,
    defaultExpanded = false,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);
  const shouldShowChevron = !defaultExpanded && showExpandIcon;

  useImperativeHandle(ref, () => ({ isExpanded }), [isExpanded]);

  return (
    <MuiAccordion
      expanded={isExpanded}
      onChange={(): void => !defaultExpanded && setIsExpanded(!isExpanded)}
      elevation={0}
    >
      <MuiAccordionSummary
        css={styles.accordionSummary(!defaultExpanded)}
        expandIcon={
          shouldShowChevron && <Icons.ChevronDown height={24} width={24} />
        }
      >
        {summary}
      </MuiAccordionSummary>
      <MuiAccordionDetails css={styles.accordionDetails}>
        {details}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
});

export default Accordion;
