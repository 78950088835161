/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid, Paper as MuiPaper } from '@material-ui/core';
import { Loading, Select, Selectable } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Tooltip } from 'components';
import { useMerchantData } from 'contexts';
import { DateRange, OrderStatus, PageAction, PageRoute } from 'enums';
import { Constants, css, theme } from 'global';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OrdersToReviewResponse } from 'types';
import { logError, splitTitleCase, useReportFunctions } from 'utils';
import * as styles from './OrdersToReview.styles';

const OrdersToReviewModule: FC = () => {
  const { merchantIdentity, profileData, profileDataIsLoading } =
    useMerchantData();
  const ReportFunctions = useReportFunctions();
  const { getNumberOfDays } = ReportFunctions;
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateRanges] = useState<Selectable[]>(
    Object.keys(DateRange).map((dateRange) => ({
      label: splitTitleCase(dateRange),
      value: dateRange.toLowerCase(),
    }))
  );
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    DateRange.Last7Days.toLowerCase()
  );

  const handleDateRangeChange = (newDateRange): void =>
    setSelectedDateRange(newDateRange);

  const [ordersToReviewData, setOrdersToReviewData] =
    useState<OrdersToReviewResponse>();

  function getStartDate(): Date {
    const startDate = new Date();
    startDate.setUTCDate(
      startDate.getDate() - getNumberOfDays(selectedDateRange)
    );
    startDate.setUTCHours(0, 0, 0, 0);
    return startDate;
  }

  const handleCardClick = (orderStatus: OrderStatus): void =>
    push(`${PageRoute.Orders}/status/${orderStatus?.toLowerCase()}`);

  useEffect(() => {
    const abortController = new AbortController();
    const getOrdersToReviewData = async (): Promise<void> => {
      setIsLoading(true);
      const endDate = new Date();
      endDate.setUTCDate(endDate.getDate() + 1);
      endDate.setUTCHours(0, 0, 0, 0);
      const query = {
        startDate: getStartDate(),
        endDate,
      };
      await ReportFunctions.fetchOrdersToReview(query, abortController.signal)
        .then((response) => {
          setOrdersToReviewData(
            response?.orderCounts as OrdersToReviewResponse
          );
          setIsLoading(false);
        })
        .catch((err) => {
          if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
            logError(PageAction.fetchOrdersToReview, err, query);
            setOrdersToReviewData(null);
            setIsLoading(false);
          }
        });
    };

    if (profileData && !profileDataIsLoading) {
      getOrdersToReviewData();
    }

    return (): void => abortController.abort();
  }, [selectedDateRange, merchantIdentity]);

  return (
    <MuiPaper elevation={0} css={styles.container}>
      <div css={css.moduleHeader(false)}>
        <div css={css.moduleTitleBar}>
          <span className="heading1">Orders to review</span>
          <Tooltip
            content={
              <div>
                Order statuses that may require action from you or your customer
                to be completed.{' '}
                <a
                  href={Constants.helpArticles.orderStatuses}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Learn more
                </a>{' '}
                about order statuses
              </div>
            }
            maxWidth={320}
            placement="top"
            interactive
            arrow
            dense
            css={css.moduleTooltip}
          >
            <Icons.InformationCircle
              fill={theme.colors.neutral[500]}
              css={css.infoIcon}
            />
          </Tooltip>
        </div>
        <Select.Basic
          id="OrdersToReview-dateRangeDropdown"
          options={dateRanges}
          onChange={(e): void => handleDateRangeChange(e.target.value)}
          value={selectedDateRange}
        />
      </div>
      <MuiGrid container spacing={window.innerWidth <= theme.breaks.xs ? 1 : 2}>
        <MuiGrid item xs={12} sm={4}>
          <div
            css={styles.statusCard}
            onClick={(): void => handleCardClick(OrderStatus.Authorised)}
            role="button"
            tabIndex={-1}
          >
            <span>Authorised</span>
            {isLoading ? (
              <Loading />
            ) : (
              <p className="heading1" css={css.noMargin}>
                {ordersToReviewData?.Authorised ?? 'N/A'}
              </p>
            )}
          </div>
        </MuiGrid>
        <MuiGrid item xs={12} sm={4}>
          <div
            css={styles.statusCard}
            onClick={(): void => handleCardClick(OrderStatus.InProgress)}
            role="button"
            tabIndex={-1}
          >
            <span>In progress</span>
            {isLoading ? (
              <Loading />
            ) : (
              <p className="heading1" css={css.noMargin}>
                {ordersToReviewData?.InProgress ?? 'N/A'}
              </p>
            )}
          </div>
        </MuiGrid>
        <MuiGrid item xs={12} sm={4}>
          <div
            css={styles.statusCard}
            onClick={(): void => handleCardClick(OrderStatus.NotStarted)}
            role="button"
            tabIndex={-1}
          >
            <span>Not started</span>
            {isLoading ? (
              <Loading />
            ) : (
              <p className="heading1" css={css.noMargin}>
                {ordersToReviewData?.NotStarted ?? 'N/A'}
              </p>
            )}
          </div>
        </MuiGrid>
      </MuiGrid>
    </MuiPaper>
  );
};

export default OrdersToReviewModule;
