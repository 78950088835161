import { css } from '@emotion/react';
import { theme } from 'global';

export const row = css`
  :nth-child(even) {
    background: ${theme.colors.neutral[50]};
  }
`;

export const role = css`
  vertical-align: top;
  text-align: left;
  min-width: 128px;
  padding: 8px 12px !important;
`;

export const description = css`
  padding: 8px 12px !important;
`;
