/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper, useMediaQuery, useTheme } from '@material-ui/core';
import { Buttons, TextFields } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { BasePage, DenseButton, Sidebars } from 'components';
import { useMerchantData } from 'contexts';
import { PageRoute, PermissionEnum } from 'enums';
import { css } from 'global';
import { CustomersTableModule, InviteCustomerDialogModule } from 'modules';
import { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageProps } from 'types';

import * as styles from './CustomersPage.styles';

const CustomersPage: FC<PageProps> = ({ innerDrawerCallback }) => {
  const { checkPermission } = useMerchantData();
  const { status, consumerId } = useParams<{
    status: string;
    consumerId: string;
  }>();
  const { push, location } = useHistory<{ consumerId: string }>();
  const isSearchingCliRequest = location?.pathname.includes('/cli-invite');
  const [isInviteCustomerDialogOpen, setIsInviteCustomerDialogOpen] =
    useState<boolean>(false);

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const customersTableRef = useRef();
  const sidebarRef = useRef();

  const [searchValue, setSearchValue] = useState<string>(
    location?.state?.consumerId ?? consumerId ?? ''
  );
  const [selectedConsumerId, setSelectedConsumerId] = useState<number>(
    Number(consumerId) ?? null
  );

  const handleSearchValueChange = (e): void => setSearchValue(e?.target?.value);

  const handleInviteCustomerClick = (): void =>
    isSearchingCliRequest
      ? push(`${PageRoute.Tools}${PageRoute.CliInvite}`)
      : setIsInviteCustomerDialogOpen(true);

  const handleSidebarClose = (): void => {
    const newPath = status
      ? `${PageRoute.Customers}/status/${status}`
      : PageRoute.Customers;
    push(newPath);
    setSelectedConsumerId(null);
  };

  // listen to escape key to close sidebar
  useEffect(function setupListener() {
    function closeSidebar(e: { code: string }): void {
      if (consumerId && e.code === `Escape`) {
        handleSidebarClose();
      }
    }
    document.addEventListener('keyup', closeSidebar);

    return (): void => document.removeEventListener('keyup', closeSidebar);
  }, []);

  // Button to invite customer that is moved for mobile view
  const inviteCustomerButton = (
    <Buttons.Secondary
      css={styles.inviteCustomerButton(!!selectedConsumerId)}
      onClick={handleInviteCustomerClick}
    >
      <Icons.Paperplane width={20} height={20} />
      <span id="buttonLabel">
        {isSearchingCliRequest ? 'Increase limit' : 'Invite customer'}
      </span>
    </Buttons.Secondary>
  );

  return (
    <BasePage
      title={isSearchingCliRequest ? 'Credit limit requests' : 'Customers'}
      variant="layout"
      fullWidth
      fullHeight
      flex
      action={isMobile && inviteCustomerButton}
    >
      <MuiPaper
        elevation={0}
        css={styles.ordersTableContainer(!!selectedConsumerId)}
      >
        <div id="toolbar" css={styles.toolbar}>
          <span>
            <TextFields.Outlined
              id="searchValue"
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchValueChange}
              css={styles.input}
              startIcon={<Icons.Search />}
              endIcon={
                searchValue && (
                  <Icons.Close
                    css={css.clearIcon}
                    onClick={handleSearchValueChange}
                  />
                )
              }
              fullWidth={isMobile}
            />
            {isMobile && (
              <DenseButton
                id="searchStatusFilter"
                ButtonIcon={status ? Icons.Check : Icons.Filter}
                onClick={innerDrawerCallback}
                fullWidth
              >
                Filter statuses
              </DenseButton>
            )}
          </span>
          {!isMobile &&
            checkPermission(PermissionEnum.CreateOrderExecute) &&
            inviteCustomerButton}
        </div>
        <CustomersTableModule
          ref={customersTableRef}
          searchValue={searchValue}
          selectedConsumerId={selectedConsumerId}
          setSelectedConsumerId={setSelectedConsumerId}
        />
      </MuiPaper>

      <InviteCustomerDialogModule
        open={isInviteCustomerDialogOpen}
        onClose={(): void => setIsInviteCustomerDialogOpen(false)}
      />

      <Sidebars.Customer
        ref={sidebarRef}
        consumerId={selectedConsumerId}
        onClose={handleSidebarClose}
      />
    </BasePage>
  );
};

export default CustomersPage;
