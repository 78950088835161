/** @jsxImportSource @emotion/react */
import {
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import { Select, Selectable, Spinner } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Tooltip } from 'components';
import { useMerchantData } from 'contexts';
import { DateRange, PageAction } from 'enums';
import { Constants, css, theme } from 'global';
import { FC, useEffect, useState } from 'react';
import { CustomerOverviewResponse } from 'types';
import {
  currencyFormatter,
  logError,
  splitTitleCase,
  useReportFunctions,
} from 'utils';
import * as styles from './CustomerOverview.styles';

const CustomerOverviewModule: FC = () => {
  const { profileData, profileDataIsLoading } = useMerchantData();
  const ReportFunctions = useReportFunctions();
  const { getNumberOfDays } = ReportFunctions;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateRanges] = useState<Selectable[]>(
    Object.keys(DateRange).map((dateRange) => ({
      label: splitTitleCase(dateRange),
      value: dateRange.toLowerCase(),
    }))
  );
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    DateRange.Last7Days.toLowerCase()
  );

  const handleDateRangeChange = (newDateRange): void =>
    setSelectedDateRange(newDateRange);

  const [customerOverviewData, setCustomerOverviewData] =
    useState<CustomerOverviewResponse>();

  function getStartDate(): Date {
    const startDate = new Date();
    startDate.setUTCDate(
      startDate.getDate() - getNumberOfDays(selectedDateRange)
    );
    startDate.setUTCHours(0, 0, 0, 0);
    return startDate;
  }

  useEffect(() => {
    const abortController = new AbortController();
    const getCustomerOverviewData = async (): Promise<void> => {
      setIsLoading(true);
      const endDate = new Date();
      endDate.setUTCDate(endDate.getDate() + 1);
      endDate.setUTCHours(0, 0, 0, 0);
      const query = {
        startDate: getStartDate(),
        endDate,
      };
      await ReportFunctions.fetchCustomerOverview(query, abortController.signal)
        .then((response) => {
          setCustomerOverviewData(response as CustomerOverviewResponse);
          setIsLoading(false);
        })
        .catch((err) => {
          if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
            logError(PageAction.fetchGrossSales, err, query);
            setCustomerOverviewData(null);
            setIsLoading(false);
          }
        });
    };

    if (profileData && !profileDataIsLoading) {
      getCustomerOverviewData();
    }

    return (): void => abortController.abort();
  }, [selectedDateRange]);

  return (
    <MuiPaper elevation={0} css={styles.container}>
      <div css={css.moduleHeader(false)}>
        <div css={css.moduleTitleBar}>
          <span className="heading1">Customer overview</span>
          <Tooltip
            content="This data is not in real-time, it may take a few minutes to update."
            placement="top"
            enterTouchDelay={20}
            leaveTouchDelay={3000}
            maxWidth={250}
            arrow
            dense
            css={css.moduleTooltip}
          >
            <Icons.InformationCircle
              fill={theme.colors.neutral[500]}
              css={css.infoIcon}
            />
          </Tooltip>
        </div>
        <Select.Basic
          id="customerOverview-dateRangeDropdown"
          options={dateRanges}
          onChange={(e): void => handleDateRangeChange(e.target.value)}
          value={selectedDateRange}
        />
      </div>
      <MuiTable css={styles.table}>
        <MuiTableBody>
          {isLoading && (
            <MuiTableRow>
              <MuiTableCell colSpan={2} css={styles.loadingRow}>
                <Spinner />
              </MuiTableCell>
            </MuiTableRow>
          )}
          {!isLoading && (
            <>
              <MuiTableRow css={styles.firstRow}>
                <MuiTableCell>Total customers</MuiTableCell>
                <MuiTableCell align="right">
                  <strong>{customerOverviewData?.totalCustomers ?? 0}</strong>
                </MuiTableCell>
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>Average spend per customer</MuiTableCell>
                <MuiTableCell align="right">
                  <strong>
                    {currencyFormatter(
                      customerOverviewData?.averageSpendPerCustomer ?? 0
                    )}
                  </strong>
                </MuiTableCell>
              </MuiTableRow>
              <MuiTableRow>
                <MuiTableCell>Average visit per customer</MuiTableCell>
                <MuiTableCell align="right">
                  <strong>
                    {customerOverviewData?.averageVisitPerCustomer ?? 0}
                  </strong>
                </MuiTableCell>
              </MuiTableRow>
            </>
          )}
        </MuiTableBody>
      </MuiTable>
    </MuiPaper>
  );
};

export default CustomerOverviewModule;
