/** @jsxImportSource @emotion/react */
import { DashboardPromo, DashboardPromoSkeleton } from 'components';
import { PageAction } from 'enums';
import { isSandbox } from 'global';
import { FC, useEffect, useState } from 'react';
import { DashboardPromo as ContentfulDashboardPromo } from 'types';
import { logError, useContentful } from 'utils';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import * as styles from './PromoCarousel.styles';
import { useMerchantData } from 'contexts';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const PromoCarousel: FC = () => {
  const { getContent } = useContentful();
  const [content, setContent] = useState<ContentfulDashboardPromo[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { daysSinceMerchantCreation } = useMerchantData();

  const loadContent = async (): Promise<void> => {
    setIsLoading(true);
    try {
      setContent(
        (
          await getContent<ContentfulDashboardPromo>('dashboardPromo', {
            'fields.carouselOrder[gte]': '1',
          })
        )
          ?.filter((promo) => {
            // If rangeStart/rangeEnd are specified in Contentful, ensure: rangeStart <= daysSinceMerchantCreation <= rangeEnd
            const merchantCreatedWithinRangeIfSpecified =
              (promo.merchantCreationRangeStartInDays === undefined ||
                daysSinceMerchantCreation >=
                  promo.merchantCreationRangeStartInDays) &&
              (promo.merchantCreationRangeEndInDays === undefined ||
                daysSinceMerchantCreation <=
                  promo.merchantCreationRangeEndInDays);

            return merchantCreatedWithinRangeIfSpecified;
          })
          ?.sort((a, b) => a.carouselOrder - b.carouselOrder)
      );
    } catch (err) {
      logError(PageAction.fetchContentfulContent, err, {
        contentTypeId: 'dashboardPromo',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isSandbox) {
      loadContent();
    }
  }, []);

  const handleStepChange = (step: number): void => {
    setActiveStep(step);
  };

  return (
    <div css={styles.container}>
      {isLoading && <DashboardPromoSkeleton />}
      <AutoPlaySwipeableViews
        axis={'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {!isLoading &&
          content?.map((item) => (
            <DashboardPromo key={item.id} promo={item} carouselItem />
          ))}
      </AutoPlaySwipeableViews>
      <section css={styles.stepper}>
        {content.map((_, i) => (
          <button
            key={i}
            onClick={(): void => handleStepChange(i)}
            css={styles.stepButton(activeStep === i)}
          ></button>
        ))}
      </section>
    </div>
  );
};

export default PromoCarousel;
