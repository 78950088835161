/** @jsxImportSource @emotion/react */
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@material-ui/core';
import * as Icons from '@zip/react-icons/fearless';
import { reset } from '@amplitude/analytics-browser';
import { useAuth0 } from 'contexts';
import { FC, useState } from 'react';
import * as styles from './UserMenu.styles';

const UserMenu: FC = () => {
  const { logout } = useAuth0();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const profileOnClick = {
    logout: (): void => {
      reset();
      logout();
    },
    profile: (): void => null,
  };

  return (
    <>
      <Icons.UserCircle
        css={styles.userIcon}
        width={24}
        height={24}
        onClick={(e): void => setMenuAnchorEl(e.currentTarget)}
        data-testid="user-profile"
      />

      <MuiMenu
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 42,
          horizontal: 'right',
        }}
        MenuListProps={{
          disablePadding: true,
        }}
        getContentAnchorEl={null}
        onClose={(): void => setMenuAnchorEl(null)}
        css={styles.menu}
      >
        <MuiMenuItem
          onClick={(): void => {
            profileOnClick.logout();
            setMenuAnchorEl(null);
          }}
          css={styles.menuItem()}
          disableRipple
        >
          Log out
        </MuiMenuItem>
      </MuiMenu>
    </>
  );
};

export default UserMenu;
