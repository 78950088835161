import { css } from '@emotion/react';
import { theme } from 'global';

export const container = css`
  color: ${theme.colors.neutral[600]};
  background-color: #f9e3b9;

  padding: 5px 1rem;
  border-radius: 0px 0px ${theme.radii.lg} ${theme.radii.lg};
  user-select: none;

  position: fixed;
  width: fit-content;
  place-self: center;
  z-index: 2;

  margin: var(--header-height) auto 0px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const icon = css`
  display: flex;
  margin-left: 4px;
  width: 1rem;
  height: 1rem;
`;
