import { css } from '@emotion/react';
import { theme } from 'global';

export const container = css`
  background-color: ${theme.colors.white};
  padding: 12px 20px;
  z-index: 20;
  border-top: 1px solid ${theme.colors.neutral[300]};

  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;

  // responsive/10
  position: fixed;
  bottom: 0;
  left: var(--drawer-width);
  right: 0;

  @media screen and (max-width: ${theme.breaks.md}px) {
    left: 0;
  }
`;
