import { css } from '@emotion/react';
import { theme } from 'global';

const imageWidth = '228px';

export const container = css`
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid ${theme.colors.neutral[200]};
  @media screen and (max-width: ${theme.breaks.sm}px) {
    flex-direction: column;
  }
`;

export const imageContainer = css`
  max-width: ${imageWidth};
  @media screen and (max-width: ${theme.breaks.sm}px) {
    margin: 0 auto 24px;
  }
`;

export const image = css`
  width: ${imageWidth};
  user-select: none;
`;

export const contentContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 32px;
  max-width: 640px;
  & > #copy-container {
    user-select: none;
  }

  @media screen and (max-width: ${theme.breaks.sm}px) {
    margin-left: 0;
  }
`;

export const viewDates = (isOpen) => css`
  width: fit-content;
  display: flex;
  cursor: pointer;
  color: ${isOpen ? theme.colors.primary.medium : theme.colors.neutral[500]};
  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const actions = css`
  display: flex;
  flex-direction: row;
  & > button:not(:first-of-type) {
    margin-left: 12px;
  }
`;
