import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  background-color: ${theme.colors.white};
  max-width: 420px;
  padding: 8px;

  @media screen and (min-width: ${theme.breaks.sm}px) {
    padding: 32px;
    border-radius: 12px;
    margin: 0px auto;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 16px 16px;
  }
`;

export const actionBar = (flexDirection) => css`
  width: 100%;
  display: flex;
  flex-direction: ${flexDirection ?? 'row'};
  justify-content: space-between;
  padding: 16px 0;
`;

export const textfieldTitle = css`
  display: flex;
  justify-content: space-between;
`;

export const textfield = css`
  width: 100%;

  > .MuiInputBase-root > input[type='number']::-webkit-inner-spin-button,
  > .MuiInputBase-root > input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
