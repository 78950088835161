/** @jsxImportSource @emotion/react */
import { Buttons } from '@zip/business-components';
import { FileUpload } from 'components';
import { IdDocumentType, LocalStorageKey, PageAction } from 'enums';
import { useFormik } from 'formik';
import { Constants, css } from 'global';
import { FC, useState } from 'react';
import { ApplicationModuleProps, DirectorDocument } from 'types';
import { logEvent } from 'utils';
import * as styles from './DirectorDocument.styles';

interface DirectorDocumentForm {
  document: File[];
}

interface DirectorDocumentFormErrors {
  document?: string;
}

const DirectorDocumentModule: FC<ApplicationModuleProps> = ({
  applicationData,
  callback,
  back,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [idMatrixCount] = useState<number>(
    Number(sessionStorage.getItem(LocalStorageKey.IdMatrixCount))
  );

  const handleBackButton = (): void => {
    sessionStorage.setItem(
      LocalStorageKey.IdMatrixCount,
      (idMatrixCount + 1).toString()
    );
    back();
  };

  // 8MB
  const fileSizeLimit = 8388608;

  const formik = useFormik({
    initialValues: {
      document: null,
    },
    onSubmit: async (values) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      logEvent(PageAction.submitDirectorDocument);
      const directorDocument: DirectorDocument = {
        documentType: applicationData?.directorDetails?.documentType,
        licenseNumber: applicationData?.directorDetails?.licenseNumber,
        licenseCardNumber: applicationData?.directorDetails?.licenseCardNumber,
        passportNumber: applicationData?.directorDetails?.passportNumber,
        dateOfBirth: applicationData?.directorDetails?.dateOfBirth,
        document: values.document[0],
      };
      await callback(directorDocument);
      setIsLoading(false);
    },
    validate: (values: DirectorDocumentForm) => {
      const errors: DirectorDocumentFormErrors = {};

      if (!values?.document || values.document?.length < 1) {
        errors.document = 'Required';
      } else if (
        values?.document?.length > 0 &&
        values.document[0]?.size > fileSizeLimit
      ) {
        values.document.splice(0);
        errors.document = 'Maximum file size is 8MB';
      }

      return errors;
    },
  });

  const isSubmittingPassport =
    applicationData?.directorDetails?.documentType === IdDocumentType.Passport;
  const idImageAsset = isSubmittingPassport
    ? Constants.assets.pictorials.passport
    : Constants.assets.pictorials.driverLicence;
  const identityDocumentHelp = (
    <div css={styles.helpContent}>
      <img
        src={idImageAsset}
        alt={isSubmittingPassport ? 'passport icon' : 'licence icon'}
      />
      <br />
      <ol>
        <li>
          Use your smartphone to take a photo. Make sure the photo includes all
          4 corners and is readable without any reflections or glare.
        </li>
        <li>Send the photo to yourself via email.</li>
        <li>Download the photo file to your computer.</li>
        <li>
          Back on this page, click ‘Upload photo’ and locate the photo file to
          upload.
        </li>
      </ol>
      <h4 css={css.noBtmMrgn}>Alternatively</h4>
      <p>
        If your computer or tablet device has access to a web cam you can take a
        photo directly. Ensure the photo is clear and readable.
      </p>
    </div>
  );

  return (
    <>
      <h1>Document verification</h1>
      <p>
        Upload a clear photo of the director’s driver licence to match the
        details given.
      </p>
      <div css={styles.documentTypeTitle}>
        <p css={[css.noBtmMrgn, css.noTopMrgn]}>
          <strong>
            {isSubmittingPassport ? 'Passport' : 'Driver licence'} of
          </strong>{' '}
          <span className="fs-mask">
            {applicationData?.directorDetails?.firstName &&
              applicationData?.directorDetails?.lastName &&
              `${applicationData?.directorDetails?.firstName} ${applicationData?.directorDetails?.lastName}`}
          </span>
        </p>
      </div>
      <form
        onSubmit={(e): void => {
          setIsLoading(true);
          formik.handleSubmit(e);
          setIsLoading(false);
        }}
      >
        <FileUpload
          name="document"
          label="Upload photo"
          handleChange={(value): void => {
            formik.setFieldValue('document', value);
          }}
          onBlur={(): void => {
            formik.setFieldTouched('document');
          }}
          error={formik.touched.document && Boolean(formik.errors.document)}
          helperText={
            formik.touched.document &&
            formik.errors.document &&
            formik.errors.document?.toString()
          }
          helpContent={identityDocumentHelp}
        />
        <div css={css.onboardingConfirmButton}>
          <Buttons.Primary
            type="submit"
            disabled={
              Boolean(!Object.keys(formik.touched).length) ||
              Boolean(Object.keys(formik.errors).length)
            }
            loading={isLoading}
          >
            Submit
          </Buttons.Primary>

          <Buttons.Text onClick={handleBackButton}>Back</Buttons.Text>
        </div>
      </form>
    </>
  );
};

export default DirectorDocumentModule;
