/** @jsxImportSource @emotion/react */
import { css } from 'global';
import { FC } from 'react';
import * as styles from './TableCell.styles';
import { TableCellProps } from './TableCellProps';

const TableCell: FC<TableCellProps> = ({
  selected = false,
  onClick,
  maxWidth,
  children,
  ...props
}) => {
  return (
    <td
      css={
        maxWidth
          ? [css.tableCell(selected), styles.cellMaxWidth(maxWidth)]
          : css.tableCell(selected)
      }
      onClick={onClick}
      {...props}
    >
      {children}
    </td>
  );
};

export default TableCell;
