import { css } from '@emotion/react';

export const divider = css`
  margin-bottom: 32px;
`;

export const checkBoxWithLink = css`
  display: flex;
  flex-flow: row wrap;
`;
