/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { useSnackbar } from 'contexts';
import { OrderStatus, PageAction } from 'enums';
import { FC, useState } from 'react';
import { logError, logEvent, useOrderFunctions } from 'utils';
import { ResendOrderModuleProps } from './ResendOrderModuleProps';

const ResendOrderModule: FC<ResendOrderModuleProps> = ({
  open,
  toggleOpen,
  orderToAction,
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = (): void => toggleOpen(false);

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting) {
      return;
    }

    logEvent(PageAction.resendOrder, { orderToAction });
    setIsSubmitting(true);
    try {
      await OrderFunctions.resendOrder(orderToAction?.operationRequestId);
      handleClose();
      Snackbar.success('The order has been resent.');
    } catch (err) {
      logError(PageAction.resendOrder, err, { orderToAction });
      Snackbar.error('Something went wrong resending the order.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialogs.Basic
      id="resendDialog"
      open={open}
      title="Resend invitation"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            id="submitButton"
            onClick={handleSubmit}
            loading={isSubmitting}
          >
            Resend order
          </Buttons.Primary>

          <Buttons.Text id="cancelButton" onClick={handleClose}>
            Cancel
          </Buttons.Text>
        </>
      }
    >
      <p>
        {orderToAction?.status === OrderStatus.NotStarted
          ? "The customer hasn't opened the link to this order."
          : "The customer hasn't completed the order yet."}
      </p>
      <p>
        Would you like to resend an invitation for order{' '}
        <strong>{orderToAction?.orderReference}</strong>?
      </p>
    </Dialogs.Basic>
  );
};

export default ResendOrderModule;
