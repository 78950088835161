/* eslint-disable camelcase */
import { createClient } from 'contentful';
import { PageAction } from 'enums';
import { isProduction } from 'global';
import { useState } from 'react';

import useErrorHandler from './useErrorHandler';

interface ContentfulFunctions {
  getContent<T>(
    contentTypeId: string,
    query?: Record<string, string>
  ): Promise<T[]>;
}

export const useContentful = (
  spaceId?: string,
  accessToken?: string
): ContentfulFunctions => {
  const { handleError } = useErrorHandler();

  const [client] = useState(
    createClient({
      space: spaceId ?? `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
      environment: isProduction ? 'master' : 'sandbox', // defaults to 'master' if not set
      accessToken:
        accessToken ?? `${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    })
  );

  /**
   * Fetch all entries of the defined Content Model using
   * @param contentTypeId Id of the Content Model from Contentful
   * @param query Optional filters to apply to the fetch request. {@link https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters | Query docs}
   * @typeParam T - The expected interface for the results to be mapped to
   */
  async function getContent<T>(
    contentTypeId: string,
    query?: Record<string, string>
  ): Promise<T[]> {
    if (!contentTypeId) {
      throw new Error('ContentTypeId must be provided');
    }

    try {
      return await client
        .getEntries({
          content_type: contentTypeId,
          ...query,
        })
        .then((response) =>
          response.items.map((x) => x.fields as unknown as T)
        );
    } catch (err) {
      // @ts-ignore - Hope for a specific message, otherwise default to the parent error
      handleError(err?.message ?? err, PageAction.fetchContentfulContent, {
        contentTypeId,
      });
    }
  }

  return {
    getContent,
  };
};

export default useContentful;
