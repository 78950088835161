import { css } from '@emotion/react';

export const authPrompt = css`
  margin: 0 auto;
`;

export const authActions = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px 0;
`;
