/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid, Paper as MuiPaper } from '@material-ui/core';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import { Tooltip } from 'components';
import { css, theme } from 'global';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { quickActionFormatter } from 'utils';
import * as styles from './QuickAction.styles';
import { QuickActionProps } from './QuickActionProps';

export const Basic: FC<QuickActionProps> = ({
  actionType,
  Icon,
  path,
  onClick,
  tooltipProps = undefined,
  ...props
}) => {
  const history = useHistory();
  const hasTooltip = !!tooltipProps;
  function handleClick(): void {
    if (path) {
      history.push(path);
    }
    if (onClick) {
      onClick();
    }
  }
  const content = (
    <MuiPaper
      elevation={0}
      css={styles.card}
      onClick={handleClick}
      tabIndex={0}
      {...props}
    >
      <Icon css={styles.icon} fill={theme.colors.primary.medium} />
      <p css={[styles.paragraph, css.noTopMrgn]}>
        {quickActionFormatter(actionType)}
      </p>
    </MuiPaper>
  );
  return (
    <MuiGrid item xs={3} css={styles.cardContainer}>
      {hasTooltip ? (
        <Tooltip arrow maxWidth={326} {...tooltipProps}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </MuiGrid>
  );
};

export const Skeleton: FC = ({ ...props }) => {
  return (
    <MuiGrid item xs={3} css={styles.cardContainer}>
      <MuiPaper elevation={0} css={styles.skeletonCard} tabIndex={0} {...props}>
        <MuiSkeleton variant="circle" css={styles.icon} />
        <MuiSkeleton
          variant="text"
          css={[styles.paragraph, css.noTopMrgn]}
          style={{ height: 30 }}
        />
      </MuiPaper>
    </MuiGrid>
  );
};

export default Basic;
