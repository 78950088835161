/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs, TextFields } from '@zip/business-components';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { DateRange, DateRangeError } from 'types';
import { formikHasErrors } from 'utils/validators';
import { AddDateRangeDialogModuleProps } from './AddDateRangeDialogModuleProps';

const AddDateRangeFilterDialogModule: FC<AddDateRangeDialogModuleProps> = ({
  callback,
  onClose,
  open,
  fromDate,
  toDate,
}) => {
  const monthDiff = (d1, d2): number => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const formik = useFormik({
    initialValues: {
      fromDate: fromDate,
      toDate: toDate,
    },
    onSubmit: () => handleSubmit(),
    validate: (values: DateRange) => {
      const errors: DateRangeError = {};
      if (!values.fromDate) {
        errors.fromDate = 'Required';
      }
      if (!values.toDate) {
        errors.toDate = 'Required';
      }
      if (monthDiff(new Date(values.fromDate), new Date(values.toDate)) > 3) {
        errors.toDate = 'Date range cannot exceed three months';
      }
      return errors;
    },
    validateOnMount: false,
  });

  const handleClose = (e?, r?): void => {
    onClose(e, r);
  };

  const handleSubmit = async (): Promise<void> => {
    callback(formik.values.fromDate, formik.values.toDate);
    handleClose();
  };

  useEffect(() => {
    formik.setFieldValue('fromDate', fromDate);
    formik.setFieldValue('toDate', toDate);
  }, [fromDate, toDate]);

  return (
    <Dialogs.Basic
      open={open}
      title="Date range"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            type="submit"
            onClick={(): void => formik.handleSubmit()}
            data-testid="save-button"
            disabled={!formikHasErrors(formik)}
          >
            Search
          </Buttons.Primary>

          <Buttons.Text onClick={handleClose}>Cancel</Buttons.Text>
        </>
      }
      mobileFullscreen
    >
      <form onSubmit={formik.handleSubmit}>
        <TextFields.Date
          KeyboardButtonProps={{
            'aria-label': 'open calendar',
          }}
          id="fromDate"
          label="From"
          name="fromDate"
          error={Boolean(formik.errors.fromDate)}
          helperText={formik.errors.fromDate}
          onChange={(value): void => {
            // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
            formik.setFieldValue('fromDate', value.toDate());
          }}
          onBlur={formik.handleBlur}
          value={new Date(formik.values.fromDate)}
          disableToolbar={false}
          disableFuture
          views={['month', 'date']}
          autoOk
          fullWidth
          placeholder="DD/MM/YYYY"
        />
        <TextFields.Date
          KeyboardButtonProps={{
            'aria-label': 'open calendar',
          }}
          id="toDate"
          label="To"
          name="toDate"
          error={Boolean(formik.errors.toDate)}
          helperText={formik.errors.toDate}
          onChange={(value): void => {
            // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
            formik.setFieldValue('toDate', value.toDate());
          }}
          onBlur={formik.handleBlur}
          value={formik.values.toDate}
          disableToolbar={false}
          disableFuture
          views={['month', 'date']}
          autoOk
          fullWidth
          placeholder="DD/MM/YYYY"
        />
      </form>
    </Dialogs.Basic>
  );
};

export default AddDateRangeFilterDialogModule;
