/** @jsxImportSource @emotion/react */
import {
  Paper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import { Loading } from '@zip/business-components';
import { FC, useCallback, useLayoutEffect, useRef } from 'react';
import * as styles from './CompactTable.styles';
import { CompactTableProps } from './CompactTableProps';

const CompactTable: FC<CompactTableProps> = ({
  id = 'compact-table',
  columns,
  stickyHeader = false,
  children,
  ...props
}) => {
  return (
    <MuiTableContainer
      id={`${id}-container`}
      component={Paper}
      elevation={0}
      {...props}
    >
      <MuiTable id={id} stickyHeader={stickyHeader}>
        <MuiTableHead id={`${id}-thead`} css={styles.tableHead}>
          <MuiTableRow>
            {columns.map((column) => (
              <MuiTableCell key={column}>{column}</MuiTableCell>
            ))}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody id={`${id}-tbody`} css={styles.tableBody}>
          {children}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

interface InfiniteScrollTableProps extends Omit<CompactTableProps, 'ref'> {
  length: number;
  loadMore: () => void;
  hasMore: boolean;
  isLoading?: boolean;
  endMessage?: string;
  emptyMessage?: string;
}

export const InfiniteScrollTable: FC<InfiniteScrollTableProps> = ({
  id = 'compact-table',
  columns,
  stickyHeader = false,
  length,
  loadMore,
  hasMore = false,
  isLoading = false,
  endMessage = "That's all we can find.",
  emptyMessage = "We're turning up nothing.",
  children,
  ...props
}) => {
  const tableContainerRef = useRef<HTMLTableElement>();

  const scrollListener = useCallback(() => {
    if (!tableContainerRef.current || isLoading || !hasMore) {
      return;
    }

    const bottom =
      tableContainerRef?.current?.scrollHeight -
      tableContainerRef?.current?.clientHeight;

    if (tableContainerRef.current.scrollTop >= bottom) {
      loadMore();
    }
  }, [hasMore, loadMore, isLoading]);

  useLayoutEffect(() => {
    const tableRef = tableContainerRef.current;
    tableRef.addEventListener('scroll', scrollListener);
    return (): void => tableRef.removeEventListener('scroll', scrollListener);
  }, [scrollListener]);

  return (
    <MuiTableContainer
      id={`${id}-container`}
      component={Paper}
      elevation={0}
      ref={tableContainerRef}
      {...props}
    >
      <MuiTable id={id} stickyHeader={stickyHeader}>
        <MuiTableHead id={`${id}-thead`} css={styles.tableHead}>
          <MuiTableRow>
            {columns.map((column) => (
              <MuiTableCell key={column}>{column}</MuiTableCell>
            ))}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody id={`${id}-tbody`} css={styles.tableBody}>
          {children}
        </MuiTableBody>
        <MuiTableFooter>
          {isLoading && (
            <MuiTableRow>
              <MuiTableCell
                colSpan={columns?.length ?? 1}
                css={styles.endMessage}
              >
                <Loading width={80} height={30} />
              </MuiTableCell>
            </MuiTableRow>
          )}
          {!isLoading && length === 0 && (
            <MuiTableRow>
              <MuiTableCell
                colSpan={columns?.length ?? 1}
                css={styles.endMessage}
              >
                <span className="body4">{emptyMessage}</span>
              </MuiTableCell>
            </MuiTableRow>
          )}
          {!isLoading && !hasMore && length > 0 && (
            <MuiTableRow>
              <MuiTableCell
                colSpan={columns?.length ?? 1}
                css={styles.endMessage}
              >
                <span className="body4">{endMessage}</span>
              </MuiTableCell>
            </MuiTableRow>
          )}
        </MuiTableFooter>
      </MuiTable>
    </MuiTableContainer>
  );
};

export default CompactTable;
