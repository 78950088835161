// The ordering here determines the display order in the InnerDrawer
export enum CustomerStatus {
  Active = 'active', // hidden
  AllInvitedCustomers = 'allinvitedcustomers', // keep in index 1
  Registered = 'registered',
  ApplicationInProgress = 'applicationinprogress',
  ApplicationCompleted = 'applicationcompleted',
  Refer1 = 'refer1',
  Verify = 'verify',
  Approved = 'approved',
  Declined = 'declined',
  Cancelled = 'cancelled',
}
