/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper, useMediaQuery, useTheme } from '@material-ui/core';
import { css } from 'global';
import { FC, useEffect } from 'react';
import * as styles from './SendGuide.styles';
import PhoneInput from 'components/phone-input';
import CopyButton from 'components/copy-button';
import { SendGuideContent, SendGuideProps } from './SendGuideProps';
import { useFetch } from 'hooks';
import { logError, logEvent } from 'utils';
import { PageAction } from 'enums';
import { useSnackbar } from 'contexts';

const SendGuideModule: FC<SendGuideProps> = ({
  category,
  merchantUniqueId,
}) => {
  const Snackbar = useSnackbar();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const { post } = useFetch();

  const contentDefinition: SendGuideContent[] = [
    {
      category: 'vet',
      subHeading:
        "Answer your pet owners' questions by sharing our Path-to Purchase guide below",
      placeholder: isMobile ? 'Phone Number' : "Pet owners' Phone Number",
      text: 'Unlock more sales for your business by supporting your pet owners in understanding how Zip works.',
      path: '/au/pos/zip-money-vet',
    },
    {
      category: 'dental',
      subHeading:
        "Answer your patients' questions about Zip by sharing our Path-to Purchase guide below",
      placeholder: isMobile ? 'Phone Number' : "Patients' Phone Number",
      text: 'Unlock more sales for your business by supporting your patients in understanding how Zip works.',
      path: '/au/pos/zip-money-dental',
    },
  ];

  const content = contentDefinition.find((element) => {
    return element.category === category;
  });

  const baseUrl = process.env.REACT_APP_ZIP_URL;
  const link = `${baseUrl}${content.path}?m=${merchantUniqueId}`;

  useEffect(() => {
    logEvent(PageAction.purchantPlan, {
      category: category,
      merchantUniqueId: merchantUniqueId,
    });
  }, []);

  async function sendGuide(mobilePhone: string): Promise<void> {
    try {
      post('/customer/send-plan-to-purchase', { mobilePhone });

      logEvent(PageAction.clickedPurchasePlanSend, {
        category: category,
        merchantUniqueId: merchantUniqueId,
      });
    } catch (err) {
      logError(PageAction.clickedPurchasePlanSend, err, {
        category: category,
        merchantUniqueId: merchantUniqueId,
      });

      Snackbar.error(
        'An error occured processing your request. Please try again.'
      );

      throw err;
    }
  }

  function postCopyGuideLink(): void {
    logEvent(PageAction.clickedPurchasePlanCopy, {
      category: category,
      merchantUniqueId: merchantUniqueId,
    });
  }

  return (
    <MuiPaper elevation={0} css={styles.container}>
      <div css={css.moduleTitleBar}>
        <span className="title4" css={styles.heading}>
          Path to Purchase Guide
        </span>
      </div>
      <span className="body2" css={styles.subHeading}>
        {content.subHeading}
      </span>
      <div css={styles.input}>
        <PhoneInput
          placeholder={content.placeholder}
          preButtonText={'Send guide'}
          postButtonText={'Guide sent'}
          onSubmit={sendGuide}
        />
        {!isMobile && (
          <CopyButton
            value={link}
            preButtonText="Copy link"
            postButtonText="Copied"
            variant="primary"
            postOnClick={postCopyGuideLink}
          />
        )}
      </div>
      <span className="body4">{content.text}</span>
      <div>
        {isMobile && (
          <CopyButton
            value={link}
            preButtonText="Copy link"
            postButtonText="Copied"
            variant="text"
            postOnClick={postCopyGuideLink}
          />
        )}
      </div>
    </MuiPaper>
  );
};

export default SendGuideModule;
