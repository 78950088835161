/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper, useMediaQuery, useTheme } from '@material-ui/core';
import { Buttons, TextFields } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import {
  Banners,
  BasePage,
  DenseButton,
  SidebarRef,
  Sidebars,
} from 'components';
import { useMerchantData } from 'contexts';
import {
  FeatureFlag,
  MerchantClassSettingType,
  OrderSearchType,
  OrderStatus,
  PageRoute,
  PermissionEnum,
} from 'enums';
import { Constants, css, isSandbox } from 'global';
import {
  AddDateRangeFilterDialogModule,
  AddProductDetailsDialogModule,
  CancelOrderModule,
  CompleteOrderModule,
  DeleteOrderModule,
  OrdersTableModule,
  OrdersTableRef,
  RefundOrderModule,
  RemindCustomerModule,
  ResendOrderModule,
} from 'modules';
import { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  OrderActionOption,
  OrderDetails,
  OrderSummary,
  OrderToAction,
  PageProps,
  ProductDetails,
} from 'types';
import { hasDateExpired } from 'utils/validators';

import { useDecision } from '@optimizely/react-sdk';
import * as styles from './OrdersPage.styles';

function parseOrderIdParam(input: string): string {
  const parsed = parseInt(input);
  if (isNaN(parsed)) {
    return;
  }
  return input;
}

const OrdersPage: FC<PageProps> = ({ innerDrawerCallback }) => {
  const { checkPermission, checkMerchantDashboardSetting } = useMerchantData();
  const { status, orderId } = useParams<{ status: string; orderId: string }>();
  const { push, location } = useHistory<{ orderId: string }>();
  const { breakpoints } = useTheme();

  const [expiresSoonDecision] = useDecision(FeatureFlag.EnableExpiresSoon);
  const showExpiresSoonExplainer =
    (status === 'expires-soon' &&
      expiresSoonDecision?.enabled &&
      expiresSoonDecision?.variables?.showExplainer) ??
    false;

  const currentDate = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' })
  );
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const showDatePickerDialog = checkMerchantDashboardSetting(
    MerchantClassSettingType.ShowDatePickerDialogInOrdersPage
  );
  const ordersTableRef = useRef<OrdersTableRef>();
  const sidebarRef = useRef<SidebarRef>();

  const parsedOrderId = parseOrderIdParam(location?.state?.orderId ?? orderId);
  const [searchValue, setSearchValue] = useState<string>(parsedOrderId);
  const [searchType, setSearchType] = useState<OrderSearchType>(
    parsedOrderId
      ? OrderSearchType.ZipTransactionReceipt
      : OrderSearchType.NoFilter
  );

  const [fromDate, setFromDate] = useState<Date>(
    new Date(
      new Date().setMonth(
        new Date(
          new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' })
        ).getMonth() - 3
      )
    )
  );
  const [toDate, setToDate] = useState<Date>(currentDate);

  const [selectedOrderId, setSelectedOrderId] = useState<number>(
    Number(parsedOrderId) ?? null
  );
  const [selectedProductDetail, setSelectedProductDetail] =
    useState<ProductDetails>(null);
  const [orderToAction, setOrderToAction] = useState<OrderToAction>();

  const orderSearchTypes = [
    { label: 'No filter', value: OrderSearchType.NoFilter },
    {
      label: 'Order Id',
      value: OrderSearchType.MerchantOrderId,
    },
    {
      label: 'Reference',
      value: OrderSearchType.Reference,
    },
    {
      label: 'Zip Receipt number',
      value: OrderSearchType.ZipTransactionReceipt,
    },
    { label: 'Last name', value: OrderSearchType.LastName },
    {
      label: 'Email',
      value: OrderSearchType.EmailAddress,
    },
    {
      label: 'Mobile',
      value: OrderSearchType.MobileNumber,
    },
  ];

  const handleSearchValueChange = (e): void => setSearchValue(e?.target?.value);
  const handleSearchTypeChange = (e): void => setSearchType(e?.target?.value);

  const handleSidebarClose = (): void => {
    const newPath = status
      ? `${PageRoute.Orders}/status/${status}`
      : PageRoute.Orders;
    push(newPath);
    setSelectedOrderId(null);
    setSelectedProductDetail(null);
  };

  // Dialogs
  const [isAddProductDetailsDialogOpen, setIsAddProductDetailsDialogOpen] =
    useState<boolean>(false);
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] =
    useState<boolean>(false);
  const [isDeleteOrderDialogOpen, setIsDeleteOrderDialogOpen] =
    useState<boolean>(false);
  const [isResendOrderDialogOpen, setIsResendOrderDialogOpen] =
    useState<boolean>(false);
  const [isRemindCustomerDialogOpen, setIsRemindCustomerDialogOpen] =
    useState<boolean>(false);
  const [isCompleteOrderDialogOpen, setIsCompleteOrderDialogOpen] =
    useState<boolean>(false);
  const [isCancelOrderDialogOpen, setIsCancelOrderDialogOpen] =
    useState<boolean>(false);
  const [isRefundOrderDialogOpen, setIsRefundOrderDialogOpen] =
    useState<boolean>(false);

  const handleCreateOrderClick = (): void => push(PageRoute.CreateOrder);

  const handleOpenDatePickerClick = (): void => setIsDatePickerDialogOpen(true);

  const handleSuccess = (): void => {
    if (selectedOrderId) {
      sidebarRef?.current?.getOrderDetails(selectedOrderId);
    }
    ordersTableRef?.current?.getOrders(0);
  };
  const setFromAndToDate = (from: Date, to: Date): void => {
    setFromDate(from);
    setToDate(to);
  };

  // shared between sidebar and table
  function getOrderActions(
    order: OrderSummary | OrderDetails
  ): OrderActionOption[] {
    const options: OrderActionOption[] = [];
    const orderReference = order?.orderReference ?? order?.merchantOrderId;
    const status =
      // @ts-ignore - covers both OrderDetails and OrderSummary
      order?.orderStatus?.toLowerCase() ?? order?.status?.toLowerCase();
    // @ts-ignore - covers both OrderDetails and OrderSummary
    const operationRequestId = order?.id ?? selectedOrderId;
    const branchId: number = order?.branchId;

    const canResendOrder =
      checkPermission(PermissionEnum.CreateInviteExecute) &&
      (status === OrderStatus.NotStarted || status === OrderStatus.InProgress);
    if (canResendOrder) {
      options.push({
        label: 'Resend invitation',
        onClick: () => {
          setOrderToAction({ operationRequestId, orderReference, status });
          setIsResendOrderDialogOpen(true);
        },
        highlight: order?.shouldSendOrderNotification,
      });
    }

    const canRemindCustomer =
      checkPermission(PermissionEnum.CreateInviteExecute) &&
      (status === OrderStatus.UnderReview ||
        status === OrderStatus.ContractPending);
    if (canRemindCustomer) {
      options.push({
        label: 'Remind customer',
        onClick: () => {
          setOrderToAction({ operationRequestId, orderReference, status });
          setIsRemindCustomerDialogOpen(true);
        },
        highlight: order?.shouldSendOrderNotification,
      });
    }

    const canCompleteOrder =
      checkPermission(PermissionEnum.CompleteOrderExecute) &&
      (status === OrderStatus.Authorised ||
        status === OrderStatus.PartiallyCaptured);
    if (canCompleteOrder) {
      options.push({
        label: 'Complete',
        onClick: () => {
          setOrderToAction({ operationRequestId, orderReference, status });
          setIsCompleteOrderDialogOpen(true);
        },
      });
    }

    const canRefund =
      checkPermission(PermissionEnum.RefundOrderExecute) &&
      (status === OrderStatus.Completed ||
        status === OrderStatus.PartiallyCaptured ||
        status === OrderStatus.Refunded);
    if (canRefund) {
      options.push({
        label: 'Refund',
        onClick: () => {
          setOrderToAction({
            operationRequestId,
            orderReference,
            branchId,
            status,
          });
          setIsRefundOrderDialogOpen(true);
        },
      });
    }

    const canAddProductDetails: boolean =
      order?.brand &&
      !order?.productDetails &&
      !selectedProductDetail &&
      status !== OrderStatus.Cancelled &&
      status !== OrderStatus.Declined &&
      status !== OrderStatus.Refunded;
    if (canAddProductDetails) {
      options.push({
        label: 'Add product details',
        onClick: () => {
          setOrderToAction({
            operationRequestId,
            orderReference,
            status,
          });
          setIsAddProductDetailsDialogOpen(true);
        },
      });
    }

    const canCancelOperationRequest =
      checkPermission(PermissionEnum.CreateOrderExecute) &&
      (status === OrderStatus.NotStarted ||
        status === OrderStatus.InProgress ||
        status === OrderStatus.UnderReview ||
        status === OrderStatus.ContractPending);
    if (canCancelOperationRequest) {
      options.push({
        label: 'Cancel',
        onClick: () => {
          setOrderToAction({ operationRequestId, orderReference, status });
          setIsCancelOrderDialogOpen(true);
        },
      });
    }

    const canCancelOrder =
      checkPermission(PermissionEnum.CreateOrderExecute) &&
      (status === OrderStatus.Authorised ||
        status === OrderStatus.PartiallyCaptured);
    if (canCancelOrder) {
      options.push({
        label:
          status === OrderStatus.PartiallyCaptured
            ? 'Cancel outstanding amount'
            : 'Cancel',
        onClick: () => {
          setOrderToAction({ operationRequestId, orderReference, status });
          setIsCancelOrderDialogOpen(true);
        },
      });
    }

    const canRemoveOrder =
      checkPermission(PermissionEnum.CreateOrderExecute) &&
      (status === OrderStatus.Cancelled || status === OrderStatus.Declined);
    if (canRemoveOrder) {
      options.push({
        label: 'Delete',
        onClick: () => {
          setOrderToAction({ operationRequestId, orderReference, status });
          setIsDeleteOrderDialogOpen(true);
        },
      });
    }

    return options;
  }

  // listen to escape key to close sidebar
  useEffect(function setupListener() {
    function closeOrder(e: { code: string }): void {
      if (orderId && e.code === `Escape`) {
        const newPath = status
          ? `${PageRoute.Orders}/status/${status}`
          : PageRoute.Orders;
        push(newPath);
        setSelectedOrderId(null);
        setSelectedProductDetail(null);
      }
    }
    document.addEventListener('keyup', closeOrder);

    return (): void => document.removeEventListener('keyup', closeOrder);
  }, []);

  // Button to create order that is moved for mobile view
  const createOrderButton = (
    <Buttons.Secondary
      css={styles.createOrderButton(!!selectedOrderId)}
      onClick={handleCreateOrderClick}
    >
      <Icons.Plus width={20} height={20} />
      <span id="buttonLabel">Create order</span>
    </Buttons.Secondary>
  );

  // Button to open a calendar dialog if it is enabled in MST
  const openDatetimeDialogButton = (
    <Buttons.Secondary
      css={styles.createDatetimePickerButton()}
      fullWidth={isMobile}
      onClick={handleOpenDatePickerClick}
    >
      <Icons.Calendar width={20} height={20} />
      <span id="dateTimeDialogButtonLabel">Select date</span>
    </Buttons.Secondary>
  );

  /** Redirect if merchant is excluded from expiry */
  useEffect(() => {
    if (
      status === 'expires-soon' &&
      checkMerchantDashboardSetting(
        MerchantClassSettingType.ExcludeFromAuthChargeExpiry
      )
    ) {
      push(PageRoute.Orders);
    }
  }, [status]);

  return (
    <BasePage
      title="Orders"
      variant="layout"
      fullWidth
      fullHeight
      flex
      action={isMobile && createOrderButton}
      bannerProps={
        // Do not show in sandbox
        !isSandbox &&
        // Do not show past expiryDate
        !hasDateExpired(Constants.expiryDate.orderReminderBanner)
          ? {
              promoId: 'order-remind',
              title: 'Waiting on the customer?',
              content:
                'You can now remind customers to take action on orders that are Under Review or have a Contract Pending using the Action menu.',
              variant: 'consumer',
              image: Constants.assets.animation.remindCustomer,
            }
          : undefined
      }
    >
      <MuiPaper
        elevation={0}
        css={styles.ordersTableContainer(!!selectedOrderId)}
      >
        {showExpiresSoonExplainer && (
          <Banners.Notice
            css={styles.expiresSoonExplainer}
            title="Uncaptured order notifications and FAQs"
            content={
              <>
                Visit{' '}
                <a href={Constants.helpArticles.expiryFaq} target="_blank">
                  our FAQs
                </a>{' '}
                for more information on authorised and partially captured
                orders.{' '}
                <a
                  href="#"
                  onClick={(): void =>
                    push(`${PageRoute.Settings}${PageRoute.Notifications}`)
                  }
                >
                  Opt-in to reminders
                </a>{' '}
                to stay updated of your orders' status.
              </>
            }
            dense
          />
        )}
        <div id="toolbar" css={styles.toolbar}>
          <span>
            <TextFields.Outlined
              id="searchValue"
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchValueChange}
              css={styles.input}
              startIcon={<Icons.Search />}
              endIcon={
                searchValue && (
                  <Icons.Close
                    css={css.clearIcon}
                    onClick={handleSearchValueChange}
                  />
                )
              }
              fullWidth={isMobile}
            />
            <TextFields.Select
              id="searchType"
              label="Search type"
              options={orderSearchTypes}
              value={searchType}
              onChange={handleSearchTypeChange}
              css={styles.input}
              fullWidth={isMobile}
            />
            {showDatePickerDialog && openDatetimeDialogButton}
            {isMobile && (
              <DenseButton
                id="searchStatusFilter"
                ButtonIcon={status ? Icons.Check : Icons.Filter}
                onClick={innerDrawerCallback}
                fullWidth
              >
                Filter statuses
              </DenseButton>
            )}
          </span>
          {!isMobile &&
            checkPermission(PermissionEnum.CreateOrderExecute) &&
            createOrderButton}
        </div>
        {/* 
            Both default From and To Date will be supplied if the showDatePickerDialog is true
            params can be able to be changed by the datepicker
          */}
        <OrdersTableModule
          ref={ordersTableRef}
          searchValue={searchValue}
          fromDate={showDatePickerDialog ? fromDate : undefined}
          toDate={showDatePickerDialog ? toDate : undefined}
          searchType={searchType}
          selectedOrderId={selectedOrderId}
          setSelectedOrderId={setSelectedOrderId}
          setSelectedProductDetail={setSelectedProductDetail}
          getOrderActions={getOrderActions}
        />
      </MuiPaper>

      <Sidebars.Order
        ref={sidebarRef}
        orderId={selectedOrderId}
        productDetails={selectedProductDetail}
        onClose={handleSidebarClose}
        getOrderActions={getOrderActions}
        onSuccess={handleSuccess}
      />

      <AddProductDetailsDialogModule
        open={isAddProductDetailsDialogOpen}
        onClose={(): void => setIsAddProductDetailsDialogOpen(false)}
        orderToAction={orderToAction}
        onSuccess={handleSuccess}
      />

      {showDatePickerDialog && (
        <AddDateRangeFilterDialogModule
          open={isDatePickerDialogOpen}
          fromDate={fromDate}
          toDate={toDate}
          onClose={(): void => setIsDatePickerDialogOpen(false)}
          callback={setFromAndToDate}
        />
      )}

      <CancelOrderModule
        open={isCancelOrderDialogOpen}
        toggleOpen={setIsCancelOrderDialogOpen}
        orderToAction={orderToAction}
        onSuccess={handleSuccess}
      />
      <DeleteOrderModule
        open={isDeleteOrderDialogOpen}
        toggleOpen={setIsDeleteOrderDialogOpen}
        orderToAction={orderToAction}
        onSuccess={handleSuccess}
      />
      <ResendOrderModule
        open={isResendOrderDialogOpen}
        toggleOpen={setIsResendOrderDialogOpen}
        orderToAction={orderToAction}
      />
      <RemindCustomerModule
        open={isRemindCustomerDialogOpen}
        toggleOpen={setIsRemindCustomerDialogOpen}
        orderToAction={orderToAction}
        onSuccess={handleSuccess}
      />
      <CompleteOrderModule
        open={isCompleteOrderDialogOpen}
        toggleOpen={setIsCompleteOrderDialogOpen}
        orderToAction={orderToAction}
        onSuccess={handleSuccess}
      />
      <RefundOrderModule
        open={isRefundOrderDialogOpen}
        toggleOpen={setIsRefundOrderDialogOpen}
        orderToAction={orderToAction}
        onSuccess={handleSuccess}
      />
    </BasePage>
  );
};

export default OrdersPage;
