/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { FC, useState } from 'react';
import { logError, logEvent, useOrderFunctions } from 'utils';
import { DeleteOrderModuleProps } from './DeleteOrderModuleProps';

const DeleteOrderModule: FC<DeleteOrderModuleProps> = ({
  open,
  toggleOpen,
  orderToAction,
  onSuccess,
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = (): void => toggleOpen(false);

  const handleSubmit = async (): Promise<void> => {
    if (isSubmitting) {
      return;
    }

    logEvent(PageAction.removeOrder, { orderToAction });
    setIsSubmitting(true);
    try {
      await OrderFunctions.removeOrder(orderToAction?.operationRequestId);
      handleClose();
      Snackbar.success('Order was deleted successfully.');
      onSuccess();
    } catch (err) {
      logError(PageAction.removeOrder, err, { orderToAction });
      Snackbar.error('Something went wrong deleting the order.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialogs.Basic
      id="deleteOrderDialog"
      open={open}
      title="Delete order"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            id="submitButton"
            onClick={handleSubmit}
            loading={isSubmitting}
          >
            Delete order
          </Buttons.Primary>

          <Buttons.Text id="cancelButton" onClick={handleClose}>
            Cancel
          </Buttons.Text>
        </>
      }
    >
      <p>
        Are you sure you wish to remove order{' '}
        <strong>{orderToAction?.orderReference}</strong> from your dashboard?
      </p>
    </Dialogs.Basic>
  );
};

export default DeleteOrderModule;
