/** @jsxImportSource @emotion/react */
import { Spinner } from '@zip/business-components';
import { BasePage, DashboardPromo } from 'components';
import { useMerchantData } from 'contexts';
import { css, isSandbox } from 'global';
import { FC, useEffect, useState } from 'react';
import { DashboardPromo as ContentfulDashboardPromo } from 'types';
import { useContentful } from 'utils';

const OffersPage: FC = () => {
  const { getContent } = useContentful();
  const { isMerchantDisabled, daysSinceMerchantCreation } = useMerchantData();
  const [offers, setOffers] = useState<ContentfulDashboardPromo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(!isSandbox);

  const getOffers = async (): Promise<void> => {
    setIsLoading(true);
    try {
      setOffers(
        (
          await getContent<ContentfulDashboardPromo>('dashboardPromo', {
            'fields.pageOrder[gte]': '1',
          })
        )
          ?.filter((promo) => {
            // If rangeStart/rangeEnd are specified in Contentful, ensure: rangeStart <= daysSinceMerchantCreation <= rangeEnd
            const merchantCreatedWithinRangeIfSpecified =
              (promo.merchantCreationRangeStartInDays === undefined ||
                daysSinceMerchantCreation >=
                  promo.merchantCreationRangeStartInDays) &&
              (promo.merchantCreationRangeEndInDays === undefined ||
                daysSinceMerchantCreation <=
                  promo.merchantCreationRangeEndInDays);

            return merchantCreatedWithinRangeIfSpecified;
          })
          ?.sort((a, b) => a.pageOrder - b.pageOrder)
      );
    } catch (err) {
      setOffers([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isMerchantDisabled && !isSandbox) {
      getOffers();
    }
  }, []);

  return (
    <BasePage
      title="Offers for you"
      variant="layout"
      fullWidth
      sandboxAlert="Our merchant offers will be available once your application is approved"
    >
      {isMerchantDisabled && (
        <>
          <p css={css.noTopMrgn}>
            Your account is currently <strong>disabled</strong> and not eligible
            for offers.
          </p>
          <p css={css.noBtmMrgn}>
            Please contact our support team for further assistance.
          </p>
        </>
      )}
      {!isMerchantDisabled && (
        <>
          {isLoading && <Spinner />}
          {!isLoading &&
            (offers?.length > 0 ? (
              offers?.map((promo) => (
                <DashboardPromo key={promo.id} promo={promo} />
              ))
            ) : (
              <p css={css.noMargin}>No offers are currently available</p>
            ))}
        </>
      )}
    </BasePage>
  );
};

export default OffersPage;
