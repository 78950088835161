export enum MessageConfigId {
  CustomerUnderReview = 37,
  CustomerDeclined = 39,
  OrderAuthorised = 40,
  OrderCompleted = 41,
  OrderExpiring = 109,
  OrderRefunded = 42,
  OrderCancelled = 43,
  CustomerApproved = 60,
}
