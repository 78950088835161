/** @jsxImportSource @emotion/react */
import { Labels, Menu } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Tooltip } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import {
  CliRequestStatus,
  CustomerStatus,
  MerchantClassSettingType,
  OrderStatus,
  PageAction,
} from 'enums';
import { Constants, css } from 'global';
import { FC } from 'react';
import {
  currencyFormatter,
  dateFormatter,
  formatNumberOfDays,
  getStatusVariant,
  logEvent,
  productFormatter,
  statusFormatter,
  toTitleCase,
} from 'utils';
import TableCell from '../table-cell';
import * as styles from './TableRow.styles';
import {
  CustomerDetailsRowProps,
  OrderDetailsRowProps,
  UserDetailsRowProps,
} from './TableRowProps';

export const Customer: FC<CustomerDetailsRowProps> = ({
  customer,
  onClick,
  selected = false,
  isCliInvite = false,
}) => {
  const statusInformation = (status: string): JSX.Element => {
    function getInfo(status: string): string {
      switch (String(status)?.toLowerCase()) {
        case CustomerStatus.Active:
        case CustomerStatus.Registered:
        case CliRequestStatus.Accepted:
          return 'The customer is set up and able to transact using Zip.';
        case CustomerStatus.ApplicationInProgress:
        case CliRequestStatus.InProgress:
          return 'The customer has started their application but are yet to complete it.';
        case CustomerStatus.ApplicationCompleted:
          return 'The customer has submitted their application and are awaiting an outcome from our assessment team.';
        case CustomerStatus.Refer1:
          return "The customer's application is being reviewed by our team. The customer will be sent an email with the outcome within 10 minutes of submitting the application (during working hours).";
        case CustomerStatus.Approved:
        case CliRequestStatus.Approved:
          return 'The customer has been approved! They just need to sign into their account to complete the set up.';
        case CustomerStatus.Declined:
        case CliRequestStatus.Declined:
          return "The customer's application was unsuccessful. You can find more information here.";
        case CustomerStatus.Cancelled:
        case CliRequestStatus.Cancelled:
          return 'The application has been cancelled.';
        case CliRequestStatus.Submitted:
          return 'The customer has submitted their request and are awaiting an outcome from our assessment team.';
        default:
          return 'Please contact Zip for further information';
      }
    }
    return (
      <div>
        {getInfo(status)}
        <br />
        <div style={{ textAlign: 'right' }}>
          <a
            href={
              String(status)?.toLowerCase() === CustomerStatus.Declined
                ? 'https://help.zip.co/hc/en-us/articles/360001592436'
                : 'https://help.zip.co/hc/en-us/articles/4407107398543'
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more
          </a>
        </div>
      </div>
    );
  };

  // @ts-expect-error - nullables to handle CustomerDetail or CliRequest models
  const date = customer?.statusDate ?? customer?.updatedDateTime;

  return (
    <tr key={customer?.consumerId} css={css.row(selected, isCliInvite)}>
      <TableCell
        selected={selected}
        onClick={onClick}
        css={css.ellipsisOverflow}
        maxWidth="150px"
      >
        <span className="fs-mask">{customer?.consumerName}</span>
      </TableCell>
      <TableCell selected={selected} onClick={onClick}>
        <Tooltip
          content={statusInformation(customer?.status)}
          interactive
          dense
          arrow
          maxWidth={230}
          style={{ width: 'fit-content' }}
          placement="bottom-start"
        >
          <Labels.Basic
            variant={getStatusVariant(customer?.status)}
            label={
              <>
                {statusFormatter(customer?.status)}{' '}
                <Icons.InformationCircle css={styles.statusInfoIcon} />
              </>
            }
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </TableCell>
      <TableCell selected={selected} onClick={onClick}>
        {dateFormatter(date, 'day')}
      </TableCell>
      <TableCell selected={selected} onClick={onClick}>
        {productFormatter(customer?.productClassification)}
      </TableCell>
    </tr>
  );
};

export const User: FC<UserDetailsRowProps> = ({
  user,
  onClick,
  selected = false,
  actions = [],
}) => {
  const { success } = useSnackbar();
  const { isCompanyUser } = useMerchantData();
  const noActionsAvailableJsx = (
    <>
      You are not able to modify this user, please contact Zip to edit this
      user.
    </>
  );
  return (
    <tr key={user?.id} css={css.row(selected)}>
      <TableCell
        selected={selected}
        onClick={onClick}
        css={css.ellipsisOverflow}
        maxWidth="150px"
      >
        <span className="fs-mask">
          {user?.firstName} {user?.lastName}
        </span>
      </TableCell>
      {isCompanyUser && (
        <TableCell selected={selected} onClick={onClick}>
          {user.userType}
        </TableCell>
      )}
      <TableCell selected={selected} onClick={onClick}>
        {user.roleName}
      </TableCell>
      <TableCell selected={selected} onClick={onClick} maxWidth="150px">
        <Tooltip
          content={
            <>
              <span className="fs-mask">{user.email} </span>
              <Icons.Copy
                css={{ cursor: 'pointer' }}
                onClick={async (): Promise<void> => {
                  await navigator.clipboard.writeText(user?.email);
                  success('Copied email to clipboard');
                }}
              />
            </>
          }
          dense
          arrow
          interactive
          maxWidth={260}
        >
          <span css={css.ellipsisOverflow} className="fs-mask">
            {user.email}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell selected={selected} onClick={onClick}>
        <Labels.Basic
          variant={user.blocked ? 'error' : 'success'}
          label={user.blocked ? 'Disabled' : 'Active'}
          style={{ cursor: 'pointer' }}
        />
      </TableCell>
      <TableCell selected={selected} onClick={onClick}>
        <Tooltip
          content={(actions?.length < 1 && noActionsAvailableJsx) || ''}
          maxWidth={260}
          dense
        >
          <Menu
            id="userActionsMenu"
            label="Actions"
            options={actions}
            dense
            disabled={actions?.length < 1}
          />
        </Tooltip>
      </TableCell>
    </tr>
  );
};

export const Order: FC<OrderDetailsRowProps> = ({
  order,
  showExpiry = false,
  onClick,
  selected = false,
  sidebarOpen = false,
  actions = [],
}) => {
  const { checkMerchantDashboardSetting } = useMerchantData();
  const showReceipt = checkMerchantDashboardSetting(
    MerchantClassSettingType.ShowReceiptInOrdersTable
  );
  const { shouldSendOrderNotification } = order;
  const statusInformation = (status: string): JSX.Element => {
    function getInfo(status: string): string {
      switch (String(status)?.toLowerCase()) {
        case OrderStatus.NotStarted:
          return "The customer has been sent the link for the order, but they haven't yet opened it.";
        case OrderStatus.InProgress:
          return "The customer has started the order, but they haven't yet completed it.";
        case OrderStatus.UnderReview:
          return "We're reviewing the customer's application, which should take 10 minutes during working hours. If the order status remains unchanged after this time, we may need additional details from the customer to proceed.";
        case OrderStatus.ContractPending:
          return 'The customer has been approved but has not yet accepted their contract. To do so, they need to log in to their account and accept the Terms & Conditions.';
        case OrderStatus.Authorised:
          return 'The order has been approved by Zip and authorised by the customer. If the goods or services are ready, you can take a deposit or complete the order in the action menu. This is necessary in order to be paid.';
        case OrderStatus.PartiallyCaptured:
          return 'A partial amount of the order has been completed. You can capture an additional or full order amount in the action menu. This is necessary in order to be paid.';
        case OrderStatus.Completed:
          return 'The order is completed and the goods or services have been given to the customer. You will receive your disbursement for the order the following business day.';
        case OrderStatus.Refunded:
          return 'The order has been refunded. No further actions are available.';
        case OrderStatus.Cancelled:
          return 'The order has been cancelled.';
        case OrderStatus.Expired:
          return 'The order has expired, you will need to create a new order for the customer.';
        case OrderStatus.Declined:
          return 'Zip has declined the purchase. In this case, the customer should use another payment method.';
        default:
          return 'Please contact Zip for further information';
      }
    }
    return (
      <div>
        {getInfo(status)}
        <br />
        <div style={{ textAlign: 'right' }}>
          <a
            href={Constants.helpArticles.orderStatuses}
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more
          </a>
        </div>
      </div>
    );
  };
  return (
    <tr
      key={order?.id}
      css={styles.orderRow(selected, shouldSendOrderNotification)}
    >
      <TableCell selected={selected} onClick={onClick}>
        {dateFormatter(order?.orderDate, 'day')}
      </TableCell>
      {showExpiry && (
        <TableCell selected={selected} onClick={onClick}>
          {order?.expireInDays >= 0 && formatNumberOfDays(order?.expireInDays)}
        </TableCell>
      )}
      <TableCell selected={selected} onClick={onClick} maxWidth="125px">
        <Tooltip
          content={
            <span className="fs-mask">
              !order?.firstName ? order?.lastName : `${order?.firstName} $
              {order?.lastName}`
            </span>
          }
          dense
          arrow
          css={css.ellipsisOverflow}
        >
          <>
            <span className="fs-mask">
              {!order?.firstName
                ? order?.lastName
                : `${order?.firstName} ${order?.lastName}`}
            </span>
          </>
        </Tooltip>
      </TableCell>
      <TableCell selected={selected} onClick={onClick}>
        {currencyFormatter(order?.orderTotal)}
      </TableCell>
      <TableCell selected={selected} onClick={onClick} maxWidth="100px">
        <Tooltip content={order?.merchantOrderId} dense arrow interactive>
          <span css={css.ellipsisOverflow}>{order?.merchantOrderId}</span>
        </Tooltip>
      </TableCell>
      <TableCell selected={selected} onClick={onClick} maxWidth="100px">
        <Tooltip
          content={showReceipt ? order?.id : order?.orderReference}
          dense
          arrow
          interactive
        >
          <span css={css.ellipsisOverflow}>
            {showReceipt ? order?.id : order?.orderReference}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell selected={selected} onClick={onClick}>
        <Tooltip
          content={statusInformation(order?.status)}
          interactive
          dense
          arrow
          maxWidth={230}
          style={{ width: 'fit-content' }}
          placement="bottom-start"
        >
          <Labels.Basic
            variant={getStatusVariant(order?.status)}
            label={
              <>
                {statusFormatter(order?.status)}{' '}
                <Icons.InformationCircle css={styles.statusInfoIcon} />
              </>
            }
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </TableCell>
      {!sidebarOpen && (
        <TableCell css={css.tableCellCanHide(sidebarOpen)}>
          <Menu
            id={`${order.id}-actions`}
            label="Actions"
            options={actions}
            disabled={actions?.length < 1}
            anchorOrigin={{ vertical: 'bottom', horizontal: 20 }}
            onClick={(): void =>
              logEvent(PageAction.openOrderActions, { fromDetails: false })
            }
            dense
          />
        </TableCell>
      )}
    </tr>
  );
};
