import { css } from '@emotion/react';
import { css as globalCss, theme } from 'global';

const menuMaxWidth = '200px';

export const userIcon = css`
  color: ${theme.colors.secondary};
  margin-left: 16px;

  ${globalCss.icon(true)}
`;

export const menu = css`
  & > .MuiPopover-paper {
    box-shadow: unset;
    border: 1px solid ${theme.colors.neutral[100]};
    margin-top: -8px;
    max-width: ${menuMaxWidth};
  }
`;

export const menuItem = (isClickable = true) => css`
  font-size: 1em;
  line-height: 1.24em;
  min-height: fit-content;
  padding: 12px 24px;
  max-width: ${menuMaxWidth};
  cursor: ${isClickable ? 'pointer' : 'default'};
  &:hover {
    color: ${theme.colors.primary.dark};
    background-color: ${theme.colors.neutral[100]};
  }
`;
