/* eslint-disable @typescript-eslint/ban-types */
import { createContext, useContext, useEffect, useState } from 'react';
import { CookiesContextProps } from './CookiesContextProps';

export interface CookiesProviderProps {
  children?: React.ReactNode;
}

export const CookiesContext = createContext<CookiesContextProps>(null);
export const useCookies = (): CookiesContextProps => useContext(CookiesContext);

export const CookiesProvider: React.FC<CookiesProviderProps> = ({
  children,
}) => {
  const [cookies, setCookies] = useState<object>({});

  function setCookie(name: string, val: string, days?: number): void {
    const date = new Date();
    const value = val;

    // If days is defined, set it to add that many days, otherwise add 100 years
    if (days) {
      date.setDate(date.getDate() + days);
    } else {
      date.setFullYear(date.getFullYear() + 100);
    }

    // Set it
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
    setCookies({ ...cookies, [name]: value });
  }

  const getCookie = (name: string): string => cookies[name];

  function deleteCookie(name: string): void {
    const date = new Date();

    // Set it expire in -1 day
    date.setDate(date.getDate() + -1);

    // Set it
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
    setCookies({ ...cookies, [name]: null });
  }

  function clearAuthentication(): void {
    const authCookies = document.cookie
      .split('; ')
      .filter((cookie) => cookie.includes('auth0'))
      .map((cookie) => cookie.split('=')[0]);

    authCookies.forEach((cookie) => deleteCookie(cookie));

    window.location.reload();
  }

  useEffect(() => {
    const parts = document.cookie.split('; ');
    let object: object;
    parts?.forEach((cookie) => {
      const [key, value] = cookie.split('=');
      object = { ...object, [key]: value };
    });
    setCookies(object);
  }, []);

  return (
    <CookiesContext.Provider
      value={{
        cookies,
        setCookie,
        getCookie,
        deleteCookie,
        clearAuthentication,
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};

export default useCookies;
