import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  padding: 24px 32px;
  border-bottom: 1px solid ${theme.colors.neutral[200]};
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.neutral[0]};
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 16px;
  }
`;

export const icon = css`
  background-color: ${theme.colors.primary.lightest};
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 25px;
  overflow: visible;
  margin-right: 12px;
`;

export const title = css`
  margin: 4px 0 0;
`;

export const description = css`
  margin: 8px 0 0;
`;
