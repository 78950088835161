/** @jsxImportSource @emotion/react */
import {
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
} from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';
import { Divider, HighlightTag, Tooltip } from 'components';
import { useNavigation } from 'contexts';
import { FeatureFlag, PageRoute } from 'enums';
import { theme } from 'global';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { statusFormatter } from 'utils';
import DrawerExpandableItem from '../drawer-expandable-item';
import MenuListItem, { InnerMenuItem, MenuItem } from '../menu-list-item';

import * as styles from './Drawer.styles';
import { DrawerProps } from './DrawerProps';

const Drawer: FC<DrawerProps> = ({
  isDrawerOpen,
  isInnerDrawerOpen,
  setIsDrawerOpen,
  setIsInnerDrawerOpen,
}) => {
  const { location, push } = useHistory();
  const { menuItems, selectedItems } = useNavigation();

  const [expiresSoonDecision] = useDecision(FeatureFlag.EnableExpiresSoon, {
    autoUpdate: true,
  });

  function toggleDrawers(
    openDrawer: boolean,
    openInnerDrawer: boolean,
    override?: boolean
  ): void {
    setIsDrawerOpen(
      window.innerWidth > theme.breaks.md + 1 || override ? openDrawer : false
    );
    setIsInnerDrawerOpen(
      window.innerWidth > theme.breaks.md + 1 || override
        ? openInnerDrawer
        : false
    );
  }

  function handleInnerMenuItemClick(
    menuItem: InnerMenuItem,
    overrideSettings = false,
    changeRoute = true
  ): void {
    toggleDrawers(false, true, overrideSettings);
    if (location.pathname !== menuItem?.path && changeRoute) {
      push(menuItem?.path);
    }
    if (menuItem?.onClick) {
      menuItem?.onClick();
    }
  }

  function handleMenuItemClick(menuItem, changeRoute = true): void {
    toggleDrawers(menuItem?.openDrawer, menuItem?.openInnerDrawer);
    if (location.pathname !== menuItem?.path && changeRoute) {
      push(menuItem?.path);
    }
  }

  useEffect(() => {
    if (!selectedItems?.menuItem) {
      return;
    }
    const { menuItem } = selectedItems;
    toggleDrawers(menuItem.openDrawer, menuItem.openInnerDrawer);
  }, [selectedItems]);

  return (
    <>
      <MuiDrawer
        id="mainDrawer"
        variant="permanent"
        css={styles.drawer(isDrawerOpen)}
      >
        <div css={styles.flexList}>
          <MuiList css={styles.list}>
            {menuItems &&
              menuItems?.length > 0 &&
              menuItems?.map((item: MenuItem) => (
                <Tooltip
                  key={item.value}
                  content={!isDrawerOpen ? item.text : ''}
                  placement="right"
                  dense
                >
                  <MenuListItem
                    item={item}
                    onClick={(): void => {
                      if (item?.onClick) {
                        item?.onClick();
                      }
                      handleMenuItemClick(item);
                    }}
                    selected={selectedItems?.menuItem?.value === item.value}
                    isDrawerOpen={isDrawerOpen}
                  />
                </Tooltip>
              ))}
          </MuiList>
        </div>
      </MuiDrawer>
      <MuiDrawer
        id="innerDrawer"
        variant="permanent"
        css={styles.innerDrawer(isInnerDrawerOpen)}
      >
        <p
          className="body1"
          style={{
            margin: 0,
            padding: '32px 24px 8px',
            borderBottom: '1px solid lightgrey',
          }}
        >
          {selectedItems?.menuItem?.text}
        </p>
        <div css={styles.flexList}>
          <MuiList css={styles.list}>
            {selectedItems?.menuItem.children
              ?.filter((x) =>
                !expiresSoonDecision?.enabled
                  ? x.path !== PageRoute.ExpiringOrders
                  : true
              )
              ?.map((item, i) => {
                const itemId = `menu-item-${selectedItems?.menuItem?.value}-${item.value}`;
                return (
                  <>
                    {item?.divider === 'top' && <Divider top={0} />}
                    {item?.children && item?.children?.length > 0 ? (
                      <DrawerExpandableItem
                        key={i}
                        id={itemId}
                        item={item}
                        innerSelectedItem={selectedItems?.innerMenuItem}
                        setInnerSelectedItem={(
                          child,
                          overrideDrawerClose
                        ): void =>
                          handleInnerMenuItemClick(child, overrideDrawerClose)
                        }
                        autoSelectOnOpen={item?.autoSelectOnOpen}
                      />
                    ) : (
                      <MuiListItem
                        button
                        id={itemId}
                        key={i}
                        css={styles.listItem(
                          selectedItems?.innerMenuItem?.path === item.path
                        )}
                        onClick={(): void => handleInnerMenuItemClick(item)}
                        disableRipple
                      >
                        <MuiListItemText
                          primary={item.text || statusFormatter(item.value)}
                          css={styles.listItemText(true, !!item.tag)}
                        />
                        {item.tag && (
                          <HighlightTag
                            label={item.tag}
                            expiryDate={item.tagExpiry}
                          />
                        )}
                      </MuiListItem>
                    )}
                    {item?.divider === 'bottom' && <Divider />}
                  </>
                );
              })}
          </MuiList>
        </div>
      </MuiDrawer>
    </>
  );
};

export default Drawer;
