import _ from 'lodash';
import { theme } from 'global';

export class LegendColour {
  static legends: { key: string; colour: string }[] = [];

  static availableColours = [theme.colors.primary.medium];

  public static addNewLegend(merchantName: string): void {
    const nextAvailableColour =
      this.availableColours.length > this.legends.length
        ? this.availableColours[this.legends.length]
        : theme.colors.primary.medium;
    if (!_.findKey(this.legends, ['key', merchantName])) {
      this.legends.push({ key: merchantName, colour: nextAvailableColour });
    }
  }

  public static getColour(merchantName: string): string {
    const legend = _.find(this.legends, ['key', merchantName]);
    return legend?.colour || theme.colors.primary.medium;
  }

  public static clearLegends(): void {
    this.legends = [];
  }
}
