import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  padding: 32px;
  min-height: 284px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 24px 16px;
  }
`;

export const table = css`
  > .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-body {
    color: ${theme.colors.neutral[600]};
  }
`;

export const loadingRow = css`
  border-bottom: none;
`;

export const firstRow = css`
  border-top: 1px solid ${theme.colors.neutral[200]};
`;
