import { css } from '@emotion/react';
import { lighten } from '@material-ui/core';

import { theme } from './theme';

export const container = css`
  background-color: ${theme.colors.white};
  max-width: 420px;
  padding: 8px;

  @media screen and (min-width: ${theme.breaks.sm}px) {
    padding: 32px;
    border-radius: 12px;
    margin: 0px auto;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 16px;
  }
`;

export const pageContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const pagePaper = css`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${theme.breaks.md}px) {
    padding: 16px;
  }
`;

export const paper = css`
  height: calc(100% - 69px);
  overflow-y: hidden;

  @media screen and (max-width: ${theme.breaks.summary}px) {
    background-color: transparent;
    height: calc(100% - 84px);
  }

  @media screen and (max-width: ${theme.breaks.md}px) {
    height: calc(100vh - 198px);
  }

  @media screen and (max-width: ${theme.breaks.sm}px) {
    height: fit-content;
  }
`;

export const tableContainer = css`
  padding: 32px;
  transition: width 400ms;
  width: 100%;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 0;
    background-color: transparent;
  }
`;

export const header = css`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media screen and (max-width: 1395px) {
    margin-bottom: 16px;
  }
`;

export const pageTitle = css`
  margin: 0;
`;

export const ellipsisOverflow = css`
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const mobileToolbar = css`
  overflow-x: auto;
  white-space: nowrap;
  display: none;
  margin-bottom: 16px;

  @media screen and (max-width: ${theme.breaks.summary}px) {
    display: block;
  }
`;

export const mobileButton = css`
  border-radius: ${theme.radii.lg};
  height: fit-content;
  font-size: 0.86em;
  font-weight: 500;
  line-height: 0.93em;
  border: 2px solid ${theme.colors.neutral[300]};
  padding: 8px 14px;
  color: ${theme.colors.neutral[600]};
  justify-content: center;
  margin-left: 8px;
  min-width: fit-content;

  &:first-of-type {
    margin: 0;
  }
`;

export const mobileButtonIcon = css`
  margin-right: 6px;
  width: 13px;
  height: 13px;
`;

export const clearButton = css`
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-weight: 400;
  font-size: 0.86em;
  line-height: 0.93em;
  min-width: fit-content;
  color: ${theme.colors.primary.medium};
  margin-left: 8px;
`;

export const toolbar = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;

  @media screen and (max-width: ${theme.breaks.summary}px) {
    display: flex;
    align-items: center;
    border-bottom: unset;
  }

  @media screen and (max-width: ${theme.breaks.sm - 1}px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const searchFieldsContainer = css`
  display: flex;

  @media screen and (max-width: ${theme.breaks.summary}px) {
    padding-top: 5px;
  }

  @media screen and (max-width: ${theme.breaks.xs - 1}px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

export const searchFields = css`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${theme.breaks.md}px) {
    justify-content: space-evenly;
  }

  @media screen and (max-width: ${theme.breaks.sm - 1}px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${theme.breaks.xs - 1}px) {
    flex-direction: column;
  }
`;

export const search = css`
  align-items: center;

  @media screen and (max-width: ${theme.breaks.xs - 1}px) {
    display: flex;
    margin: 0;
    width: 100%;
  }
`;

export const searchBar = css`
  margin: 0 16px 0 0;
  min-width: 240px;

  & > .MuiTextField-root {
    background-color: ${theme.colors.white};
  }

  @media screen and (max-width: ${theme.breaks.xs - 1}px) {
    margin: 0 !important;
  }
`;

export const searchTypeSelect = css`
  background-color: white;
  margin: 0 16px 0 0 !important;
  max-width: 148px;

  @media screen and (max-width ${theme.breaks.md}px) {
    margin: 0 !important;
    max-width: unset;
  }

  @media screen and (max-width: ${theme.breaks.xs - 1}px) {
    width: 100%;
    max-width: unset;
    margin: 16px 0 0 !important;
  }
`;

export const searchButton = css`
  @media screen and (max-width: ${theme.breaks.summary}px) {
    margin-left: 0;
  }
`;

export const searchBarIcon = css`
  margin: 0 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;

  @media screen and (min-width: ${theme.breaks.xs}px) {
    display: none;
  }
`;

export const toolbarIcon = css`
  margin: 0 16px 0 0;
  cursor: pointer;

  @media screen and (max-width: ${theme.breaks.xs - 1}px) {
    display: none;
  }
`;

export const infiniteScrollContainer = css`
  display: none;
  height: fit-content !important;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    display: block;
  }
`;

export const infiniteScroll = css`
  @media screen and (max-width: ${theme.breaks.summary}px) {
    display: none;
  }
`;

export const mobileInfiniteScroll = css`
  @media screen and (min-width: ${theme.breaks.summary + 1}px) {
    display: none;
  }
`;

export const table = css`
  background-color: transparent;
  padding: 8px;
  overflow: hidden;
  border-top: 1px solid ${theme.colors.neutral[200]};
  border-radius: 0;

  table > thead > tr > th {
    padding: 8px 24px;
  }

  @media screen and (max-width: 1395px) {
    table > thead > tr > th {
      padding: 8px 16px;
    }
  }
`;

export const row = (selected, isNotClickable) => css`
  padding: 16px 0;
  background-color: ${selected
    ? lighten(theme.colors.primary.medium, 0.92)
    : 'inherit'};
  cursor: ${isNotClickable ? 'default' : 'pointer'};

  :hover {
    background-color: ${selected
      ? lighten(theme.colors.primary.medium, 0.92)
      : theme.colors.neutral[0]};
  }
`;

export const tableCell = (selected) => css`
  padding: 8px 24px !important;
  height: 56px;
  color: ${selected
    ? `${theme.colors.primary.dark} !important`
    : theme.colors.neutral[500]};

  ${ellipsisOverflow}

  :first-of-type {
    border-radius: ${theme.radii.md} 0 0 ${theme.radii.md};
  }

  :last-of-type {
    border-radius: 0 ${theme.radii.md} ${theme.radii.md} 0;
  }

  @media screen and (max-width: 1395px) {
    padding: 8px 16px !important;
  }
`;

export const tableCellCanHide = (hidden) => css`
  width: ${hidden ? 0 : '110px'};
  transition: width 400ms;
`;

export const marginTop = (px) => css`
  margin-top: ${px}px!important;
`;

export const marginBottom = (px) => css`
  margin-bottom: ${px}px!important;
`;

export const marginRight = (px) => css`
  margin-right: ${px}px!important;
`;

export const noTopMrgn = css`
  margin-top: 0 !important;
`;

export const noBtmMrgn = css`
  margin-bottom: 0 !important;
`;

export const noMargin = css`
  margin: 0 !important;
`;

export const subtext = css`
  font-size: ${theme.fontSizes.body2};
  line-height: ${theme.lineHeights.body2};
  letter-spacing: ${theme.letterSpacings.body2};

  color: ${theme.colors.neutral[500]};
`;

export const closeIcon = css`
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
`;

export const onboardingConfirmButton = css`
  margin-top: 72px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin-top: 0;
    background-color: ${theme.colors.white};
    padding: 16px 16px 16px 24px;
    position: fixed;
    bottom: 0;
    right: 0;
    box-shadow: 0px -8px 16px ${theme.colors.boxshadow};
    z-index: 500;
  }
`;

export const inheritColour = css`
  color: inherit;
`;

export const blinkingText = css`
  animation: blinker 1.5s linear infinite;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export const flex = (direction, justifyContent, alignItems) => css`
  display: flex;
  flex-direction: ${direction ?? 'row'};
  justify-content: ${justifyContent ?? 'normal'};
  align-items: ${alignItems ?? 'normal'};
`;

export const icon = (isButton, width, height) => css`
  width: ${width ?? '24px'};
  height: ${height ?? '24px'};
  cursor: ${isButton ? 'pointer' : 'inherit'};
`;

export const statusInfoIcon = css`
  width: 15px;
  height: 15px;
  vertical-align: sub;
`;

export const titleBar = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  user-select: none;
`;

export const moduleHeader = (rowOnMobile) => css`
  ${titleBar}
  align-items: flex-start;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    justify-content: ${rowOnMobile ? 'space-between' : 'flex-start'};
    flex-direction: ${rowOnMobile ? 'row' : 'column'};
    ${!rowOnMobile && `> div:last-of-type { margin-top: 12px;}`}
  }
`;

export const moduleTitleBar = css`
  display: flex;
  align-items: center;

  > span {
    color: ${theme.colors.primary.dark};
  }

  @media screen and (max-width: ${theme.breakpoints.xs}px) {
    margin-bottom: 12px;
  }
`;

export const moduleTooltip = css`
  display: flex;
  align-items: center;
`;

export const infoIcon = css`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 5px;
`;

export const h2 = css`
  color: ${theme.colors.primary.dark};
`;

export const defaultList = css`
  padding: 0 20px;
`;

export const loadingPrompt = css`
  text-align: center;
  max-width: 250px;
  margin: 8px auto 32px;
`;

export const clearIcon = css`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const badge = css`
  background-color: ${theme.colors.neutral[50]};
  color: ${theme.colors.primary.medium};
  font-weight: ${theme.fontWeights.medium};
  height: fit-content;
  border-radius: 20px;
  padding: 1px 8px;
  margin-left: 4px;
`;
