/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import {
  Buttons,
  Spinner,
  TextFields,
  Widgets,
} from '@zip/business-components';
import { BasePage } from 'components';
import { useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import _ from 'lodash';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { PageProps } from 'types';
import { logError, useMerchantProfile, useReportFunctions } from 'utils';

const yesterday: Date = new Date(
  new Date(
    new Date().setDate(
      new Date(
        new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' })
      ).getDate() - 1
    )
  ).setHours(0, 0, 0, 0)
);

const DisbursementPage: FC<PageProps> = () => {
  const Snackbar = useSnackbar();
  const ReportFunctions = useReportFunctions();
  const MerchantProfileFunctions = useMerchantProfile();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contact] = useState(MerchantProfileFunctions.getContactFromClaims());

  const [isScheduleSubmitting, setIsScheduleSubmitting] =
    useState<boolean>(false);
  const [isGenerateSubmitting, setIsGenerateSubmitting] =
    useState<boolean>(false);

  const [dailyChecked, setDailyChecked] = useState<boolean>(false);
  const [weeklyChecked, setWeeklyChecked] = useState<boolean>(false);
  const [monthlyChecked, setMonthlyChecked] = useState<boolean>(false);

  const [fromDate, setFromDate] = useState<Date>(yesterday);
  const [toDate, setToDate] = useState<Date>(yesterday);
  const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);
  const [fileName, setFileName] = useState<string>();

  async function submitScheduleSettings(): Promise<void> {
    if (isScheduleSubmitting) {
      return;
    }

    setIsScheduleSubmitting(true);
    const payload = {
      isDaily: dailyChecked,
      isWeekly: weeklyChecked,
      isMonthly: monthlyChecked,
    };

    const result = await ReportFunctions.updateScheduleSettings(payload);
    if (result.isSuccess) {
      Snackbar.success('Disbursement report settings updated successfully');
    } else {
      Snackbar.error(
        'An error occurred updating your disbursement report settings. Please try again'
      );
    }
    setIsScheduleSubmitting(false);
  }

  async function submitGenerateReport(): Promise<void> {
    if (isGenerateSubmitting) {
      return;
    }

    setIsGenerateSubmitting(true);
    setFileName(null);

    try {
      const result = await ReportFunctions.generateReport(
        moment(fromDate),
        moment(toDate)
      );
      if (result) {
        setFileName(result);
        Snackbar.success('Report generated successfully');
      } else {
        Snackbar.error(
          'An error occurred generating your report. Please try again'
        );
      }
    } catch (err) {
      logError(PageAction.generateDisbursementReport, err, {
        fromDate,
        toDate,
      });
      Snackbar.error(
        'An unexpected error occurred generating your report. Please try again with different dates'
      );
    } finally {
      setIsGenerateSubmitting(false);
    }
  }

  function blobifyCsv(result): void {
    let csv = result?.csvData;
    const isMac = !!navigator?.platform.match(/(Mac|iPhone|iPod|iPad)/i);

    if (!isMac) {
      const BOM = '\ufeff';
      csv = BOM + csv;
    }

    const blob = new Blob([csv], {
      type: 'text/csv;charset=UTF-8',
    });
    const blobUrl = window.URL.createObjectURL(blob);

    // @ts-ignore to cover TS2339
    if (window.navigator && window.navigator?.msSaveOrOpenBlob) {
      // IE
      // @ts-ignore to cover TS2339
      window.navigator?.msSaveOrOpenBlob(blob, result?.fileName);
    } else {
      // Chrome & Firefox
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = result?.fileName;
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      a.remove();
    }
  }

  async function downloadReport(): Promise<void> {
    const result = await ReportFunctions.fetchReport(fileName);
    if (result) {
      blobifyCsv(result);
    } else {
      Snackbar.error(
        'An error occurred fetching your report. Please try again'
      );
    }
  }

  function updateState(updatedSchedule): void {
    setDailyChecked(updatedSchedule?.isDaily);
    setWeeklyChecked(updatedSchedule?.isWeekly);
    setMonthlyChecked(updatedSchedule?.isMonthly);
  }

  const getMaxToDate = (): Date => {
    const maxDate = new Date(fromDate);
    maxDate.setMonth(maxDate?.getMonth() + 3);
    return _.min([maxDate, yesterday]);
  };

  const validateDateRange = (): void => {
    const threeMonths = new Date(fromDate);
    threeMonths.setMonth(threeMonths?.getMonth() + 3);
    setIsDateRangeValid(fromDate <= toDate && toDate <= threeMonths);
  };

  useEffect(() => {
    setFileName(null);
    validateDateRange();
  }, [fromDate, toDate]);

  useEffect(() => {
    async function getScheduleSettings(): Promise<void> {
      setIsLoading(true);
      try {
        const result = await ReportFunctions.getScheduleSettings();
        if (result?.disbursementSchedule) {
          updateState(result?.disbursementSchedule);
        }
      } catch (err) {
        logError(PageAction.loadDisbursementSchedule, err);
      }
      setIsLoading(false);
    }

    getScheduleSettings();
  }, []);

  return (
    <BasePage title="Disbursement reports">
      {isLoading && <Spinner />}
      {!isLoading && (
        <MuiGrid container spacing={2}>
          <MuiGrid item xs={12}>
            <h2>Report schedule</h2>
            <p>
              Click the Daily, Weekly or Monthly checkboxes to receive Zip CSV
              disbursement reports to:
              <br />
              <strong className="fs-mask">{contact.contactEmail}</strong>.
            </p>
            <MuiGrid container spacing={2}>
              <MuiGrid item xs={6}>
                <Widgets.Checkbox
                  label="Daily"
                  checked={dailyChecked}
                  onChange={(e, checked): void => setDailyChecked(checked)}
                />
              </MuiGrid>
              <MuiGrid item xs={6}>
                <Widgets.Checkbox
                  label="Weekly"
                  checked={weeklyChecked}
                  onChange={(e, checked): void => setWeeklyChecked(checked)}
                />
              </MuiGrid>
              <MuiGrid item xs={6}>
                <Widgets.Checkbox
                  label="Monthly"
                  checked={monthlyChecked}
                  onChange={(e, checked): void => setMonthlyChecked(checked)}
                />
              </MuiGrid>
              <MuiGrid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Buttons.Primary
                    onClick={(): Promise<void> => submitScheduleSettings()}
                    loading={isScheduleSubmitting}
                  >
                    Save
                  </Buttons.Primary>
                </div>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <h2>Generate CSV report</h2>
            <p>Select a date range (search up to 3 months)</p>
            <MuiGrid container spacing={2} justifyContent="flex-end">
              <MuiGrid item xs={6} sm={4}>
                <TextFields.Date
                  KeyboardButtonProps={{
                    'aria-label': 'open calendar',
                  }}
                  id="fromDate"
                  data-testid="fromDate"
                  label="Start date"
                  name="fromDate"
                  onChange={(value): void =>
                    // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
                    setFromDate(value)
                  }
                  value={fromDate}
                  disableToolbar={false}
                  disableFuture
                  maxDate={yesterday}
                  views={['month', 'date']}
                  autoOk
                  placeholder="DD/MM/YYYY"
                />
              </MuiGrid>
              <MuiGrid item xs={6} sm={4}>
                <TextFields.Date
                  KeyboardButtonProps={{
                    'aria-label': 'open calendar',
                  }}
                  id="toDate"
                  data-testid="toDate"
                  label="End date"
                  name="toDate"
                  onChange={(value): void =>
                    // @material-ui/pickers sends the value to onChange, not the event object ಠ_ಠ
                    setToDate(value)
                  }
                  value={toDate}
                  disableToolbar={false}
                  disableFuture
                  views={['month', 'date']}
                  minDate={fromDate}
                  minDateMessage="Date must be after start date"
                  maxDate={getMaxToDate()}
                  maxDateMessage="Date range cannot exceed 3 months"
                  autoOk
                  placeholder="DD/MM/YYYY"
                />
              </MuiGrid>
              <MuiGrid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                  }}
                >
                  <Buttons.Primary
                    onClick={(): Promise<void> => submitGenerateReport()}
                    loading={isGenerateSubmitting}
                    disabled={!isDateRangeValid}
                  >
                    Create
                  </Buttons.Primary>
                  {fileName && (
                    <Buttons.Tertiary
                      onClick={(): Promise<void> => downloadReport()}
                    >
                      Download
                    </Buttons.Tertiary>
                  )}
                </div>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      )}
    </BasePage>
  );
};

export default DisbursementPage;
