/** @jsxImportSource @emotion/react */
import {
  Grid as MuiGrid,
  Hidden as MuiHidden,
  Paper as MuiPaper,
} from '@material-ui/core';
import { Buttons, Labels } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { useCookies, useMerchantData } from 'contexts';
import { Constants, css, theme } from 'global';
import { FC, useState } from 'react';
import * as styles from './Banners.styles';
import { NoticeBannerProps, PromoBannerProps } from './BannersProps';

export const Notice: FC<NoticeBannerProps> = ({
  title,
  content,
  action,
  actionLabel,
  dense = false,
  ...props
}) => {
  return (
    <MuiGrid item {...props}>
      <MuiPaper elevation={0} css={styles.noticeBanner(dense)}>
        <h1 className="heading1">{title}</h1>
        {(typeof content === 'string' && <p>{content}</p>) || content}

        {action && actionLabel && (
          <Buttons.Primary onClick={action}>{actionLabel}</Buttons.Primary>
        )}
      </MuiPaper>
    </MuiGrid>
  );
};

export const Promo: FC<PromoBannerProps> = ({
  title,
  titleTag,
  content,
  promoId,
  variant = 'default',
  image,
  imageBackground = theme.colors.white,
  action,
  actionLabel,
  onClose = (): void => null,
  ...props
}) => {
  const { currentUserId } = useMerchantData();
  const { getCookie, setCookie } = useCookies();
  const cookieId = `${Constants.cookies.promoBanner}${promoId}-${currentUserId}`;
  const showPromoBannerValue = getCookie(cookieId)?.toLowerCase() !== 'false';
  const [showBanner, setShowBanner] = useState<boolean>(showPromoBannerValue);

  const handleClose = (): void => {
    setCookie(cookieId, 'false');
    setShowBanner(false);
    onClose();
  };

  return (
    showBanner && (
      <MuiGrid item {...props}>
        <div css={styles.promoBanner(variant)} data-testid="promo-banner">
          {image && (
            <div css={styles.imageContainer(imageBackground)}>
              {typeof image == 'string' ? (
                <img
                  src={image}
                  alt="Promotional graphic"
                  css={styles.promoImage}
                />
              ) : (
                image
              )}
              <MuiHidden smUp>
                <Icons.Close
                  data-testid="dismiss-promo"
                  fill={theme.colors.primary.dark}
                  onClick={handleClose}
                  css={styles.promoCloseIcon}
                />
              </MuiHidden>
            </div>
          )}
          <div css={styles.copyContainer}>
            <div css={styles.titleBar}>
              <h1 className="title3">
                {title}
                {titleTag && (
                  <>
                    {' '}
                    <Labels.Success label={titleTag} />
                  </>
                )}
              </h1>
              <MuiHidden xsDown={!!image}>
                <Icons.Close
                  data-testid="dismiss-promo"
                  fill={theme.colors.primary.dark}
                  onClick={handleClose}
                  css={css.closeIcon}
                />
              </MuiHidden>
            </div>

            {(typeof content === 'string' && (
              <p className="body2" css={styles.bodyCopy}>
                {content}
              </p>
            )) ||
              content}

            {action && actionLabel && (
              <Buttons.Primary onClick={action}>{actionLabel}</Buttons.Primary>
            )}
          </div>
        </div>
      </MuiGrid>
    )
  );
};
