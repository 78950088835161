/** @jsxImportSource @emotion/react */
import { Button as MuiButton } from '@material-ui/core';
import React, { ChangeEvent, FC } from 'react';
import * as styles from './SimpleUpload.styles';
import { SimpleUploadProps } from './SimpleUploadProps';

const SimpleUpload: FC<SimpleUploadProps> = ({
  label,
  handleChange,
  onBlur,
  name,
  error,
  helperText,
  multiple = false,
}) => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newFiles = event?.target?.files;
    if (newFiles?.length > 0) {
      handleChange(newFiles);
    }
  };

  const blurHandler = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ): void => onBlur(event);

  return (
    <>
      {/* @ts-ignore - Button component does not need to cater for HTMLTextAreaElement in onBlur */}
      <MuiButton
        variant="contained"
        component="label"
        disableRipple
        css={styles.uploadButton}
        onBlur={blurHandler}
      >
        <input
          type="file"
          accept="image/*"
          name={name}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            changeHandler(e);
            e.target.value = null;
          }}
          onBlur={onBlur}
          multiple={multiple}
          hidden
        />
        <p css={styles.label}>{label}</p>
      </MuiButton>
      {error && (
        <p css={styles.helperText} className="body4">
          {helperText}
        </p>
      )}
    </>
  );
};

export default SimpleUpload;
