import { css } from '@emotion/react';
import { theme, css as globalCss } from 'global';

export const container = css`
  width: 100%;
`;

export const branchSearch = css`
  display: inline-flex;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    display: inline-flex;
    width: 100%;

    & > .MuiOutlinedInput-root {
      width: 100%;
    }
  }
`;

export const searchButton = css`
  margin-left: 8px;
`;

export const mobileToolbar = css`
  ${globalCss.mobileToolbar}

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const detailContainer = css`
  display: flex;
  flex-direction: column;
`;

export const innerContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const centeredData = css`
  text-align: center;
`;
