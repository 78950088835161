/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs, TextFields } from '@zip/business-components';
import { useSnackbar } from 'contexts';
import { PageAction, ProductDetail } from 'enums';
import { useFormik } from 'formik';
import { useFetch } from 'hooks';
import { FC, useState } from 'react';
import { ProductDetails } from 'types';
import { logError, logEvent } from 'utils';
import { isFormikValid } from 'utils/validators';
import * as styles from './AddProductDetailsDialog.styles';
import { AddProductDetailsDialogProps } from './AddProductDetailsDialogModuleProps';

const AddProductDetailsDialogModule: FC<AddProductDetailsDialogProps> = ({
  onClose,
  orderToAction,
  open,
  onSuccess,
}) => {
  const { post } = useFetch();
  const Snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      productName: '',
      serialNumber: '',
      modelNumber: '',
    },
    onSubmit: () => handleSubmit(),
    validate: (values) => {
      const errors: ProductDetails = {};
      if (!values.productName) {
        errors.productName = 'Required';
      }
      if (!values.serialNumber) {
        errors.serialNumber = 'Required';
      }
      if (!values.modelNumber) {
        errors.modelNumber = 'Required';
      }
      return errors;
    },
    validateOnMount: false,
  });

  const handleClose = (e?, ref?): void => {
    onClose(e, ref);
    formik.resetForm();
  };

  const handleSubmit = async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const payload = {
      operationRequestId: orderToAction?.operationRequestId,
      productDetails: {
        [ProductDetail.ProductName]: formik.values.productName,
        [ProductDetail.SerialNumber]: formik.values.serialNumber,
        [ProductDetail.ModelNumber]: formik.values.modelNumber,
      },
    };
    logEvent(PageAction.addProductDetails, payload);
    await post('/order/productdetails', payload)
      .then((res) => {
        if (!res?.ok) {
          throw new Error('Not ok');
        }
        handleClose();
        onSuccess();
        Snackbar.success('Product details added to order successfully');
      })
      .catch((err) => {
        logError(PageAction.addProductDetails, err, formik.values);
        Snackbar.error('Something went wrong adding the product details.');
      });
    setIsLoading(false);
  };

  return (
    <Dialogs.Basic
      open={open}
      title="Product details"
      onClose={handleClose}
      spaceActions
      actions={
        <>
          <Buttons.Primary
            type="submit"
            loading={isLoading}
            onClick={(): void => formik.handleSubmit()}
            data-testid="save-button"
            disabled={!isFormikValid(formik)}
          >
            Save
          </Buttons.Primary>

          <Buttons.Text onClick={handleClose}>Cancel</Buttons.Text>
        </>
      }
      mobileFullscreen
    >
      <form onSubmit={formik.handleSubmit}>
        <TextFields.Outlined
          name="productName"
          label="Product name"
          helperText={
            (formik.touched.productName && formik?.errors?.productName) ||
            'Enter a product name'
          }
          value={formik.values.productName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik?.touched?.productName && formik?.errors?.productName
          )}
          fullWidth
          css={styles.textfields}
          data-testid="product-name"
        />
        <TextFields.Outlined
          name="serialNumber"
          label="Serial number"
          helperText={
            (formik?.touched?.serialNumber && formik?.errors?.serialNumber) ||
            'Enter a serial number'
          }
          value={formik.values.serialNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik?.touched?.serialNumber && formik?.errors?.serialNumber
          )}
          fullWidth
          css={styles.textfields}
          data-testid="serial-number"
        />
        <TextFields.Outlined
          name="modelNumber"
          label="Model number"
          helperText={
            (formik?.touched?.modelNumber && formik?.errors?.modelNumber) ||
            'Enter a model number'
          }
          value={formik.values.modelNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik?.touched?.modelNumber && formik?.errors?.modelNumber
          )}
          fullWidth
          css={styles.textfields}
          data-testid="model-number"
        />
      </form>
    </Dialogs.Basic>
  );
};

export default AddProductDetailsDialogModule;
