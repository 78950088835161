/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper } from '@material-ui/core';
import * as Icons from '@zip/react-icons/fearless';
import { useMerchantData } from 'contexts';
import { PageAction, PageRoute } from 'enums';
import { Constants } from 'global';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { logEvent } from 'utils';
import * as styles from './SetupArticle.styles';
import { SetupArticleProps } from './SetupArticleProps';

const SetupArticle: FC<SetupArticleProps> = ({
  title,
  description,
  minsToRead,
  link,
  roles = [],
  excludeRoles = [],
  id,
}) => {
  const { push } = useHistory();
  const handleClick = (): void => {
    logEvent(PageAction.openedSetupArticle, { article: id });
    const paths = link.split('/').map((path) => `/${path}`);
    if (Object.values<string>(PageRoute).includes(paths[paths.length - 1])) {
      push(link);
    } else {
      window.open(link);
    }
  };

  const { userRole, isAdmin } = useMerchantData();

  const canViewArticle = useMemo((): boolean => {
    if (roles?.length > 0) {
      return roles.includes(userRole);
    }
    if (excludeRoles?.length > 0) {
      return !excludeRoles.includes(userRole);
    }
    return isAdmin;
  }, [userRole]);

  return (
    canViewArticle && (
      <MuiPaper
        elevation={0}
        css={styles.articleContainer}
        role="button"
        onClick={handleClick}
        tabIndex={0}
        data-testid={`${id}-container`}
      >
        <div css={styles.imageContainer}>
          <img
            src={`${Constants.assets.articleImgBaseUrl}${id}.png`}
            alt="Article image"
            css={styles.articleImage}
            data-testid={`${id}-image`}
          />
        </div>
        <div css={styles.contentContainer}>
          <h4 className="heading3" data-testid={`${id}-title`}>
            {title}
          </h4>

          <p className="body2" data-testid={`${id}-description`}>
            {description}
          </p>

          <p className="caption" data-testid={`${id}-minsToRead`}>
            <Icons.Clock width={13} height={13} /> {minsToRead}
            {minsToRead > 1 ? ' mins' : ' min'}
          </p>
        </div>
      </MuiPaper>
    )
  );
};

export default SetupArticle;
