/** @jsxImportSource @emotion/react */
import {
  Paper as MuiPaper,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import { Buttons, Labels } from '@zip/business-components';
import { CompactTable } from 'components';
import { OrderSearchType, PageAction, PageRoute } from 'enums';
import { Constants, css } from 'global';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OrderSummary } from 'types';
import {
  currencyFormatter,
  getStatusVariant,
  logError,
  statusFormatter,
  useOrderFunctions,
} from 'utils';
import * as styles from './RecentOrders.styles';

const RecentOrdersModule: FC = () => {
  const { push } = useHistory();
  const OrderFunctions = useOrderFunctions();
  const [orders, setOrders] = useState<OrderSummary[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const columns = ['Customer', 'Amount', 'Status'];

  const handleSeeAllClick = (): void => push(PageRoute.Orders);

  useEffect(() => {
    async function getRecentOrders(): Promise<void> {
      const query = {
        searchType: OrderSearchType.NoFilter,
        sortBy: 'OrderDate',
        orderBy: 'Descending',
        take: 5,
        skip: 0,
      };

      await OrderFunctions.getOrders(query)
        .then((res) => {
          setOrders(res?.orders);
          setIsLoading(false);
        })
        .catch((err) => {
          if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
            logError(PageAction.searchOrders, err, query);
            setIsLoading(false);
          }
          setOrders([]);
        });
    }
    getRecentOrders();
  }, []);

  return (
    <MuiPaper elevation={0} css={styles.container}>
      <div css={[css.titleBar]}>
        <span className="heading1">Recent orders</span>
        <Buttons.Text onClick={handleSeeAllClick} css={styles.seeAll}>
          See all
        </Buttons.Text>
      </div>
      <CompactTable columns={columns}>
        {(!isLoading &&
          orders?.length > 0 &&
          orders?.map((order) => (
            <MuiTableRow key={order?.id}>
              <MuiTableCell css={styles.nameCell}>
                <span className="fs-mask">{order?.lastName}</span>
              </MuiTableCell>
              <MuiTableCell>
                {currencyFormatter(order?.orderTotal)}
              </MuiTableCell>
              <MuiTableCell>
                <Labels.Basic
                  variant={getStatusVariant(order?.status)}
                  label={statusFormatter(order?.status)}
                />
              </MuiTableCell>
            </MuiTableRow>
          ))) || (
          <MuiTableRow>
            <MuiTableCell colSpan={columns?.length} css={styles.noResults}>
              No recent orders
            </MuiTableCell>
          </MuiTableRow>
        )}
      </CompactTable>
    </MuiPaper>
  );
};

export default RecentOrdersModule;
