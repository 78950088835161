import { css } from '@emotion/react';
import { theme } from 'global';

export const container = css`
  padding: 32px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 24px 16px;
  }
`;

export const statusCard = css`
  border: 1px solid ${theme.colors.neutral[200]};
  border-radius: ${theme.radii.lg};
  padding: 24px;
  min-height: 96px;

  & > h2 {
    margin-bottom: 0;
  }

  & > span {
    font-size: 14px;
    white-space: nowrap;
  }

  &:hover {
    background-color: ${theme.colors.neutral[50]};
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${theme.breakpoints.xs}px) {
    min-height: 48px;
    padding: 10px 12px;
    flex-direction: row;
    justify-content: space-between;
  }
`;
