import { useAuth0, useMerchantData } from 'contexts';

export interface HttpFunctions {
  get: (endpoint: string, signal?: AbortSignal, api?: string) => Promise<any>;
  patch: (
    endpoint: string,
    payload: unknown,
    signal?: AbortSignal,
    api?: string
  ) => Promise<any>;
  post: (
    endpoint: string,
    payload: unknown,
    signal?: AbortSignal,
    api?: string
  ) => Promise<any>;
  put: (
    endpoint: string,
    payload: unknown,
    signal?: AbortSignal,
    api?: string
  ) => Promise<any>;
}

export const useFetch = (): HttpFunctions => {
  const { accessToken } = useAuth0();
  const MerchantData = useMerchantData();

  function configureHeader(): HeadersInit {
    let header: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (!accessToken) {
      return header;
    }
    header = { ...header, Authorization: `Bearer ${accessToken}` };

    if (!MerchantData?.merchantIdentity) {
      return header;
    }

    const { merchantId, branchId } = MerchantData?.merchantIdentity;
    return {
      ...header,
      ...(merchantId > 0 && {
        'md-merchant-id': merchantId?.toString(),
      }),
      ...(branchId !== 0 && {
        'md-branch-id': branchId?.toString(),
      }),
    };
  }

  async function get(
    endpoint: string,
    signal: AbortSignal = new AbortController().signal,
    api: string = process.env.REACT_APP_API_URL
  ): Promise<any> {
    const url = `${api}${endpoint}`;
    const headers = configureHeader();
    try {
      return await fetch(url, { headers, method: 'GET', signal });
    } catch (err) {
      throw err;
    }
  }

  async function patch(
    endpoint: string,
    payload: unknown,
    signal = new AbortController().signal,
    api = process.env.REACT_APP_API_URL
  ): Promise<any> {
    const url = `${api}${endpoint}`;
    const headers = configureHeader();
    try {
      return await fetch(url, {
        headers,
        method: 'PATCH',
        body: JSON.stringify(payload),
        signal,
      });
    } catch (err) {
      throw err;
    }
  }

  async function post(
    endpoint: string,
    payload: unknown,
    signal = new AbortController().signal,
    api = process.env.REACT_APP_API_URL
  ): Promise<any> {
    const url = `${api}${endpoint}`;
    const headers = configureHeader();
    try {
      return await fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
        signal,
      });
    } catch (err) {
      throw err;
    }
  }

  async function put(
    endpoint: string,
    payload: unknown,
    signal = new AbortController().signal,
    api = process.env.REACT_APP_API_URL
  ): Promise<any> {
    const url = `${api}${endpoint}`;
    const headers = configureHeader();
    try {
      return await fetch(url, {
        headers,
        method: 'PUT',
        body: JSON.stringify(payload),
        signal,
      });
    } catch (err) {
      throw err;
    }
  }

  return { get, patch, post, put };
};
