/** @jsxImportSource @emotion/react */
import { Constants } from 'global';
import { FC, useEffect, useRef } from 'react';
import { createImage } from 'utils';

interface ProfileCanvasProps {
  src: string;
  zoom?: number;
}

export const Background: FC<ProfileCanvasProps> = ({ src = undefined }) => {
  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    if (!src) {
      return;
    }

    const updateCanvas = async (): Promise<void> => {
      const image = await createImage(src);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // reset canvas size & remove any existing image
      canvas.width = Constants.storeProfile.canvas.width;
      canvas.height = Constants.storeProfile.canvas.height;

      // assume background has been cropped & display full cropped image
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
    };

    updateCanvas();
  }, [src]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 0,
      }}
      width={Constants.storeProfile.canvas.width}
      height={Constants.storeProfile.canvas.height}
    />
  );
};

export const Logo: FC<ProfileCanvasProps> = ({ src, zoom = 1 }) => {
  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    if (!src) {
      return;
    }

    const updateCanvas = async (): Promise<void> => {
      const image = await createImage(src);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      canvas.width = Constants.storeProfile.canvas.width;
      canvas.height = Constants.storeProfile.canvas.height;
      const newWidth = image.width * zoom;
      const newHeight = image.height * zoom;

      const xOffset =
        newWidth < canvas.width
          ? (canvas.width - newWidth) / 2
          : (newWidth - canvas.width) / -2;
      const yOffset =
        newHeight < canvas.height
          ? (canvas.height - newHeight) / 2
          : (newHeight - canvas.height) / -2;

      ctx.drawImage(image, xOffset, yOffset, newWidth, newHeight);
    };

    updateCanvas();
  }, [src, zoom]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 1,
      }}
    />
  );
};
