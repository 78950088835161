import { css } from '@emotion/react';
import { theme } from 'global';

export const container = css`
  background-color: #ede6ff;
  padding: 32px;
  min-height: 325px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 38px 38px 38px 16px;
  }
`;

export const heading = css`
  @media screen and (max-width: ${theme.breaks.xs}px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.2px;
  }
`;

export const subHeading = css`
  margin-top: 4px;
`;

export const input = css`
  display: flex;
  align-items: center;
  margin: 29px 0 13px 0;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin: 12px 0 24px 0;
  }
`;
