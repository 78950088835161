import * as amplitude from '@amplitude/analytics-browser';
import createAnalytics, {
  CommonEventProperties,
  PageViewEvent,
  ZipAnalyticsInstance,
  withDefaults,
} from '@zip/analytics-core';
import { Constants } from 'global';
import React, { ReactElement, useContext, useMemo, useReducer } from 'react';
import {
  DispatchIdentityAction,
  DispatchPageAction,
  DispatchTrackAction,
} from 'types';

const initialState = createAnalytics({
  ...withDefaults(
    process.env.REACT_APP_ANALYTICS_APP_ID,
    'partners',
    process.env.REACT_APP_ANALYTICS_COLLECTOR_ENDPOINT
  ),
  ...Constants.config.zipAnalytics(window.location.host),
});

type DispatchInterface = <T>(
  action: DispatchPageAction | DispatchTrackAction<T> | DispatchIdentityAction
) => void;

type ContextProps = {
  state: ZipAnalyticsInstance;
  dispatch: DispatchInterface;
};
const ZipAnalyticsContext = React.createContext<ContextProps | undefined>(
  undefined
);

if (process.env.NODE_ENV !== 'production') {
  ZipAnalyticsContext.displayName = 'ZipAnalytics';
}

const analyticsReducer = (
  state: ZipAnalyticsInstance,
  action: DispatchPageAction | DispatchTrackAction<any> | DispatchIdentityAction
): ZipAnalyticsInstance => {
  switch (action.type) {
    case 'page':
      state.page(action.payload);
      return state;
    case 'track': {
      state.trackEvent(action.payload, action.context);
      return state;
    }
    case 'identity':
      state.identify(action.userId);
      return state;
    default:
      throw new Error('Invalid action type in context.');
  }
};

export const AnalyticsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(analyticsReducer, initialState);

  const analyticsMemo = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

  return (
    <ZipAnalyticsContext.Provider value={analyticsMemo}>
      {children}
    </ZipAnalyticsContext.Provider>
  );
};

type PageTrackingProps = {
  page?: PageViewEvent & CommonEventProperties;
};

export const useAnalytics = (): ContextProps | undefined =>
  useContext(ZipAnalyticsContext);

export const PageTracking: React.FC<PageTrackingProps> = ({
  page,
  children,
}): ReactElement => {
  const analytics = useAnalytics();

  if (analytics) {
    analytics?.dispatch({ type: 'page', payload: page });
  }

  return <>{children}</>;
};
