import { css, SerializedStyles } from '@emotion/react';

import { theme } from 'global';

export const cardContainer = css`
  display: flex;
`;

export const card = (disabled: boolean): SerializedStyles => css`
  height: 186px;
  padding: 32px;
  overflow: hidden;
  cursor: ${disabled ? 'inherit' : 'pointer'};
  background-color: ${disabled ? theme.colors.neutral[0] : theme.colors.white};
  border: 1px solid ${theme.colors.neutral[200]};
  user-select: none;
  h2 {
    margin-bottom: 8px;
  }
  h2,
  p {
    color: ${disabled ? `${theme.colors.neutral[200]} !important` : 'inherit'};
  }
`;

export const iconContainer = css`
  margin-bottom: 8px;
`;

export const icon = (disabled: boolean): SerializedStyles => css`
  width: 40px;
  height: 40px;
  background-color: ${theme.colors.neutral[0]}${disabled ? '30' : ''};
  padding: 8px;
  border-radius: 25px;
  overflow: visible;
  filter: ${disabled ? 'contrast(0.1)opacity(0.4)' : 'unset'};
`;
