import { css } from '@emotion/react';
import { theme } from 'global';

export const textfield = css`
  margin: 16px 0;
  min-width: 148px;
  background-color: ${theme.colors.white};

  > .MuiInputBase-root > input[type='number']::-webkit-inner-spin-button,
  > .MuiInputBase-root > input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > .MuiInputBase-root.Mui-error > .MuiInputAdornment-root > p {
    color: ${theme.colors.danger};
  }
`;
