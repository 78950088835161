/** @jsxImportSource @emotion/react */
import {
  CircularProgress as MuiCircularProgress,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import {
  Buttons,
  Dialogs,
  Labels,
  Selectable,
  Spinner,
  TextFields,
} from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import {
  Accordion,
  Divider,
  InstoreCodeDialog,
  InterestFreePeriodDialog,
  PageActions,
  PriceInput,
  Tooltip,
} from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import {
  Country,
  FeatureFlag,
  MerchantClassSettingType,
  OperationRequestStatus,
  OrderStatus,
  PageAction,
  PageRoute,
  ProductClassification,
  ProductDetail,
} from 'enums';
import { useFormik } from 'formik';
import { css, theme } from 'global';
import _ from 'lodash';
import AuthoriseAndCaptureDialog from 'modules/authorise-and-capture-dialog';
import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ChargeDetails,
  CreateOrderCommand,
  CreateOrderErrors,
  GetRepaymentsQuoteQuery,
  MerchantProduct,
  OrderDetails,
} from 'types';
import {
  Validators,
  currencyFormatter,
  logError,
  logEvent,
  productFormatter,
  selectFormatter,
  toTitleCase,
  useOrderFunctions,
} from 'utils';

import * as styles from './CreateOrder.styles';
import { CreateOrderModuleProps } from './CreateOrderModuleProps';

interface ProductDetailsAccordionRef {
  isExpanded: boolean;
}

const classificationOrder = {
  'Zip Money': 1,
  'Zip Plus': 2,
  'Zip Pay': 3,
};

export const CreateOrderModule: FC<CreateOrderModuleProps> = ({
  customerToCharge = null,
  updateCustomerToCharge,
  repaymentsQuoteCallback,
  isNewCustomerCallback,
  formHasErrorCallback,
  selectedProductClassificationCallback,
}) => {
  const Snackbar = useSnackbar();
  const OrderFunctions = useOrderFunctions();
  const { push, goBack } = useHistory();
  const {
    branches,
    getCurrentSelectedBranch,
    merchantCountry,
    merchantIdentity,
    merchantProducts,
    merchantProductsForDisplay,
    merchantDashboardSettings,
    checkMerchantDashboardSetting,
  } = useMerchantData();

  // Group merchantProducts
  const groupMerchantProducts = (
    products: MerchantProduct[]
  ): Record<string, Selectable[]> => {
    const newProducts: Record<string, Selectable[]> = {};

    _.sortBy(
      products,
      (mp) => classificationOrder[productFormatter(mp.productClassification)]
    ).forEach((mp) => {
      if (
        // Hide zipplus product if zipplus is not enabled or if customer is new for remote order
        // TODO: for future Z+ work once it's enabled, we need to remove this feature flag & logic once acqui is live for new zip plus customer
        !isZipPlusEnabled &&
        customerIsNewAndRemote &&
        mp.productClassification === ProductClassification.ZipPlus
      ) {
        return;
      }
      const classification = productFormatter(mp.productClassification);
      newProducts[classification] ??= [];
      newProducts[classification].push({
        label: (
          <span>
            {mp.description}
            {mp.promotion && (
              <Labels.Basic
                variant="primary"
                className="caption1"
                label="PROMO"
                style={{ marginLeft: 8, padding: '4px 16px' }}
              />
            )}
          </span>
        ),
        value: String(mp.creditProductId),
      });
    });
    return newProducts;
  };

  // Experiments
  const [isZipPlusEnabled] = useFeature(FeatureFlag.EnableZipPlus);

  const handleSuccess = (): void => {
    const selectedProduct: MerchantProduct = merchantProducts?.find(
      (product) =>
        product.creditProductId.toString() === formik.values.creditProduct
    );
    logEvent(PageAction.createOrderSuccess, {
      amount: formik.values.price,
      isRemoteOrder: !formik.values.isInstoreOrder,
      includesProductDetails: !!formik.values.productName,
      merchantDashboardSettings,
      creditProductToCharge: selectedProduct?.interestFreeMonths,
      classification: selectedProduct?.productClassification,
      promotion: selectedProduct?.promotion,
      orderSource: 'page',
    });
    push(PageRoute.Orders);
  };

  // Dialog controls
  const [isInstoreCodeDialogOpen, setIsInstoreCodeDialogOpen] =
    useState<boolean>(false);
  const [isIfpDialogOpen, setIsIfpDialogOpen] = useState<boolean>(false);

  // Form options
  const [branchesToDisplay, setBranchesToDisplay] = useState<Selectable[]>([]);
  const [brandsToDisplay, setBrandsToDisplay] = useState<Selectable[]>([]);
  const [creditProductsToDisplay, setCreditProductsToDisplay] = useState<
    Record<string, Selectable[]>
  >(groupMerchantProducts(merchantProductsForDisplay));
  const [customerIsNewAndRemote, setCustomerIsNewAndRemote] =
    useState<boolean>(false);

  // Form config
  const [instoreCodeEndIcon, setInstoreCodeEndIcon] = useState<JSX.Element>();
  const showBrandFields = brandsToDisplay?.length >= 1;
  const productDetailsAccordionRef = useRef<ProductDetailsAccordionRef>();
  const isReferenceRequired = checkMerchantDashboardSetting(
    MerchantClassSettingType.OrderReferenceRequired
  );

  // Loading states
  const [isProcessingOrder, setIsProcessingOrder] = useState<boolean>(false);
  const [isInstoreCodeValidating, setIsInstoreCodeValidating] =
    useState<boolean>(false);
  const [isWaitingCustomerAction, setIsWaitingCustomerAction] =
    useState<boolean>(false);
  const [isConfirmingAuthorised, setIsConfirmingAuthorised] =
    useState<boolean>(false);

  // Charge config
  const [promptContent, setPromptContent] = useState<JSX.Element | null>();
  const [completedCharge, setCompletedCharge] = useState<ChargeDetails>();
  const [authorisedOrder, setAuthorisedOrder] = useState<OrderDetails>();

  // Formik setup
  const formik = useFormik({
    initialValues: {
      branch: getCurrentSelectedBranch(),
      price: null,
      creditProduct:
        // if merchant has products, default to their Standard
        (merchantProducts &&
          merchantProducts?.length > 0 &&
          merchantProducts
            ?.find((product) => product.standard)
            ?.creditProductId?.toString()) ??
        // if no Standard, use lowest interest free period
        (merchantProducts?.length > 0 &&
          _(merchantProducts)
            .minBy((product) => product.interestFreeMonths)
            ?.creditProductId?.toString()) ??
        // edge case to assume no products, show Required error
        '',
      reference: '',
      brand: '',

      isInstoreOrder: true,
      instoreCode: '',

      isNewCustomer: true,
      lastName: '',
      email: '',
      // default to dead number if merchant is not in Australia to allow remote order
      mobile: merchantCountry === Country.Australia ? '' : '0400000000',
    },
    onSubmit: (values) => {
      if (isProcessingOrder) {
        return;
      }

      const selectedProduct: MerchantProduct = merchantProducts?.find(
        (product) =>
          product.creditProductId.toString() === formik.values.creditProduct
      );

      setIsProcessingOrder(true);
      logEvent(PageAction.createOrder, {
        amount: formik.values.price,
        isRemoteOrder: !values.isInstoreOrder,
        includesProductDetails: !!formik.values.productName,
        merchantDashboardSettings,
        creditProductToCharge: selectedProduct?.interestFreeMonths,
        classification: selectedProduct?.productClassification,
        promotion: selectedProduct?.promotion,
        orderSource: 'page',
      });

      let metadata;
      // if branded order
      if (formik?.values?.brand) {
        // only add brand to metadata
        metadata = {
          brand: formik?.values?.brand,
        };
        if (
          productDetailsAccordionRef?.current?.isExpanded &&
          !!formik?.values?.productName
        ) {
          // add product details if a brand that allows ProductDetails is selected
          metadata = {
            ...metadata,
            [ProductDetail.ProductName]: formik?.values?.productName,
            [ProductDetail.SerialNumber]: formik?.values?.serialNumber,
            [ProductDetail.ModelNumber]: formik?.values?.modelNumber,
          };
        }
      }

      return values.isInstoreOrder
        ? createInstoreOrder(selectedProduct?.interestFreeMonths, metadata)
        : createRemoteOrder(metadata);
    },
    initialTouched: {
      creditProduct: true,
    },
    validate: (values: CreateOrderCommand) => {
      const errors: CreateOrderErrors = {};

      if (!values.branch) {
        errors.branch = 'Required';
      }

      if (values.isInstoreOrder) {
        if (!values.instoreCode) {
          errors.instoreCode = 'Required';
        } else if (
          !isInstoreCodeValidating &&
          (!values.instoreCodeValidated || values.instoreCode?.length < 6)
        ) {
          errors.instoreCode = 'Please enter a valid 6 digit in-app barcode';
        }
      } else {
        if (!values.lastName) {
          errors.lastName = 'Required';
        } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.lastName)) {
          errors.lastName = 'Name contains invalid characters';
        } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.lastName)) {
          errors.lastName = 'Name must contain at least 2 letters';
        }

        if (!values.email) {
          errors.email = 'Required';
        } else if (!Validators.validateEmail(values.email)) {
          errors.email = 'Invalid email address';
        }

        if (!values.mobile) {
          errors.mobile = 'Required';
        } else if (!Validators.validateMobile(values.mobile)) {
          errors.mobile = 'Invalid mobile number';
        }
      }

      if (!values.creditProduct) {
        errors.creditProduct = 'Required';
      }

      if (!values.price) {
        errors.price = 'Required';
      } else if (
        values.price.toString().split('.')?.length > 1 &&
        values.price.toString().split('.')[1].length > 2
      ) {
        errors.price = 'Purchase amount must not exceed two decimal places';
      } else if (values.price && values.creditProduct) {
        // validate price is in selected product's trxMin and trxMax
        const currentProduct = merchantProductsForDisplay?.find(
          (product) =>
            product.creditProductId.toString() === values.creditProduct
        );
        if (values.price > currentProduct?.transactionLimitMax) {
          errors.creditProduct = `Maximum price for this interest free period is ${currencyFormatter(
            currentProduct?.transactionLimitMax
          )}`;
        }
        if (values.price < currentProduct?.transactionLimitMin) {
          errors.creditProduct = `Minimum price for this interest free period is ${currencyFormatter(
            currentProduct?.transactionLimitMin
          )}`;
        }
      }

      if (showBrandFields) {
        if (!values.brand) {
          errors.brand =
            "Please select 'Other' if the item's brand is not listed";
        }
        if (productDetailsAccordionRef?.current?.isExpanded) {
          if (
            !values.productName &&
            (!!values.serialNumber || !!values.modelNumber)
          ) {
            errors.productName =
              'All product details must be provided at the same time';
          }
          if (
            !values.serialNumber &&
            (!!values.productName || !!values.modelNumber)
          ) {
            errors.serialNumber =
              'All product details must be provided at the same time';
          }
          if (
            !values.modelNumber &&
            (!!values.productName || !!values.serialNumber)
          ) {
            errors.modelNumber =
              'All product details must be provided at the same time';
          }
        }
      }

      if (isReferenceRequired && !values.reference) {
        errors.reference = 'Required';
      }

      errors.price || errors.creditProduct
        ? formHasErrorCallback(true)
        : formHasErrorCallback(false);

      return errors;
    },
  });

  // Update ProductDetails validation on isExpanded changes
  useEffect(() => {
    if (!productDetailsAccordionRef?.current?.isExpanded) {
      formik.setFieldTouched('productName', false);
      formik.setFieldTouched('serialNumber', false);
      formik.setFieldTouched('modelNumber', false);
    }
    formik.validateForm();
  }, [productDetailsAccordionRef?.current?.isExpanded]);

  // Instore Order
  async function createInstoreOrder(
    interestFreeMonths: number,
    metadata?: Record<string, string>
  ): Promise<void> {
    if (!customerToCharge) {
      return;
    }

    try {
      const instoreOrder = await OrderFunctions.createCharge(
        formik.values,
        interestFreeMonths,
        metadata
      );
      setCompletedCharge(instoreOrder);
    } catch (err) {
      logError(PageAction.createOrder, err, formik.values);
      setIsProcessingOrder(false);

      const errorMessage = determineErrorMessage(err);
      Snackbar.error(errorMessage);
    }
  }

  function determineErrorMessage(error): JSX.Element | string {
    if (error.status === 409) {
      formik.setFieldTouched('instoreCode', false, false);
      formik.setFieldError('reference', 'The order reference must be unique');

      return (
        <>
          The order reference must be unique.
          <br />
          This reference has already been used.
        </>
      );
    }

    formik.setFieldValue('isInstoreCodeValidated', false, true);
    setInstoreCodeEndIcon(null);

    return 'Something went wrong creating the transaction. Please try again.';
  }

  // Poll for Customer action
  useEffect(() => {
    let poll;

    // React to Customer action
    async function statusChanged(chargeStatus): Promise<void> {
      clearInterval(poll);
      switch (chargeStatus as string) {
        case OperationRequestStatus.Cancelled:
        case OperationRequestStatus.Expired:
        case OperationRequestStatus.Declined:
          handleFailedCharge(chargeStatus);
          break;
        case OperationRequestStatus.Approved:
        case OperationRequestStatus.Completed:
          setPromptContent(<p>Finishing up on our end</p>);
          handleSuccessfulCharge(completedCharge?.id);
          break;
        default:
          setPromptContent(null);
          Snackbar.warning(
            'Something unexpected has occurred. Please verify your action was completed or try again.'
          );
      }
      setIsWaitingCustomerAction(false);
    }

    function handleFailedCharge(chargeStatus: string): void {
      Snackbar.warning(
        `The attempted order was ${String(
          chargeStatus
        )?.toLowerCase()}. Please try again.`
      );
      setIsProcessingOrder(false);
      setInstoreCodeEndIcon(
        <Icons.WarningCircle
          width="20"
          height="20"
          fill={theme.colors.danger}
        />
      );
      formik.setFieldValue('instoreCode', '');
      formik.setFieldValue('instoreCodeValidated', false);
    }

    async function handleSuccessfulCharge(chargeId: number): Promise<void> {
      const order = await OrderFunctions.getOrderDetails(chargeId);
      if (order?.orderStatus?.toLowerCase() === OrderStatus.Authorised) {
        setAuthorisedOrder(order);
        setIsConfirmingAuthorised(true);
      } else if (order?.orderStatus?.toLowerCase() === OrderStatus.Completed) {
        Snackbar.success(
          `Order was processed successfully with receipt number: ${chargeId}`
        );
        handleSuccess();
      }
      setIsProcessingOrder(false);
    }

    if (completedCharge?.id) {
      poll = setInterval(async () => {
        const charge = await OrderFunctions.checkChargeStatus(
          completedCharge?.id
        );

        if (
          charge?.status &&
          charge?.status !== OperationRequestStatus.Pending
        ) {
          statusChanged(charge.status);
        } else if (
          charge?.status &&
          charge?.status === OperationRequestStatus.Pending
        ) {
          setIsWaitingCustomerAction(true);
          setPromptContent(
            <p>
              Waiting for the customer to confirm the order on their mobile
              phone
            </p>
          );
        }
      }, 2500);
    }
    return (): void => clearInterval(poll);
  }, [completedCharge]);

  // Remote order/Send order
  async function createRemoteOrder(
    metadata?: Record<string, string>
  ): Promise<void> {
    try {
      metadata = {
        ...metadata,
        'is-new-customer': customerIsNewAndRemote.toString(),
      };

      await OrderFunctions.sendOrder(formik.values, metadata);
      Snackbar.success('Order sent successfully');
      handleSuccess();
    } catch (err) {
      logError(PageAction.remoteOrder, err, formik.values);
      Snackbar.error(
        'Something went wrong sending the transaction. Please try again.'
      );
    }
    setIsProcessingOrder(false);
  }

  // Update branches when loaded
  useEffect(() => {
    if (branches && merchantProducts) {
      const filteredBranches =
        branches?.filter((branch) => branch.id > 0) ?? [];

      setBranchesToDisplay(selectFormatter(filteredBranches, 'name', 'id'));
      formik.setFieldValue(
        'branch',
        getCurrentSelectedBranch() ?? filteredBranches[0]?.id?.toString()
      );
    }
  }, [merchantIdentity]);

  // Update Brands when MerchantSettingTypes is loaded
  useEffect(() => {
    const brandsList: Selectable[] = merchantDashboardSettings[
      MerchantClassSettingType.BrandedOrders
    ]
      ?.split(',')
      ?.map((brand: string) => ({
        label: brand?.trim(),
        value: brand?.toLowerCase()?.trim(),
      }));

    if (
      brandsList?.length > 0 &&
      !brandsList?.find((x) => x.value == 'other')
    ) {
      // Only add 'Other' option if not already defined in MST
      brandsList.push({ label: 'Other', value: 'other' });
    }
    setBrandsToDisplay(brandsList?.length > 0 && brandsList);
  }, [merchantDashboardSettings]);

  // Update available Interest Free Periods when in-store code is validated
  useEffect(() => {
    let filteredCreditProducts: MerchantProduct[] = [];

    if (!customerToCharge || !formik.values.isInstoreOrder) {
      // show all products if no validated customer and if remote order and customer exist
      // TODO: for future Z+ work once it's enabled, we need to remove this feature flag & logic once acqui is live for new zip plus customer
      filteredCreditProducts = merchantProductsForDisplay?.filter((product) =>
        !isZipPlusEnabled &&
        customerIsNewAndRemote &&
        product.productClassification === ProductClassification.ZipPlus
          ? false
          : true
      );

      // If it is remote order and customer is new reset selected creditProduct
      // TODO: for future Z+ work once it's enabled, we need to remove this feature flag & logic once acqui is live for new zip plus customer
      if (!isZipPlusEnabled && customerIsNewAndRemote) {
        const selectedProduct: MerchantProduct = merchantProducts?.find(
          (product) =>
            product.creditProductId.toString() === formik.values.creditProduct
        );

        if (
          selectedProduct?.productClassification ===
          ProductClassification.ZipPlus
        ) {
          formik.setFieldValue('creditProduct', '', true);
        }
      }
    }

    if (formik.values.isInstoreOrder && customerToCharge) {
      // show matching products to validated customer's ProductClassification
      filteredCreditProducts = merchantProductsForDisplay.filter(
        (product) =>
          product.productClassification ===
          customerToCharge?.productMetadata?.productClassification
      );
    }

    setCreditProductsToDisplay(groupMerchantProducts(filteredCreditProducts));

    // if there's only 1 product, set it by default
    if (filteredCreditProducts?.length === 1) {
      formik.setFieldValue(
        'creditProduct',
        filteredCreditProducts[0].creditProductId.toString(),
        true
      );
    }
  }, [customerToCharge, customerIsNewAndRemote]);

  // Update instoreCode endIcon for validation
  useEffect(() => {
    async function validateInstoreCode(): Promise<void> {
      if (!isProcessingOrder && !isConfirmingAuthorised) {
        setInstoreCodeEndIcon(
          <MuiCircularProgress size={18} thickness={5} color="primary" />
        );
        formik.setFieldValue('instoreCodeValidated', false, true);

        const result = await OrderFunctions.validateInStoreToken(
          formik.values.instoreCode
        );

        if (result?.isValidToken) {
          formik.setFieldValue('instoreCodeValidated', true, true);
          updateCustomerToCharge(result);
          setInstoreCodeEndIcon(
            <>
              <p className="body4" css={styles.validatedProduct}>
                {productFormatter(
                  result?.productMetadata?.productClassification
                )}
              </p>
              <Icons.CheckCircle
                width="20"
                height="20"
                fill={theme.colors.primary.medium}
              />
            </>
          );
          selectedProductClassificationCallback(
            result?.productMetadata?.productClassification
          );
        } else {
          formik.setFieldValue('instoreCodeValidated', false, true);
          setInstoreCodeEndIcon(
            <Icons.WarningCircle
              width="20"
              height="20"
              fill={theme.colors.danger}
            />
          );
          Snackbar.error('Something went wrong validating the instore code.');
        }
        setIsInstoreCodeValidating(false);
      }
    }

    if (
      formik.values.instoreCode?.toString()?.length === 6 &&
      !isInstoreCodeValidating &&
      formik.values.isInstoreOrder
    ) {
      validateInstoreCode();
    } else {
      formik.setFieldValue('instoreCodeValidated', false, true);
      setInstoreCodeEndIcon(null);
      updateCustomerToCharge(null);
    }
  }, [
    formik.values.instoreCode,
    formik.values.isInstoreOrder,
    isInstoreCodeValidating,
  ]);

  // Update repayments module
  useEffect(() => {
    const { price, creditProduct, isInstoreOrder, instoreCodeValidated } =
      formik.values;
    const { price: priceError, creditProduct: creditProductError } =
      formik.errors;

    const currentProduct = merchantProductsForDisplay?.find(
      (product) => product.creditProductId?.toString() === creditProduct
    );

    if (currentProduct?.productClassification in ProductClassification) {
      selectedProductClassificationCallback(
        currentProduct?.productClassification
      );
    }

    if (priceError || creditProductError) {
      repaymentsQuoteCallback({} as GetRepaymentsQuoteQuery);
      return;
    }

    const query = {
      price: price,
      interestFreeMonths: currentProduct?.interestFreeMonths,
    } as GetRepaymentsQuoteQuery;

    if (isInstoreOrder && instoreCodeValidated && customerToCharge?.accountId) {
      try {
        query.accountId = parseInt(customerToCharge.accountId);
      } catch (err) {
        logError(PageAction.quoteOrder, err, customerToCharge);
      }
    }

    repaymentsQuoteCallback(query);
  }, [
    formik.values.price,
    formik.values.creditProduct,
    formik.values.isInstoreOrder,
    formik.values.instoreCodeValidated,
    formik.errors.price,
    formik.errors.creditProduct,
    customerToCharge,
  ]);

  useEffect(() => {
    const isNewCustomer: boolean =
      !formik.values.isInstoreOrder && formik.values.isNewCustomer;
    isNewCustomerCallback(isNewCustomer);
    setCustomerIsNewAndRemote(isNewCustomer);
  }, [formik.values.isNewCustomer, formik.values.isInstoreOrder]);

  // blank out the loading prompt copy after an order has
  // been attempted whether successful or not
  useEffect(() => {
    if (!isProcessingOrder) {
      setPromptContent(null);
    }
  }, [isProcessingOrder]);

  // Shared Select component
  const FormikSelect: FC<{
    id: string;
    options: Selectable[];
    label?: string;
    helperText?: string;
    forceShowValidationError?: boolean;
  }> = ({
    id,
    label = toTitleCase(id),
    options = [],
    helperText = null,
    forceShowValidationError = false,
  }) => {
    const getHelperText = (): string => {
      if (
        (formik.touched[id] || forceShowValidationError) &&
        Boolean(formik.errors[id])
      ) {
        return formik.errors[id];
      }
      return helperText;
    };

    return (
      <TextFields.Select
        id={id}
        name={id}
        label={label}
        options={options}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[id]}
        error={formik.touched[id] && Boolean(formik.errors[id])}
        helperText={getHelperText()}
        fullWidth
        css={styles.textfield}
      />
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {branches && branches?.length > 1 && (
          <>
            <section id="business-details">
              <FormikSelect id="branch" options={branchesToDisplay} />
            </section>

            <Divider top={8} bottom={32} />
          </>
        )}

        <section id="transaction-type">
          <p className="body1" css={css.noMargin}>
            Does the customer have an in-app barcode?
          </p>
          <Buttons.Text
            className="body4"
            onClick={(): void => setIsInstoreCodeDialogOpen(true)}
          >
            How do I find this?
          </Buttons.Text>

          <MuiRadioGroup
            id="isInstoreOrder"
            value={String(formik.values.isInstoreOrder)}
            onChange={(e): void => {
              formik.setFieldValue('isInstoreOrder', e.target.value === 'true');
            }}
            css={styles.radioGroup}
            row
          >
            <MuiFormControlLabel
              value="true"
              control={<MuiRadio color="primary" />}
              label="Yes"
            />
            <MuiFormControlLabel
              value="false"
              control={<MuiRadio color="primary" />}
              label="No"
            />
          </MuiRadioGroup>

          {formik.values.isInstoreOrder && (
            <TextFields.Outlined
              id="instoreCode"
              type="tel"
              label="Customer barcode"
              inputProps={{ pattern: '[0-9]{6}' }}
              endIcon={
                <span css={styles.instoreCodeEndIcon}>
                  {instoreCodeEndIcon}
                </span>
              }
              disabled={!formik.values.isInstoreOrder}
              onChange={(e): void => {
                e?.target?.value?.length > 6
                  ? null
                  : formik.setFieldValue(
                      'instoreCode',
                      Math.abs(Number(e?.target?.value)),
                      true
                    );
              }}
              onBlur={formik.handleBlur}
              value={formik.values.instoreCode}
              error={
                formik.touched.instoreCode && Boolean(formik.errors.instoreCode)
              }
              helperText={'Enter or scan 6 digit in-app barcode'}
              css={styles.textfield}
              fullWidth
            />
          )}

          {!formik.values.isInstoreOrder && (
            <>
              <p className="body1" css={css.noMargin}>
                New or existing customer?
              </p>

              <MuiRadioGroup
                id="isNewCustomer"
                value={String(formik.values.isNewCustomer)}
                onChange={(e): void => {
                  formik.setFieldValue(
                    'isNewCustomer',
                    e.target.value === 'true'
                  );
                }}
                css={styles.radioGroup}
                row
              >
                <MuiFormControlLabel
                  value="true"
                  control={<MuiRadio color="primary" />}
                  label="New"
                />
                <MuiFormControlLabel
                  value="false"
                  control={<MuiRadio color="primary" />}
                  label="Existing"
                />
              </MuiRadioGroup>
            </>
          )}
        </section>

        <Divider top={0} bottom={32} />

        <section id="order-details">
          <PriceInput
            id="price"
            label="Enter price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.price ?? ''}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
            fullWidth
            css={styles.textfield}
          />
          <TextFields.GroupedSelect
            id="creditProduct"
            label="Product"
            name="creditProduct"
            options={creditProductsToDisplay}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.creditProduct}
            error={
              formik.touched.creditProduct &&
              Boolean(formik.errors.creditProduct)
            }
            helperText={
              formik.touched.creditProduct && formik.errors.creditProduct
            }
            fullWidth
            css={styles.textfield}
          />
          <Buttons.Text
            onClick={(): void => setIsIfpDialogOpen(true)}
            className="body4"
          >
            What interest free periods are available?
          </Buttons.Text>
        </section>

        <Divider top={32} bottom={32} />

        {!formik.values.isInstoreOrder && (
          <>
            <section id="customer-details">
              <TextFields.Outlined
                id="lastName"
                label="Customer's last name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                css={styles.textfield}
                className="fs-mask"
                fullWidth
              />
              <TextFields.Outlined
                id="email"
                label="Customer's email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                css={styles.textfield}
                className="fs-mask"
                fullWidth
              />
              {merchantCountry === Country.Australia && (
                <TextFields.Outlined
                  id="mobile"
                  type="tel"
                  label="Customer's mobile number"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                  css={styles.textfield}
                  className="fs-mask"
                  fullWidth
                />
              )}
            </section>

            <Divider top={0} bottom={32} />
          </>
        )}

        {showBrandFields && (
          <>
            <section id="product-details">
              <FormikSelect id="brand" options={brandsToDisplay} />
              <Accordion
                ref={productDetailsAccordionRef}
                summary={
                  <p className="body1" css={css.noMargin}>
                    Product details (optional)
                  </p>
                }
                details={
                  <>
                    <TextFields.Outlined
                      id="productName"
                      label="Product name"
                      value={formik.values.productName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        (formik.touched.productName ||
                          formik.touched.serialNumber ||
                          formik.touched.modelNumber) &&
                        Boolean(formik.errors.productName)
                      }
                      helperText={
                        (formik.touched.productName ||
                          formik.touched.serialNumber ||
                          formik.touched.modelNumber) &&
                        formik.errors.productName
                      }
                      inputProps={{ maxLength: 254 }}
                      css={styles.textfield}
                      fullWidth
                    />
                    <TextFields.Outlined
                      id="serialNumber"
                      label="Serial number"
                      value={formik.values.serialNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        (formik.touched.productName ||
                          formik.touched.serialNumber ||
                          formik.touched.modelNumber) &&
                        Boolean(formik.errors.serialNumber)
                      }
                      helperText={
                        (formik.touched.productName ||
                          formik.touched.serialNumber ||
                          formik.touched.modelNumber) &&
                        formik.errors.serialNumber
                      }
                      inputProps={{ maxLength: 254 }}
                      css={styles.textfield}
                      fullWidth
                    />
                    <TextFields.Outlined
                      id="modelNumber"
                      label="Model number"
                      value={formik.values.modelNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        (formik.touched.productName ||
                          formik.touched.serialNumber ||
                          formik.touched.modelNumber) &&
                        Boolean(formik.errors.modelNumber)
                      }
                      helperText={
                        (formik.touched.productName ||
                          formik.touched.serialNumber ||
                          formik.touched.modelNumber) &&
                        formik.errors.modelNumber
                      }
                      inputProps={{ maxLength: 254 }}
                      css={styles.textfield}
                      fullWidth
                    />
                  </>
                }
              />
            </section>

            <Divider top={8} bottom={32} />
          </>
        )}

        <section>
          <Accordion
            summary={
              <p className="body1" css={css.noMargin}>
                {isReferenceRequired
                  ? 'Order reference'
                  : 'Order reference (optional)'}
              </p>
            }
            defaultExpanded={isReferenceRequired}
            showExpandIcon={!isReferenceRequired}
            details={
              <TextFields.Outlined
                id="reference"
                label="Unique order reference"
                value={formik.values.reference}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.reference && Boolean(formik.errors.reference)
                }
                helperText={formik.touched.reference && formik.errors.reference}
                css={styles.textfield}
                fullWidth
                endIcon={
                  <Tooltip
                    content={
                      <>
                        <p className="body2" css={css.noMargin}>
                          This is your internal reference number, it can be an
                          invoice number, job number, or even just a reference
                          to the customer&apos;s name.
                        </p>
                      </>
                    }
                    placement="top"
                    enterTouchDelay={20}
                    leaveTouchDelay={3000}
                    interactive
                    maxWidth={300}
                    arrow
                    dense
                  >
                    <Icons.InformationCircle width="20" height="20" />
                  </Tooltip>
                }
              />
            }
          />
        </section>

        <PageActions>
          <Buttons.Primary
            id="createOrderButton"
            type="submit"
            disabled={
              isInstoreCodeValidating ||
              (formik.touched && Boolean(Object.keys(formik.errors).length))
            }
            onClick={(): void => formik.handleSubmit()}
            loading={isProcessingOrder}
          >
            Create order
          </Buttons.Primary>

          <Buttons.Text id="cancelButton" onClick={(): void => goBack()}>
            Cancel
          </Buttons.Text>
        </PageActions>
      </form>

      <Dialogs.Basic
        id="processingChargeDialog"
        onClose={(): void => null}
        open={isProcessingOrder || isWaitingCustomerAction}
        showClose={false}
        maxWidth="xs"
      >
        <div css={styles.loadingPrompt}>
          <Spinner />
          {!!promptContent && promptContent}
        </div>
      </Dialogs.Basic>

      <AuthoriseAndCaptureDialog
        open={!isProcessingOrder && isConfirmingAuthorised}
        receiptNumber={completedCharge?.id}
        orderReference={completedCharge?.refCode}
        onSuccess={handleSuccess}
        branchId={Number(formik.values.branch)}
        amountOwing={authorisedOrder?.amountOwing}
      />

      <InstoreCodeDialog
        open={isInstoreCodeDialogOpen}
        onClose={(): void => setIsInstoreCodeDialogOpen(false)}
      />

      <InterestFreePeriodDialog
        open={isIfpDialogOpen}
        customerIsNewAndRemote={customerIsNewAndRemote}
        onClose={(): void => setIsIfpDialogOpen(false)}
      />
    </>
  );
};

export default CreateOrderModule;
