// The ordering here determines the display order in the InnerDrawer
export enum OrderStatus {
  NotStarted = 'notstarted',
  InProgress = 'inprogress',
  UnderReview = 'underreview',
  ContractPending = 'contractpending',
  Authorised = 'authorised',
  PartiallyCaptured = 'partiallycaptured',
  Completed = 'completed',
  Refunded = 'refunded',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Declined = 'declined',
}
