import { css } from '@emotion/react';
import { css as globalCss, theme } from 'global';

export const headingCount = (isValid) => css`
  color: ${isValid ? theme.colors.primary.medium : theme.colors.danger};
`;

export const categoryCount = css`
  color: ${theme.colors.primary.medium};
  border-radius: 20px;
  background-color: ${theme.colors.neutral[50]};
  padding: 1px 8px;
  margin-left: 4px;
`;

export const categoryTag = css`
  margin: 0 0 8px !important;
  &:not(:last-of-type) {
    margin: 0 8px 8px 0 !important;
  }
`;
