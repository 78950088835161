export enum PageAction {
  externalRedirect = 'md-vnext-externalRedirect',
  createApiKey = 'md-vnext-apiKeys-create',
  updateApiKey = 'md-vnext-apiKeys-update',
  submitDirectorDetails = 'md-vnext-application-submitDirectorDetails',
  submitDirectorDocument = 'md-vnext-application-submitDirectorDocument',
  submitPrimaryContact = 'md-vnext-application-submitPrimaryContact',
  submitSettlementDetails = 'md-vnext-application-submitSettlementDetails',
  skipSettlementDetails = 'md-vnext-application-skipSettlementDetails',
  auth0Login = 'md-vnext-auth0-login',
  createCliInvite = 'md-vnext-cliInvite-create',
  searchCliInvites = 'md-vnext-cliInvite-search',
  searchCustomers = 'md-vnext-customers-search',
  fetchCompletedOrders = 'md-vnext-dashboard-fetchCompletedOrders',
  fetchCustomerOrders = 'md-vnext-customers-fetchOrders',
  fetchGrossSales = 'md-vnext-dashboard-fetchGrossSales',
  fetchOrdersToReview = 'md-vnext-dashboard-fetchOrdersToReview',
  loadDisbursementSchedule = 'md-vnext-disbursements-loadSchedule',
  generateDisbursementReport = 'md-vnext-disbursements-generate',
  openedHelpCentre = 'md-vnext-helpcentre-opened',
  openedHelpCentreMainPage = 'md-vnext-helpcentre-mainPageOpened',
  openedHelpCentreCategoryPage = 'md-vnext-helpcentre-categoryPageOpened',
  openedHelpCentreCategory = 'md-vnext-helpcentre-categoryOpened',
  openedHelpCentreArticle = 'md-vnext-helpcentre-articleOpened',
  initialLoad = 'md-vnext-initialLoad',
  createInvite = 'md-vnext-invite-create',
  fetchPosVendorUrl = 'md-vnext-posVendorUrl-fetch',
  openPosVendorUrl = 'md-vnext-posVendorUrl-open',
  cancelOperationRequest = 'md-vnext-operationrequests-cancel',
  addProductDetails = 'md-vnext-orders-addProductDetails',
  openOrderActions = 'md-vnext-orders-openActions',
  cancelOrder = 'md-vnext-orders-cancel',
  completeOrder = 'md-vnext-orders-complete',
  createOrder = 'md-vnext-orders-create',
  createOrderSuccess = 'md-vnext-orders-createSuccess',
  remoteOrder = 'md-vnext-orders-createRemote',
  fetchOrder = 'md-vnext-orders-fetch',
  refundOrder = 'md-vnext-orders-refund',
  quoteOrder = 'md-vnext-orders-quote',
  remindCustomer = 'md-vnext-orders-remindCustomer',
  resendOrder = 'md-vnext-orders-resend',
  removeOrder = 'md-vnext-orders-remove',
  searchOrders = 'md-vnext-orders-search',
  updateOrderReference = 'md-vnext-orders-updateReference',
  updateQuickActions = 'md-vnext-quickactions-update',
  calculateRepayments = 'md-vnext-repayments-calculate',
  fetchStoreProfile = 'md-vnext-storeProfile-fetch',
  submitStoreProfile = 'md-vnext-storeProfile-submit',
  generateStoreTileLogo = 'md-vnext-storeTile-generateLogo',
  generateStoreTileBackground = 'md-vnext-storeTile-generateBackground',
  loadUserPreferences = 'md-vnext-userpreferences-get',
  createUser = 'md-vnext-users-create',
  deleteUser = 'md-vnext-users-delete',
  searchUsers = 'md-vnext-users-search',
  updateUser = 'md-vnext-users-update',
  updateNotificationSettings = 'md-vnext-notificationSettings-update',
  fetchNotificationSettings = 'md-vnext-notificationSettings-fetch',
  openedSetupArticle = 'md-vnext-setupArticle-opened',
  openedSetupGuide = 'md-vnext-setupGuide-opened-banner',
  openedSetupGuideHC = 'md-vnext-setupGuide-opened-helpCentre',
  dismissedSetupGuide = 'md-vnext-setupGuide-dismissed',
  fetchZipCampaigns = 'md-vnext-zipCampaigns-fetch',
  openedZipCampaign = 'md-vnext-zipCampaigns-openedCampaign',
  openedZipCampaignAssets = 'md-vnext-zipCampaigns-openedAssets',
  submitStoreCategories = 'md-vnext-storeCategories-submit',
  fetchStoreCategories = 'md-vnext-storeCategories-fetch',
  openedUpdateMerchantForm = 'md-vnext-accountUpdate-merchant',
  sendMerchantOwnershipForm = 'md-vnext-accountUpdate-ownership-merchant',
  sendBranchOwnershipForm = 'md-vnext-accountUpdate-ownership-branch',
  openedUpdateBranchForm = 'md-vnext-accountUpdate-branch',
  openedNewBranchesForm = 'md-vnext-accountUpdate-newBranches',
  openedNewBranchForm = 'md-vnext-accountUpdate-newBranch',
  fetchContentfulContent = 'md-vnext-contentful-fetchContent',
  clickedContentfulAction = 'md-vnext-contentful-clickedAction',
  purchantPlan = 'md-vnext-purchasePlan',
  clickedPurchasePlanSend = 'md-vnext-purchasePlan-send',
  clickedPurchasePlanCopy = 'md-vnext-purchasePlan-copy',
}
