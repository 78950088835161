/** @jsxImportSource @emotion/react */
import { Hidden as MuiHidden } from '@material-ui/core';
import { Buttons, Loading, TextFields } from '@zip/business-components';
import { DenseButton } from 'components';
import { useMerchantData, useSnackbar } from 'contexts';
import { PermissionEnum } from 'enums';
import { css } from 'global';
import { useFetch } from 'hooks';
import CreateApiKeyDialogModule from 'modules/create-api-key/CreateApiKeyDialog.module';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  BranchIntegrationDetailResponse,
  WebBranchIntegrationDetailResponse,
  WebIntegrationDetailResponse,
} from 'types';
import IntegrationKeys from '../integration-key';
import * as styles from './IntegrationDetails.styles';

interface IntegrationDetailProps {
  callback: () => void;
}

export const Web = forwardRef(({ callback }: IntegrationDetailProps, ref) => {
  const { get } = useFetch();
  const { merchantIdentity, checkPermission } = useMerchantData();
  const { success } = useSnackbar();
  const abortController = new AbortController();

  const branchKeyHeightPx = 132;
  const pageSize = Math.round(window.innerHeight / branchKeyHeightPx);

  const [webIntegrationDetails, setWebIntegrationDetails] =
    useState<WebIntegrationDetailResponse>();
  const [webDetailsLoading, setWebDetailsLoading] = useState<boolean>(false);

  const [displayWebBranchList, setDisplayWebBranchList] =
    useState<boolean>(false);
  const [webBranchIntegrationDetails, setWebBranchIntegrationDetails] =
    useState<WebBranchIntegrationDetailResponse>();
  const [webBranchDetailsLoading, setWebBranchDetailsLoading] =
    useState<boolean>(false);
  const [webBranchSearchValue, setWebBranchSearchValue] = useState<string>();

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  async function getWebBranchDetails(
    newList = true,
    initialLoad = false
  ): Promise<void> {
    setWebBranchDetailsLoading(true);
    let url = `/integration/branch-v2-details?skip=${
      newList
        ? 0
        : webBranchIntegrationDetails?.branchIntegrationDetails?.length
    }&take=${pageSize}`;
    if (webBranchSearchValue) {
      url = `${url}&searchValue=${webBranchSearchValue}`;
    }
    get(url, abortController.signal)
      .then((res) => res?.json())
      .then((response: WebBranchIntegrationDetailResponse) => {
        if (initialLoad) {
          setDisplayWebBranchList(
            response?.branchIntegrationDetails?.length > 0
          );
        }
        if (newList) {
          setWebBranchIntegrationDetails(response);
        } else {
          setWebBranchIntegrationDetails({
            ...response,
            branchIntegrationDetails:
              webBranchIntegrationDetails?.branchIntegrationDetails?.concat(
                response?.branchIntegrationDetails
              ),
          } as WebBranchIntegrationDetailResponse);
        }
      })
      .catch((): void => {
        if (newList) {
          setWebBranchIntegrationDetails(null);
        }
      })
      .finally(() => setWebBranchDetailsLoading(false));
  }

  async function getWebDetails(newList = true): Promise<void> {
    setWebDetailsLoading(true);
    get(
      `/integration/web-details?skip=${
        newList ? 0 : webIntegrationDetails?.webIntegrationDetails?.length
      }&take=3`,
      abortController.signal
    )
      .then((res) => res?.json())
      .then((response) => {
        if (newList) {
          setWebIntegrationDetails(response as WebIntegrationDetailResponse);
        } else {
          setWebIntegrationDetails({
            ...webIntegrationDetails,
            webIntegrationDetails:
              webIntegrationDetails?.webIntegrationDetails.concat(
                response?.webIntegrationDetails
              ),
          } as WebIntegrationDetailResponse);
        }
      })
      .catch(() => {
        if (newList) {
          setWebIntegrationDetails(null);
        }
      })
      .finally(() => setWebDetailsLoading(false));
  }

  const handleCreateClick = async (): Promise<void> => {
    setIsCreateDialogOpen(true);
  };

  const getIntegrationDetails = (): void => {
    getWebDetails();
    getWebBranchDetails(true, true);
  };

  useEffect(() => {
    if (merchantIdentity) {
      getIntegrationDetails();
    }

    return (): void => abortController.abort();
  }, [merchantIdentity]);

  useImperativeHandle(ref, () => ({
    refresh: (): void => getIntegrationDetails(),
  }));

  return (
    <div css={styles.container}>
      <p css={css.noTopMrgn}>
        You can find a full list of our supported eCommerce platforms{' '}
        <a
          href="https://developers.zip.co/docs/ecommerce-platforms"
          target="_blank"
          rel="noopener noreferrer"
          className="body2"
        >
          here
        </a>
      </p>
      <p>
        Using an unsupported platform? You can find our API documentation to
        build a custom integration{' '}
        <a
          href="https://developers.zip.co/v2/docs"
          target="_blank"
          rel="noopener noreferrer"
          className="body2"
        >
          here
        </a>
      </p>
      <div>
        <div>
          <h3>Merchant ID</h3>
          <p id="merchantId" css={css.noTopMrgn}>
            {webDetailsLoading ? (
              <Loading />
            ) : (
              webIntegrationDetails?.merchantId
            )}
          </p>
        </div>
        <div>
          <h3>Public key</h3>
          <p id="publicKey" css={css.noTopMrgn}>
            <IntegrationKeys.Basic
              value={webIntegrationDetails?.publicKey}
              loading={webDetailsLoading}
            />
          </p>
        </div>
        <div>
          <h3 css={css.flex('row', 'space-between', 'baseline')}>
            Private{' '}
            {webIntegrationDetails?.webIntegrationDetails?.length > 1
              ? 'keys'
              : 'key'}
            {checkPermission(PermissionEnum.ApiIntegrationCreate) && (
              <div>
                <DenseButton onClick={handleCreateClick}>
                  Create new API key
                </DenseButton>
                <CreateApiKeyDialogModule
                  open={isCreateDialogOpen}
                  onClose={(): void => setIsCreateDialogOpen(false)}
                  onSuccess={(): void => {
                    callback();
                    success('API key created successfully');
                  }}
                />
              </div>
            )}
          </h3>

          <p id="privateKeys" css={css.noTopMrgn}>
            {(webIntegrationDetails?.webIntegrationDetails?.length > 1 && (
              <>
                {webIntegrationDetails?.webIntegrationDetails?.length > 1 && (
                  <IntegrationKeys.Branch
                    branch={{
                      integrationDetails:
                        webIntegrationDetails?.webIntegrationDetails,
                    }}
                    onUpdate={(): Promise<void> => getWebDetails()}
                  />
                )}
                {webDetailsLoading && <Loading />}
                {webIntegrationDetails?.hasMore && !webDetailsLoading && (
                  <Buttons.Text
                    onClick={(): Promise<void> => getWebDetails(false)}
                  >
                    View more
                  </Buttons.Text>
                )}
              </>
            )) || (
              <IntegrationKeys.Branch
                branch={{
                  integrationDetails:
                    webIntegrationDetails?.webIntegrationDetails,
                }}
                loading={webDetailsLoading}
              />
            )}
          </p>
        </div>
      </div>
      {/* Web Branch Integration details */}
      {displayWebBranchList && (
        <div css={styles.container}>
          <div css={styles.mobileToolbar}>
            <h4 css={css.noBtmMrgn}>Web Branches</h4>
          </div>
          <div css={styles.detailContainer}>
            <div css={styles.innerContainer}>
              <TextFields.Outlined
                id="webBranchSearch"
                value={webBranchSearchValue}
                onChange={(e): void =>
                  setWebBranchSearchValue(e?.target?.value)
                }
                onKeyPress={(e): void => {
                  if (e.code === 'Enter') {
                    getWebBranchDetails();
                  }
                }}
                label="Search web branch"
                css={[styles.branchSearch, css.noTopMrgn, css.noBtmMrgn]}
              />
              <MuiHidden only="xs">
                <Buttons.Text
                  id="submitSearchBranch"
                  onClick={(): Promise<void> => getWebBranchDetails()}
                  css={styles.searchButton}
                >
                  Search
                </Buttons.Text>
              </MuiHidden>
            </div>

            <MuiHidden smUp>
              <Buttons.Primary
                id="submitSearchWebBranch"
                onClick={(): Promise<void> => getWebBranchDetails()}
                style={{ marginBottom: 16 }}
                fullWidth
              >
                Search
              </Buttons.Primary>
            </MuiHidden>
          </div>
          {webBranchIntegrationDetails?.branchIntegrationDetails?.length > 0 &&
            webBranchIntegrationDetails?.branchIntegrationDetails?.map(
              (branch) => (
                <IntegrationKeys.Branch
                  key={branch.locationId}
                  branch={branch}
                />
              )
            )}
          {!webBranchDetailsLoading &&
            !webBranchIntegrationDetails?.branchIntegrationDetails?.length && (
              <div className="body2" css={styles.centeredData}>
                No branches found
              </div>
            )}
          {webBranchDetailsLoading && <Loading />}
          {webBranchIntegrationDetails?.hasMore && !webBranchDetailsLoading && (
            <Buttons.Text
              onClick={(): Promise<void> => getWebBranchDetails(false)}
            >
              View more
            </Buttons.Text>
          )}
        </div>
      )}
    </div>
  );
});

export const Instore = forwardRef((_, ref) => {
  const { get } = useFetch();
  const { merchantIdentity } = useMerchantData();
  const abortController = new AbortController();

  const branchKeyHeightPx = 132;
  const pageSize = Math.round(window.innerHeight / branchKeyHeightPx);

  const [instoreIntegrationDetails, setInstoreIntegrationDetails] =
    useState<BranchIntegrationDetailResponse>();
  const [instoreDetailsLoading, setInstoreDetailsLoading] =
    useState<boolean>(false);
  const [branchSearchValue, setBranchSearchValue] = useState<string>();

  async function getBranchDetails(newList = true): Promise<void> {
    setInstoreDetailsLoading(true);
    let url = `/integration/branch-details?skip=${
      newList ? 0 : instoreIntegrationDetails?.branchIntegrationDetails?.length
    }&take=${pageSize}`;
    if (branchSearchValue) {
      url = `${url}&searchValue=${branchSearchValue}`;
    }
    get(url, abortController.signal)
      .then((res) => res?.json())
      .then((response) => {
        if (newList) {
          setInstoreIntegrationDetails(
            response as BranchIntegrationDetailResponse
          );
        } else {
          setInstoreIntegrationDetails({
            ...response,
            branchIntegrationDetails:
              instoreIntegrationDetails?.branchIntegrationDetails?.concat(
                response?.branchIntegrationDetails
              ),
          } as BranchIntegrationDetailResponse);
        }
      })
      .catch(() => {
        if (newList) {
          setInstoreIntegrationDetails(null);
        }
      })
      .finally(() => setInstoreDetailsLoading(false));
  }

  useEffect(() => {
    if (merchantIdentity) {
      getBranchDetails();
    }

    return (): void => abortController.abort();
  }, [merchantIdentity]);

  useImperativeHandle(ref, () => ({
    refresh: (): Promise<void> => getBranchDetails(),
  }));

  return (
    <div css={styles.container}>
      <p css={css.noTopMrgn}>
        You can find a full list of our supported Point of Sale platforms{' '}
        <a
          href="https://developers.zip.co/v5/docs/getting-started-1"
          target="_blank"
          rel="noopener noreferrer"
          className="body2"
        >
          here
        </a>
      </p>
      <p>
        Using an unsupported system? You can still use our merchant dashboard.
        You can find training guides{' '}
        <a
          href="https://developers.zip.co/docs/the-zip-dashboard"
          target="_blank"
          rel="noopener noreferrer"
          className="body2"
        >
          here
        </a>
      </p>
      <div>
        <h3>Legacy key</h3>
        <p id="legacyKey" css={css.noTopMrgn}>
          <IntegrationKeys.Basic value={instoreIntegrationDetails?.legacyKey} />
        </p>
      </div>
      <div css={styles.mobileToolbar}>
        <h4 css={css.noBtmMrgn}>Branches</h4>
      </div>
      <div css={styles.detailContainer}>
        <div css={styles.innerContainer}>
          <TextFields.Outlined
            id="branchSearch"
            value={branchSearchValue}
            onChange={(e): void => setBranchSearchValue(e?.target?.value)}
            onKeyPress={(e): void => {
              if (e.code === 'Enter') {
                getBranchDetails();
              }
            }}
            label="Search branch"
            css={[styles.branchSearch, css.noTopMrgn, css.noBtmMrgn]}
          />
          <MuiHidden only="xs">
            <Buttons.Text
              id="submitSearchBranch"
              onClick={(): Promise<void> => getBranchDetails()}
              css={styles.searchButton}
            >
              Search
            </Buttons.Text>
          </MuiHidden>
        </div>

        <MuiHidden smUp>
          <Buttons.Primary
            id="submitSearchBranch"
            onClick={(): Promise<void> => getBranchDetails()}
            style={{ marginBottom: 16 }}
            fullWidth
          >
            Search
          </Buttons.Primary>
        </MuiHidden>
      </div>
      {instoreIntegrationDetails?.branchIntegrationDetails?.length > 0 &&
        instoreIntegrationDetails?.branchIntegrationDetails?.map((branch) => (
          <IntegrationKeys.Branch
            key={branch.locationId}
            branch={branch}
            onUpdate={(): Promise<void> => getBranchDetails()}
          />
        ))}
      {instoreDetailsLoading && <Loading />}
      {!instoreDetailsLoading &&
        !instoreIntegrationDetails?.branchIntegrationDetails?.length && (
          <div className="body2" css={styles.centeredData}>
            No branches found
          </div>
        )}
      {instoreIntegrationDetails?.hasMore && !instoreDetailsLoading && (
        <Buttons.Text onClick={(): Promise<void> => getBranchDetails(false)}>
          View more
        </Buttons.Text>
      )}
    </div>
  );
});
