/** @jsxImportSource @emotion/react */
import MuiPaper from '@material-ui/core/Paper';
import { Labels } from '@zip/business-components';

import * as Icons from '@zip/react-icons/fearless';
import { useMerchantData } from 'contexts';
import { css, theme } from 'global';
import { FC } from 'react';
import { getStatusVariant } from 'utils/colouring';
import {
  currencyFormatter,
  dateFormatter,
  formatNumberOfDays,
  productFormatter,
  statusFormatter,
} from 'utils/formatters';
import * as styles from './SummaryCard.styles';
import {
  CustomerCardProps,
  OrderCardProps,
  UserCardProps,
} from './SummaryCardProps';

export const Order: FC<OrderCardProps> = ({
  order,
  selected = false,
  onClick,
  key,
  ...props
}) => {
  return (
    <MuiPaper
      css={styles.paper(selected, false)}
      onClick={onClick}
      key={key}
      {...props}
    >
      <div css={styles.paperContent}>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 css={styles.date}>{dateFormatter(order?.orderDate, 'day')}</h4>

          {order && (
            <p css={[css.noBtmMrgn, css.noTopMrgn]}>
              {currencyFormatter(order.orderTotal)}
            </p>
          )}
        </span>
        <div css={styles.summary}>
          <div css={styles.customerDetails(false)}>
            <p className="fs-mask">
              {order.firstName
                ? `${order.firstName} ${order.lastName}`
                : order.lastName}
            </p>
            {order && <p>{order.orderReference ?? order.orderId}</p>}
          </div>
          <div css={styles.orderDetails}>
            <Labels.Basic
              variant={getStatusVariant(order?.status)}
              label={statusFormatter(order?.status)}
              css={styles.label}
            />
          </div>
        </div>
      </div>

      {order?.expireInDays >= 0 && order?.expireInDays < 30 && (
        <div css={styles.orderExpiry}>
          <p className="body3">
            Order expires {order?.expireInDays > 0 ? 'in' : ''}{' '}
            {formatNumberOfDays(order?.expireInDays).toLowerCase()}
          </p>
        </div>
      )}
    </MuiPaper>
  );
};

export const Customer: FC<CustomerCardProps> = ({
  customer,
  selected = false,
  onClick,
  key,
  ...props
}) => {
  // @ts-expect-error - nullables to handle CustomerDetail or CliRequest models
  const date = customer?.statusDate ?? customer?.updatedDateTime;
  return (
    <MuiPaper
      css={styles.paper(selected)}
      onClick={onClick}
      key={key}
      {...props}
    >
      <div css={styles.summary}>
        <div css={styles.customerDetails(true)}>
          <h4 css={styles.date} className="fs-mask">
            {customer?.consumerName}
          </h4>
          <p>{productFormatter(customer?.productClassification)}</p>
        </div>
        <div css={styles.orderDetails}>
          <p>{dateFormatter(date, 'day')}</p>
          <Labels.Basic
            variant={getStatusVariant(customer?.status)}
            label={statusFormatter(customer?.status)}
            css={styles.label}
          />
        </div>
      </div>
    </MuiPaper>
  );
};

export const User: FC<UserCardProps> = ({
  user,
  selected = false,
  onClick,
  key,
  actions = [],
  ...props
}) => {
  const { isCompanyUser } = useMerchantData();
  const mappedActions: JSX.Element[] = [];
  const updateAction = actions?.find((x) => x.label == 'Update');
  if (updateAction) {
    mappedActions.push(
      <Icons.Wrench
        width={24}
        height={24}
        fill={theme.colors.primary.dark}
        onClick={updateAction.onClick}
      />
    );
  }
  const deleteAction = actions?.find((x) => x.label == 'Delete');
  if (deleteAction) {
    mappedActions.push(
      <Icons.Trash
        width={24}
        height={24}
        fill={theme.colors.primary.dark}
        onClick={deleteAction.onClick}
      />
    );
  }
  return (
    <MuiPaper
      css={styles.paper(selected)}
      onClick={onClick}
      key={key}
      {...props}
    >
      <>
        <h4 css={styles.date} className="fs-mask">
          {user?.firstName} {user?.lastName}
        </h4>
      </>
      <div css={styles.summary}>
        <div css={styles.customerDetails}>
          <p>{user.roleName}</p>
          {mappedActions?.length >= 1 && (
            <span>
              {mappedActions?.map((action) => (
                <>{action} </>
              ))}
            </span>
          )}
        </div>
        <div css={styles.orderDetails}>
          {isCompanyUser && <p className="body4">{user.userType}</p>}
          <Labels.Basic
            variant={user?.blocked ? 'error' : 'success'}
            label={statusFormatter(user?.blocked ? 'Disabled' : 'Active')}
            css={styles.label}
          />
        </div>
      </div>
    </MuiPaper>
  );
};
