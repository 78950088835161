/** @jsxImportSource @emotion/react */
import {
  Divider,
  Grid as MuiGrid,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from '@material-ui/core';
import { useFeature } from '@optimizely/react-sdk';
import { Alert, Spinner } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Accordion, Tooltip, ZipPlusMMPDialog } from 'components';
import { useMerchantData } from 'contexts';
import { FeatureFlag, ProductClassification } from 'enums';
import { Constants, css } from 'global';
import _ from 'lodash';
import { InstalmentScheduleModule } from 'modules';
import { FC, useEffect, useState } from 'react';
import { RepaymentCalculationsV2 } from 'types';
import { currencyFormatter, productFormatter } from 'utils';

import * as styles from './RepaymentsCalculatorModule.styles';
import { RepaymentsCalculatorModuleProps } from './RepaymentsCalculatorModuleProps';

enum RepaymentFrequency {
  Weekly = 'weekly',
  Fortnightly = 'fortnightly',
  Monthly = 'monthly',
}

const RepaymentsCalculatorModule: FC<RepaymentsCalculatorModuleProps> = ({
  orderPrice,
  repaymentsQuote,
  customerToCharge,
  selectedProductClassification,
  isLoading,
  isNewCustomer,
  formHasError,
}) => {
  // Variables
  const [selectedQuote, setSelectedQuote] = useState<RepaymentCalculationsV2>();
  const [isQuoteEmpty, setIsQuoteEmpty] = useState<boolean>(true);
  const [selectedRepaymentFrequency, setSelectedRepaymentFrequency] =
    useState<RepaymentFrequency>(RepaymentFrequency.Monthly);
  const [isFlex, setIsFlex] = useState<boolean>(false);
  const [isZipPlusMmpDialogOpen, setIsZipPlusMmpDialogOpen] =
    useState<boolean>(false);

  const { profileData, merchantProductsForDisplay } = useMerchantData();

  const merchantIsExclusive = profileData?.merchantData?.isExclusive ?? false;

  const merchantMinZipMoneyInterestFreeMonths =
    _.min(
      merchantProductsForDisplay
        ?.filter(
          (merchantProduct) =>
            merchantProduct.productClassification ==
            ProductClassification.ZipMoney
        )
        .map((merchantProduct) => merchantProduct.interestFreeMonths)
    ) ?? 3;

  const ZipPlusInterestFreeThreshold = currencyFormatter(
    Constants.productDefaults['Zip Plus'].interestFreeThreshold,
    false
  );

  // Experiments
  const [isZipPlusAcquiPhase2Enabled] = useFeature(
    FeatureFlag.ZipPlusAcquiPhaseTwo
  );

  // Functions
  function getFlexMessage(): JSX.Element {
    if (customerToCharge && customerToCharge.hasInstallmentsEnabled) {
      return (
        <p className="body2">
          This customer account is set to flexible repayments.
        </p>
      );
    }
    return (
      <p className="body2" css={css.marginTop(0)}>
        Zip Money purchases below $300 are set to{' '}
        <a
          href="https://help.zip.co/hc/en-us/articles/360001585655-How-do-Zip-Money-repayments-work-custom-verse-instalment-"
          target="blank"
        >
          flexible repayments.
        </a>
      </p>
    );
  }

  function getMonthlyAccountFee(): number {
    const defaultAccountFee =
      Constants.productDefaults[productFormatter(selectedProductClassification)]
        .monthlyFee;
    return !formHasError
      ? selectedQuote?.monthlyAccountFee ?? defaultAccountFee
      : defaultAccountFee;
  }

  function merchantTransactionLimit(
    classification: ProductClassification
  ): string {
    return currencyFormatter(
      _.max(
        merchantProductsForDisplay
          ?.filter(
            (merchantProduct) =>
              merchantProduct.productClassification == classification
          )
          .map((merchantProduct) => merchantProduct.transactionLimitMax)
      ) ??
        Constants.productDefaults[productFormatter(classification)]
          .TransactionLimit,
      false
    );
  }
  function getMinimumMonthlyPayment(): number {
    const defaultMMP =
      Constants.productDefaults[productFormatter(selectedProductClassification)]
        .minimumMonthlyPayment;
    return !formHasError
      ? selectedQuote?.minimumMonthlyRepayment ?? defaultMMP
      : defaultMMP;
  }

  function getEstablishmentFee(): number {
    if (!isNewCustomer) {
      return 0;
    }
    if (formHasError) {
      return 0;
    }
    return selectedQuote?.establishmentFee ?? 0;
  }

  function getEqualInstalmentAmounts(): number {
    return !formHasError ? selectedQuote?.equalInstalmentAmounts ?? 0 : 0;
  }

  function convertAndFormatPayment(monthlyAmount: number): string {
    switch (selectedRepaymentFrequency) {
      case RepaymentFrequency.Fortnightly:
        return currencyFormatter(
          monthlyAmount / Constants.timeframe.fortnightsInAMonth
        );
      case RepaymentFrequency.Weekly:
        return currencyFormatter(
          monthlyAmount / Constants.timeframe.weeksInAMonth
        );
      case RepaymentFrequency.Monthly:
      default:
        return currencyFormatter(monthlyAmount);
    }
  }

  // Elements
  function productDescription(): JSX.Element {
    let productDescription: string;
    const transactionLimit = merchantTransactionLimit(
      selectedProductClassification
    );
    switch (selectedProductClassification) {
      case ProductClassification.ZipMoney:
        productDescription = `Use Zip Money for purchases of any amount up to${' '}
          ${transactionLimit}, with a minimum of ${merchantMinZipMoneyInterestFreeMonths} months interest free.`;
        break;
      case ProductClassification.ZipPay:
        productDescription = `Use Zip Pay for any purchases up to${' '}
          ${transactionLimit}, interest free always.`;
        break;
      case ProductClassification.ZipPlus:
        productDescription = `For any purchases up to ${transactionLimit}. Interest free when the amount owing is ${ZipPlusInterestFreeThreshold} or less at the end of the month.`;
        break;
    }

    return (
      <>
        {isZipPlusAcquiPhase2Enabled &&
        selectedProductClassification == ProductClassification.ZipPay ? (
          <Alert variant="info" css={styles.upgradeAlert}>
            This customer may be eligible for an upgrade to Zip Plus.{' '}
            <a href="https://zip.co/au/zip-plus" target="_blank">
              Learn more
            </a>
          </Alert>
        ) : (
          <p className="body2" css={css.marginBottom(0)}>
            {productDescription}
          </p>
        )}
      </>
    );
  }

  const productDisclaimer = (): JSX.Element => {
    const { abn, creditLicence } = Constants.zipInfo;
    switch (selectedProductClassification) {
      case ProductClassification.ZipPlus:
        const {
          interestFreeThreshold,
          interestRate: { percent },
          monthlyFee,
        } = Constants.productDefaults['Zip Plus'];
        return (
          <div>
            <p>
              Interest accrues daily on the total balance owing at the end of
              each day. No interest will be charged to your account when your
              balance is under {currencyFormatter(interestFreeThreshold, false)}{' '}
              (including where it is paid in full) at the end of the last day of
              each month. Each day ends at 11:59pm.
            </p>
            <p>
              Pay your closing balance in full by the end of each calendar month
              and we'll also waive the monthly account fee of{' '}
              {currencyFormatter(monthlyFee)} for that month. A monthly account
              fee of {currencyFormatter(monthlyFee)} will otherwise apply.
              Standard interest rate is {percent}% p.a.
            </p>
            <br />
            <p>
              T&Cs and credit approval criteria apply. Interest and other
              charges may be payable, see T&Cs. Interest, fees and charges
              subject to change.
            </p>
            <p>
              Credit provided by ZipMoney Payments Pty Ltd (ABN {abn}),
              Australian Credit Licence Number {creditLicence}.
            </p>
          </div>
        );
      default:
        const { effectiveDate, percent: zmPercent } =
          Constants.productDefaults['Zip Money'].interestRate;
        return (
          <div>
            <p>
              Available to approved applicants only and subject to completion of
              satisfactory credit check.
            </p>
            <p>
              Minimum monthly repayments are required. Paying only the minimum
              monthly repayment amount will generally not repay the purchase
              within the interest free period.
            </p>
            <p>
              A monthly account fee will apply and a one-off establishment fee
              may apply for new customers.
            </p>
            <p>
              Any balance outstanding at the expiry of the interest free period
              will be charged at the standard variable interest rate of{' '}
              {zmPercent}% per annum as at {effectiveDate}.
            </p>
            <p>
              Other charges may be payable, see T&Cs. Interest, fees and charges
              are subject to change.
            </p>
            <br />
            <p>
              Terms & conditions apply and are available on application. See
              your contract for further details.
            </p>
            <p>
              Credit provided by ZipMoney Payments Pty Ltd (ABN {abn}),
              Australian Credit Licence Number {creditLicence}.
            </p>
          </div>
        );
    }
  };

  function frequencyTile(): JSX.Element {
    return (
      <MuiGrid
        container
        item
        xs={12}
        css={[styles.whiteCard('md'), styles.frequencyTile]}
      >
        <MuiGrid item xs={12}>
          <MuiTabs
            value={selectedRepaymentFrequency}
            onChange={(e, value): void => setSelectedRepaymentFrequency(value)}
            indicatorColor="primary"
            textColor="primary"
            centered
            css={styles.tabs}
            selectionFollowsFocus
          >
            <MuiTab
              id="0"
              value={RepaymentFrequency.Weekly}
              label="Weekly"
              css={styles.frequencyTab}
            />
            <MuiTab
              id="1"
              value={RepaymentFrequency.Fortnightly}
              label="Fortnightly"
              css={styles.frequencyTab}
            />
            <MuiTab
              id="2"
              value={RepaymentFrequency.Monthly}
              label="Monthly"
              css={styles.frequencyTab}
            />
          </MuiTabs>
        </MuiGrid>
        <MuiGrid
          item
          css={[
            styles.whiteCard('lg'),
            styles.balanceWhiteCard,
            styles.minimumMonthlyPayment,
          ]}
        >
          <p className="body1">Min. {selectedRepaymentFrequency} payment</p>
          <p className="heading1" css={isLoading && styles.mask}>
            {convertAndFormatPayment(getMinimumMonthlyPayment())}
          </p>
        </MuiGrid>
        <MuiGrid
          item
          css={[
            styles.whiteCard('lg'),
            styles.balanceWhiteCard,
            styles.monthlyAccountFee,
          ]}
        >
          <p className="body1">
            Account fee
            <Tooltip
              content={
                <div css={styles.tooltipContent}>
                  <p className="body1">Monthly account fee</p>
                  <p className="body4">
                    No fee when you have $0 balance at the end of the month.
                  </p>
                </div>
              }
              placement="bottom"
              enterDelay={20}
              leaveDelay={1000}
              interactive
              maxWidth={240}
              arrow
              variant="primary"
              css={styles.tooltipIcon}
            >
              <Icons.InformationCircle width="16" height="16" />
            </Tooltip>
          </p>

          <p className="heading1" css={isLoading && styles.mask}>
            {currencyFormatter(getMonthlyAccountFee())}
            <span className="body4"> /month</span>
          </p>
        </MuiGrid>
        {selectedProductClassification == ProductClassification.ZipMoney &&
          isNewCustomer && (
            <MuiGrid
              item
              xs={12}
              css={[styles.whiteCard('lg'), styles.balanceWhiteCard]}
            >
              <p className="body1" css={css.marginBottom(12)}>
                Establishment fee
              </p>
              <p className="heading1" css={isLoading && styles.mask}>
                {currencyFormatter(getEstablishmentFee())}
              </p>
            </MuiGrid>
          )}
        {selectedProductClassification == ProductClassification.ZipPay && (
          <MuiGrid
            item
            xs={12}
            css={[styles.whiteCard('lg'), styles.balanceWhiteCard]}
          >
            <p className="body1" css={css.marginBottom(12)}>
              Interest
            </p>
            <p className="heading1">Interest free always</p>
          </MuiGrid>
        )}
        {selectedProductClassification == ProductClassification.ZipPlus && (
          <MuiGrid
            item
            xs={12}
            css={[styles.whiteCard('lg'), styles.balanceWhiteCard]}
          >
            <p className="body1" css={css.marginBottom(12)}>
              Interest
              <Tooltip
                content={
                  <div css={styles.tooltipContent}>
                    <p className="body1">Interest</p>
                    <p className="body4">
                      Interest applies if the total balance exceeds{' '}
                      {currencyFormatter(
                        Constants.productDefaults['Zip Plus']
                          .interestFreeThreshold,
                        false
                      )}{' '}
                      at end of the month.
                    </p>
                  </div>
                }
                placement="bottom"
                enterDelay={20}
                leaveDelay={1000}
                interactive
                maxWidth={240}
                arrow
                dense
                variant="primary"
                css={styles.tooltipIcon}
              >
                <Icons.InformationCircle width="16" height="16" />
              </Tooltip>
            </p>

            <p className="heading1">
              {Constants.productDefaults['Zip Plus'].interestRate.percent}%
              <span className="body4"> p.a</span>
            </p>
          </MuiGrid>
        )}
      </MuiGrid>
    );
  }

  // UseEffects
  useEffect(() => {
    // retrieve the productClassification specific quote from the RepaymentQuoteResponse.
    // so that the module can populate with the correct details.
    // If quote does not exist then set to null in order to show the empty state.
    if (repaymentsQuote) {
      const isEmpty = repaymentsQuote[
        productFormatter(selectedProductClassification)
      ]
        ? false
        : true;
      setIsQuoteEmpty(isEmpty);
      if (isEmpty) {
        setSelectedQuote(null);
      } else {
        setSelectedQuote(
          repaymentsQuote[productFormatter(selectedProductClassification)]
        );
      }
    }
  }, [repaymentsQuote, selectedProductClassification]);

  useEffect(() => {
    // Check isFlex
    if (customerToCharge && !customerToCharge.hasInstallmentsEnabled) {
      setIsFlex(true);
    } else if (orderPrice && orderPrice < 300) {
      setIsFlex(true);
    } else {
      setIsFlex(false);
    }
  }, [customerToCharge, orderPrice]);

  // HTML module
  return !repaymentsQuote || isQuoteEmpty ? (
    <div css={[styles.base, styles.emptyState]}>
      <img
        src={Constants.assets.illustrations.calculator}
        alt="Calculator"
        width={96}
      />
      <p className="title3">Enter order information to get started</p>
      <p className="body2">
        The calculator will appear here when order details have been entered.
      </p>
      {isLoading && <Spinner />}
    </div>
  ) : (
    <>
      <MuiGrid container css={styles.base}>
        <img
          src={
            Constants.assets.productLogos[
              productFormatter(selectedProductClassification)
            ].light
          }
          alt={productFormatter(selectedProductClassification)}
          css={styles.productHeader}
        />
        <MuiGrid item xs={12} css={styles.productDescription}>
          {productDescription()}
        </MuiGrid>
        <Divider css={styles.divider} />
        <p className="heading2">Repayments</p>
        {selectedProductClassification == ProductClassification.ZipMoney && (
          <MuiGrid container>
            {isFlex ? (
              <>
                {getFlexMessage()}
                {frequencyTile()}
                <p className="body2">
                  Customer{'\u2019'}s monthly account fee is waived if their
                  statement balance is paid by the due date.
                </p>
              </>
            ) : (
              <>
                <MuiGrid
                  container
                  css={styles.zipMoneyFirstTwoCards(isNewCustomer)}
                >
                  <MuiGrid item css={styles.whiteCard('lg')}>
                    <p className="body1">Monthly payment</p>
                    <p
                      className="heading1"
                      css={[
                        isQuoteEmpty && styles.fade,
                        isLoading && styles.mask,
                      ]}
                    >
                      {currencyFormatter(getEqualInstalmentAmounts())}
                    </p>
                  </MuiGrid>
                  {isNewCustomer && (
                    <MuiGrid item xs css={styles.whiteCard('lg')}>
                      <p className="body1" css={css.marginBottom(16)}>
                        Establishment fee
                      </p>
                      <p
                        className="heading1"
                        css={[
                          isQuoteEmpty && styles.fade,
                          isLoading && styles.mask,
                        ]}
                      >
                        {currencyFormatter(getEstablishmentFee())}
                      </p>
                    </MuiGrid>
                  )}
                </MuiGrid>
                {isNewCustomer && (
                  <p className="body2" css={css.marginBottom(0)}>
                    One-off establishment fee is added when setting up a new Zip
                    Money account.
                  </p>
                )}
                <Divider css={styles.divider} />
                <p className="heading2" css={css.marginBottom(0)}>
                  Instalment plan
                </p>
                <p className="body2">
                  Payments and dates below are an example only. A customers
                  first repayment will be due before their statement date.
                </p>
                <MuiGrid container css={styles.whiteCard('md')}>
                  <InstalmentScheduleModule
                    instalmentAmount={getEqualInstalmentAmounts()}
                    instalmentCount={selectedQuote?.numberOfRepayments}
                    establishmentFee={getEstablishmentFee()}
                    isLoading={isLoading}
                    isEmpty={isQuoteEmpty}
                  />
                </MuiGrid>
                <Divider css={styles.divider} />
                <p className="heading3" css={css.marginBottom(16)}>
                  Monthly account fee
                </p>
                <MuiGrid
                  item
                  xs={12}
                  css={[styles.whiteCard('md'), styles.zipMoneyAccountFee]}
                >
                  <span>
                    <p
                      className="heading3"
                      css={[css.marginRight(8), isLoading && styles.mask]}
                    >
                      {currencyFormatter(getMonthlyAccountFee())}
                    </p>
                    <p className="body4">per month</p>
                  </span>
                </MuiGrid>
                <p className="body2">
                  Paid alongside the customer{'\u2019'}s regular repayment(s).
                  This account fee is waived if you have paid your statement
                  balance by the due date.
                </p>
                {merchantIsExclusive && (
                  <Alert variant="info" css={styles.infoAlert}>
                    Customer will only be able to make future purchases with you
                    as an exclusive merchant.
                  </Alert>
                )}
              </>
            )}
          </MuiGrid>
        )}
        {selectedProductClassification == ProductClassification.ZipPay && (
          <MuiGrid container>
            {frequencyTile()}
            <p className="body2">
              Customer{'\u2019'}s monthly account fee is waived if their
              statement balance is paid by the due date.
            </p>
          </MuiGrid>
        )}
        {selectedProductClassification == ProductClassification.ZipPlus && (
          <MuiGrid container css={css.marginBottom(16)}>
            <p className="body2" css={css.marginTop(0)}>
              The{' '}
              <a
                onClick={(): void => setIsZipPlusMmpDialogOpen(true)}
                css={styles.zPlusDialogLink}
              >
                minimum monthly repayment
              </a>{' '}
              amount is based on the account credit limit.
            </p>
            {frequencyTile()}
          </MuiGrid>
        )}
        <MuiGrid item xs={12} css={styles.disclaimer}>
          <Accordion
            summary={<p>Disclaimer</p>}
            details={productDisclaimer()}
          />
        </MuiGrid>
      </MuiGrid>

      <ZipPlusMMPDialog
        open={isZipPlusMmpDialogOpen}
        onClose={(): void => setIsZipPlusMmpDialogOpen(false)}
      />
    </>
  );
};

export default RepaymentsCalculatorModule;
