export enum MerchantClassSettingType {
  StaffReferenceOriginationStep = 'StaffReferenceOriginationStep',
  ShowReferenceOnPaymentCodeForm = 'ShowReferenceOnPaymentCodeForm',
  CreateInviteAccountLimitSelector = 'CreateInviteAccountLimitSelector',
  PartialCaptureEnabled = 'PartialCaptureEnabled',
  OrderReferenceRequired = 'OrderReferenceRequired',
  AuthoriseBeforeCapture = 'AuthoriseBeforeCapture',
  EnableRepaymentCalculator = 'EnableRepaymentCalculator',
  CustomS3ReportBucket = 'CustomS3ReportBucket',
  EnableMerchantV3 = 'EnableMerchantV3',
  ShowReferredAndDeclinedProfiles = 'ShowReferredAndDeclinedProfiles',
  CLIInvitationEnabled = 'CLIInvitationEnabled',
  BrandedOrders = 'DashboardOrderBrands',
  UseCompanyMarketing = 'UseCompanyMarketing',
  UseCheckoutsRemoteOrder = 'UseCheckoutsRemoteOrder',
  ShowReferenceOnCreatedOrderInsteadOfReceipt = 'ShowReferenceOnCreatedOrderInsteadOfReceipt',
  EnableSmsNotifications = 'EnableSmsNotifications',
  ShowReceiptInOrdersTable = 'ShowReceiptInOrdersTable',
  ShowDatePickerDialogInOrdersPage = 'ShowDatePickerDialogInOrdersPage',
  ShowPlanToPurchase = 'MerchantDashboard_PlanToPurchase',
  /** AuthChargeExpiry */
  ExcludeFromAuthChargeExpiry = 'ExcludeFromAuthChargeExpiry',
  CustomZipPayAuthChargeExpiryInDays = 'CustomZipPayAuthChargeExpiryInDays',
  CustomZipMoneyAuthChargeExpiryInDays = 'CustomZipMoneyAuthChargeExpiryInDays',
  CustomZipPlusAuthChargeExpiryInDays = 'CustomZipPlusAuthChargeExpiryInDays',
  /** Deprecated */
  ShowMerchantProfileSettings = 'ShowMerchantProfileSettings',
}
