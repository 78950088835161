/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import { css, theme } from 'global';
import { FC } from 'react';
import * as styles from './DrawerPost.styles';
import { DrawerPostProps } from './DrawerPostProps';

const DrawerPost: FC<DrawerPostProps> = ({
  id,
  title,
  description,
  onClick = undefined,
  Icon,
  url,
}) => {
  const handleClick = (): void => {
    if (typeof onClick != 'undefined') {
      onClick();
    }
    if (!!url) {
      window.open(url);
    }
  };
  return (
    <MuiGrid
      container
      id={id}
      css={[css.noMargin, styles.container]}
      onClick={handleClick}
    >
      {Icon && (
        <MuiGrid item xs="auto">
          <Icon
            fill={theme.colors.primary.medium}
            width={20}
            height={20}
            css={styles.icon}
          />
        </MuiGrid>
      )}
      <MuiGrid item xs={10}>
        <p className="heading3" css={styles.title}>
          {title}
        </p>
        {description && (
          <p className="body2" css={styles.description}>
            {description}
          </p>
        )}
      </MuiGrid>
    </MuiGrid>
  );
};

export default DrawerPost;
