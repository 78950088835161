import { css } from '@emotion/react';

import { css as globalCss, theme } from 'global';

export const checkbox = css`
  & > .MuiCheckbox-root {
    margin-left: unset;
    margin-right: unset;
  }
`;

export const customerTable = css`
  margin-top: 40px;
`;

const tableCell = css`
  padding: 16px 24px;
  height: 64px;
  color: ${theme.colors.primary.dark};
  border-bottom: 1px solid ${theme.colors.neutral[200]};
  text-align: center;

  ${globalCss.ellipsisOverflow}

  &:first-of-type {
    text-align: left;
  }
  @media screen and (max-width: 1395px) {
    padding: 8px !important;
  }
`;

export const tableHead = css`
  > .MuiTableRow-root {
    background-color: ${theme.colors.neutral[0]};

    & > .MuiTableCell-root {
      border-bottom: none;
      padding: 18px 24px;
      max-width: 90px;
      ${tableCell}
      font-size: ${theme.fontSizes.heading1};
      line-height: ${theme.lineHeights.heading1};
      letter-spacing: ${theme.letterSpacings.heading1};

      &:first-of-type {
        max-width: unset;
      }
    }
  }
`;

export const tableBody = css`
  > .MuiTableRow-root {
    & > .MuiTableCell-root {
      ${tableCell}
      font-size: ${theme.fontSizes.heading4};
      line-height: ${theme.lineHeights.heading4};
      letter-spacing: ${theme.letterSpacings.heading4};
    }
  }
`;

export const table = css`
  > .MuiTable-root {
    & > .MuiTableBody-root {
      ${tableBody}
    }
    & > .MuiTableHead-root {
      ${tableHead}
    }
  }
`;

export const flexCellContent = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-left: 4px;
  }

  > span {
    height: 24px;
    > svg {
      cursor: pointer;
    }
  }

  > div.MuiChip-root {
    margin-left: 8px;
  }
`;
