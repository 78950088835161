import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import { createContext, FC, useContext, useState } from 'react';

enum SnackbarType {
  Success = 'success',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
}

interface SnackbarContextProps {
  success: (message: string | JSX.Element, duration?: number) => void;
  info: (message: string | JSX.Element, duration?: number) => void;
  error: (message: string | JSX.Element, duration?: number) => void;
  warning: (message: string | JSX.Element, duration?: number) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>(undefined);
export const useSnackbar = (): SnackbarContextProps =>
  useContext(SnackbarContext);

export const SnackbarProvider: FC = ({ children }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const [snackBarType, setSnackbarType] = useState<SnackbarType>(
    SnackbarType.Info
  );

  const [snackBarMessage, setSnackbarMessage] = useState<
    string | JSX.Element
  >();

  const [snackbarTimeout, setSnackbarTimeout] = useState<number>(6000);

  function handleSnackbarClose(): void {
    setIsSnackbarOpen(false);
    setSnackbarTimeout(6000);
  }

  const openSnackbar = (message, duration, severity): void => {
    setSnackbarMessage(message);
    setSnackbarType(severity || SnackbarType.Info);
    setIsSnackbarOpen(true);
    setSnackbarTimeout(duration > 0 ? duration : null);
  };

  const success = (message, duration = 6000): void =>
    openSnackbar(message, duration, SnackbarType.Success);
  const info = (message, duration = 6000): void =>
    openSnackbar(message, duration, SnackbarType.Info);
  const error = (message, duration = 6000): void =>
    openSnackbar(message, duration, SnackbarType.Error);
  const warning = (message, duration = 6000): void =>
    openSnackbar(message, duration, SnackbarType.Warning);

  return (
    <SnackbarContext.Provider value={{ success, info, error, warning }}>
      {children}
      <MuiSnackbar
        id="snackbar"
        open={isSnackbarOpen}
        autoHideDuration={snackbarTimeout}
        onClose={handleSnackbarClose}
        style={{ zIndex: 999999 }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackBarType}>
          {snackBarMessage}
        </MuiAlert>
      </MuiSnackbar>
    </SnackbarContext.Provider>
  );
};
