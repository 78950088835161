/** @jsxImportSource @emotion/react */
import { Buttons, Paper, TextFields } from '@zip/business-components';
import { useFormik } from 'formik';
import { Constants, css } from 'global';
import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Contact, ContactErrors } from 'types';
import { Validators } from 'utils';
import * as styles from './ContactCard.styles';
import { ContactCardProps } from './ContactCardProps';
import { ContactCardRef } from './ContactCardRef';

const ContactCard: FC<ContactCardProps> = forwardRef(
  (
    {
      contactData,
      callback,
      setIsFormValid,
      setIsLoading,
      setIsContactBeingEdited,
    },
    ref
  ) => {
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const toggleEdit = (): void => {
      setIsFormValid(showEditForm);
      setShowEditForm(!showEditForm);
    };

    const formik = useFormik({
      initialValues: {
        fullName: contactData?.fullName,
        contactEmail: contactData?.contactEmail,
        contactNumber: contactData?.contactNumber,
      },
      onSubmit: async (values) => {
        callback(values);
      },
      validate: (values: Contact) => {
        const errors: ContactErrors = {};

        if (!values.fullName) {
          errors.fullName = 'Required';
        } else if (!/^[a-zA-Z'\s-]{1,}$/.test(values.fullName)) {
          errors.fullName = 'Name contains invalid characters';
        } else if (!/^[a-zA-Z'\s-]{2,}$/.test(values.fullName)) {
          errors.fullName = 'Name must contain at least 2 letters';
        } else if (values.fullName.length > 50) {
          errors.fullName = 'Name must not exceed 50 characters';
        }

        if (!values.contactEmail) {
          errors.contactEmail = 'Required';
        } else if (!Validators.validateEmail(values.contactEmail)) {
          errors.contactEmail = 'Invalid email address';
        } else if (values.contactEmail.length > 100) {
          errors.contactEmail = 'Email must not exceed 100 characters';
        }

        if (!values.contactNumber) {
          errors.contactNumber = 'Required';
        } else if (!Validators.validateMobile(values.contactNumber)) {
          errors.contactNumber = 'Invalid mobile number';
        } else if (values.contactNumber.length > 15) {
          errors.contactNumber = 'Mobile must not exceed 15 characters';
        }

        setIsFormValid(Boolean(!Object.keys(errors).length));

        return errors;
      },
    });

    useImperativeHandle(
      ref,
      (): ContactCardRef => ({
        resetForm: (): void => {
          formik.resetForm();
          toggleEdit();
        },
        getFormValues: (): Contact => formik.values,
        updateFormValues: (values: Contact): void => {
          const { fullName, contactNumber, contactEmail } = values;
          formik.setValues({ fullName, contactNumber, contactEmail });
        },
      })
    );

    useEffect(() => {
      setIsContactBeingEdited(showEditForm);
    }, [showEditForm]);

    return (
      <Paper css={styles.card}>
        <div css={styles.heading}>
          <p className="body3" css={[css.noTopMrgn, styles.sendToLabel]}>
            Send to:
          </p>
          <img
            src={Constants.assets.pictorials.email}
            alt="Email"
            css={styles.emailImage(showEditForm)}
          />
        </div>
        {!showEditForm && (
          <>
            <p css={[css.noBtmMrgn, css.noTopMrgn]} className="fs-mask">
              <strong>{contactData?.fullName}</strong>
            </p>
            <p css={css.noTopMrgn} className="fs-mask">
              {contactData?.contactEmail}
              <br />
              {contactData?.contactNumber}
            </p>
            <Buttons.Text onClick={toggleEdit}>Change</Buttons.Text>
          </>
        )}
        {showEditForm && (
          <form
            data-testid="edit-form"
            onSubmit={async (e): Promise<void> => {
              setIsLoading(true);
              formik.handleSubmit(e);
              setIsLoading(false);
            }}
          >
            <TextFields.Outlined
              type="text"
              name="fullName"
              label="Full name"
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
              placeholder="Jordan Smith"
              fullWidth
            />
            <TextFields.Outlined
              type="email"
              name="contactEmail"
              label="Email"
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactEmail}
              error={
                formik.touched.contactEmail &&
                Boolean(formik.errors.contactEmail)
              }
              helperText={
                formik.touched.contactEmail && formik.errors.contactEmail
              }
              placeholder="jordan.smith@example.com"
              fullWidth
            />
            <TextFields.Outlined
              type="tel"
              name="contactNumber"
              label="Mobile"
              className="fs-mask"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactNumber}
              error={
                formik.touched.contactNumber &&
                Boolean(formik.errors.contactNumber)
              }
              helperText={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
              placeholder="04xxxxxxxx"
              fullWidth
            />
          </form>
        )}
      </Paper>
    );
  }
);

export default ContactCard;
