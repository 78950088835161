/** @jsxImportSource @emotion/react */
import {
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Labels } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { theme } from 'global';
import { FC, ReactNode } from 'react';
import { MultiSelectProps } from './MultiSelectProps';

const MultiSelect: FC<MultiSelectProps> = ({
  options,
  value = [],
  ...props
}) => {
  return (
    <MuiTextField
      select
      SelectProps={{
        displayEmpty: true,
        multiple: true,
        IconComponent: KeyboardArrowDownIcon,
        renderValue: (selected: unknown): ReactNode => (
          <div
            style={{
              maxWidth: '100%',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {(selected as unknown[])?.map((valueToMap, i) => (
              <Labels.Basic
                variant="basic"
                key={i}
                label={
                  options?.find((option) => option.value === valueToMap).label
                }
                style={{ marginRight: 2 }}
              />
            ))}
          </div>
        ),
        ...props?.SelectProps,
        MenuProps: {
          MenuListProps: {
            disablePadding: true,
            ...props?.SelectProps?.MenuProps?.MenuListProps,
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },

          ...props?.SelectProps?.MenuProps,
        },
      }}
      variant="outlined"
      value={value}
      style={{ margin: '16px 0', minWidth: 148 }}
      {...props}
    >
      {options?.length > 0 &&
        options?.map((option) => (
          <MuiMenuItem
            key={option.value}
            value={option.value}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div
              style={{
                boxShadow: '0 0 0 1px inset #4c5a6938',
                width: 16,
                height: 16,
                margin: '0 8px 0 0',
              }}
            >
              <Icons.Check
                width="20"
                height="20"
                style={{ marginTop: -2, marginLeft: -2 }}
                fill={
                  value?.includes(option.value.toString())
                    ? theme.colors.primary.medium
                    : 'transparent'
                }
              />
            </div>

            {option.label}
          </MuiMenuItem>
        ))}
    </MuiTextField>
  );
};

export default MultiSelect;
