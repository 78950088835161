import { css } from '@emotion/react';

export const imgContainer = css`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  > img {
    width: 45%;
  }
`;
