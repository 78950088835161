/** @jsxImportSource @emotion/react */
import { Buttons, Dialogs } from '@zip/business-components';
import { FC } from 'react';
import { BaseDialogProps } from 'types';

import { Constants, css } from 'global';
import * as styles from './InstoreCodeDialog.styles';

export const InstoreCodeDialog: FC<BaseDialogProps> = ({
  onClose,
  ...props
}) => {
  const handleClose = (): void => onClose(null, null);
  return (
    <Dialogs.Basic
      title="Customer barcode"
      onClose={handleClose}
      actions={<Buttons.Primary onClick={handleClose}>Got it</Buttons.Primary>}
      {...props}
    >
      <p className="body2">
        The in-app barcode is a 6 digit number that can be used to attach an
        order to a customer's account.
      </p>

      <p className="body2" css={css.noBtmMrgn}>
        To find the customer barcode in the Zip app, simply follow these steps:
        <ol>
          <li>
            Click the <strong>‘Pay’</strong> icon at the bottom of the app
          </li>
          <li>
            Select <strong>‘Instore’</strong> at the top
          </li>
          <li>
            Tap <strong>‘Generate barcode’</strong>
          </li>
        </ol>
      </p>

      <span css={styles.imgContainer}>
        <img src={Constants.assets.customerScreens.customerPay} />
        <img src={Constants.assets.customerScreens.customerBarcode} />
      </span>
    </Dialogs.Basic>
  );
};

export default InstoreCodeDialog;
