/** @jsxImportSource @emotion/react */
import { Select, Selectable } from '@zip/business-components';
import { BasePage, SetupAccordion, SetupArticle, Tabs } from 'components';
import { useMerchantData } from 'contexts';
import {
  EcommercePlatformUrlSuffixes,
  MerchantRoleGuid,
  PageRoute,
  SalesChannel,
} from 'enums';
import { collections, Constants, css as globalCss } from 'global';
import { FC, useMemo, useState } from 'react';
import { PageProps } from 'types';
import * as styles from './SetupGuidePage.styles';

const SetupGuidePage: FC<PageProps> = ({ openHelpCentre }) => {
  const [salesChannelOptions] = useState<Selectable[]>([
    { label: 'Instore', value: SalesChannel.Instore },
    { label: 'Online', value: SalesChannel.Online },
    { label: 'Online & Instore', value: SalesChannel.Both },
  ]);
  const { profileData, isAdmin, userRole, hasRole } = useMerchantData();
  const [selectedSalesChannel, setSelectedSalesChannel] =
    useState<SalesChannel>(
      profileData?.merchantData?.salesChannel ?? SalesChannel.Both
    );

  const isMarketingUser = useMemo(
    () => hasRole(MerchantRoleGuid.MarketingUser),
    [userRole]
  );

  return (
    <BasePage
      title="Setup guide"
      fullWidth
      variant="layout"
      action={
        isAdmin && (
          <Select.Basic
            name="brand"
            options={salesChannelOptions}
            onChange={(e): void =>
              setSelectedSalesChannel(e.target.value as SalesChannel)
            }
            value={selectedSalesChannel}
          />
        )
      }
      breadcrumbs={[{ pageName: 'Help centre', onClick: openHelpCentre }]}
    >
      {isMarketingUser && <Marketing />}

      <Basics selectedSalesChannel={selectedSalesChannel} />

      {isAdmin && <ManageTeam />}

      {isAdmin && <Marketing />}
    </BasePage>
  );
};

const Basics: FC<{
  selectedSalesChannel: SalesChannel;
}> = ({ selectedSalesChannel }) => {
  enum TabOption {
    Online = 'online',
    Instore = 'instore',
  }
  const [selectedTab, setSelectedTab] = useState<string>(TabOption.Online);
  const handleTabChange = (event, newTab): void => setSelectedTab(newTab);

  const { userRole, isAdmin } = useMerchantData();

  const isOnline =
    collections.roles.onlineRoles.includes(userRole) ||
    ((selectedSalesChannel === SalesChannel.Online ||
      selectedSalesChannel === SalesChannel.Both) &&
      isAdmin);
  const isInstore =
    collections.roles.instoreRoles.includes(userRole) ||
    ((selectedSalesChannel === SalesChannel.Instore ||
      selectedSalesChannel === SalesChannel.Both) &&
      isAdmin);

  const getHeading = (): string => {
    // for admins
    if (collections.roles.adminRoles.includes(userRole)) {
      return 'Get started with the basics';
    }
    // for non-admins
    switch (userRole) {
      case MerchantRoleGuid.MarketingUser:
        return 'Your customers & Zip';
      case MerchantRoleGuid.StoreUser:
        return 'Get started with Zip instore payments';
      default:
        return 'Get started';
    }
  };

  const getDescription = (): string => {
    // for non-admins
    if (!collections.roles.adminRoles.includes(userRole)) {
      switch (userRole) {
        case MerchantRoleGuid.MarketingUser:
          return 'Inform your customers about Zip.';
        case MerchantRoleGuid.StoreUser:
          return 'Be ready for when customer wants to pay with Zip instore.';
        default:
          return 'Learn how to manage orders and inform customers about Zip.';
      }
    }

    // for admins
    if (isOnline && isInstore) {
      return 'Set up Zip online and instore.';
    } else if (!isOnline && isInstore) {
      return 'From checkout to signage, get started with Zip instore. Start transacting directly with Zip dashboard or integrate into your point of sale.';
    } else if (isOnline && !isInstore) {
      return 'Connect your e-commerce platform with Zip.';
    }

    // catch all fall through in-case something manages to make it through
    return 'Set up Zip online and instore';
  };

  return (
    <SetupAccordion
      defaultExpanded={userRole !== MerchantRoleGuid.MarketingUser}
      summary={
        <>
          <h4 className="heading2">{getHeading()}</h4>
          <p className="body2" css={globalCss.noBtmMrgn}>
            {getDescription()}
          </p>
        </>
      }
    >
      {!isOnline && isInstore && <InstoreBasics />}
      {isOnline && !isInstore && <OnlineBasics />}
      {isOnline && isInstore && (
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          tabs={Object.keys(TabOption).map((tab) => ({
            label: tab,
            value: TabOption[tab],
          }))}
          content={{
            [TabOption.Online]: <OnlineBasics showDescription />,
            [TabOption.Instore]: <InstoreBasics showDescription />,
          }}
        />
      )}
    </SetupAccordion>
  );
};

const ManageTeam: FC = () => (
  <SetupAccordion
    summary={
      <>
        <h4 className="heading2">Set up your team and reports</h4>
        <p className="body2" css={globalCss.noBtmMrgn}>
          Use in dashboard tools to help manage your business.
        </p>
      </>
    }
  >
    <SetupArticle
      id="team-manage"
      title="Manage your team"
      description="Add team members with different roles and permissions."
      minsToRead={5}
      link={`${PageRoute.Settings}${PageRoute.Users}`}
    />
    <SetupArticle
      id="team-training"
      title="Get your team started with Zip"
      description="Explore training resources and empower your team to use Zip."
      minsToRead={15}
      link={Constants.setupGuides.trainingResources}
    />
    <SetupArticle
      id="disbursement-reports"
      title="Schedule your disbursement reports"
      description="Receive custom reports straight to your inbox."
      minsToRead={2}
      link={`${PageRoute.Settings}${PageRoute.Disbursement}`}
    />
  </SetupAccordion>
);

const Marketing: FC = () => {
  const { userRole } = useMerchantData();
  const getHeading = (): string => {
    switch (userRole) {
      case MerchantRoleGuid.MarketingUser:
        return 'Get started with Zip promotions';
      default:
        return 'Boost your sales by using Zip promotions';
    }
  };
  return (
    <SetupAccordion
      summary={
        <>
          <h4 className="heading2">{getHeading()}</h4>
          <p className="body2" css={globalCss.noBtmMrgn}>
            Gain exposure to 2.7 million Zip customers that are ready to spend.
          </p>
        </>
      }
      defaultExpanded={userRole === MerchantRoleGuid.MarketingUser}
    >
      <SetupArticle
        id="marketing-social-media"
        title="Announce on social media you are accepting Zip"
        description="Share with your customers the value of paying later with Zip."
        minsToRead={10}
        link={Constants.setupGuides.socialMedia}
        roles={[
          MerchantRoleGuid.Admin,
          MerchantRoleGuid.AdminV3,
          MerchantRoleGuid.MarketingUser,
        ]}
      />
      <SetupArticle
        id="marketing-profile"
        title="Set up your online store profile"
        description="Reach over 2.7 million Zip customers through our store directory."
        minsToRead={6}
        link={`${PageRoute.Marketing}${PageRoute.StoreDirectory}`}
        roles={[
          MerchantRoleGuid.Admin,
          MerchantRoleGuid.AdminV3,
          MerchantRoleGuid.MarketingUser,
        ]}
      />
      <SetupArticle
        id="marketing-campaigns"
        title="Promote your offers for free through Zip marketing campaigns"
        description="Boost your sales and reach new potential customers by joining campaigns."
        minsToRead={10}
        link={`${PageRoute.Marketing}${PageRoute.Campaigns}`}
        roles={[
          MerchantRoleGuid.Admin,
          MerchantRoleGuid.AdminV3,
          MerchantRoleGuid.MarketingUser,
        ]}
      />
    </SetupAccordion>
  );
};

const OnlineBasics: FC<{ showDescription?: boolean }> = ({
  showDescription = false,
}) => {
  const { ecommercePlatform } = useMerchantData();

  const getIntegrateCheckoutUrl = useMemo((): string => {
    const suffix =
      EcommercePlatformUrlSuffixes[ecommercePlatform]?.integrationUrlSuffix ??
      'ecommerce-platforms';

    return `${Constants.setupGuides.ecommerceIntegration}/${suffix}`;
  }, [ecommercePlatform]);

  const getIntegrateBpiUrl = useMemo((): string => {
    const suffix =
      EcommercePlatformUrlSuffixes[ecommercePlatform]?.bpiUrlSuffix ??
      'best-practice-implementation';

    return `${Constants.setupGuides.ecommerceIntegration}/${suffix}`;
  }, [ecommercePlatform]);

  return (
    <>
      {showDescription && (
        <p className="body2" css={styles.description}>
          Connect your e-commerce platform with Zip.
        </p>
      )}
      <SetupArticle
        id="integrate-checkout"
        title="Integrate Zip in your website checkout"
        description="Add Zip to your website with the simple step by step guide."
        minsToRead={30}
        link={getIntegrateCheckoutUrl}
      />
      <SetupArticle
        id="integrate-bpi"
        title="Use Zip's smart messaging on your website"
        description="Let your customers know they can pay later with Zip."
        minsToRead={5}
        link={getIntegrateBpiUrl}
      />
      <SetupArticle
        id="disbursement-reports"
        title="Schedule your disbursement reports"
        description="Receive custom reports straight to your inbox."
        minsToRead={2}
        link={`${PageRoute.Settings}${PageRoute.Disbursement}`}
        roles={[MerchantRoleGuid.ReportingUser]}
      />
      <SetupArticle
        id="dashboard-refunds"
        title="Learn how to refund an order"
        description="Explore training resources to get started."
        minsToRead={2}
        link={Constants.setupGuides.dashboardRefunds}
        roles={[MerchantRoleGuid.WebManager]}
      />
      <SetupArticle
        id="zip-for-customers"
        title="How does Zip work for customers"
        description="Learn about customer experience and the difference between Zip products."
        minsToRead={10}
        link={Constants.setupGuides.zipForCustomers}
        excludeRoles={[
          MerchantRoleGuid.Admin,
          MerchantRoleGuid.AdminV3,
          MerchantRoleGuid.MarketingUser,
        ]}
      />
    </>
  );
};

const InstoreBasics: FC<{ showDescription?: boolean }> = ({
  showDescription = false,
}) => (
  <>
    {showDescription && (
      <p className="body2" css={styles.description}>
        From checkout to signage get started with Zip instore. Start transacting
        directly with Zip dashboard or integrate into your point of sale.
      </p>
    )}
    <SetupArticle
      id="dashboard-sales"
      title="Process sales directly through the Zip dashboard"
      description="Learn how to make sales with Zip with our easy guides."
      minsToRead={15}
      link={Constants.setupGuides.trainingResources}
    />
    <SetupArticle
      id="dashboard-sales"
      title="Learn how to create an order instore"
      description="Explore training resources to get started."
      minsToRead={15}
      link={Constants.setupGuides.dashboardSales}
      roles={collections.roles.shouldSeeCreateOrderArticle}
    />
    <SetupArticle
      id="dashboard-refunds"
      title="Learn how to refund an order"
      description="Explore training resources to get started."
      minsToRead={2}
      link={Constants.setupGuides.dashboardRefunds}
      roles={[
        MerchantRoleGuid.MerchantWebManagerRefundOnly,
        MerchantRoleGuid.WebManager,
      ]}
    />
    <SetupArticle
      id="dashboard-sales"
      title="Learn how to create and refund an order"
      description="Explore training resources to get started."
      minsToRead={15}
      link={Constants.setupGuides.trainingResources}
      roles={collections.roles.shouldSeeCreateAndRefundArticle}
    />
    <SetupArticle
      id="integrate-pos"
      title="Integrate Zip into your point of sale"
      description="Use our simple guides to integrate instore to process a sale."
      minsToRead={30}
      link={Constants.setupGuides.posIntegration}
    />
    <SetupArticle
      id="pos-materials"
      title="Order free Zip instore signage"
      description="Let your customers know they can use Zip instore."
      minsToRead={5}
      link={`${PageRoute.Marketing}${PageRoute.PosMaterials}`}
    />
    <SetupArticle
      id="disbursement-reports"
      title="Schedule your disbursement reports"
      description="Receive custom reports straight to your inbox."
      minsToRead={2}
      link={`${PageRoute.Settings}${PageRoute.Disbursement}`}
      roles={[MerchantRoleGuid.ReportingUser]}
    />
    <SetupArticle
      id="zip-for-customers"
      title="How does Zip work for customers"
      description="Learn about customer experience and the difference between Zip products."
      minsToRead={10}
      link={Constants.setupGuides.zipForCustomers}
      excludeRoles={[MerchantRoleGuid.Admin, MerchantRoleGuid.AdminV3]}
    />
  </>
);

export default SetupGuidePage;
