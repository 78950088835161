import { css } from '@emotion/react';

import { theme } from 'global';

export const uploadButton = css`
  background-color: ${theme.colors.primary.lightest};
  box-shadow: none;
  height: 100%;
  padding: 16px;

  > .MuiButton-label {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    background-color: ${theme.colors.primary.lighter};
    box-shadow: none;
  }
`;

export const label = css`
  color: ${theme.colors.primary.medium};
  margin: 0 auto;
  max-width: 100%;
`;

export const helperText = css`
  color: ${theme.colors.danger};
  text-align: center;
  margin: 8px 0px 0px;
`;
