/** @jsxImportSource @emotion/react */
import { Popover as MuiPopover } from '@material-ui/core';
import { FC } from 'react';
import { dateFormatter } from 'utils';
import * as styles from './CampaignDates.styles';
import { CampaignDatesProps } from './CampaignDatesProps';

export const CampaignDates: FC<CampaignDatesProps> = ({
  campaign,
  anchorEl,
  ...props
}) => (
  <MuiPopover
    open={!!anchorEl}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  >
    <table css={styles.table}>
      <tr>
        <th>Submissions close</th>
        <td>
          <span css={styles.timeline} />
        </td>
        <td css={styles.date}>
          {dateFormatter(campaign?.timeline?.close, 'day', false)}
        </td>
      </tr>
      <tr>
        <th>Campaign goes live</th>
        <td>
          <span css={styles.timeline} />
        </td>
        <td css={styles.date}>
          {dateFormatter(campaign?.timeline?.live, 'full', false)}
        </td>
      </tr>
      <tr>
        <th>Campaign ends</th>
        <td>
          <span css={styles.timeline} />
        </td>
        <td css={styles.date}>
          {dateFormatter(campaign?.timeline?.end, 'full', false)}
        </td>
      </tr>
    </table>
  </MuiPopover>
);

export default CampaignDates;
