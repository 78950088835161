/** @jsxImportSource @emotion/react */
import { Paper as MuiPaper } from '@material-ui/core';
import { Select, Selectable } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Graphs, Tooltip } from 'components';
import { useMerchantData } from 'contexts';
import { DateFrequency, DateRange, GraphUnit, PageAction } from 'enums';
import { Constants, css, theme } from 'global';
import { FC, useEffect, useState } from 'react';
import { ReportResponse } from 'types';
import {
  currencyFormatter,
  LegendColour,
  logError,
  splitTitleCase,
  useReportFunctions,
} from 'utils';
import * as styles from './GrossSales.styles';

const GrossSalesModule: FC = () => {
  const { merchantIdentity, profileData, profileDataIsLoading } =
    useMerchantData();
  const ReportFunctions = useReportFunctions();
  const { getNumberOfDays } = ReportFunctions;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dateRanges] = useState<Selectable[]>(
    Object.keys(DateRange).map((dateRange) => ({
      label: splitTitleCase(dateRange),
      value: dateRange.toLowerCase(),
    }))
  );
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    DateRange.Last7Days.toLowerCase()
  );

  const handleDateRangeChange = (newDateRange): void =>
    setSelectedDateRange(newDateRange);
  const [grossSalesData, setGrossSalesData] = useState<ReportResponse[]>([]);

  function getStartDate(): Date {
    const startDate = new Date();
    startDate.setUTCDate(
      startDate.getDate() - getNumberOfDays(selectedDateRange)
    );
    startDate.setUTCHours(0, 0, 0, 0);
    return startDate;
  }

  function getTotalCount(dataToCount: ReportResponse[]): number {
    let totalCount = 0;
    if (dataToCount && dataToCount?.length > 0) {
      dataToCount.forEach((entry) => {
        totalCount += entry.totalCount;
      });
    }
    return totalCount;
  }

  function prepareGrossSalesData(summaries): ReportResponse[] {
    const mappedSummaries: ReportResponse[] = summaries?.map(
      (grossVolumeResponse) => ({
        periodStartDateTime: grossVolumeResponse.periodStartDateTime,
        totalCount: grossVolumeResponse.totalVolume,
        merchantSummaries: grossVolumeResponse.merchantVolumeSummaries.map(
          (summary) => {
            LegendColour.addNewLegend(summary.merchantName);
            return {
              value: summary.volume,
              name: summary.merchantName,
            };
          }
        ),
      })
    );

    const periodDateTime = getStartDate();
    const endDate = new Date();
    endDate.setUTCDate(endDate.getDate());
    endDate.setUTCHours(endDate.getHours(), 0, 0, 0);

    const merchantName =
      mappedSummaries && mappedSummaries[0].merchantSummaries[0].name;

    const summariesToReturn: ReportResponse[] = [];
    while (periodDateTime <= endDate) {
      const [periodISODateTime] = periodDateTime.toISOString().split('.');

      let periodEntry =
        mappedSummaries &&
        mappedSummaries.find(
          (summary) => summary.periodStartDateTime === periodISODateTime
        );

      if (!periodEntry) {
        periodEntry = {
          periodStartDateTime: periodISODateTime,
          totalCount: 0,
          merchantSummaries: [{ name: merchantName, value: 0 }],
        };
      }
      summariesToReturn.push(periodEntry);
      if (selectedDateRange === DateRange.Today) {
        periodDateTime.setUTCHours(periodDateTime.getUTCHours() + 1);
      } else {
        periodDateTime.setUTCDate(periodDateTime.getUTCDate() + 1);
      }
    }

    return summariesToReturn;
  }

  useEffect(() => {
    const abortController = new AbortController();
    const getGrossSalesData = async (): Promise<void> => {
      setIsLoading(true);
      const endDate = new Date();
      endDate.setUTCDate(endDate.getDate() + 1);
      endDate.setUTCHours(0, 0, 0, 0);
      const query = {
        startDate: getStartDate(),
        endDate,
        frequency:
          selectedDateRange === DateRange.Today
            ? DateFrequency.Hourly
            : DateFrequency.Daily,
      };
      await ReportFunctions.fetchGrossSales(query, abortController.signal)
        .then((response) => {
          const mappedSalesData =
            response?.grossVolumeReportSummaries &&
            prepareGrossSalesData(response?.grossVolumeReportSummaries);

          setGrossSalesData(mappedSalesData as ReportResponse[]);
          setIsLoading(false);
        })
        .catch((err) => {
          if (!err?.stack?.includes(Constants.errors.abortedSignal)) {
            logError(PageAction.fetchGrossSales, err, query);
            setGrossSalesData([]);
            setIsLoading(false);
          }
        });
    };

    if (profileData && !profileDataIsLoading) {
      getGrossSalesData();
    }

    return (): void => abortController.abort();
  }, [selectedDateRange, merchantIdentity]);

  return (
    <MuiPaper elevation={0} css={styles.container}>
      <div css={css.moduleHeader(true)}>
        <div>
          <div css={css.moduleTitleBar}>
            <span className="body2">Gross sales</span>
            <Tooltip
              content="This graph is not in real-time, it may take a few minutes to update."
              placement="top"
              enterTouchDelay={20}
              leaveTouchDelay={3000}
              maxWidth={250}
              arrow
              dense
            >
              <Icons.InformationCircle
                fill={theme.colors.neutral[500]}
                css={css.infoIcon}
              />
            </Tooltip>
          </div>
          <span
            className={
              window.innerWidth <= theme.breaks.xs ? 'heading1' : 'title4'
            }
          >
            {currencyFormatter(getTotalCount(grossSalesData))}
          </span>
        </div>

        <Select.Basic
          id="grossSales-dateRangeDropdown"
          options={dateRanges}
          onChange={(e): void => handleDateRangeChange(e.target.value)}
          value={selectedDateRange}
        />
      </div>
      <Graphs.Area
        frequency={
          selectedDateRange === DateRange.Today
            ? DateFrequency.Hourly
            : DateFrequency.Daily
        }
        data={grossSalesData}
        title="Gross sales"
        unit={GraphUnit.currency}
        loading={isLoading}
        showAllTicks={selectedDateRange !== DateRange.Last30Days}
      />
    </MuiPaper>
  );
};

export default GrossSalesModule;
