import { css } from '@emotion/react';

import { theme } from 'global';

export const container = css`
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.lg};
  max-width: 600px;
  padding: 16px;

  @media screen and (min-width: ${theme.breaks.sm}px) {
    padding: 32px;
    border-radius: 12px;
    margin: 0px auto;
  }

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding-bottom: 76px;
  }
`;

export const submittedContainer = css`
  padding: 16px;
`;

export const submittedText = css`
  & > p {
    @media screen and (max-width: ${theme.breaks.xs}px) {
      text-align: center;
    }
  }
`;
