import { css } from '@emotion/react';

export const documentTypeTitle = css`
  display: flex;
  margin-bottom: 24px;
  align-items: baseline;
`;

export const helpContent = css`
  display: flex;
  flex-flow: column wrap;
`;
