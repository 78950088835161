import {
  Country,
  EcommercePlatform,
  LocalStorageKey,
  MerchantClassSettingType,
  MerchantRoleGuid,
  MerchantStatus,
  PermissionEnum,
  classificationsToShow,
} from 'enums';
import { Constants, collections } from 'global';
import { usePrevious } from 'hooks';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  MerchantAccountType,
  MerchantDashboardSettings,
  MerchantIdentity,
  MerchantProduct,
  MerchantProfileProgress,
  ProfileData,
} from 'types';
import {
  getProductDescription,
  hasAuth0Error,
  setIdentities,
  useMerchantProfile,
} from 'utils';
import { useAuth0 } from './Auth0.context';
import { useAnalytics } from './Analytics.context';

interface MerchantDataContextProps {
  merchantProfile: MerchantProfileProgress;
  hasPublicId: boolean;
  profileData: ProfileData;
  profileDataIsLoading: boolean;
  currentUserName: string;
  currentUserId: string;
  merchantIdentity: MerchantIdentity;
  merchants: { id: number; name: string }[];
  branches: { id: number; name: string }[];
  merchantDashboardSettings: MerchantDashboardSettings;
  merchantProducts: MerchantProduct[];
  merchantProductsForDisplay: MerchantProduct[];
  merchantAccountTypes: MerchantAccountType[];
  merchantCountry: Country;
  userRole: MerchantRoleGuid;
  isAdmin: boolean;
  isCompanyUser: boolean;
  isMerchantDisabled: boolean;
  merchantCreationTimeStamp: Date;
  daysSinceMerchantCreation: number;
  ecommercePlatform: EcommercePlatform;
  shouldShowCreditProductSelector: boolean;
  updateCurrentMerchantId: (newId) => void;
  updateCurrentBranchId: (any) => void;
  getMerchantDashboardSettingValue: (mst: MerchantClassSettingType) => string;
  checkMerchantDashboardSetting: (mst: MerchantClassSettingType) => boolean;
  checkMerchantDashboardSettings: (settings, allRequired?: boolean) => boolean;
  getMerchantStatus: () => MerchantStatus;
  getMerchantAbn: () => string;
  getMerchantUniqueId: () => string;
  hasRole: (role: string) => boolean;
  shouldShowOverview: () => boolean;
  getCurrentSelectedBranch: (nullOnNotInstoreBranch?: boolean) => string;
  checkPermission: (any) => boolean;
  checkPermissions: (any: PermissionEnum[]) => boolean;
  getProfileData: () => void;
  getMerchantProfile: (useLoadingSpinner?: boolean) => void;
  isUnlinkedUser: () => boolean;
  isLinkingUser: () => boolean;
  needsToReauth: () => boolean;
}

export const MerchantDataContext =
  createContext<MerchantDataContextProps>(undefined);
export const useMerchantData = (): MerchantDataContextProps =>
  useContext(MerchantDataContext);

export const MerchantDataProvider: FC = ({ children }) => {
  const analytics = useAnalytics();
  const { accessToken } = useAuth0();
  const MerchantProfileFunctions = useMerchantProfile();

  const [merchantProfile, setMerchantProfile] =
    useState<MerchantProfileProgress>();
  const [hasPublicId, setHasPublicId] = useState<boolean>(true);
  const [profileData, setProfileData] = useState<ProfileData>();
  const [profileDataIsLoading, setProfileDataIsLoading] =
    useState<boolean>(false);
  const [currentUserName, setCurrentUserName] = useState<string>();
  const [currentUserId, setCurrentUserId] = useState<string>();
  const [merchantIdentity, setMerchantIdentity] = useState<MerchantIdentity>({
    companyId: null,
    merchantId: null,
    branchId: null,
  });
  const [merchants, setMerchants] = useState<{ id: number; name: string }[]>(
    []
  );
  const [branches, setBranches] = useState<{ id: number; name: string }[]>([]);
  const [merchantDashboardSettings, setMerchantDashboardSettings] =
    useState<MerchantDashboardSettings>([]);
  const [merchantAccountTypes, setMerchantAccountTypes] = useState<
    MerchantAccountType[]
  >([]);

  const previousMerchantId = usePrevious(merchantIdentity.merchantId) ?? null;

  function updateState(newValue: ProfileData): void {
    setCurrentUserName(
      `${newValue?.userProfile?.firstName} ${newValue?.userProfile?.lastName}`
    );
    setCurrentUserId(newValue?.userProfile?.userProfileId);

    setBranches(newValue?.userProfile?.branches);
    setMerchants(newValue?.userProfile?.merchants);

    let newMerchantId;

    function getMerchantIdForNewSession(): void {
      newMerchantId =
        newValue?.userProfile?.merchants?.length > 0
          ? newValue.userProfile.merchants[0].id
          : null;
      if (!newMerchantId) {
        localStorage.removeItem(LocalStorageKey.SelectedMerchant);
      } else {
        localStorage.setItem(
          LocalStorageKey.SelectedMerchant,
          newMerchantId?.toString()
        );
      }
    }

    const isNewPageSession =
      !localStorage.getItem(LocalStorageKey.SelectedMerchant) &&
      !merchantIdentity?.merchantId;

    if (isNewPageSession) {
      getMerchantIdForNewSession();
    } else {
      newMerchantId =
        merchantIdentity?.merchantId ??
        +parseFloat(localStorage.getItem(LocalStorageKey.SelectedMerchant));

      const isUserAuthorisedToUseMerchant =
        newValue?.userProfile?.merchants &&
        newValue?.userProfile?.merchants?.find(
          (merchant) => merchant.id === newMerchantId
        );

      if (!isUserAuthorisedToUseMerchant) {
        getMerchantIdForNewSession();
      }
    }

    const newBranches = newValue?.userProfile?.branches;
    let newBranchId;
    if (newBranches?.length > 1) {
      newBranchId = -1;
    } else if (newBranches?.length === 1) {
      newBranchId = newBranches[0]?.id;
    }

    const newMerchant: MerchantIdentity = {
      companyId: newValue?.merchantData?.companyId,
      merchantId: newMerchantId,
      branchId: newBranchId,
    };
    setMerchantIdentity(newMerchant);

    setProfileDataIsLoading(false);

    setMerchantDashboardSettings(newValue?.merchantData?.merchantSettings);

    setMerchantAccountTypes(
      newValue?.merchantData?.merchantAccountTypes?.sort((a, b) =>
        a.limit < b.limit ||
        (a.limit === b.limit &&
          a.productClassification < b.productClassification)
          ? -1
          : 1
      )
    );
  }

  const updateCurrentMerchantId = (newMerchantId: number): void =>
    setMerchantIdentity({
      ...merchantIdentity,
      merchantId: newMerchantId,
      branchId: -1,
    });

  const updateCurrentBranchId = (newBranchId: number): void =>
    setMerchantIdentity({ ...merchantIdentity, branchId: newBranchId });

  const getMerchantDashboardSettingValue = (
    mst: MerchantClassSettingType
  ): any => {
    const merchantDashboardSettingsArray =
      profileData?.merchantData?.merchantSettings;

    if (merchantDashboardSettingsArray) {
      for (const [type, value] of Object.entries(
        merchantDashboardSettingsArray
      )) {
        if (type === mst.toString()) {
          // @ts-ignore - value is the string value of the MST
          return value;
        }
      }
    }
  };

  const checkMerchantDashboardSetting = (
    mst: MerchantClassSettingType
  ): boolean => {
    let result: boolean;
    const merchantDashboardSettingsArray =
      profileData?.merchantData?.merchantSettings;

    if (merchantDashboardSettingsArray) {
      for (const [type, value] of Object.entries(
        merchantDashboardSettingsArray
      )) {
        if (type === mst.toString()) {
          // @ts-ignore - value is the string value of the MST
          result = value?.toLowerCase() === 'true';
        }
      }
    }
    return result;
  };

  const checkMerchantDashboardSettings = (
    settings: string[],
    allRequired = false
  ): boolean => {
    /*
      add all boolean outcomes to results array,
      return result boolean based on if allRequired
      -- if allRequired & results does not include a false, return true
      -- if allRequired & results includes a false, return false
      -- if anyRequired & results include a true, return true
      -- if anyRequired & results does not include a true, return false
    */
    const merchantDashboardSettingsArray =
      profileData?.merchantData?.merchantSettings;

    const results: boolean[] = [];
    if (merchantDashboardSettingsArray) {
      for (const [setting, value] of Object.entries(
        merchantDashboardSettingsArray
      )) {
        if (settings.includes(setting)) {
          results?.push((value as unknown as string)?.toLowerCase() === 'true');
        }
      }
    }

    return allRequired ? !results?.includes(false) : results?.includes(true);
  };

  const getMerchantStatus = (): MerchantStatus =>
    useMemo(() => profileData?.merchantData?.merchantStatus, [profileData]);

  const getMerchantAbn = (): string =>
    useMemo(() => profileData?.merchantData?.merchantAbn, [profileData]);

  const getMerchantUniqueId = (): string =>
    useMemo(() => profileData?.merchantData?.merchantUniqueId, [profileData]);

  const isMerchantDisabled = useMemo(
    (): boolean =>
      !profileData?.merchantData?.merchantStatus ||
      profileData?.merchantData?.merchantStatus === MerchantStatus.Disabled,
    [profileData]
  );

  const merchantCreationTimeStamp = useMemo(
    (): Date => profileData?.merchantData?.timeStamp,
    [profileData]
  );

  const daysSinceMerchantCreation = useMemo(
    (): number => profileData?.merchantData?.daysSinceMerchantCreation,
    [profileData]
  );

  const userRole = useMemo((): MerchantRoleGuid => {
    if (!accessToken && !profileData) {
      return;
    }

    // Preference use of profileData to allow use through impersonation
    if (profileData?.userProfile?.userRoles?.length > 0) {
      const [role] = profileData?.userProfile?.userRoles as MerchantRoleGuid[];
      return role;
    }

    // If no defined userRoles, fallback to claims as it will always be defined
    const claims = jwtDecode(accessToken);
    return claims[
      `${process.env.REACT_APP_AUTH0_AUDIENCE}roles`
    ] as MerchantRoleGuid;
  }, [accessToken, profileData]);

  const hasRole = (role: string): boolean => role === userRole;

  const isAdmin = useMemo(
    (): boolean => collections.roles.adminRoles.includes(userRole),
    [userRole]
  );

  const isCompanyUser = useMemo(
    (): boolean => profileData?.userProfile?.isCompanyUser,
    [profileData?.userProfile]
  );

  const ecommercePlatform = useMemo((): EcommercePlatform => {
    return profileData?.merchantData?.ecommercePlatform?.toLowerCase() as EcommercePlatform;
  }, [profileData]);

  const shouldShowOverview = (): boolean =>
    hasRole(MerchantRoleGuid.Admin) ||
    hasRole(MerchantRoleGuid.AdminV3) ||
    hasRole(MerchantRoleGuid.ReportingUser);

  const shouldShowCreditProductSelector = useMemo((): boolean => {
    const zmProducts = profileData?.merchantData?.merchantProducts?.filter(
      (product) => product.interestFreeMonths > 0
    );

    switch (zmProducts?.length) {
      case undefined:
      case null:
      case 0:
        return false;
      case 1:
        return zmProducts[0].interestFreeMonths > 3;
      default:
        return true;
    }
  }, [profileData]);

  const getCurrentSelectedBranch = (nullOnNotInstoreBranch = false): string => {
    let currentSelectedBranch;
    if (nullOnNotInstoreBranch) {
      currentSelectedBranch =
        merchantIdentity?.branchId <= 0 ? null : merchantIdentity.branchId;
    } else {
      currentSelectedBranch =
        merchantIdentity?.branchId <= 0
          ? branches?.filter((branch) => branch.id > 0)[0]?.id
          : merchantIdentity.branchId;
    }
    return currentSelectedBranch?.toString();
  };

  const merchantProducts = useMemo(
    () =>
      _.orderBy(
        profileData?.merchantData?.merchantProducts,
        ['interestFreeMonths', 'productClassification'],
        ['asc', 'asc']
      ),
    [profileData]
  );

  const merchantProductsForDisplay = useMemo(() => {
    return _.orderBy(
      _(merchantProducts)
        .filter((product) =>
          classificationsToShow.has(product.productClassification)
        )
        .groupBy((product) => [
          product.productClassification,
          product.creditProductId,
          product.interestFreeMonths,
          product.promotion,
        ])
        .map((period) => ({
          creditProductId: period[0]?.creditProductId,
          description: getProductDescription(period[0]),
          interestFreeMonths: period[0]?.interestFreeMonths,
          productClassification: period[0]?.productClassification,
          standard: Boolean(
            period?.find((product) => product?.standard === true)
          ),
          promotion: period[0]?.promotion,
          transactionLimitMax: Math.max(
            ...period?.map((o) => o?.transactionLimitMax)
          ),
          transactionLimitMin: Math.min(
            ...period?.map((o) => o?.transactionLimitMin)
          ),
        }))
        .value(),
      ['productClassification', 'interestFreeMonths', 'promotion'],
      ['asc', 'asc', 'asc']
    ) as MerchantProduct[];
  }, [profileData]);

  const checkPermission = (permission: PermissionEnum): boolean => {
    return !!profileData?.permissions?.find(
      (authPermission) => authPermission === permission
    );
  };

  const checkPermissions = (permissions: PermissionEnum[]): boolean => {
    let result: boolean;
    if (permissions?.length > 0) {
      permissions?.forEach((permission) => {
        if (
          profileData?.permissions?.length > 0 &&
          profileData?.permissions?.includes(permission)
        ) {
          result = true;
        }
      });
    }
    return result;
  };

  const merchantCountry: Country = useMemo(
    () => profileData?.merchantData?.country?.toLowerCase() as Country,
    [profileData]
  );

  async function getProfileData(): Promise<void> {
    setProfileDataIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/merchantdata`;
    const headers = merchantIdentity?.merchantId
      ? {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'md-merchant-id': merchantIdentity?.merchantId?.toString(),
        }
      : {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };

    const result: ProfileData = await fetch(url, {
      headers,
      method: 'GET',
    }).then((res) => (res.ok ? res.json() : null));
    if (result) {
      setProfileData(result as ProfileData);
      updateState(result as ProfileData);
      const claims = jwtDecode<Record<string, string>>(accessToken);
      setIdentities(
        result?.userProfile?.userProfileId,
        result?.merchantData?.merchantId,
        claims[`${process.env.REACT_APP_AUTH0_AUDIENCE}email`] as string
      );
    } else {
      setProfileDataIsLoading(false);
    }
  }

  async function getMerchantProfile(
    useLoadingSpinner = true
  ): Promise<boolean | void> {
    let completed;
    setProfileDataIsLoading(useLoadingSpinner);
    const publicId = await MerchantProfileFunctions.getPublicId();
    if (publicId) {
      setHasPublicId(true);
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
      completed = await fetch(
        `${process.env.REACT_APP_MERCHANT_PROFILE_API_URL}/${publicId}`,
        { headers }
      )
        .then(async (res) => {
          if (!res.ok) {
            const response = await res.json();
            throw new Error(response.error.message);
          }
          return res.json();
        })
        .then((result) => {
          setMerchantProfile(result as MerchantProfileProgress);
          return true;
        });
    } else {
      setHasPublicId(false);
    }
    return completed;
  }

  function isUnlinkedUser(): boolean {
    return (
      (!!profileData?.userProfile?.userId &&
        !profileData?.userProfile?.userProfileId) ||
      hasAuth0Error(Constants.errors.unlinkedUser)
    );
  }
  function isLinkingUser(): boolean {
    return (
      (!!profileData?.userProfile?.userId &&
        !profileData?.userProfile?.userProfileId) ||
      hasAuth0Error(Constants.errors.linkingUser)
    );
  }

  const needsToReauth = (): boolean =>
    profileData?.userProfile?.userId &&
    profileData?.userProfile?.userProfileId &&
    !profileData?.permissions?.length;

  useEffect(() => {
    if (previousMerchantId !== merchantIdentity?.merchantId) {
      localStorage.setItem(
        LocalStorageKey.SelectedMerchant,
        merchantIdentity.merchantId?.toString()
      );
      getProfileData();
    }
  }, [merchantIdentity.merchantId]);

  useEffect(() => {
    if (accessToken && profileData) {
      analytics.dispatch({
        type: 'identity',
        userId: profileData?.userProfile?.userProfileId,
      });
    }
  }, [accessToken, profileData]);

  return (
    <MerchantDataContext.Provider
      value={{
        merchantProfile,
        hasPublicId,
        profileData,
        profileDataIsLoading,
        currentUserName,
        currentUserId,
        merchantIdentity,
        merchants,
        branches,
        merchantDashboardSettings,
        merchantProducts,
        merchantProductsForDisplay,
        merchantAccountTypes,
        merchantCountry,
        userRole,
        isAdmin,
        isCompanyUser,
        isMerchantDisabled,
        merchantCreationTimeStamp,
        daysSinceMerchantCreation,
        ecommercePlatform,
        shouldShowCreditProductSelector,
        updateCurrentMerchantId,
        updateCurrentBranchId,
        getMerchantDashboardSettingValue,
        checkMerchantDashboardSetting,
        checkMerchantDashboardSettings,
        getMerchantStatus,
        getMerchantAbn,
        getMerchantUniqueId,
        hasRole,
        shouldShowOverview,
        getCurrentSelectedBranch,
        checkPermission,
        checkPermissions,
        getProfileData,
        getMerchantProfile,
        isUnlinkedUser,
        isLinkingUser,
        needsToReauth,
      }}
    >
      {children}
    </MerchantDataContext.Provider>
  );
};
