/** @jsxImportSource @emotion/react */
import { Grid } from '@material-ui/core';
import * as Icons from '@zip/react-icons/fearless';
import { BasePage, Tile } from 'components';
import { useMerchantData } from 'contexts';
import { MerchantRoleGuid, PageRoute, PermissionEnum } from 'enums';
import { Constants, isSandbox } from 'global';
import { FC } from 'react';
import { PageProps } from 'types';

const SettingsPage: FC<PageProps> = () => {
  const { checkPermission, checkPermissions, hasRole, isAdmin } =
    useMerchantData();
  return (
    <BasePage title="Settings" variant="layout" fullWidth>
      <Grid container spacing={3}>
        {!isSandbox &&
          (isAdmin || hasRole(MerchantRoleGuid.FranchiseManager)) && (
            <Tile
              id="accountTile"
              Icon={Icons.Settings}
              title="Account"
              subtitle="Update business, banking and branch information"
              path={`${PageRoute.Settings}${PageRoute.Account}`}
              tag="NEW"
              tagExpiry={Constants.expiryDate.accountSettings}
            />
          )}
        {checkPermission(PermissionEnum.NotificationsUpdate) && (
          <Tile
            id="notificationsTile"
            Icon={Icons.Envelope}
            title="Notifications"
            subtitle="Manage when you receive order and customer status notifications"
            path={`${PageRoute.Settings}${PageRoute.Notifications}`}
          />
        )}
        {checkPermissions([
          PermissionEnum.ApiIntegrationRead,
          PermissionEnum.ApiIntegrationCreate,
          PermissionEnum.ApiIntegrationUpdate,
        ]) && (
          <Tile
            id="integrationTile"
            Icon={Icons.DeviceLaptop}
            title="Integration"
            subtitle="Access details required for online and instore integration"
            path={`${PageRoute.Settings}${PageRoute.Integration}`}
          />
        )}
        {checkPermission(PermissionEnum.UserManagementExecute) && (
          <Tile
            id="userManagementTile"
            Icon={Icons.UserCirclePlus}
            title="User management"
            subtitle="Add, update, and delete users"
            path={`${PageRoute.Settings}${PageRoute.Users}`}
          />
        )}
        {checkPermission(PermissionEnum.ReportsRead) && (
          <Tile
            id="disbursementTile"
            Icon={Icons.Notebook}
            title="Disbursements"
            subtitle="Schedule regular CSV reports or generate a one-off report"
            path={`${PageRoute.Settings}${PageRoute.Disbursement}`}
          />
        )}
      </Grid>
    </BasePage>
  );
};

export default SettingsPage;
