/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid } from '@material-ui/core';
import { Spinner } from '@zip/business-components';
import { useMerchantData, useSnackbar } from 'contexts';
import {
  MerchantProfileStep,
  MerchantProfileStepStatus,
  PageAction,
  PageRoute,
} from 'enums';
import { Constants, css } from 'global';
import {
  DirectorDetailsModule,
  DirectorDocumentModule,
  PrimaryContactModule,
  SettlementDetailsModule,
} from 'modules';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ApplicationData,
  Contact,
  DirectorDetails,
  DirectorDocument,
  PageProps,
  SettlementDetails,
  SettlementDocument,
} from 'types';
import { delay, logError, useMerchantProfile } from 'utils';
import * as styles from './ApplicationPage.styles';

const ApplicationPage: FC<PageProps> = () => {
  const Snackbar = useSnackbar();
  const MerchantProfileFunctions = useMerchantProfile();
  const [applicationData, setApplicationData] = useState<ApplicationData>();
  const [applicationStep, setApplicationStep] = useState<number>(
    MerchantProfileStep.loadingProfile
  );

  const { merchantProfile, getProfileData, getMerchantProfile } =
    useMerchantData();

  const history = useHistory<{ redirect: string }>();

  async function autoProgressFromSubmittedStep(step): Promise<void> {
    await delay();
    setApplicationStep(step + 1);
  }

  function prevStep(): void {
    const nextStep = applicationStep + 1;
    setApplicationStep(applicationStep - 1);
    if (nextStep === MerchantProfileStep.submitted) {
      autoProgressFromSubmittedStep(nextStep);
    }
  }

  function nextStep(): void {
    const nextStep = applicationStep + 1;
    setApplicationStep(nextStep);
    if (nextStep === MerchantProfileStep.submitted) {
      autoProgressFromSubmittedStep(nextStep);
    }
  }

  async function redirectToDashboard(): Promise<void> {
    await getMerchantProfile();
    await getProfileData();

    history.push(PageRoute.Home);
  }

  async function submitDirectorDetails(
    directorDetails: DirectorDetails
  ): Promise<void> {
    try {
      const { idVerified } =
        await MerchantProfileFunctions.submitDirectorDetails(directorDetails);
      if (idVerified !== 'ACCEPT') {
        nextStep();
      } else {
        setApplicationStep(MerchantProfileStep.primaryContact);
      }
    } catch (err) {
      logError(PageAction.submitDirectorDetails, err, applicationData);
      Snackbar.error(
        'An error occurring updating your application. Please try again.'
      );
    }
  }

  async function submitDirectorDocument(
    directorDocument: DirectorDocument
  ): Promise<void> {
    try {
      await MerchantProfileFunctions.submitDirectorDocument(directorDocument);
      nextStep();
    } catch (err) {
      logError(PageAction.submitDirectorDocument, err, applicationData);
      Snackbar.error(
        'An error occurring submitting your attachment. Please try again.'
      );
    }
  }

  async function submitPrimaryContact(primaryContact: Contact): Promise<void> {
    try {
      const isDirector =
        primaryContact.contactEmail ===
          applicationData.directorDetails?.contactEmail &&
        primaryContact.contactNumber ===
          applicationData?.directorDetails?.contactNumber;
      await MerchantProfileFunctions.submitPrimaryContact(
        primaryContact,
        isDirector
      );
      getMerchantProfile(false);
      nextStep();
    } catch (err) {
      logError(PageAction.submitPrimaryContact, err, applicationData);
      Snackbar.error(
        'An error occurring updating your application. Please try again.'
      );
    }
  }

  async function submitSettlementModule(settlementModule: {
    settlementDetails: SettlementDetails;
    settlementDocument: SettlementDocument;
  }): Promise<void> {
    try {
      await MerchantProfileFunctions.submitSettlementDetails(
        settlementModule.settlementDetails
      );
      await MerchantProfileFunctions.submitSettlementDocument(
        settlementModule.settlementDocument
      );
      redirectToDashboard();
    } catch (err) {
      logError(PageAction.submitSettlementDetails, err, applicationData);
      Snackbar.error(
        'An error occurring submitting your application. Please try again.'
      );
    }
  }

  const setDirectorDetails = async (
    directorDetails: DirectorDetails
  ): Promise<boolean> => {
    setApplicationData({
      ...applicationData,
      directorDetails,
    });
    try {
      await submitDirectorDetails(directorDetails);
    } catch (err) {
      return false;
    }
    return true;
  };

  const setDirectorDocument = async (
    directorDocument: DirectorDocument
  ): Promise<boolean> => {
    setApplicationData({
      ...applicationData,
      directorDocument,
    });
    try {
      await submitDirectorDocument(directorDocument);
    } catch (err) {
      return false;
    }
    return true;
  };

  const setPrimaryContact = async (
    primaryContact: Contact
  ): Promise<boolean> => {
    setApplicationData({
      ...applicationData,
      primaryContact,
    });
    try {
      await submitPrimaryContact(primaryContact);
    } catch (err) {
      return false;
    }
    return true;
  };

  const setSettlementDetails = async (settlementModule: {
    settlementDetails: SettlementDetails;
    settlementDocument: SettlementDocument;
  }): Promise<boolean> => {
    setApplicationData({
      ...applicationData,
      settlementDetails: settlementModule.settlementDetails,
      settlementDocument: settlementModule.settlementDocument,
    });
    try {
      await submitSettlementModule(settlementModule);
      getMerchantProfile(false);
    } catch (err) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    async function initialLoad(): Promise<void> {
      try {
        if (history?.location?.state?.redirect) {
          getMerchantProfile();
        }
        if (merchantProfile) {
          const statusesToGetDataFrom = [
            MerchantProfileStepStatus.InProgress,
            MerchantProfileStepStatus.Completed,
          ];
          const statusesToContinueFrom = [
            MerchantProfileStepStatus.NotStarted,
            MerchantProfileStepStatus.InProgress,
          ];

          if (
            statusesToGetDataFrom.includes(merchantProfile.director) &&
            merchantProfile.contact === MerchantProfileStepStatus.NotStarted
          ) {
            const directorDetails =
              await MerchantProfileFunctions.getDirectorDetails();
            setApplicationData({ ...applicationData, directorDetails });
          }
          if (
            merchantProfile.director === MerchantProfileStepStatus.NotStarted
          ) {
            setApplicationStep(MerchantProfileStep.directorDetails);
            return;
          }
          if (
            merchantProfile.director === MerchantProfileStepStatus.InProgress
          ) {
            setApplicationStep(MerchantProfileStep.directorDocument);
            return;
          }

          if (statusesToGetDataFrom.includes(merchantProfile.contact)) {
            const { directorDetails, primaryContactDetails } =
              await MerchantProfileFunctions.getPrimaryContact();
            setApplicationData({
              directorDetails,
              primaryContact: primaryContactDetails,
            });
          }
          if (statusesToContinueFrom.includes(merchantProfile.contact)) {
            setApplicationStep(MerchantProfileStep.primaryContact);
            return;
          }

          if (
            merchantProfile.settlement === MerchantProfileStepStatus.InProgress
          ) {
            const settlementDetails =
              await MerchantProfileFunctions.getSettlementDetails();
            setApplicationData({ ...applicationData, settlementDetails });
          }
          if (statusesToContinueFrom.includes(merchantProfile.settlement)) {
            setApplicationStep(MerchantProfileStep.settlementDetails);
            return;
          }

          if (merchantProfile.profile.submitted) {
            redirectToDashboard();
          }
        } else {
          history.push(PageRoute.Home);
        }
      } catch (err) {
        setApplicationData(null);
      }
    }

    initialLoad();
  }, []);

  return (
    <>
      <div css={styles.container}>
        {applicationStep === MerchantProfileStep.loadingProfile && <Spinner />}
        {applicationStep === MerchantProfileStep.directorDetails && (
          <DirectorDetailsModule
            applicationData={applicationData}
            callback={setDirectorDetails}
          />
        )}
        {applicationStep === MerchantProfileStep.directorDocument && (
          <DirectorDocumentModule
            applicationData={applicationData}
            callback={setDirectorDocument}
            back={prevStep}
          />
        )}
        {applicationStep === MerchantProfileStep.primaryContact && (
          <PrimaryContactModule
            applicationData={applicationData}
            callback={setPrimaryContact}
            back={prevStep}
          />
        )}
        {applicationStep === MerchantProfileStep.submitted && (
          <MuiGrid
            container
            xs={12}
            direction="row"
            css={styles.submittedContainer}
          >
            <MuiGrid item xs={12} md={4}>
              <img
                src={Constants.assets.pictorials.success}
                alt="Success icon"
              />
            </MuiGrid>
            <MuiGrid item xs={12} md={8} css={styles.submittedText}>
              <h2>You&lsquo;ve submitted your application!</h2>
              <p css={css.blinkingText}>Please wait...</p>
            </MuiGrid>
          </MuiGrid>
        )}
        {applicationStep === MerchantProfileStep.settlementDetails && (
          <SettlementDetailsModule
            applicationData={applicationData}
            // TODO: Fix typing
            // @ts-ignore
            callback={setSettlementDetails}
          />
        )}
      </div>
    </>
  );
};

export default ApplicationPage;
