import { css } from '@emotion/react';
import { theme } from 'global';

export const bodyContainer = (isDrawerOpen) => css`
  display: flex;
  height: calc(100% - var(--header-height));

  --drawer-width: ${isDrawerOpen ? 280 : 72}px;

  // let him cook
  // this means usage of position: fixed within the page components will be caught by this component
  // crucial for PageActions to remain fixed when the page scrolls
  transform: rotate(0deg);
`;

export const loading = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 420px;
  padding: 16px;
  height: calc(100vh - 56px);
`;

export const loginErrorAlert = css`
  margin: 0 auto;
  min-width: 320px;
  max-width: 500px;
  padding: 12px 16px;
`;

export const cacheHelper = css`
  margin: -56px auto 0;
`;

export const textButton = css`
  vertical-align: inherit;
  text-decoration: underline;
  color: inherit;
`;

export const main = css`
  flex-grow: 1;
  background-color: ${theme.colors.neutral[0]};
  width: 100%;
  padding: 2rem;
  scrollbar-gutter: stable both-edges;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: ${theme.breaks.lg - 1}px) {
    padding: 1rem;
  }

  @media screen and (max-width: ${theme.breaks.md - 1}px) {
    height: 100%;
    max-height: -webkit-fill-available;
  }
`;

export const fullPageComm = css`
  max-width: 480px;
  margin: 0 auto;
`;

export const appBar = css`
  position: fixed;
`;

export const logout = css`
  font-size: 0.86em !important;
  vertical-align: bottom;
`;
