import { css } from '@emotion/react';
import { theme } from 'global';

export const categoryContainer = css`
  padding: 32px 0;
  border-top: 1px solid ${theme.colors.neutral[200]};
  color: ${theme.colors.neutral[600]} !important;

  &:first-of-type {
    padding: 12px 0 32px;
    border-top: unset;
  }
`;

export const titleCount = css`
  color: ${theme.colors.primary.medium};
`;

export const subCategory = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ${theme.breaks.md}px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const categoryHeading = css`
  margin: 0 0 16px;
  ${subCategory};
`;
