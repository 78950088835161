export enum MerchantRoleGuid {
  Admin = '795074BE-5868-4859-8779-E71544AC27A7',
  AdminV3 = 'B539DAC7-A445-4F1C-AF71-6FA6428BBD43',
  CustomerSupportFantastic = 'CD2055A3-6B80-44E4-B7D1-AAC8A7FACF77',
  FranchiseManager = '3B342955-4DBA-4647-A5D4-44646B102562',
  Manager = 'E7999DC2-591D-4ABE-B963-892A11FE4866',
  ManagerJB = '3CC8604A-F2EB-4DC7-89BB-1B0A5003FD01',
  MarketingUser = 'BF28B711-E94E-4C22-B806-F987282157FF',
  MerchantBranchManagerCanRefund = '23C34E1F-6BB9-494D-9C5A-2FE8271AD2B9',
  MerchantBranchManagerHoneyBirdette = '45387552-16D8-4515-8498-DA340FC4C163',
  MerchantBranchManagerLimitedOrderUpdates = '3F3ED610-0FB5-427F-84A0-376B7A269525',
  MerchantBranchManagerTripADeal = 'e3686b1d-e450-4fcb-8ab9-77fa20ffac84',
  MerchantBranchUser = 'A2F7C934-A52A-43D3-860C-3A4707685337',
  MerchantBranchUserCrazyDomains = '96f40078-a43e-4027-a0c2-57478a5ed293',
  MerchantBranchUserGlamaCo = '0f1b73a4-fe64-4454-a2f3-bc57f50e540f',
  MerchantBranchUserLube = 'D5F24F88-49C1-45DC-877B-B5F3EF150D39',
  MerchantWebManager = '8AAAF18B-6F88-4A93-9449-F8B89FEDA43A',
  MerchantWebManagerCanRefund = '980B6786-13BE-4036-8831-519FD4A0426B',
  MerchantWebManagerRefundOnly = 'E6AA020A-1DEB-45F9-A781-F03B330649FD',
  MerchantWebUser = '6551947B-13A7-4FDE-811F-B40D59FB6371',
  ReportingUser = '771EAB08-4C00-432A-802A-76238EDA8428',
  StoreUser = 'F67CF4DC-6584-43CD-B3A7-5F2966CE13F5',
  StoreUserJB = 'FB95C9D3-DA55-4C03-AD05-0C994F0098F6',
  StoreUserKitchenConnection = '8600A0B9-ADED-406E-A034-2B9EECA585D4',
  StoreUserKmart = '738D6012-25E6-45A8-85FA-36A519B817ED',
  StoreUserFantastic = '68ec1ce6-3326-4ac3-b42c-91722bcf837e',
  SuperUser = '74156AEF-9CC9-43E9-8629-B16E3689BBF1',
  WebManager = 'CA11F9FA-53DD-4255-8D8A-DB6D1D97DECD',
}
