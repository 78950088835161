/** @jsxImportSource @emotion/react */
import { TableCell as MuiTableCell } from '@material-ui/core';
import { Widgets } from '@zip/business-components';
import * as Icons from '@zip/react-icons/fearless';
import { Tooltip } from 'components';
import { css, theme } from 'global';
import { FC } from 'react';
import { quickActionFormatter } from 'utils';
import * as styles from './QuickActionRow.styles';
import { QuickActionRowProps } from './QuickActionRowProps';

const QuickActionRow: FC<QuickActionRowProps> = ({
  Icon,
  quickAction,
  isChecked,
  disabled = false,
  onChange,
}) => {
  const handleCheck = (e, checked): void => onChange(quickAction, checked);

  return (
    (Icon && (
      <>
        <MuiTableCell size="small" align="center">
          <Icons.DotsNine
            style={{
              width: 24,
              height: 24,
            }}
          />
        </MuiTableCell>
        <MuiTableCell size="small" css={styles.fullWidthCell}>
          <span>
            <Icon css={styles.icon} fill={theme.colors.primary.medium} />{' '}
            <span className="body1" css={css.noMargin}>
              {quickActionFormatter(quickAction)}
            </span>
          </span>
        </MuiTableCell>
        <MuiTableCell size="small" align="right">
          {disabled ? (
            <Tooltip
              content={disabled && 'This action cannot be removed'}
              arrow
              placement="bottom-end"
              maxWidth={212}
              dense
            >
              <Widgets.Checkbox
                label=""
                checked={isChecked}
                onChange={handleCheck}
                disabled={disabled}
                css={styles.checkbox(disabled)}
              />
            </Tooltip>
          ) : (
            <Widgets.Checkbox
              label=""
              checked={isChecked}
              onChange={handleCheck}
              css={styles.checkbox(false)}
            />
          )}
        </MuiTableCell>
      </>
    )) ||
    null
  );
};

export default QuickActionRow;
