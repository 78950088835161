/** @jsxImportSource @emotion/react */
import { Banners, BasePage, Divider } from 'components';
import { useMerchantData } from 'contexts';
import { MerchantProfileStepStatus, PageRoute } from 'enums';
import { css, isProduction, isSandbox } from 'global';
import {
  DashboardOverviewModule,
  DashboardTilesModule,
  PromoCarousel,
} from 'modules';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { PageProps } from 'types';
import * as styles from './DashboardPage.styles';

const DashboardPage: FC<PageProps> = () => {
  const { merchantProfile, shouldShowOverview, profileData, isAdmin } =
    useMerchantData();
  const { push } = useHistory();

  const areSettlementDetailsCompleted =
    merchantProfile?.settlement === MerchantProfileStepStatus.Completed;

  // show SettlementDetails banner to Admin users if settlement details are missing
  const settlementDetailsRequired =
    (merchantProfile && !areSettlementDetailsCompleted) ||
    (isProduction &&
      merchantProfile &&
      !areSettlementDetailsCompleted &&
      !profileData?.merchantData?.hasSettlementDetails &&
      isAdmin);

  const showApplicationCompletedBanner =
    merchantProfile && !isProduction && areSettlementDetailsCompleted;

  const getGreeting = (): string => {
    const hour: number = new Date().getHours();
    if (hour <= 11) return 'Good morning';
    if (hour <= 16) return 'Good afternoon';
    else return 'Good evening';
  };

  return (
    <BasePage title={null} variant="layout" fullWidth>
      {settlementDetailsRequired && (
        <Banners.Notice
          xs={12}
          css={styles.merchantProfileGrid}
          title="Next, add settlement details"
          content={
            isProduction
              ? 'Submit settlement details to activate your merchant account.'
              : 'Submit settlement details to finalise your application.'
          }
          action={(): void => push(PageRoute.Application)}
          actionLabel="Add details"
        />
      )}
      {showApplicationCompletedBanner && (
        <Banners.Notice
          xs={12}
          css={styles.merchantProfileGrid}
          title="Application completed"
          content={
            <p css={css.noBtmMrgn}>
              Application updates will come via email in the next 1-2 days. For
              any questions, call us on{' '}
              <a href="tel:0282942345">02 8294 2345</a>.
            </p>
          }
        />
      )}
      {!isSandbox && (
        <>
          <PromoCarousel />
          <Divider top={8} bottom={24} />
        </>
      )}
      <p className="heading1" css={css.noTopMrgn}>
        {getGreeting()}
      </p>
      {(shouldShowOverview() && <DashboardOverviewModule />) || (
        <DashboardTilesModule />
      )}
    </BasePage>
  );
};

export default DashboardPage;
