// These ID correspond with merchant-relevant Zendesk category IDs found at help.zip.co/hc/
export enum HelpCentreCategories {
  AboutZip = 360000129956,
  AccountUpdates = 4407845157775,
  Application = 4407845140495,
  Customers = 4407853482255,
  Dashboard = 4407845141391,
  Marketing = 4410370939791,
  Orders = 4407845149839,
  Training = 4408239686927,
}
