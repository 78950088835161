/** @jsxImportSource @emotion/react */
import { Grid as MuiGrid, Paper as MuiPaper } from '@material-ui/core';
import { HighlightTag } from 'components';
import { css, theme } from 'global';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as styles from './Tile.styles';
import { TileProps } from './TileProps';

const Tile: FC<TileProps> = ({
  Icon,
  title,
  subtitle,
  path,
  onClick,
  disabled = false,
  fullWidth = false,
  tag = undefined,
  tagExpiry = undefined,
  ...props
}) => {
  const history = useHistory();
  function handleClick(): void {
    if (disabled) {
      return;
    }
    if (path) {
      history.push(path);
    }
    if (onClick) {
      onClick();
    }
  }
  return (
    <MuiGrid
      item
      xs={12}
      sm={fullWidth ? 12 : 6}
      md={fullWidth ? 12 : 4}
      xl={fullWidth ? 12 : 3}
    >
      <MuiPaper
        elevation={0}
        css={styles.card(disabled)}
        onClick={handleClick}
        {...props}
      >
        <div
          css={[
            css.flex('row', 'space-between', 'center'),
            styles.iconContainer,
          ]}
        >
          <Icon
            css={styles.icon(disabled)}
            fill={theme.colors.primary.medium}
          />
          {tag && <HighlightTag label={tag} expiryDate={tagExpiry} />}
        </div>
        <h2 css={css.noTopMrgn}>{title}</h2>
        <p css={[css.noTopMrgn, css.noBtmMrgn]}>{subtitle}</p>
      </MuiPaper>
    </MuiGrid>
  );
};

export default Tile;
