import { css } from '@emotion/react';

import { css as globalCss, theme } from 'global';

const tableCell = css`
  padding: 16px 24px;
  height: 56px;
  color: ${theme.colors.primary.dark};
  font-size: ${theme.fontSizes.body2};
  line-height: ${theme.lineHeights.body2};
  letter-spacing: ${theme.letterSpacings.body2};

  ${globalCss.ellipsisOverflow}

  @media screen and (max-width: 1395px) {
    padding: 16px;
  }
`;

export const tableHead = css`
  > .MuiTableRow-root {
    background-color: ${theme.colors.neutral[0]};

    & > .MuiTableCell-root {
      border-bottom: none;
      ${tableCell}
    }
  }
`;

export const tableBody = css`
  > .MuiTableRow-root {
    & > .MuiTableCell-root {
      ${tableCell}
    }
  }
`;

export const endMessage = css`
  border: none;
  text-align: center;
  > span {
    color: ${theme.colors.neutral[500]};
  }
`;
