import { css } from '@emotion/react';
import { css as globalCss, theme } from 'global';

export const container = css`
  padding: 32px;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    padding: 24px 16px;
  }
`;

export const seeAll = css`
  font-weight: ${theme.fontWeights.normal};
`;

export const noResults = css`
  text-align: center;
  color: ${theme.colors.neutral[500]} !important;
`;

export const nameCell = css`
  ${globalCss.ellipsisOverflow}
  max-width: 200px;

  @media screen and (max-width: ${theme.breaks.lg -
    1}px) and (min-width: 1233px) {
    max-width: 134px;
  }
`;
