/** @jsxImportSource @emotion/react */
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  ClickAwayListener as MuiClickAwayListener,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
} from '@material-ui/core';
import * as Icons from '@zip/react-icons/fearless';
import { FC, useState } from 'react';
import * as styles from './DrawerExpandableItem.styles';
import { DrawerExpandableItemProps } from './DrawerExpandableItemProps';

const DrawerExpandableItem: FC<DrawerExpandableItemProps> = ({
  id,
  item,
  innerSelectedItem,
  setInnerSelectedItem,
  autoSelectOnOpen = false,
}): React.ReactElement => {
  const childIsSelected = Boolean(
    item?.children?.find((child) => child === innerSelectedItem)
  );
  const [isMenuExpanded, setIsMenuExpanded] =
    useState<boolean>(childIsSelected);

  const handleClickAway = (): void => {
    if (innerSelectedItem === item || childIsSelected) {
      return;
    }

    setIsMenuExpanded(false);
  };

  const handleExpandableClick = (): void => {
    setIsMenuExpanded(!isMenuExpanded);
    if (item?.children?.length > 0 && autoSelectOnOpen) {
      setInnerSelectedItem(item?.children?.[0], true);
    }
  };

  return (
    <MuiClickAwayListener onClickAway={handleClickAway}>
      <MuiAccordion css={styles.accordian} expanded={isMenuExpanded}>
        <MuiAccordionSummary
          expandIcon={<Icons.ChevronDown width="16" height="16" />}
          IconButtonProps={{ disableRipple: true }}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
          css={styles.accordianSummary(
            innerSelectedItem?.value === item.value,
            isMenuExpanded
          )}
          onClick={handleExpandableClick}
        >
          {item.text}
        </MuiAccordionSummary>
        <MuiAccordionDetails css={styles.accordianDetails} id={`${id}-content`}>
          {item?.children?.map((child) => (
            <MuiListItem
              button
              id={`${id}-${child.value?.toLowerCase()}`}
              key={child.text.toLowerCase()}
              css={styles.listItem(innerSelectedItem?.value === child.value)}
              onClick={(): void => {
                setInnerSelectedItem(child, false);
                if (child.onClick) {
                  child.onClick();
                }
              }}
              disableRipple
            >
              <MuiListItemText
                primary={child.text}
                css={styles.listItemText(true)}
              />
            </MuiListItem>
          ))}
        </MuiAccordionDetails>
      </MuiAccordion>
    </MuiClickAwayListener>
  );
};

export default DrawerExpandableItem;
