import { css } from '@emotion/react';

import { theme } from 'global';

export const fullWidthCell = css`
  width: 100%;
  & > span {
    display: flex;
    align-items: center;
  }
`;

export const icon = css`
  width: 32px;
  height: 32px;
  background-color: ${theme.colors.primary.lightest};
  padding: 6px;
  border-radius: 25px;
  margin-right: 12px;
`;

export const checkbox = (isDisabled = false) => css`
  > .MuiIconButton-root.Mui-checked > .MuiIconButton-label > svg > rect {
    fill: ${isDisabled
      ? theme.colors.neutral[300]
      : theme.colors.primary.medium};
  }
`;
