import { css } from '@emotion/react';

import { theme } from 'global';

export const h4 = css`
  margin-top: 26px;
`;

export const newSection = css`
  margin-top: 37px;
`;

export const p = css`
  margin: 8px 0 14px;
`;

export const titleBar = css`
  margin-bottom: 24px;
`;

export const helperText = css`
  > .MuiFormHelperText-root {
    margin-bottom: -10px;

    &.Mui-error {
      margin-bottom: -20px;
    }
  }
`;

export const select = css`
  margin: 15px 0 !important;
  z-index: 500;

  > div > div {
    height: 1.1876em;
  }

  ${helperText}
`;

export const textfield = css`
  width: 100%;
  margin: 15px 0 !important;

  ${helperText}
`;

export const idDocType = css`
  margin: 24px 0 !important;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    margin: 42px 0 !important;
  }
`;

export const licenceDetails = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${theme.breaks.xs}px) {
    flex-direction: column;
  }
`;

export const licenceStateSelect = css`
  min-width: 120px !important;
  margin-right: 8px !important;
`;

export const radioGroupItem = css`
  font-size: 1em !important;
  font-weight: 500;
  color: ${theme.colors.primary.dark};
  margin: 0;
`;

export const consentText = css`
  margin-top: 52px;
`;
