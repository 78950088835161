/** @jsxImportSource @emotion/react */
import { Buttons } from '@zip/business-components';
import moment from 'moment';
import { arrayOf, bool, exact, number, string } from 'prop-types';
import { FunctionComponent, useEffect, useState } from 'react';
import { currencyFormatter } from 'utils';
import * as styles from './InstalmentScheduleModule.styles';
import {
  FilteredPayments,
  IPayment,
  InstalmentScheduleModuleProps,
  PaymentProps,
  PaymentScheduleProps,
} from './InstalmentScheduleModule.types';

// How many leading payments to show regardless of `expanded` state
const X_LEADING_PAYMENTS = 2;

const filtered = (payments: IPayment[]): FilteredPayments => {
  // The payments we always show, regardless of `expanded` state
  const range = [...Array(X_LEADING_PAYMENTS).keys(), payments.length - 1];

  return {
    count: payments.filter((payment, i) => !range.includes(i)).length,
  };
};

const Payment: FunctionComponent<PaymentProps> = ({
  amount,
  date,
  isLoading,
  label,
  instalmentNumber,
  childPayment,
}) => (
  <li>
    <span
      css={styles.amount(
        isLoading,
        instalmentNumber,
        childPayment?.amount > 0 ? 115 : 34
      )}
    >
      <span css={isLoading && styles.mask}>
        {amount >= 0 && childPayment?.amount > 0
          ? currencyFormatter(
              Math.round(amount * 100) / 100 +
                Math.round(childPayment.amount * 100) / 100
            )
          : currencyFormatter(amount)}
      </span>
    </span>
    <span css={styles.description}>
      <small css={isLoading && styles.mask}>
        {date && `Due ${moment(date).format('MMM YYYY')}`}
      </small>
      {label && <small css={styles.label}>{label}</small>}
    </span>
    {!isLoading && childPayment?.amount && childPayment.amount > 0 && (
      <div css={styles.breakdown}>
        <p className="body3">
          <b>Breakdown</b>
        </p>
        <p className="body4">
          <b>{currencyFormatter(amount)}</b> monthly instalment
        </p>
        <p className="body4">
          <b>{currencyFormatter(childPayment.amount)}</b> {childPayment.label}
        </p>
      </div>
    )}
  </li>
);

const PaymentSchedule: FunctionComponent<PaymentScheduleProps> = ({
  payments,
  establishmentFee,
  isLoading,
  isEmpty,
  ...props
}) => {
  const [filteredPayments, set] = useState(filtered(payments));
  const finalPayment = payments[payments.length - 1];
  const [firstPayment] = payments;

  useEffect(() => {
    set(filtered(payments));
  }, [payments]);

  return (
    <div {...props}>
      <ul css={styles.payments(isEmpty)}>
        {payments.length >= 1 && (
          <Payment
            amount={firstPayment.amount}
            date={firstPayment.date}
            isLoading={isLoading}
            key={`${firstPayment.amount}-${0}`}
            label={firstPayment.label}
            instalmentNumber={0}
            childPayment={
              establishmentFee &&
              establishmentFee > 0 && {
                amount: establishmentFee,
                date: firstPayment.date,
                label: 'one-off establishment fee',
              }
            }
          ></Payment>
        )}
        {payments.length > 2 &&
          payments
            .slice(1, Math.min(X_LEADING_PAYMENTS, payments.length - 1))
            .map(({ amount, date, label }, i) => (
              <Payment
                amount={amount}
                date={date}
                isLoading={isLoading}
                // We re-render the entire list when the data changes so it's safe to use the array index as a key here
                // eslint-disable-next-line react/no-array-index-key
                key={`${amount}-${i + 1}`}
                label={label}
                instalmentNumber={i + 1}
              />
            ))}
        {!isLoading && filteredPayments.count > 0 && (
          <li>
            <Buttons.Text className="body2" css={styles.paymentSummary}>
              + {filteredPayments.count} monthly instalment
              {filteredPayments.count > 1 ? 's' : ''}
            </Buttons.Text>
          </li>
        )}
        {payments.length > 1 && (
          <Payment
            amount={finalPayment.amount}
            date={finalPayment.date}
            instalmentNumber={payments.length - 1}
            isLoading={isLoading}
            label={finalPayment.label}
          />
        )}
      </ul>
    </div>
  );
};

const InstalmentScheduleModule: FunctionComponent<
  InstalmentScheduleModuleProps
> = ({
  instalmentAmount,
  instalmentCount,
  establishmentFee,
  isLoading,
  isEmpty,
}) => {
  const payments: IPayment[] = [];
  let installmentDate = moment().add(1, 'month').toDate();
  if (!(instalmentAmount > 0) || !(instalmentCount > 0) || isEmpty) {
    instalmentCount = 3;
    instalmentAmount = 0;
    establishmentFee = 0;
    isEmpty = true;
  }

  for (let i = 0; i < instalmentCount; i++) {
    const payment: IPayment = {
      amount: isEmpty ? 0 : instalmentAmount,
      date: installmentDate.toISOString(),
    };

    payments.push(payment);

    installmentDate = moment(installmentDate).add(1, 'month').toDate();
  }

  return (
    <div>
      <p className="body1" style={{ marginBottom: '8px' }}>
        {isEmpty ? 'I' : `${instalmentCount} i`}nterest-free instalment
        {instalmentCount > 1 ? 's' : ''}
      </p>
      <PaymentSchedule
        payments={payments}
        establishmentFee={establishmentFee}
        isLoading={isLoading}
        isEmpty={isEmpty}
      />
    </div>
  );
};

Payment.propTypes = {
  amount: number.isRequired,
  date: string.isRequired,
  instalmentNumber: number,
  isLoading: bool,
  label: string,
};

PaymentSchedule.propTypes = {
  isLoading: bool,
  payments: arrayOf(
    exact({
      amount: number.isRequired,
      date: string.isRequired,
      isFee: bool,
      label: string.isRequired,
    }).isRequired
  ).isRequired,
};

Payment.defaultProps = {
  instalmentNumber: undefined,
  isLoading: false,
  label: undefined,
};

PaymentSchedule.defaultProps = {
  isLoading: false,
};

export default InstalmentScheduleModule;
