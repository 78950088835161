import { css } from '@emotion/react';

import { theme } from 'global';

const radius = '20px';

export const button = (count) => css`
  background-color: ${theme.colors.white};
  border: 1px solid
    ${count ? theme.colors.primary.medium : theme.colors.neutral[300]};
  border-radius: ${radius};
  height: 40px;
  font-size: ${theme.fontSizes.body1};
  font-weight: ${theme.fontWeights.body1};
  line-height: ${theme.lineHeights.body1};
  padding: 9px 16px;
  color: ${theme.colors.primary.dark};
  justify-content: center;
  min-width: fit-content;
  margin-bottom: 8px;
  > span > span#badge {
    background-color: ${theme.colors.neutral[50]};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:focus {
    border: 1px solid ${theme.colors.primary.medium};
    box-shadow: 0 0 4px ${theme.colors.primary.medium}D9;
    background-color: ${theme.colors.white};
  }

  &:active {
    box-shadow: unset;
    background-color: ${theme.colors.white};
  }

  &:hover {
    background-color: ${theme.colors.neutral[0]};
  }

  &.Mui-disabled {
    opacity: 0.5;
  }

  &.Mui-disabled,
  :hover.Mui-disabled {
    color: ${theme.colors.neutral[600]};
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`;

export const label = css`
  font-weight: ${theme.fontWeights.body1};
`;
