/** @jsxImportSource @emotion/react */
import { withOptimizely } from '@optimizely/react-sdk';
import { useEffect } from 'react';

const OptimizelyContent = (props): JSX.Element => {
  const { optimizely } = props;
  const handleClick = (): void => optimizely.track('opened_url');

  useEffect(() => optimizely.track('banner_rendered'));

  return (
    <a
      href="https://developers.zip.co/docs/default-web-assets"
      target="_blank"
      rel="noopenner noreferrer"
      onClick={handleClick}
      className="body2"
    >
      Learn more
    </a>
  );
};

export const OptimizelyBannerContent = withOptimizely(OptimizelyContent);

export default OptimizelyBannerContent;
