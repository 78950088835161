import { Selectable } from '@zip/business-components';
import {
  CliRequestStatus,
  CustomerStatus,
  DateFrequency,
  OrderStatus,
  ProductClassification,
  QuickActionType,
} from 'enums';
import _ from 'lodash';
import { Address, MerchantProduct } from 'types';

export function dateFormatter(
  timestamp: string,
  dateStyle: 'month' | 'week' | 'day' | 'hour' | 'full' = null,
  short = true
): string {
  const date = new Date(timestamp);
  if (date.toString() !== 'Invalid Date') {
    switch (dateStyle) {
      case 'month':
        return new Intl.DateTimeFormat('en-AU', {
          month: short ? 'short' : 'long',
          year: short ? '2-digit' : 'numeric',
        }).format(date);
      case 'week':
        return `Start date ${new Intl.DateTimeFormat('en-AU', {
          day: '2-digit',
          month: short ? 'short' : 'long',
          year: short ? '2-digit' : 'numeric',
        }).format(date)}`;
      case 'day':
        return new Intl.DateTimeFormat('en-AU', {
          day: '2-digit',
          month: short ? 'short' : 'long',
          year: short ? '2-digit' : 'numeric',
        }).format(date);
      case 'hour':
        return new Intl.DateTimeFormat('en-AU', {
          hour: 'numeric',
          hour12: true,
        }).format(date);
      case 'full':
        return new Intl.DateTimeFormat('en-AU', {
          day: '2-digit',
          month: short ? 'short' : 'long',
          year: short ? '2-digit' : 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }).format(date);
      default:
        return timestamp;
    }
  }
  return 'Invalid Date';
}

/**
 * adopted sv-SE because it is the currently the most compatible and conformant to the current ISO 8601 format
 */
export function dateToIso(date: Date): string {
  const formatter = Intl.DateTimeFormat('sv-SE', {
    timeZone: 'Australia/Sydney',
  });

  return formatter.format(new Date(date));
}

export function currencyFormatter(
  amount = 0,
  showDecimals: boolean = true,
  currency = 'AUD',
  suffix?: string
): string {
  if (showDecimals) {
    const formatter = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency,
    });

    return `${formatter.format(amount)}${suffix || ''}`;
  } else {
    const formatter = new Intl.NumberFormat('en-AU');

    return `$${formatter.format(_.round(amount))}${suffix || ''}`;
  }
}

export function toTitleCase(str: string): string {
  if (str === undefined || typeof str !== 'string') return String(str);

  return str?.replace(/\w\S*/g, function titleCase(txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
  });
}

export function statusFormatter(status: string): string {
  switch (String(status)?.toLowerCase()) {
    case OrderStatus.ContractPending:
      return 'Contract pending';
    case OrderStatus.UnderReview:
    case CustomerStatus.Refer1:
      return 'Under review';
    case OrderStatus.InProgress:
    case CustomerStatus.ApplicationInProgress:
    case CliRequestStatus.InProgress:
      return 'In progress';
    case OrderStatus.NotStarted:
      return 'Not started';
    case OrderStatus.PartiallyCaptured:
      return 'Partially captured';
    case CustomerStatus.ApplicationCompleted:
      return 'Completed';
    case CustomerStatus.AllInvitedCustomers:
      return 'All invited';
    case CliRequestStatus.AllCliRequests:
      return 'All requests';
    default:
      return toTitleCase(status);
  }
}

export function selectFormatter(
  array: unknown[],
  labelKey: string,
  valueKey: string
): Selectable[] {
  return array?.map((item) => ({
    label: `${item[labelKey]}`,
    value: `${item[valueKey]}`,
  }));
}

export function productFormatter(
  productClassification: ProductClassification
): string {
  switch (productClassification) {
    case ProductClassification.ZipMoney:
      return 'Zip Money';
    case ProductClassification.ZipPay:
      return 'Zip Pay';
    case ProductClassification.ZipBusinessTradePlus:
      return 'ZB Trade Plus';
    case ProductClassification.ZipBusinessTrade:
      return 'ZB Trade';
    case ProductClassification.ZipPlus:
      return 'Zip Plus';
    default:
      return ProductClassification[productClassification];
  }
}

export function splitTitleCase(stringToConvert: string): string {
  /*  
  regex finds capital letters and numbers, splits the words into an array of strings, 
  then replaces capital letters of all but first word with lowercase variants 
  */
  return stringToConvert
    .match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g)
    .map((word, i) => (i > 0 ? word?.toLowerCase() : word))
    .join(' ');
}

export function quickActionFormatter(quickActionType: QuickActionType): string {
  switch (quickActionType) {
    case QuickActionType.SearchOrders:
      return 'Order search';
    case QuickActionType.SearchCustomers:
      return 'Customer search';
    case QuickActionType.CreditLimitIncreaseInvite:
      return 'Credit limit increase';
    default:
      return splitTitleCase(quickActionType);
  }
}

export function dateFrequencyFormatter(
  timestampToFormat: string,
  frequency: DateFrequency,
  short = true
): string {
  switch (frequency) {
    case DateFrequency.Hourly:
      return dateFormatter(timestampToFormat, 'hour', short);
    case DateFrequency.Daily:
      return dateFormatter(timestampToFormat, 'day', short);
    case DateFrequency.Weekly:
      return dateFormatter(timestampToFormat, 'week', short);
    case DateFrequency.Monthly:
      return dateFormatter(timestampToFormat, 'month', short);
    default:
      return '';
  }
}

export function formatOneLineAddress(address: Address): string {
  return (
    address &&
    `${address?.unitNumber ? `${address?.unitNumber}/` : ''}${
      address?.streetNumber ?? ''
    } ${address?.streetName}, ${address?.suburb}, ${address?.state} ${
      address?.postcode
    }, ${address?.country}`
  );
}

export function getProductDescription(product: MerchantProduct): string {
  switch (product.productClassification) {
    case ProductClassification.ZipPay:
      return 'Zip Pay - Always interest free';
    case ProductClassification.ZipPlus:
      return 'Zip Plus - Low or no interest charged';
    case ProductClassification.ZipMoney:
    default:
      return product.interestFreeMonths > 0
        ? `Zip Money - ${product.interestFreeMonths} months interest free`
        : productFormatter(product.productClassification);
  }
}

export function formatNumberOfDays(numberOfDays: number): string {
  switch (true) {
    case numberOfDays < 0:
      return numberOfDays.toString();
    case numberOfDays === 0:
      return 'Today';
    case numberOfDays === 1:
      return '1 day';
    default:
      return `${numberOfDays} days`;
  }
}
