/** @jsxImportSource @emotion/react */
import {
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
} from '@material-ui/core';
import { theme } from 'global';
import { FC } from 'react';
import * as styles from './MenuListItem.styles';
import { MenuListItemProps } from './MenuListItemProps';

const MenuListItem: FC<MenuListItemProps> = ({
  item,
  onClick,
  isDrawerOpen = false,
  selected = false,
}) => {
  return (
    <MuiListItem
      button
      key={item.value}
      css={[styles.listItem(selected), styles.drawerItem(isDrawerOpen)]}
      onClick={onClick}
      disableRipple
      id={`menu-item-${item.value}`}
    >
      <MuiListItemIcon css={styles.listItemIcon(isDrawerOpen, selected)}>
        {item.icon && (
          <item.icon
            width={24}
            height={24}
            fill={
              selected ? theme.colors.primary.medium : theme.colors.neutral[600]
            }
          />
        )}
      </MuiListItemIcon>
      <MuiListItemText
        primary={item.text}
        css={[
          styles.listItemText(isDrawerOpen),
          styles.drawerText(isDrawerOpen),
        ]}
      />
    </MuiListItem>
  );
};

export default MenuListItem;
