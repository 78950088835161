export enum PermissionEnum {
  ApiIntegrationCreate = 'ApiIntegrationCreate',
  ApiIntegrationRead = 'ApiIntegrationRead',
  ApiIntegrationUpdate = 'ApiIntegrationUpdate',
  CompleteOrderExecute = 'CompleteOrderExecute',
  CreateCLIInvitationExecute = 'CreateCLIInvitationExecute',
  CreateInviteExecute = 'CreateInviteExecute',
  CreateOrderExecute = 'CreateOrderExecute',
  CreateOrderForCustomerExecute = 'CreateOrderForCustomerExecute',
  CreatePaymentCodeExecute = 'CreatePaymentCodeExecute',
  CustomersRead = 'CustomersRead',
  CustomS3ReportRead = 'CustomS3ReportRead',
  DisbursementsCreate = 'DisbursementsCreate',
  DisbursementsRead = 'DisbursementsRead',
  EditOrderReference = 'EditOrderReference',
  NotificationsUpdate = 'NotificationsUpdate',
  OrdersRead = 'OrdersRead',
  OverviewRead = 'OverviewRead',
  ReassignCustomerStaffMemberExecute = 'ReassignCustomerStaffMemberExecute',
  ReconciliationRead = 'ReconciliationRead',
  RefundOrderExecute = 'RefundOrderExecute',
  ReportsDashboardRead = 'ReportsDashboardRead',
  ReportsRead = 'ReportsRead',
  SettingsRead = 'SettingsRead',
  SignUpExecute = 'SignUpExecute',
  SignUpRead = 'SignUpRead',
  StoreAdvertising = 'StoreAdvertisingExecute',
  StoreCategoryRead = 'StoreCategoryRead',
  StoreCategoryUpdate = 'StoreCategoryUpdate',
  StoreProfileRead = 'StoreProfileRead',
  StoreProfileUpdate = 'StoreProfileUpdate',
  UserManagementExecute = 'UserManagementExecute',
  ViewAllBranches = 'ViewAllBranchesRead',
}
