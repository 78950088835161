/* eslint-disable @typescript-eslint/ban-types */
import { useSnackbar } from 'contexts';
import { PageAction } from 'enums';
import { logError } from 'utils';

interface ErrorHandlers {
  handleError: (
    errorMessage: string,
    errorEvent: PageAction,
    payload: object
  ) => void;
}

export const useErrorHandler = (): ErrorHandlers => {
  const { error } = useSnackbar();

  const handleError = (
    errorMessage: string,
    errorEvent: PageAction,
    payload: object
  ): void => {
    const formError = errorMessage;
    logError(errorEvent, formError, payload);
    error(formError);
  };

  return {
    handleError,
  };
};

export default useErrorHandler;
